/* eslint-disable no-underscore-dangle */
import { log } from './toolbox/util';
import { RefElement } from 'widgets/toolbox/RefElement';
import EventBusWrapper from 'widgets/toolbox/EventBusWrapper';
import widgetsMgr from './widgetsMgr';
const templateProp = '@@@_template';
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

/**
 * @description save template during webpack HMR
 * @param renderTo rendering element
 * @param template template string
 */
function saveTemplateForHotReload(renderTo: RefElement, template: string): void {
    if (!PRODUCTION) { // save template in element for hot reload
        const tmpEl = renderTo.get();

        if (tmpEl) {
            tmpEl[templateProp] = template;
        }
    }
}

/**
 * @description Find modified element
 * @param nodeOrig element
 * @param routeOrig path to element
 * @returns modified element or false if not found
 */
function getFromRoute(nodeOrig: ChildNode, routeOrig: number[]): ChildNode | false {
    let node = nodeOrig;
    const route = routeOrig.slice();

    while (route.length > 0) {
        if (node && node.childNodes) {
            const c = route.splice(0, 1)[0];

            node = node.childNodes[c];
        } else {
            return false;
        }
    }

    return node;
}

/**
 * @description Callback assigned on diff-dom post hook after applying changes
 * @param action diff-dom action happens with DOM node
 * @param node changed element
 * @param info diff-dom changes object
 * @returns callback
 */
function getDelayedCallback(action: string, node: HTMLElement, info: diffDOM.Info): () => void {
    return () => {
        if (action === 'modifyAttribute') {
            widgetsMgr.removeAttribute(node, info.diff);
            widgetsMgr.addAttribute(node, info.diff);
        } else if (action === 'removeAttribute') {
            widgetsMgr.removeAttribute(node, info.diff);
        } else if (action === 'addAttribute') {
            widgetsMgr.addAttribute(node, info.diff);
        } else {
            throw new Error(`Unknown action "${action}"`);
        }
    };
}

/**
 * @description Diff-dom post applying callback
 * @param info - Diff-dom changes object
 * @param el Element to change
 * @param delayedAttrModification List of modifications that should be used after diffs appliance
 */
function postDiffApplyCallback(info: diffDOM.Info, el: HTMLElement, delayedAttrModification: Array<() => void>): void {
    const { action, name } = info.diff;

    if (['removeAttribute', 'addAttribute', 'modifyAttribute'].includes(action)
        && typeof name === 'string'
        && name.startsWith('data-') // handle only data attr changes
        && info.node instanceof HTMLElement) {
        const node = getFromRoute(el, info.diff.route);

        if (node && node instanceof HTMLElement) {
            const delayedCallback: () => void = getDelayedCallback(action, node, info);

            // data-initialized should be executed at last point
            delayedAttrModification[name === 'data-initialized' ? 'push' : 'unshift'](delayedCallback);
        }
    }

    if ((action === 'addAttribute' || action === 'removeAttribute')
        && info.node.nodeName === 'INPUT'
        && name === 'checked') {
        const node = <HTMLInputElement>info.node;

        if (node.type === 'checkbox' || node.type === 'radio') {
            node.checked = (action === 'addAttribute');
        }
    }
}

/**
 * @description Diff-dom outer diffs filter callback
 * @param t1 - Target element
 */
function filterOuterDiffCallback(t1: diffDOM.NodeObj): void {
    if (t1.attributes && t1.attributes['data-skip-render']) {
        // will not diff childNodes
        t1.innerDone = true;
    }
}

// eslint-disable-next-line no-use-before-define
type WidgetConstructor<T = typeof Widget> = (type: string) => T;

/** Core component to extend for each widget */
/**
 * @category widgets
 * @subcategory framework
 */
class Widget {
    /**
     * @description RefElements related to current widget
     */
    refs: {[key : string] : RefElement} = Object.create(null);

    /**
     * @description state explain widget is shown
     */
    shown: boolean;

    /**
     * @description config from data attributes
     */
    config: {[x: string]: {[key: string]: unknown} |string|number|boolean|null|undefined};

    /**
     * @description functions which executing during destructuring of widget
     */
    disposables: Array<() => void>|undefined;

    /**
     * @description function assigned by WidgetsMgr after constructor call to
     * provide ability for parent widget listen child widget event
     * fired by method `this.emit()`
     */
    parentHandler: (eventName: string, ...args: unknown[]) => void = noop;

    /**
     * @description children widgets
     */
    items: Array<Widget> = [];

    /**
     * @description Widget ID
     */
    id: string | undefined;

    /**
     * @param id - widget id
     * @description Get constructor id for current widget. Used in widgetMgr
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getConstructor: WidgetConstructor = (id) => Widget;

    /**
     * @description Indicate if widget is in progress of refreshing
     */
    isRefreshingWidget = false;

    /**
     * @description Container to cache templates for rendering
     */
    cachedTemplates: {[id: string]: string | undefined} = {};

    /**
     * @description EventBusWrapper instance for event emitting
     */
    protected _eventBus: EventBusWrapper | undefined;

    /**
     * @description Creates self RefElement.js wrapper, add initial states, configuration.
     * @param el DOM element
     * @param config widget config
     */
    constructor(el: HTMLElement, config: Widget['config'] = {}) {
        if (this.refs) { // for type check
            this.refs.self = new RefElement([el]);
        }

        this.config = config;

        if (this.ref('self').attr('id')) {
            this.id = <string> this.ref('self').attr('id');
        }

        if (!this.id && this.config.id) {
            this.id = <string> this.config.id;
        }

        this.shown = !this.config.hidden && !this.ref('self').hasAttr('hidden');

        if (typeof this.config.passEvents === 'string') {
            if (!PRODUCTION) {
                log.warn('Usage of "data-pass-events" has been deprecated. Please take a look "data-forward-to-parent"');
            }

            this.config.passEvents.split(':').forEach(pair => {
                const [methodName, emitEvent] = pair.split('-');

                this[methodName] = (...args) => {
                    this.parentHandler(emitEvent, ...args);
                };
            });
        }
    }

    get length() {
        return 1;
    }

    /**
     * @description call class method provided in argument `name` with arguments `(classContext, value, ...args)` or
     * call `RefElement.data(name, value)` for wrapper widget DOM node to get or set data attributes
     * @param name of class method or data-attribute
     * @param [value] if class has method `name` - will be used as argument, otherwise will be used as `value` to set into data attribute `name`
     * @param [args] if class has method `name` - will be used as argument, otherwise would not be used
     * @returns
     * - execution result of the method specified as `name` argument
     * - if class has no method `name` - get or set data attribute `name` depending on provided or no `value` argument
     */
    data(name: string, value?: unknown, ...args: unknown[]): unknown {
        const classMethod: (v?: unknown, ...a: unknown[]) => unknown = this[name];

        if (typeof classMethod === 'function') {
            return classMethod.call(this, value, ...args);
        }

        return this.ref('self').data(name, value);
    }

    /**
     * @description call class method provided in argument `name` with arguments `...args` if method exists
     *
     * @param name of class method
     * @param [args] if class has method `name` - will be used as arguments
     * @returns result of call (any) or undefined, if method not found
     */
    callIfExists(name: string, ...args: unknown[]): unknown {
        const classMethod = this[name];

        if (typeof classMethod === 'function') {
            return classMethod.call(this, ...args);
        }

        return undefined;
    }

    /**
     * @description Emit widget event that will be listened by parent handler with context of current widget

     * @param eventName name of event
     * @param args argument to pass
     */
    emit(eventName: string, ...args: unknown[]): void {
        this.parentHandler(eventName, this, ...args);
    }

    /**
     * @description Emit widget event that will be listened by parent handler without context of current widget
     * @param eventName name of event
     * @param args argument to pass
     */
    emitWithoutContext(eventName: string, ...args: unknown[]): void {
        this.parentHandler(eventName, ...args);
    }

    /**
     * @description In case if you need to emit/subscribe global event you may get an event bus with this method.
     * @description Get widget's EventBusWrapper instance
     * @returns Instance of EventBusWrapper
     */
    eventBus(): EventBusWrapper {
        if (!this._eventBus) {
            this._eventBus = new EventBusWrapper(this);
            this.onDestroy(() => {
                this._eventBus = undefined;
            });
        }

        return this._eventBus;
    }

    /**
     * @description Merge data-attribute properties to default widget properties (defined in widget javascript file, or extended from parent widget)
     * and returns widget configuration map
     * @returns config widget config
     */
    prefs() {
        return {
            /** is component hidden */
            hidden: false,
            /** class of component during loading */
            classesLoading: 'm-widget-loading',
            /** class of component once component loaded and inited */
            /** id of component */
            id: '',
            // configs form data attributes
            ...this.config
        };
    }

    /**
     * @description Sets preference to the data-attribute properties and widget properties
     * to store widget preference state.
     * @param key - preference key
     * @param value - preference value to set
     */
    setPref(key: string, value: string | number | boolean | { [key: string]: unknown; } | null | undefined) {
        this.ref('self').data(key, String(value));
        this.config[key] = value;
    }

    /**
     * @description This method executed in the end of [Widgets Application Lifecycle,]{@link tutorial-WidgetsApplicationLifecycle.html}
     *  in order to add business logic before initialization is finished.
     */
    init() {
        this.ref('self').removeClass(this.prefs().classesLoading);
    }

    /**
     * @description Get child refElement by key from prefs() or id
     * @param name Id of RefElement or preference key that contains id
     * @returns found RefElement instance or empty RefElement if doesn't exist
     * @protected
     */
    ref(name: string): RefElement {
        const prefsName = this.prefs();
        const prefsValue = prefsName[name];
        let ref : RefElement;

        if (prefsValue) {
            ref = this.refs && this.refs[prefsValue];

            if (ref) {
                return ref;
            }
        }

        ref = this.refs && this.refs[name];

        if (ref) {
            return ref;
        }

        return new RefElement([]);
    }

    /**
     * @description search `refElement` inside of widget by `name`
     * - if `cb` exist - run `cb` with found `refElement` as argument
     * - otherwise return existing state
     *
     * @param name Id of widget/refElement or preference that contain id of widget/refElement
     * @param [cb] callback will be executed if element found
     * @returns true if found `refElement`
     */
    has(name: string, cb?: (arg: RefElement) => void): boolean {
        const ref = this.refs && this.refs[name];

        if (ref) {
            if (cb) {
                cb(ref);
            }

            return true;
        }

        return false;
    }

    /**
     * @description Destroys widgets. Only for internal usage
     *
     * @protected
     */
    destroy() {
        if (this.disposables) {
            this.disposables.forEach(disposable => disposable());
            this.disposables = undefined;
        }

        if (this.items && this.items.length) {
            this.items.forEach(item => {
                if (item && typeof item.destroy === 'function') {
                    item.destroy();
                }
            });
        }

        this.items = [];
        this.refs = {};
    }

    /**
     * @description Attach an event handler function for one or more events to the selected elements.
     * @param eventName ex: 'click', 'change'
     * @param cb callback
     * @param selector CSS selector
     * @param passive is handler passive?
     * @returns dispose functions for each element event handler
     *
     * @protected
     */
    ev<T = HTMLElement, E = Event>(
        eventName: string,
        cb: (this: this, element: T, event: E, ...args: unknown[]) => unknown,
        selector: string | EventTarget = '', passive = true
    ): (() => void)[] {
        let elements: EventTarget[] = [];
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        if (selector instanceof Element || selector === window || selector === window.document) {
            elements = [selector];
        } else if (typeof selector === 'string' && this.refs && this.refs.self) {
            const el = this.refs.self.get();

            if (el) {
                elements = Array.from(el.querySelectorAll(selector));
            }
        } else if (this.refs && this.refs.self) {
            const el = this.refs.self.get();

            if (el) {
                elements = [el];
            }
        }

        return elements.map(element => {
            let fn: EventListener | undefined = <EventListener><unknown> function fn(this: T, event: E, ...args: unknown[]) {
                return cb.apply(self, [this, event, ...args]);
            };

            element.addEventListener(eventName, fn, passive ? { passive: true } : { passive: false });

            const dispose = () => {
                if (fn) {
                    element.removeEventListener(eventName, fn);
                    fn = undefined;
                }
            };

            this.onDestroy(dispose);
            dispose.eventName = eventName;

            return dispose;
        });
    }

    /**
     * @description Assign function to be executed during widget destructuring
     * @param fn function to be executed during destroy
     * @returns called function
     */
    onDestroy(fn: () => void): () => void {
        if (!this.disposables) {
            this.disposables = [];
        }

        this.disposables.push(fn);

        return fn;
    }

    /**
     * @description executed when widget is re-rendered
     */
    onRefresh() {
        this.shown = !this.config.hidden && !this.ref('self').hasAttr('hidden');

        if (this.ref('self').attr('id')) {
            this.id = <string> this.ref('self').attr('id');
        } else if (this.ref('self').data('id')) {
            this.id = <string> this.ref('self').data('id');
        }
    }

    /**
     * @description Search for child component instance and execute callback with this instance as argument
     * @param id of component
     * @param cb callback with widget
     * @returns callback result if element found, otherwise undefined
     */
    getById<T = Widget>(id: string, cb: (args0: T) => unknown): unknown {
        if (id && this.items && this.items.length) {
            for (let c = 0; c < this.items.length; c += 1) {
                const item = this.items[c];

                if (item && item.id === id) {
                    return cb.call(this, <T><unknown> item);
                }
            }
        }

        if (!PRODUCTION) {
            log.warn(`Widget with id "${id}" is not found in the children list of "${this.constructor.name}" `, this);
        }

        return undefined;
    }

    /**
     * Travels over nearest/next level child components
     *
     * @param fn callback
     * @returns arrays of callback results
     */
    eachChild<T = Widget, R = T | void>(fn: (args0: T) => R): R[] {
        if (this.items && this.items.length) {
            return this.items.map(item => {
                return fn(item as unknown as T);
            });
        }

        return [];
    }

    /**
     * @description Hide widget
     * @returns current instance for chaining
     */
    hide(): this {
        if (this.shown) {
            this.ref('self').hide();
            this.shown = false;
        }

        return this;
    }

    /**
     * @description Show widget
     * @returns current instance for chaining
     */
    show(): this {
        if (!this.shown) {
            this.ref('self').show();
            this.shown = true;
        }

        return this;
    }

    /**
     * @description Show or hide widget element
     * @param [display]  Use true to show the element or false to hide it.
     * @returns current instance for chaining
     */
    toggle(display: boolean): this {
        const state = typeof display !== 'undefined' ? display : !this.shown;

        this[state ? 'show' : 'hide']();

        return this;
    }

    /**
     * @description Returns whether the widget is hidden
     * @returns Hidden flag
     */
    isHidden(): boolean {
        return !this.shown;
    }

    /**
     * @description Returns whether the widget is shown
     * @returns Shown flag
     */
    isShown(): boolean {
        return this.shown;
    }

    /**
     * @description This method provides ability to get template from ref element.
     * @param templateRefId - Id of template
     * @param renderTo - Render into element
     * @returns return template string if it was found
     *
     * @protected
     */
    getTemplateByRefID(templateRefId: string, renderTo: RefElement): string | undefined {
        const templateElement = this.ref(templateRefId).get();
        let template;

        if (templateElement) {
            template = templateElement.textContent || templateElement.innerHTML;
        } else if (!PRODUCTION) {
            const tmpEl = renderTo.get();

            if (tmpEl && tmpEl[templateProp]) {
                template = tmpEl[templateProp];
            }
        }

        return template;
    }

    /**
     * @description This method provides ability to dynamically render HTML for widgets.
     * @param templateRefId id of template
     * @param data data to render
     * @param [renderTo] render into element
     * @param [strToRender] pre-rendered template
     * @returns resolved if rendered or rejected if no found template promise
     *
     * @protected
     */
    render(
        templateRefId = 'template',
        data: {[key: string]: unknown} = {},
        renderTo: RefElement = this.ref('self'),
        strToRender = ''
    ): Promise<void> {
        return import(/* webpackChunkName: 'dynamic-render' */ 'mustache').then(d => d.default).then((Mustache) => {
            let template = this.cachedTemplates[templateRefId];

            if (!strToRender && !template) {
                template = this.getTemplateByRefID(templateRefId, renderTo);

                if (template) {
                    this.cachedTemplates[templateRefId] = template;
                    saveTemplateForHotReload(renderTo, template);
                } else if (!PRODUCTION) {
                    log.error(`Unable find template ${templateRefId}`, this);

                    return Promise.reject(new Error(`Unable find template ${templateRefId}`));
                }
            }

            if (data) {
                data.lower = function () {
                    return function (text, render) {
                        return render(text).toLowerCase();
                    };
                };
            }

            const renderedStr = strToRender || Mustache.render(template, data);
            const el = renderTo.get();

            if (el && el.parentNode) {
                // use new document to avoid loading images when diffing
                const newHTMLDocument = document.implementation.createHTMLDocument('diffDOM');
                const diffNode: HTMLElement = newHTMLDocument.createElement('div');

                diffNode.innerHTML = renderedStr;

                return this.applyDiff(el, diffNode);
            } else {
                log.error(`Missing el to render ${templateRefId}`, this);
            }

            return Promise.resolve();
        });
    }

    /**
     * @description Find diff between `el` and `diffNode` and apply diff by `diff-dom`
     * @param el Element before change
     * @param diffNode Changed element to find diff
     * @returns promise that resolved when diff founded and applied
     */
    applyDiff(el: HTMLElement, diffNode: HTMLElement): Promise<void> {
        return import(/* webpackChunkName: 'dynamic-render' */ 'diff-dom/src/index').then(({ DiffDOM }) => {
            const delayedAttrModification: Array<() => void> = [];
            const dd: diffDOM.DiffDOM = new DiffDOM({
                filterOuterDiff: filterOuterDiffCallback,
                postDiffApply: (info: diffDOM.Info) => {
                    postDiffApplyCallback(info, el, delayedAttrModification);
                }
            });

            // Normalize DOM tree before applying diff to prevent infinite loop
            // Infinite loop appear in case when few text nodes became one by one
            el.normalize();
            const diff: Array<diffDOM.Diff> = dd.diff(el, diffNode.firstElementChild);

            if (diff && diff.length) {
                dd.apply(el, diff);
            }

            // report attr modification once app changes are applied
            delayedAttrModification.forEach(action => action());

            if (diff && diff.length) {
                this.eventBus().emit('rendering.applied');
            }
        });
    }
}

export type TWidget = typeof Widget;

export default Widget;
