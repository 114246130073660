/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof BreadCrumbName} BreadCrumbName
 */

export default function (Widget) {
    class BreadCrumbName extends Widget {
        prefs() {
            return {
                ...super.prefs()
            };
        }

        init() {
            this.eventBus().on('product.attribute.select', 'renderBreadCrumbName');
        }

        /**
         * @description renders Breadcrumb Name
         *
         * @param {object} data - product data
         * @param {string} data.productName - product name
         */
        renderBreadCrumbName(data) {
            if (data.productName) {
                this.ref('bcName').setText(data.productName);
            }
        }

        breadcrumbClick() {
            return true;
        }
    }

    return BreadCrumbName;
}
