import { getCookie } from 'widgets/toolbox/cookie';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

const SESSION_ID_TRUNCATE = 'noibu-for-log-center';
/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Exponea Segmentation implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof Noibu} Noibu class
 */
export default function (Widget) {
    /**
     * @class Noibu
     * @augments Widget
     */
    class Noibu extends Widget {
        /**
         * @description Initialize Noibu
         * @returns {void}
         */
        init() {
            super.init();

            this.checkSDKExistanceAndAddCustomAttribute();
        }

        checkSDKExistanceAndAddCustomAttribute() {
            const isSameDWSID = localStorageWrapper.getItem(SESSION_ID_TRUNCATE) === getCookie(SESSION_ID_TRUNCATE);

            if (window.NOIBUJS && !isSameDWSID) {
                localStorageWrapper.setItem(SESSION_ID_TRUNCATE, getCookie(SESSION_ID_TRUNCATE));
                window.NOIBUJS.addCustomAttribute('sfcc-session-id', getCookie(SESSION_ID_TRUNCATE));
            }
        }
    }

    return Noibu;
}
