/**
 * @typedef {ReturnType<typeof import('widgets/global/Modal').default>} BaseModal
 */

/**
 * @description Modal implementation
 * @param {BaseModal} BaseModal Base widget for extending
 * @returns {typeof Modal} Modal class
 */
export default function (BaseModal) {
    /**
     * @class Modal
     * @augments BaseModal
     */
    class Modal extends BaseModal {
        /**
         * @description Close Modal
         * @returns {void}
         */
        closeModal() {
            super.closeModal();

            this.emit('modalclosed');
        }

        /**
         * @description If modal is currently closed
         * @returns {boolean} true if modal is currently closed
         */
        isClosed() {
            return this.ref(this.prefs().refDialog).hasClass(this.prefs().classesClosed);
        }

        /**
         * @description If modal is currently opened
         * @returns {boolean} true if modal is currently opened
         */
        isOpened() {
            return !this.isClosed();
        }

        /**
         * @description Scroll event handler
         * @listens dom#scroll
         * @returns {void}
         */
        onScroll() {
            this.eventBus().emit('highlighter.update');
        }
    }

    return Modal;
}
