import { TWidget } from 'widgets/Widget';
import { submitFormJson, getContentByUrl, submitFormJsonWithAbort } from 'widgets/toolbox/ajax';
import { scrollWindowTo } from 'widgets/toolbox/scroll';
import { RefElement } from 'widgets/toolbox/RefElement';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {ReturnType<typeof import('widgets/forms/InputStepper').default>} InputStepper
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

type TCart = {
    items: Array<Record<string, unknown>>,
    shipments: Array<Record<string, unknown>>,
    valid: Record<string, unknown>,
    error: string,
    redirectUrl: string,
    couponAction: string,
    pageError: string
};

type IModal = InstanceType<ReturnType<typeof import('widgets/global/Modal').default>>;
type IConfirmDialog = InstanceType<ReturnType<typeof import('widgets/global/ConfirmDialog').default>>;
type IInputStepper = InstanceType<ReturnType<typeof import('widgets/forms/InputStepper').default>>;

type TActionsUrls = { moveProductToWishlist: string };

/**
 * @description Base CartMgr implementation
 * @param Widget Base widget for extending
 * @returns Cart Manager class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory cart
     * @class CartMgr
     * @augments Widget
     * @classdesc CartMgr component on Cart page. Renders the whole cart template on each update e.g. quantity/coupon/product edit etc.
     * Widget manages next actions:
     * 1. Edit product in a cart
     * 2. Change product quantity
     * 3. Remove product (move to Wish list) from Cart
     * 4. Communicate actions results
     * 5. Re-render Cart including products list and cart summary in case of actions like: product updated, coupon applied etc.
     * 6. Enable/disable "Secure Checkout" button to move to Checkout process depending on if there are errors in cart, which prevents moving to Checkout
     * @property {string} data-widget - Widget name `cartMgr`
     * @property {Array} data-items - Cart items list
     * @property {Array} data-approaching-discounts - Cart approaching discounts list
     * @property {object} data-action-urls - Cart action URLs
     * @property {object} data-totals - Cart totals
     * @property {Array} data-shipments - Cart shipments list
     * @property {boolean} data-valid - Is Cart valid
     * @property {number} data-num-items - Number of items in Cart
     * @property {string} data-cart-get-url - An URL to get updated Cart object from server
     * @property {object} data-accessibility-alerts - Accessibility alerts messages for different user actions
     * Possible values are: `quantitychanged`, `productupdated`, `productremoved`,
     * `promocodeadded`, `promocodeapplied`, `promocoderemoved`, `addedtowishlist`
     * @example <caption>Example of CartMgr widget markup</caption>
     * <div
     *     data-widget="cartMgr"
     *     data-items="${JSON.stringify(pdict.items.toArray())}"
     *     data-approaching-discounts="${JSON.stringify(pdict.approachingDiscounts.toArray())}"
     *     data-action-urls="${JSON.stringify(pdict.actionUrls)}"
     *     data-cart-get-url="${URLUtils.url('Cart-Get')}"
     *     data-totals="${JSON.stringify(pdict.totals)}"
     *     data-shipments="${JSON.stringify(pdict.shipments.toArray())}"
     *     data-valid="${JSON.stringify(pdict.valid)}"
     *     data-num-items="${JSON.stringify(pdict.numItems)}"
     *     data-accessibility-alerts='{
     *         "quantitychanged": "${Resource.msg('alert.quantitychanged', 'cart', null)}",
     *         ...
     *     }'
     * >
     *     <div data-ref="cartContainer"></div>
     *     <script data-ref="cartTemplate" type="template/mustache">
     *         <div data-ref="cartContainer">
     *              ... cart global errors
     *              ... cart approaching discounts
     *              ... cart items
     *              ... cart summary
     *         </div>
     *     </script>
     * </div>
     */
    class CartMgr extends Widget {
        cart?: TCart;

        removeButton?: RefElement | IInputStepper;

        prefs() {
            return {
                cartGetUrl: '',
                accessibilityAlerts: <TAccessibilityAlerts>{},
                actionUrls: <TActionsUrls>{},
                tauAttrRemoveProductConfirmationPopUp: 'remove_item_confirmation_dialog',
                items: [],
                shipments: [],
                valid: true,
                error: '',
                redirectUrl: '',
                couponAction: '',
                pageError: '',
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         * @listens "minicart.updated"
         * @listens "product.updated"
         * @listens "coupon.updated"
         * @emits "cart.rendered"
         * @returns {void}
         */
        init() {
            this.cart = <TCart><unknown> this.prefs();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateQtyRequests' does not exist on typ... Remove this comment to see the full error message
            this.updateQtyRequests = [];
            this.eventBus().on('minicart.updated', 'renderCart');
            this.eventBus().on('product.updated', 'onProductUpdated');
            this.eventBus().on('coupon.updated', 'onCouponUpdated');
        }

        /**
         * @description Executes on product updated in Quick View
         * @param {object} response Response object from server
         * @returns {void}
         */
        onProductUpdated(response) {
            const cart = response.cartModel;

            if (cart) {
                this.renderCart(cart)
                    .then(() => this.accessibilityAlert(this.prefs().accessibilityAlerts.productupdated));
            }
        }

        /**
         * @description Executes on product updated in Quick View
         * @param {Cart} cart Cart object
         * @returns {void}
         */
        onCouponUpdated(cart) {
            this.renderCart(cart)
                .then(() => {
                    if (cart) {
                        const accessibilityAlert = this.prefs().accessibilityAlerts[`promocode${cart.couponAction}`];

                        this.accessibilityAlert(accessibilityAlert);
                    }
                });
        }

        /**
         * @description Renders Cart object and saves it as a current cart state
         * Adds some information into Cart object prior to rendering,
         * to make the whole object mustache template friendly
         * @emits "cart.updated"
         * @param cart - Cart object to reflect in template
         * @returns Promise object represents cart rendering result
         */
        renderCart(cart: TCart) {
            if (!cart) {
                return Promise.reject(new Error());
            }

            this.cart = cart;
            /**
             * @description Event, indicates that cart content was updated
             * @event "cart.updated"
             */
            this.eventBus().emit('cart.updated', cart);

            return this.render('cartTemplate', cart, this.ref('cartContainer'));
        }

        /**
         * @description Updates quantity of chosen product in Cart page
         * @param {inputStepper} quantityStepper - Select quantity input
         * @returns {Promise<object|null>} Promise object represents server response with quantity updating result
         */
        updateQty(quantityStepper) {
            this.showProgressBar();
            // @ts-expect-error TS2339: Property 'numItems' does not exist on type 'TCart'.
            const qtyBeforeUpdate = this.cart.numItems;
            const uuid = quantityStepper.data('uuid');
            const currentQuantity = quantityStepper.currentValue;
            const isQuantityBelowMinValue = currentQuantity < quantityStepper.config.lineItemMinOrderQuantity;

            if ((currentQuantity === 0) || isQuantityBelowMinValue) {
                this.removeProduct(quantityStepper);
                this.hideProgressBar();

                return Promise.resolve(null);
            }

            const requestObject = submitFormJsonWithAbort(quantityStepper.data('action'), {
                pid: quantityStepper.data('pid'),
                uuid: uuid,
                quantity: quantityStepper.getValue()
            }, 'POST', false);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateQtyRequests' does not exist on typ... Remove this comment to see the full error message
            this.updateQtyRequests.push(requestObject);

            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            return requestObject.promise
                .then((resp) => {
                    // @ts-expect-error TS2571: Object is of type 'unknown'.
                    if (resp.numItems > qtyBeforeUpdate) {
                        // @ts-expect-error TS2571: Object is of type 'unknown'.
                        this.eventBus().emit('product.cart.qty.increment', quantityStepper, resp.numItems - qtyBeforeUpdate);
                    } else {
                        // @ts-expect-error TS2339: Property 'numItems' does not exist on type 'TCart'.
                        this.eventBus().emit('product.cart.qty.decrement', quantityStepper, qtyBeforeUpdate - resp.numItems);
                    }

                    this.renderCartResponse(resp);
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.quantitychanged);

                    return resp;
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        return Promise.resolve(null);
                    }

                    if (error && error.error && error.errorMessage) {
                        this.render('errorTemplate', { message: error.errorMessage }, this.ref('errorMsgs'));
                    } else {
                        this.renderCartWithItemLevelActionError(uuid, error.message);
                    }

                    return Promise.resolve(null);
                })
                .finally(() => {
                    this.hideProgressBar();

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateQtyRequests' does not exist on typ... Remove this comment to see the full error message
                    const index = this.updateQtyRequests.indexOf(requestObject);

                    if (index > -1) {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateQtyRequests' does not exist on typ... Remove this comment to see the full error message
                        this.updateQtyRequests.splice(index, 1);
                    }
                });
        }

        /**
         * @description Aborts redundant update product quantity requests
         * @returns {void}
         */
        dismissQty() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateQtyRequests' does not exist on typ... Remove this comment to see the full error message
            this.updateQtyRequests.forEach((request) => {
                request.abortController.abort();
            });

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateQtyRequests' does not exist on typ... Remove this comment to see the full error message
            this.updateQtyRequests = [];
        }

        /**
         * @description Fires `alert.show` event to be handled with Global Alerting System
         * @param {string} accessibilityAlert - alert string
         * @emits "alert.show"
         * @returns {void}
         */
        accessibilityAlert(accessibilityAlert) {
            this.eventBus().emit('alert.show', {
                accessibilityAlert
            });
        }

        /**
         * @description Renders cart response from server.
         * Follows redirect URL in case if cart was not found on server
         * (request to update product/quantity etc)
         * @param {Cart} resp - server response
         * @returns {void}
         */
        renderCartResponse(resp) {
            if (resp && resp.error && resp.redirectUrl) {
                window.location.assign(resp.redirectUrl);
            } else {
                this.renderCart(resp);
            }
        }

        /**
         * @description Handler for "Edit" product button in a Cart
         * @param {refElement} editBtn - Target "Edit" button
         * @emits "product.tile.qv.open.edit"
         * @returns {void}
         */
        editProduct(editBtn) {
            this.showProgressBar();
            const uuid = editBtn.data('uuid');

            getContentByUrl(editBtn.data('href'), { uuid })
                .then((response) => {
                    this.getById('editProductModal', (editProductModal: IModal) => editProductModal.showModal({
                        body: response,
                        attributes: {
                            'data-tau-unique': 'edit_product_dialog'
                        }
                    }));
                    this.eventBus().emit('product.tile.qv.open.edit', editBtn);
                })
                .catch(error => {
                    this.renderCartWithItemLevelActionError(uuid, error.message);
                })
                .finally(() => {
                    this.hideProgressBar();
                });
        }

        /**
         * @description Handler for "Remove" product button in a Cart
         * Stores clicked button instance to property because of "Remove Popup" displaying and further interaction
         * @param {refElement|inputStepper} removeButton - Clicked "Remove" product button or qtyStepper if value was 0
         * @returns {void}
         */
        removeProduct(removeButton: RefElement | IInputStepper) {
            const InputStepper = /** @type {InputStepper} */ (this.getConstructor('InputStepper'));
            const url = removeButton.data('removeAction') || removeButton.data('action');
            const pid = removeButton.data('pid');
            const uuid = removeButton.data('uuid');
            const qtyStepperID = `quantityStepper-${uuid}`;
            let currentQuantityStepperValue = 1;
            let lineItemMinOrderQuantity = null;

            if (removeButton instanceof InputStepper) {
                currentQuantityStepperValue = parseInt(removeButton.getValue(), 10);

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'lineItemMinOrderQuantity' does not exist... Remove this comment to see the full error message
                lineItemMinOrderQuantity = removeButton.prefs().lineItemMinOrderQuantity;
            } else {
                this.getById<IInputStepper>(qtyStepperID, (stepper) => {
                    currentQuantityStepperValue = parseInt(stepper.getValue(), 10);
                });
            }

            this.removeButton = removeButton;
            this.getById<IConfirmDialog>('confirmDialog', (/** @type {confirmDialog} */ confirmDialog) => {
                confirmDialog.showModal({
                    productName: removeButton.data('name'),
                    attributes: {
                        'data-tau': this.prefs().tauAttrRemoveProductConfirmationPopUp,
                        url: url,
                        pid: pid,
                        uuid: uuid,
                        currentValue: currentQuantityStepperValue,
                        minOrderQuantity: lineItemMinOrderQuantity
                    }
                });
            });
        }

        /**
         * @description Executes when user clicks on product details link in the cart.
         * Usually used by analytics etc.
         * @emits "cart.product.link.click"
         * @param {refElement} link - clicked product tile link
         * @returns {void}
         */
        onProductLinkClick(link) {
            this.eventBus().emit('cart.product.link.click', link);
        }

        /**
         * @description Renders Cart with error message on Cart Item level.
         * Usually it may happens when in parallel tab user removed product from cart.
         * As far as we should display error on product, which was removed, thus we don't need
         * to update this exact item in a stored earlier cart object
         * @param {string} itemUUID - Product item identifier
         * @param {string} error - an error response from the server
         * @returns {void}
         */
        renderCartWithItemLevelActionError(itemUUID, error) {
            if (!this.cart) {
                return;
            }

            this.cart.items = this.cart.items.map(item => {
                const existingOldItem = this.cart && this.cart.items.find((cartItem) => cartItem.UUID === item.UUID);
                const returnItem = !existingOldItem ? item : existingOldItem;

                return {
                    ...returnItem,
                    actionErrorMessage: (itemUUID === item.UUID) ? error : ''
                };
            });

            this.cart.valid.error = true;

            this.renderCart(this.cart);
        }

        /**
         * @description A callback, which is executed, when customer agreed to remove product in confirmation popup
         * @param button - Button, pressed in confirmation modal
         * @param [movedToWishlist] - Indicates if product was moved to wishlist
         * @emits "cart.remove.product"
         * @returns Promise object represents server response for product deletion
         */
        confirmedRemoveProduct(button: IConfirmDialog, movedToWishlist = false) {
            const attributes = button.attributes;

            if (!attributes) {
                return Promise.resolve({ error: true });
            }

            const url = attributes.url;
            const pid = attributes.pid;
            const uuid = attributes.uuid;

            this.eventBus().emit('cart.remove.product', this);

            if (!movedToWishlist) {
                this.showProgressBar();
            }

            let removePromise = submitFormJson(url, {
                pid: pid,
                uuid: uuid
            }, 'POST')
                .then((response) => {
                    if (response.error) {
                        throw Error(<string> response.error);
                    }

                    this.confirmationRemoveCallback(response.basket, movedToWishlist);

                    return response;
                });

            if (!movedToWishlist) {
                removePromise = removePromise
                    .catch(error => {
                        this.renderCartWithItemLevelActionError(uuid, error.message);

                        return error;
                    })
                    .finally(() => {
                        this.hideProgressBar();
                    });
            }

            return removePromise;
        }

        /**
         * @description Handle cancel confirmDialog that was called due quantity stepper value is 0
         * Set quantity stepper value to 1 if confirmDialog cancel
         * @param confirmationDialog Target popup
         */
        cancelProductRemoving(confirmationDialog: IConfirmDialog) {
            const attributes = confirmationDialog && confirmationDialog.attributes;

            if (attributes
                && (attributes.currentValue === 0
                    || attributes.currentValue < attributes.minOrderQuantity)) {
                const uuid = attributes.uuid;
                const minOrderQuantity = attributes.minOrderQuantity;
                const qtyStepperID = `quantityStepper-${uuid}`;

                this.getById<IInputStepper>(qtyStepperID, (stepper) => {
                    stepper.setInputValue(stepper.filterInput(minOrderQuantity));
                });
            }
        }

        /**
         * @description Move product from cart to wishlist.
         * In fact this functionality implemented in 2 steps:
         * 1. Add product to wishlist
         * 2. Remove product from cart
         * This is done due to possible backend code duplication in one universal endpoint
         * @param confirmationDialog Target popup
         * @returns Promise object represents server response for product adding to wishlist
         */
        moveProductToWishlist(confirmationDialog: IConfirmDialog) {
            if (!confirmationDialog) {
                return Promise.resolve([{ error: true }]);
            }

            const removeButtonUUID = confirmationDialog.attributes.uuid;

            this.showProgressBar();

            return submitFormJson(this.prefs().actionUrls.moveProductToWishlist, {
                pid: confirmationDialog.attributes.pid,
                qty: confirmationDialog.attributes.currentValue
            }).then((response) => {
                if (response.error && !response.itemAlreadyExists) {
                    throw Error(<string>response.error);
                }

                const removeFromCartPromise = this.confirmedRemoveProduct(confirmationDialog, true);

                return Promise.all([response, removeFromCartPromise]);
            }).catch((error: Error) => {
                this.renderCartWithItemLevelActionError(removeButtonUUID, <string> error.message);

                return [{ error }];
            }).finally(() => {
                this.hideProgressBar();
            });
        }

        /**
         * @description A callback, which is executed once `remove` product server call was performed
         * @param {Cart} cart - server response object
         * @param {boolean} [movedToWishlist] - Indicates if product was moved to wishlist
         * @returns {void}
         */
        confirmationRemoveCallback(cart, movedToWishlist = false) {
            this.renderCart(cart)
                .then(() => this.accessibilityAlert(movedToWishlist
                    ? this.prefs().accessibilityAlerts.movedtowishlist
                    : this.prefs().accessibilityAlerts.productremoved));
        }

        /**
         * @description Shows progress bar
         * @returns {void}
         */
        showProgressBar() {
            this.ref('cartContainer').attr('aria-busy', 'true');
        }

        /**
         * @description Hides progress bar
         * @returns {void}
         */
        hideProgressBar() {
            this.ref('cartContainer').attr('aria-busy', 'false');
        }

        /**
         * @description Handles `proceed to checkout` button click.
         *
         * In case of error - to not allow Customer to enter checkout flow and scroll to cart error message
         *
         * @param {refElement} btn - clicked button
         * @param {Event} event - target event
         * @emits "cart.page.submitted"
         * @returns {void}
         */
        proceedToCheckout(btn, event) {
            if (btn.data('error')) {
                event.preventDefault();
                this.scrollToError();
            } else {
                this.showProgressBar();
            }

            this.eventBus().emit('cart.page.submitted', this);
        }

        /**
         * @description Scrolls cart error messages into viewport
         * @returns {void}
         */
        scrollToError() {
            const cartErrorMessage = this.ref('errorMsgs').get();

            if (cartErrorMessage) {
                scrollWindowTo(cartErrorMessage);
            }
        }

        /**
         * @description Show cart error
         * @param widget - Widget that emit error
         * @param error - Error message string
         */
        showError(widget: TWidget, error: string) {
            if (!error || !this.cart) {
                return;
            }

            this.cart.pageError = error;

            this.renderCart(this.cart)
                .then(() => this.scrollToError());
        }
    }

    return CartMgr;
}
