import { TWidget } from 'widgets/Widget';
import { timeout, isDOMElementFocusable } from 'widgets/toolbox/util';
import { scrollIntoView } from 'widgets/toolbox/scroll';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @param Widget Base widget for extending
 * @returns AccessibilityFocusMixin class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class AccessibilityFocusMixin
     * @augments Widget
     * @classdesc Base implementation for inheritance, to be used to handle focus-related actions like getting set of focusable inputs etc.
     * Also could be used to set focus on:
     * - concrete element in widget's markup by ref element
     * - first input in widget's markup
     * - first focusable element in widget's markup
     * This class is not intended to have a separate DOM widget. It should be used as a mixin for any other target widgets.
     */
    class AccessibilityFocusMixin extends Widget {
        /**
         * @description Get keyboard-focusable elements
         * @param {string} [refName] ref name to get focusable elements in, or nothing to get from `self`
         * @param {string[]} [types] array of elements selectors to search for, or nothing for default set
         * @returns {HTMLElement[]} array containing focusable elements, or empty array if no elements found
         */
        getFocusableElements(refName = 'self', types = []) {
            if (!types.length) {
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
                types = ['a', 'button', 'input', 'textarea', 'select', 'details'];
            }

            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            types.push('[tabindex]:not([tabindex="-1"])');
            const selector = types.join(',');
            let result = [];

            this.has(refName, refElement => {
                const htmlEl = refElement.get();

                if (htmlEl) {
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element[]' is not assignable to type 'never[... Remove this comment to see the full error message
                    result = Array.from(htmlEl.querySelectorAll(selector))
                        .filter(el => isDOMElementFocusable(el));
                }
            });

            return result;
        }

        /**
         * @description Get keyboard-focusable input elements
         * @param {string} [refName] ref name to get focusable elements in, or nothing to get from `self`
         * @returns {HTMLElement[]} array containing focusable input elements, or empty array if no elements found
         */
        getFocusableInputElements(refName = 'self') {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
            return this.getFocusableElements(refName, ['input', 'textarea', 'select']);
        }

        /**
         * @description Focus first element found in ref with min timeout
         * @param {string} [containerRefName] container ref name to get focusable elements in, or nothing to get from `self`
         * @param {string[]} [types] optional, array of elements selectors to search for, or nothing for default set
         * @param {boolean} [needScrollIntoView] scroll into view active element after focus
         * @returns {void}
         */
        focusFirst(containerRefName = 'self', types = [], needScrollIntoView = false) {
            const focusableElements = this.getFocusableElements(containerRefName, types);

            if (focusableElements.length) {
                timeout(() => {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'focus' does not exist on type 'never'.
                    focusableElements[0].focus();

                    if (needScrollIntoView) {
                        scrollIntoView(focusableElements[0]); // Fix iOS not scrolled to focused element
                    }
                }, 100);
            }
        }

        /**
         * @description Method to focus on first focusable input, accepts refName as param to search input in
         * @param {string} [containerRefName] container ref name to get focusable elements in, or nothing to get from `self`
         * @param {boolean} [needScrollIntoView] scroll into view active element after focus
         * @returns {void}
         */
        focusFirstInput(containerRefName = 'self', needScrollIntoView = false) {
            const focusableInputElements = this.getFocusableInputElements(containerRefName);

            if (focusableInputElements.length) {
                timeout(() => {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'focus' does not exist on type 'never'.
                    focusableInputElements[0].focus();

                    if (needScrollIntoView) {
                        scrollIntoView(focusableInputElements[0]); // Fix iOS not scrolled to focused element
                    }
                }, 100);
            }
        }

        /**
         * @description Method to focus on a concrete focusable element, accepts refName as param to search element
         * @param {string} [elementRefName] element ref name to check if it focusable, end set focus into it
         * @returns {void}
         */
        focusElement(elementRefName) {
            if (elementRefName) {
                this.has(elementRefName, (element) => {
                    const domNode = element.get();

                    if (domNode && isDOMElementFocusable(domNode)) {
                        timeout(() => domNode.focus(), 100);
                    }
                });
            }
        }
    }

    return AccessibilityFocusMixin;
}
