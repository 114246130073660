/**
 * @typedef {ReturnType<typeof import('widgets/global/HeroCarousel').default>} BaseHeroCarousel
 */

/**
 * @param {BaseHeroCarousel} BaseHeroCarousel Base widget for extending
 * @returns {typeof HeroCarousel} HeroCarousel class
 */

export default function (BaseHeroCarousel) {
    /**
     * @category widgets
     * @subcategory global
     * @class HeroCarousel
     * @augments BaseHeroCarousel
     * @classdesc Represents HeroCarousel component with next features:
     * @property {string} data-autoplay-infinite
    */

    class HeroCarousel extends BaseHeroCarousel {
        pref() {
            return {
                autoplayInfinite: true,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize carousel structure with slides
         * @returns {void}
         */
        initStructure() {
            const track = this.ref('elemCarouselTrack').get();

            if (track) {
                this.slides = track.children;
                this.slidesTotal = this.slides.length;

                for (let i = 0; i < this.slides.length; i++) {
                    const slide = this.slides[i];

                    slide.setAttribute('id', (this.prefs().slidePrefix + i));
                    slide.setAttribute('aria-label', ((i + 1) + ' ' + track.getAttribute('data-label-delimiter') + ' ' + this.slidesTotal));
                }
            }

            this.isSlider = this.slidesTotal && this.slidesTotal > 1;
        }

        /**
         * @description Normalizes slide index according to looped carousel approach
         * @param {number} index - Slide index
         * @returns {number} Normalized slide index
         */
        normalizeIndex(index) {
            let normalizedIndex = 0;

            if (this.slidesTotal) {
                if (index < 0) {
                    normalizedIndex = this.slidesTotal === 2 ? this.slidesTotal : this.slidesTotal - 1;
                } else {
                    normalizedIndex = index % this.slidesTotal;
                }
            }

            return normalizedIndex;
        }

        /**
         * @description Changes current slide to slide with provided index
         * @param {number} index Next slide index
         * @returns {void}
         */
        goToSlide(index) {
            if (index === -1 && !this.prefs().autoplayInfinite) {
                return this.startAutoplay();
            }

            if (this.slidesTotal === index && !this.prefs().autoplayInfinite) {
                return this.endAutoplay();
            }

            return super.goToSlide(index);
        }
    }

    return HeroCarousel;
}
