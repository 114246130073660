import { initYoutubePlayerApi } from 'core/toolbox/googleMgr';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Youtube VideoPlayer implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof VideoPlayer} VideoPlayer class
 */
export default function (Widget) {
    /**
     * @class VideoPlayer
     * @augments Widget
     */
    class VideoPlayer extends Widget {
        prefs() {
            return {
                videoConfig: {},
                rootMargin: '0px',
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.player = null;
            this.observe();
            this.eventBus().on('zoom.closed', 'stopVideo');
            this.eventBus().on('videoChange', 'videoChange');
            this.eventBus().on('hideVideoModule', 'hideVideoModule');
        }

        observe() {
            let observer = new IntersectionObserver(([entry]) => {
                if (entry.intersectionRatio < 1) {
                    this.pauseVideo();
                }

                if (!this.player) {
                    initYoutubePlayerApi().then(() => {
                        this.initPlayer();
                        this.onDestroy(() => {
                            if (this.player) {
                                this.stopVideo();
                                this.player.destroy();
                                this.player = undefined;
                            }
                        });
                    });
                }
            }, {
                threshold: 1,
                rootMargin: this.prefs().rootMargin
            });

            observer.observe(this.ref('self').get());
            this.onDestroy(() => {
                if (observer) {
                    observer.disconnect();
                    observer = undefined; // eslint-disable-line no-undef
                }
            });
        }

        startVideo() {
            this.ref('player').attr('tabindex', '0');
            this.playVideo();
            this.ref('cover').remove();
        }

        /**
         * @description Plays video playing by external event
         */
        playVideo() {
            if (this.player && typeof this.player.playVideo === 'function') {
                this.player.playVideo();
            }
        }

        /**
         * @description Pause video playing by external event
         */
        pauseVideo() {
            if (this.player && typeof this.player.pauseVideo === 'function') {
                this.player.pauseVideo();
            }
        }

        /**
         * @description Stops video playing by external event
         */
        stopVideo() {
            if (this.player && typeof this.player.stopVideo === 'function') {
                this.player.stopVideo();
            }
        }

        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.startVideo();
                    preventEventActions = true;
                    break;
                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        // eslint-disable-next-line sonarjs/cognitive-complexity
        initPlayer() {
            const videoConfig = this.prefs().videoConfig;
            const videoElement = this.ref('player').get();
            const playerConfig = {
                videoId: videoConfig.videoId,
                playerVars: videoConfig.playerVars || {}
            };

            let isYoutubeList = false;
            let ytListParts = null;

            if (videoConfig.videoUrl && videoConfig.videoUrl.includes('list=')) {
                isYoutubeList = true;
                playerConfig.playerVars.listType = 'playlist';
                const listRegex = /=(.*)\w*\w+/;

                ytListParts = videoConfig.videoUrl.match(listRegex);

                if (ytListParts && ytListParts.length) {
                    playerConfig.playerVars.list = ytListParts[0].substr(1);
                }
            }

            // Case if full URL passed instead ID
            if (!playerConfig.videoId && videoConfig.videoUrl && !isYoutubeList) {
                /* eslint-disable-next-line */
                const ytRegex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*/;
                const urlParts = videoConfig.videoUrl.match(ytRegex);

                if (urlParts) {
                    playerConfig.videoId = urlParts[2];
                }
            }

            if (videoConfig.height && videoConfig.height > 0) {
                playerConfig.height = videoConfig.height;
            }

            if (videoConfig.width && videoConfig.width > 0) {
                playerConfig.width = videoConfig.width;
            }

            this.player = new YT.Player(videoElement, playerConfig); // eslint-disable-line no-undef
        }

        videoChange(itemVideoUrl, event) {
            if (itemVideoUrl) {
                /* eslint-disable-next-line */
                const ytRegex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*/;
                const urlParts = itemVideoUrl.match(ytRegex);

                const myPlayer = this.player;

                myPlayer.stopVideo();

                if (urlParts && urlParts[2]) {
                    myPlayer.loadVideoById(
                        {
                            videoId: urlParts[2],
                            startSeconds: 0,
                            suggestedQuality: 'large'
                        }
                    );
                }
            }

            if (event) {
                event.stopImmediatePropagation();
                event.preventDefault();
            }
        }

        hideVideoModule(hide) {
            if (hide) {
                this.hide();
                this.stopVideo();
            } else {
                this.show();
            }
        }
    }

    return VideoPlayer;
}
