import { clickOutside } from 'widgets/toolbox/util';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32,
    TAB: 9
});

// @ts-nocheck
/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof Tooltip} Tooltip class
 */

export default function (Widget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Tooltip
     * @augments Widget
     * @classdesc Represents Tooltip component  */

    class Tooltip extends Widget {
        prefs() {
            return {
                closeOnClickOutside: true,
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         */
        init() {
            super.init();

            if (this.prefs().closeOnClickOutside) {
                clickOutside(this.ref('self'), () => {
                    this.closeTooltip();
                }, false);
            }
        }

        handleClick() {
            this.showTooltip();
        }

        handleKeydown(_, event) {
            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    event.preventDefault();
                    this.showTooltip();
                    break;
                case keyCode.TAB:
                    if (!event.target.closest('.b-age_restricted-badge')) {
                        this.closeTooltip();
                    }

                    break;
                default:
                    break;
            }
        }

        showTooltip() {
            this.ref('content').show();
        }

        closeTooltip() {
            this.ref('content').hide();
        }

        stopPropagition(_, event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    return Tooltip;
}
