import { postJsonData } from 'widgets/toolbox/ajax';
import { getCookie } from 'widgets/toolbox/cookie';

/**
 * @typedef {typeof import('core/storelocator/PreferredStoreSelector').default} PreferredStoreSelector
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof PreferredStoreSelector} Widget
 */

export default function (Widget) {
    class PreferredStoreSelector extends Widget {
        prefs() {
            return {
                storeCookie: 'dw_store',
                storeDetail: false,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.initPreferredStoreData();
            this.initEvents();
        }

        /**
         * @description inits widget events
         */
        initEvents() {
            if (this.prefs().storeDetail) {
                this.eventBus().on('update.store.details.data', 'updateStoreData');
            }
        }

        /**
         * @description updates store data
         * @param {string} storeID - store id
         */
        updateStoreData(storeID) {
            if (this.prefs().storeId === storeID) {
                this.renderTemplateStore();
            }
        }

        /**
        * @description inits store locator link data
        */
        initPreferredStoreData() {
            const storeID = getCookie(this.prefs().storeCookie);

            if (!storeID) {
                return;
            }

            this.updateStoreData(storeID);
        }

        handleSelect() {
            postJsonData(this.prefs().action, {
                storeID: this.prefs().storeId
            }).then((response) => {
                if (!response.isSuccess) {
                    return;
                }

                const storeData = {
                    name: this.prefs().name,
                    url: response.storeURL,
                    storeId: this.prefs().storeId
                };

                this.eventBus().emit('preferredstore.selected', storeData);
                this.eventBus().emit('gtm.datalayer.event', {
                    event: 'data-layer-event',
                    eventCategory: 'Key interactions',
                    eventAction: 'Make my preferred store',
                    eventLabel: this.prefs().name
                });

                this.renderTemplateStore();

                if (this.prefs().storeDetail) {
                    this.eventBus().emit('update.store.details.data', this.prefs().storeId);
                }
            });
        }

        /**
        * @description render selected store
        * @returns {void}
        */
        renderTemplateStore() {
            if (this.ref('storeContainer').length) {
                this.render('templateStore', null, this.ref('storeContainer'));
            }
        }
    }

    return PreferredStoreSelector;
}
