import { clickOutside } from 'widgets/toolbox/util';
const KEY_DOWN = 40;
const KEY_UP = 38;
const KEY_ESCAPE = 27;
const KEY_RETURN = 13;
const KEY_TAB = 9;
const ACTIVE_DESCENDANT = 'aria-activedescendant';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Base Combobox implementation
 * @param BasicInput Base widget for extending
 * @returns Combobox class
 */
export default function (BasicInput: ReturnType<typeof import('widgets/forms/BasicInput').default>) {
    /*
     * This content is based on w3.org design pattern examples and licensed according to the
     * W3C Software License at
     * https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
     * Please see specification:
     * https://www.w3.org/TR/wai-aria-practices/#combobox
     */
    /**
     * @category widgets
     * @subcategory forms
     * @class Combobox
     * @augments BasicInput
     * @classdesc Combobox implementation in accordance with accessibility statements.
     * Class could be extended to implement any provider-specific remote/local data fetching for Combobox inner elements.
     * It is needed to implement `getSuggestions` method in a way, that is specific for subclasses
     * Supports keyboard navigation among Combobox items.
     * As far as Combobox could be used only in a subclasses, example, given below, is related to known subclass `searchBox`
     * @property {string} data-widget - Widget name `searchBox`
     * @property {string} data-url - URL to obtain serach suggestions from server based on customer's input
     * @property {boolean} data-close-on-tab - If true - `tab` keypress will close listbox
     * @property {string} data-widget-event-closesearch - An event, fired when `close` element was pressed
     * @property {string} data-event-keydown - event handler for `keydown` event
     * @property {boolean} data-close-from-outside - config, which shows, if combobox should be closed when click outside
     * @property {number} data-min-chars - minimum characters to trigger show combobox
     * @property {number} data-max-chars - maximum characters to enter
     * @property {number} data-updating-delay - update listbox delay
     * @property {string} data-classes-focused-item - class for currently focused item
     * @property {string} data-classes-active-list-box - active listbox class
     * @example
     * // use this code to display minicart widget
     * <div
     *     data-widget="searchBox"
     *     data-url="${URLUtils.url('SearchServices-GetSuggestions')}"
     *     data-close-on-tab="true"
     * >
     *     <form>
     *         ... serach input
     *     </form>
     *     ...
     *     <div data-ref="listbox" data-event-mouseenter="markHover" data-event-mouseleave="unMarkHover">
     *         <div role="none" class="b-suggestions-inner" data-ref="listboxInner"></div>
     *     </div>
     *     <script data-ref="template" type="template/mustache">
     *         <div data-ref="listboxInner">
     *             ... search suggestions
     *         </div>
     *     </script>
     * </div>
     */
    class Combobox extends BasicInput {
        constructor(el, config) {
            super(el, config);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastSearchedTerm' does not exist on type... Remove this comment to see the full error message
            this.lastSearchedTerm = '';

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
            this.selectedIndex = -1;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'resultsCount' does not exist on type 'Co... Remove this comment to see the full error message
            this.resultsCount = 0;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isListboxOpen' does not exist on type 'C... Remove this comment to see the full error message
            this.isListboxOpen = false;
        }

        prefs() {
            return {
                minChars: 3,
                maxChars: 50,
                updatingDelay: 300,
                closeFromOutside: true,
                closeOnTab: true,
                classesFocusedItem: 'm-focused',
                classesActiveListBox: 'm-active',
                ...super.prefs()
            };
        }

        /**
         * @description Handles customer input
         * @returns {void}
         */
        handleInput() {
            const inputLength = this.ref('field').prop('value').length;

            if (inputLength >= this.prefs().minChars && inputLength <= this.prefs().maxChars) {
                this.updateListbox();
            } else {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'Combobo... Remove this comment to see the full error message
                if (this.timeout) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'Combobo... Remove this comment to see the full error message
                    clearTimeout(this.timeout);
                }

                this.closeListbox();
            }
        }

        /**
         * @description Marks element as hover, once mouse over
         * @listens dom#mouseenter
         * @returns {void}
         */
        markHover() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasHoverWithin' does not exist on type '... Remove this comment to see the full error message
            this.hasHoverWithin = true;
        }

        /**
         * @description Un-mark element as hover, once mouse out
         * @listens dom#mouseleave
         * @returns {void}
         */
        unMarkHover() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasHoverWithin' does not exist on type '... Remove this comment to see the full error message
            this.hasHoverWithin = false;
        }

        /**
         * @description Handles `focus` on combobox input
         * @listens dom#focus
         * @returns {void}
         */
        handleFocus() {
            this.updateListbox();
        }

        /**
         * @description Handles `blur` on combobox input
         * @listens dom#blur
         * @returns {void}
         */
        handleBlur() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasHoverWithin' does not exist on type '... Remove this comment to see the full error message
            if (this.hasHoverWithin || this.selectedIndex < 0) {
                return;
            }

            this.closeListbox();
        }

        /**
         * @description Handles `keydown` on combobox input
         * @param {HTMLElement} _ - source of keydown event
         * @param {KeyboardEvent} event - keydown event object
         * @listens dom#keydown
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case KEY_ESCAPE:
                    this.closeListbox();
                    preventEventActions = true;
                    break;
                case KEY_UP:
                    this.setSelectToNextItem();
                    this.selectItem(this.getItemByIndex());
                    preventEventActions = true;
                    break;
                case KEY_DOWN:
                    this.setSelectToPreviousItem();
                    this.selectItem(this.getItemByIndex());
                    preventEventActions = true;
                    break;
                case KEY_RETURN:
                    this.activateItem(this.getItemByIndex());

                    return;
                case KEY_TAB:
                    if (this.prefs().closeOnTab) {
                        this.closeListbox();
                    }

                    return;
                default:
                    return;
            }

            if (preventEventActions) {
                event.stopPropagation();
                event.preventDefault();
            }
        }

        /**
         * @description Sets previous item as selected, when navigating from keyboard
         * @returns {void}
         */
        setSelectToPreviousItem() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
            if (this.selectedIndex === -1 || this.selectedIndex >= this.resultsCount - 1) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
                this.selectedIndex = 0;
            } else {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
                this.selectedIndex += 1;
            }
        }

        /**
         * @description Sets next item as selected, when navigating from keyboard
         * @returns {void}
         */
        setSelectToNextItem() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
            if (this.selectedIndex <= 0) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
                this.selectedIndex = this.resultsCount - 1;
            } else {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
                this.selectedIndex -= 1;
            }
        }

        /**
         * @description Selects combobox dropdown item
         * @param {null|refElement} selectedItem - item, selected by user
         * @returns {void}
         */
        selectItem(selectedItem) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSelected' does not exist on type ... Remove this comment to see the full error message
            if (this.currentSelected) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSelected' does not exist on type ... Remove this comment to see the full error message
                this.deselectItem(this.currentSelected);
            }

            if (selectedItem) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
                this.ref('field').attr(ACTIVE_DESCENDANT, `result-item-${this.selectedIndex}`);
                selectedItem
                    .addClass(this.prefs().classesFocusedItem)
                    .attr('aria-selected', 'true');
                this.afterItemSelected(selectedItem);
            } else {
                this.ref('field').attr(ACTIVE_DESCENDANT, false);
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSelected' does not exist on type ... Remove this comment to see the full error message
            this.currentSelected = selectedItem;
        }

        /**
         * @description Executes logic (sets input value) after item was selected
         * @param {refElement} item - item, selected by user
         * @returns {void}
         */
        afterItemSelected(item) {
            this.ref('field').prop('value', item.data('suggestionValue'));
        }

        /**
         * @description Deselects item in combobox dropdown when navigating from keyboard.
         * @param {refElement} item - item, selected by user
         * @returns {void}
         */
        deselectItem(item) {
            item
                .removeClass(this.prefs().classesFocusedItem)
                .attr('aria-selected', 'false');
        }

        /**
         * @description "Activate" item by press Enter on selected item or mouse click on item
         * @param {null|refElement} activeItem - item, selected by user
         * @returns {void}
         */
        activateItem(activeItem) {
            if (activeItem) {
                this.ref('field')
                    .prop('value', activeItem.data('suggestionValue'))
                    .get().focus();
                this.closeListbox();
            }
        }

        /**
         * @description Update Combobox Listbox (dropdown). Initiates items retrieving either from server or from any other source, like an array.
         * @returns {void}
         */
        updateListbox() {
            const inputValue = this.ref('field').prop('value');

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastSearchedTerm' does not exist on type... Remove this comment to see the full error message
            if (this.lastSearchedTerm === inputValue || inputValue.length < this.prefs().minChars) {
                // could be triggered from focus so we need additionally check minChars
                return;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'Combobo... Remove this comment to see the full error message
            if (this.timeout) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'Combobo... Remove this comment to see the full error message
                clearTimeout(this.timeout);
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'Combobo... Remove this comment to see the full error message
            this.timeout = setTimeout(this.getSuggestions.bind(this, inputValue), this.prefs().updatingDelay);
        }

        /**
         * @description Get items list for Combobox. Could be fetched from any proper source.
         * Usually is implemented in children.
         * @param {string} query - a query, which needs to be processed in subclasses
         * @returns {void}
         */
        getSuggestions(query) {
            // This is example of getSuggestion method to implement
            // Combo boxes like search or address should implement his own methods
            // of how to get suggestion and render it. Template should follow this:
            // <li role="option" id="result-item-${0}">${results[0]}</li>
            // ...
            // <li role="status" aria-live="polite">${results.length} suggestion found</li>
            // You should always include ids of suggestion and status about how much
            // suggestion total.
            const resultsTotal = 1;

            this.afterSuggestionsUpdate(query, resultsTotal);
        }

        /**
         * @description Executes after a list of items (suggestions) of a Combobox has being updated.
         * @param {string} query - a query, used for fetching Combobox inner items
         * @param {number} resultsCount - obtained inner items count
         * @returns {void}
         */
        afterSuggestionsUpdate(query, resultsCount) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'resultsCount' does not exist on type 'Co... Remove this comment to see the full error message
            this.resultsCount = resultsCount;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
            this.selectedIndex = -1;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastSearchedTerm' does not exist on type... Remove this comment to see the full error message
            this.lastSearchedTerm = query;
            this.openListbox();
        }

        /**
         * @description Open a Listbox (Combobox dropdown)
         * @returns {void}
         */
        openListbox() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isListboxOpen' does not exist on type 'C... Remove this comment to see the full error message
            this.isListboxOpen = true;
            const listbox = this.ref('listbox');

            listbox.addClass(this.prefs().classesActiveListBox);
            listbox.attr('aria-hidden', 'false');
            const input = this.ref('field');

            input.attr(ACTIVE_DESCENDANT, false);
            input.attr('aria-expanded', 'true'); // Should be combobox node by specs
            this.toggleOverlay(true);

            if (this.prefs().closeFromOutside) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'bodyClickListener' does not exist on typ... Remove this comment to see the full error message
                this.bodyClickListener = clickOutside(this.ref('self'), this.closeListbox.bind(this));

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'bodyClickListener' does not exist on typ... Remove this comment to see the full error message
                if (this.bodyClickListener) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'bodyClickListener' does not exist on typ... Remove this comment to see the full error message
                    this.onDestroy(this.bodyClickListener);
                }
            }
        }

        /**
         * @description Close a Listbox (Combobox dropdown)
         * @returns {void}
         */
        closeListbox() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'resultsCount' does not exist on type 'Co... Remove this comment to see the full error message
            this.resultsCount = 0;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
            this.selectedIndex = -1;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastSearchedTerm' does not exist on type... Remove this comment to see the full error message
            this.lastSearchedTerm = '';

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isListboxOpen' does not exist on type 'C... Remove this comment to see the full error message
            this.isListboxOpen = false;

            const listbox = this.ref('listbox');

            listbox.removeClass(this.prefs().classesActiveListBox);
            listbox.attr('aria-hidden', 'true');

            const input = this.ref('field');

            input.attr(ACTIVE_DESCENDANT, '');
            input.attr('aria-expanded', 'false'); // Should be combobox not by specs
            this.toggleOverlay(false);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'bodyClickListener' does not exist on typ... Remove this comment to see the full error message
            if (this.bodyClickListener) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'bodyClickListener' does not exist on typ... Remove this comment to see the full error message
                this.bodyClickListener();

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'bodyClickListener' does not exist on typ... Remove this comment to see the full error message
                this.bodyClickListener = undefined;
            }

            this.afterCloseListbox();
        }

        /**
         * @description Executes after Listbox (Combobox dropdown) was closed
         * @returns {void}
         */
        afterCloseListbox() {
            this.ref('listbox').empty();
        }

        /**
         * @description Toggles Combobox page overlay
         * @param {boolean} isShown - does overlay should be shown
         * @returns {void}
         */
        toggleOverlay(isShown) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOverlayVisible' does not exist on type... Remove this comment to see the full error message
            this.isOverlayVisible = isShown;
        }

        /**
         * @description Get Combobox item (suggestion) by index, stored in `this.selectedIndex` class property.
         * @returns {refElement|null} - founded item or null
         */
        getItemByIndex() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
            if (this.selectedIndex < 0) {
                return null;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedIndex' does not exist on type 'C... Remove this comment to see the full error message
            const listItem = this.ref(`result-item-${this.selectedIndex}`);

            if (listItem.length) {
                return listItem;
            }

            return null;
        }
    }

    return Combobox;
}
