import { timeout } from 'widgets/toolbox/util';
import { debounce } from 'widgets/toolbox/debounce';
import { TWidget } from 'widgets/Widget';

const KEY_TAB = 9;

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Focus highlighter
 * @param Widget Base widget for extending
 * @returns Focus Highlighter class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class FocusHighlighter
     * @augments Widget
     * @classdesc This class intended to address Accessibility issue with focus highlighting on links element.
     * Usually used on `<body>` tag to observe focus move events
     *
     * In case if page was focused, next subsequent `TAB` press will leads to next available link/input element focus highlighting
     * Shows bordered rectangle, which fits currently focused element and moves rectangle to element's position.
     * @property {string} data-widget - Widget name `focusHighlighter`
     * @property {string} data-event-keyup - Event handler for `handleKeyup` method
     * @property {string} data-event-click - Event handler for `handleClick` method
     * @property {string} data-classes-inited - Inited classes, added to element after initialization
     * @property {string} data-classes-hurry - Classes, which don't contains animation and used to move `highlighter` (bordered rectangle) between focused elements without animation.
     * @example <caption>FocusHighlighter widget</caption>
     * <body
     *   class="b-page"
     *   data-widget="focusHighlighter"
     *   data-event-keyup="handleKeyup"
     *   data-event-click="handleClick"
     * >
     *     ... page content here
     * </body>
     */
    class FocusHighlighter extends Widget {
        prefs() {
            return {
                classesInited: 'b-highlighter_inited',
                classesEnabled: 'm-visible',
                classesHurry: 'm-hurry',
                DELAY_TIMEOUT: 200, // Delay for animation

                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization logic
         * @returns {void}
         */
        init() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'keyboardModality' does not exist on type... Remove this comment to see the full error message
            this.keyboardModality = false;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHighlighterVisible' does not exist on ... Remove this comment to see the full error message
            this.isHighlighterVisible = false;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastFocusedElement' does not exist on ty... Remove this comment to see the full error message
            this.lastFocusedElement = null;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastFocusedElementCoords' does not exist... Remove this comment to see the full error message
            this.lastFocusedElementCoords = '';

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'highlighter' does not exist on type 'Foc... Remove this comment to see the full error message
            this.highlighter = null;

            this.ref('self').addClass(this.prefs().classesInited);
            this.ev('resize', debounce(this.handleResize.bind(this), 200), window);
            this.eventBus().on('rendering.applied', 'handleResize');
            this.eventBus().on('highlighter.update', 'updateHighlighter');
        }

        /**
         * @param {refElement} ref - key up event referenced element
         * @param {KeyboardEvent} event - key up event
         * @listens dom#keyup
         * @returns {void}
         */
        handleKeyup(ref, event) {
            if (event.keyCode === KEY_TAB) {
                this.enableHighlighter();
            }
        }

        /**
         * @param {refElement} ref - click event referenced element
         * @param {MouseEvent} event - click event
         * @listens dom#click
         * @returns {void}
         */
        handleClick(ref, event) {
            // Space key up on button DomNode generates synthetic click
            // so we need to ensure that it is not the one

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHighlighterVisible' does not exist on ... Remove this comment to see the full error message
            if (this.isHighlighterVisible && !(event.x === 0 && event.y === 0)) {
                this.disableHighlighter();
            }
        }

        /**
         * @description Window resize event handler
         * @listens dom#resize
         * @returns {void}
         */
        handleResize() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHighlighterVisible' does not exist on ... Remove this comment to see the full error message
            if (this.isHighlighterVisible) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastFocusedElement' does not exist on ty... Remove this comment to see the full error message
                this.moveTo(this.lastFocusedElement);
            }
        }

        /**
         * @description Update highlighter position. Needed when we need to update position from outside
         * @listens "highlighter.update"
         * @returns {void}
         */
        updateHighlighter() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHighlighterVisible' does not exist on ... Remove this comment to see the full error message
            if (this.isHighlighterVisible && document.activeElement instanceof HTMLElement) {
                this.moveTo(document.activeElement);
            }
        }

        /**
         * @description Handles focus change on page
         * @listens dom#focusin
         * @returns {void}
         */
        handleFocus() {
            if (!(document.activeElement instanceof HTMLElement)) { return; } // Needed only for TS linter

            const focusedElement = document.activeElement;

            if (
                !this.isValidTarget(focusedElement)

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'keyboardModality' does not exist on type... Remove this comment to see the full error message
                || (this.isTextInput(focusedElement) && !this.keyboardModality)
            ) {
                return;
            }

            this.detectHurryNavigation();
            this.moveTo(focusedElement);

            // We need to recheck focused element position since coords could
            // be changed during scroll in carousels, animation, dynamic page changes

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'recheckTimeout' does not exist on type '... Remove this comment to see the full error message
            if (this.recheckTimeout) { this.recheckTimeout(); }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'recheckSecondTimeout' does not exist on ... Remove this comment to see the full error message
            if (this.recheckSecondTimeout) { this.recheckSecondTimeout(); }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'recheckTimeout' does not exist on type '... Remove this comment to see the full error message
            this.recheckTimeout = timeout(() => this.moveTo(document.activeElement), 400);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'recheckSecondTimeout' does not exist on ... Remove this comment to see the full error message
            this.recheckSecondTimeout = timeout(() => this.moveTo(document.activeElement), 800);
        }

        /**
         * @description Enables a `highlighter` - a bordered box with sizes of currently focused element.
         * @returns {void}
         */
        enableHighlighter() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'keyboardModality' does not exist on type... Remove this comment to see the full error message
            if (this.keyboardModality) {
                return;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'keyboardModality' does not exist on type... Remove this comment to see the full error message
            this.keyboardModality = true;

            this.ref('highlighter').addClass(this.prefs().classesEnabled);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHighlighterVisible' does not exist on ... Remove this comment to see the full error message
            this.isHighlighterVisible = true;
            this.onDestroy(() => this.ref('highlighter').removeClass(this.prefs().classesEnabled));

            this.handleFocus();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'focusinHandlers' does not exist on type ... Remove this comment to see the full error message
            this.focusinHandlers = this.ev('focusin', this.handleFocus, document);
            // all other events are handled by `focus in` event
        }

        /**
         * @description Disables a `highlighter` - a bordered box with sizes of currently focused element.
         * @returns {void}
         */
        disableHighlighter() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'keyboardModality' does not exist on type... Remove this comment to see the full error message
            if (!this.keyboardModality) {
                return;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'keyboardModality' does not exist on type... Remove this comment to see the full error message
            this.keyboardModality = false;

            this.ref('highlighter').removeClass(this.prefs().classesEnabled);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHighlighterVisible' does not exist on ... Remove this comment to see the full error message
            this.isHighlighterVisible = false;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'hideTimeOut' does not exist on type 'Foc... Remove this comment to see the full error message
            this.hideTimeOut = timeout(this.hide.bind(this), this.prefs().DELAY_TIMEOUT);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'focusinHandlers' does not exist on type ... Remove this comment to see the full error message
            if (this.focusinHandlers) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'focusinHandlers' does not exist on type ... Remove this comment to see the full error message
                this.focusinHandlers.forEach(fn => fn());
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastFocusedElement' does not exist on ty... Remove this comment to see the full error message
            this.lastFocusedElement = null;
        }

        /**
         * @description Moves `highlighter` (a border box) in place of focused element
         * @param {HTMLElement} focusedElement - element, which gets focus
         */
        moveTo(focusedElement) {
            if (!(focusedElement instanceof HTMLElement)) { return; }

            const highlighterNode = this.ref('highlighter').get();

            if (!highlighterNode) { return; }

            // eslint-disable-next-line spellcheck/spell-checker
            const targetRectangle = focusedElement.getBoundingClientRect();
            const targetTop = targetRectangle.top + window.scrollY;
            const targetLeft = targetRectangle.left + window.scrollX;
            const targetWidth = focusedElement.offsetWidth;
            const targetHeight = focusedElement.offsetHeight;

            if (

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastFocusedElement' does not exist on ty... Remove this comment to see the full error message
                focusedElement === this.lastFocusedElement

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastFocusedElementCoords' does not exist... Remove this comment to see the full error message
                && this.lastFocusedElementCoords === '' + targetTop + targetLeft + targetWidth + targetHeight
            ) {
                // If we come from coords recheck do not reapply changes
                return;
            }

            const highlighterStyle = highlighterNode.style;

            highlighterStyle.top = `${targetTop - 5}px`;
            highlighterStyle.left = `${targetLeft - 5}px`;
            highlighterStyle.width = `${targetWidth + 2}px`;
            highlighterStyle.height = `${targetHeight + 2}px`;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastFocusedElementCoords' does not exist... Remove this comment to see the full error message
            this.lastFocusedElementCoords = '' + targetTop + targetLeft + targetWidth + targetHeight;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastFocusedElement' does not exist on ty... Remove this comment to see the full error message
            this.lastFocusedElement = focusedElement;
        }

        /**
         * @description Hide `highlighter`
         * @returns {this} result
         */
        hide() {
            const highlighterNode = this.ref('highlighter').get();

            if (!highlighterNode) {
                return this;
            }

            const highlighterStyle = highlighterNode.style;

            highlighterStyle.width = '0';
            highlighterStyle.height = '0';

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'hideTimeOut' does not exist on type 'Foc... Remove this comment to see the full error message
            if (this.hideTimeOut) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'hideTimeOut' does not exist on type 'Foc... Remove this comment to see the full error message
                this.hideTimeOut();
            }

            return this;
        }

        /**
         * @description Detects too fast customer navigation, and displays `highlighter`,
         * moving from previously focused element to currently focused without animation.
         * @returns {void}
         */
        detectHurryNavigation() {
            const currentTime = Date.now();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastKeyTime' does not exist on type 'Foc... Remove this comment to see the full error message
            const isHurryNavigation = (currentTime - (this.lastKeyTime || 0)) < 190;

            this.ref('highlighter').toggleClass(this.prefs().classesHurry, isHurryNavigation);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHurryNavigation' does not exist on typ... Remove this comment to see the full error message
            this.isHurryNavigation = isHurryNavigation;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastKeyTime' does not exist on type 'Foc... Remove this comment to see the full error message
            this.lastKeyTime = currentTime;
        }

        /**
         * @param {HTMLElement} domNode - focused element
         * @returns {boolean} is HTML element valid target for focus
         */
        isValidTarget(domNode) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastFocusedElement' does not exist on ty... Remove this comment to see the full error message
            return domNode !== this.lastFocusedElement
                && domNode.nodeName !== 'HTML'
                && domNode.nodeName !== 'BODY';
        }

        /**
         * @param {HTMLTextAreaElement|HTMLInputElement|HTMLElement} domNode - focused element
         * @returns {boolean} is HTML element text input
         */
        isTextInput(domNode) {
            return (domNode.tagName === 'TEXTAREA' && !domNode.readOnly)

                || (domNode.tagName === 'INPUT' && !domNode.readOnly)
                || !!(domNode.getAttribute('contenteditable'));
        }
    }

    return FocusHighlighter;
}
