import eventBus from 'widgets/toolbox/eventBus';
import { get } from 'widgets/toolbox/util';
import {
    trackProductClick, setTilePosition, pageProperties, report
} from '../utils/base';
import {
    getWishlistImpressions, impressionsDebounce, hasBeenImpressed, reportImpression
} from './impressions';
import { getActiveViewtypeName } from 'widgets/toolbox/viewtype';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

/**
 * @description GTM events
 * @module events
 * @category widgets
 * @subcategory gtm/components
 */
let shippingMethodName = '';
const viewDevicesMap = {
    sm: 'Mobile',
    md: 'Tablet',
    lg: 'Desktop ',
    xl: 'Desktop '
};

const DATA_LAYER_EVENT_LABEL = 'data-layer-event';
const DATA_LAYER_ANALYTICS_LABEL = 'data-analytics';

/**
 * @description Add cart info on cart change
 * @private
 * @param {object} response - GTM cart details
 * @returns {void}
 */
function reportOnLoadBasketDetails(response) {
    let gtmCartData = null;

    if (response.analyticsOnBasketLoadDetails) {
        gtmCartData = response.analyticsOnBasketLoadDetails;
    } else if (response.cartModel && response.cartModel.analyticsOnBasketLoadDetails) {
        gtmCartData = response.cartModel.analyticsOnBasketLoadDetails;
    }

    let context;

    if (response.cartModel && 'isQuantityIncreased' in response.cartModel && gtmCartData) {
        context = response.cartModel.isQuantityIncreased ? 'add_to_cart' : 'remove_from_cart';
        // @ts-expect-error TS2339: Property 'context' does not exist on type 'never'.
        gtmCartData.context = context;
    }

    if (gtmCartData && response.cartModel && response.cartModel.action && response.cartModel.action.includes('Cart-AddProduct')) {
        context = 'add_to_cart';
        // @ts-expect-error TS2339: Property 'context' does not exist on type 'never'.
        gtmCartData.context = context;
    }

    if ((gtmCartData && response.cartModel && response.cartModel.isProductRemoved) || response.isProductRemoved) {
        context = 'remove_from_cart';
        // @ts-expect-error TS2339: Property 'context' does not exist on type 'never'.
        gtmCartData.context = context;
    }

    if (gtmCartData) {
        report({
            basket: null
        });
        report({
            event: 'basketLoad',
            basket: gtmCartData
        });
    }
}

/**
 * @description reports add to cart events
 * @private
 * @param {object} cart - cart model
 * @param {object} widget - current widget
 * @param {Array.<object>} listOfWidgets - list of widgets
 * @param {String} subProduct - Subscription product ID
 */
function reportProductAddedToCart(cart, widget, listOfWidgets, subProduct) {
    let subscriptionInfo;

    if (subProduct) {
        subscriptionInfo = listOfWidgets
            ? listOfWidgets.map((widgetItem) => widgetItem.data('subscriptionAnalytics'))
            : widget.currentGtmInfo || widget.data('subscriptionAnalytics');
    }

    const tileInfo = listOfWidgets
        ? listOfWidgets.map((widgetItem) => widgetItem.data('analytics'))
        : widget.currentGtmInfo || widget.data('analytics');

    if (!tileInfo) {
        return;
    }

    if (!tileInfo.quantity) {
        tileInfo.quantity = 1;
    }

    const products = [].concat(tileInfo);

    if (subscriptionInfo) {
        subscriptionInfo.quantity = 1;
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        products.push(subscriptionInfo);
    }

    report({
        event: 'addToCart',
        ecommerce: {
            currencyCode: pageProperties.currentCurrency,
            add: {
                products: products
            }
        }
    });

    report({ ecommerce: null });
}

/**
 * @description GTM event on modal open
 * @private
 * @param {HTMLElement} el Source of event
 * @returns {void}
 */
function reportDialog(el) {
    const gtmModalType = el.data && el.data('gtmEventModalType');

    if (gtmModalType) {
        if (gtmModalType === 'findStoreDialog') {
            report({
                event: DATA_LAYER_EVENT_LABEL,
                eventCategory: 'Store stock checker interaction',
                eventAction: 'Store stock step 1: find store',
                eventLabel: el.data('gtmEventLabel')
            });
        }
    }
}

/**
 * @description GTM event on minicart show / render
 * @param {boolean} skipEvent - align same event for show and rerender minicart
 */
function reportMinicart(skipEvent) {
    if (skipEvent) {
        return;
    }

    const selector :any = document.getElementById('gtmMinicartBasketLoadDetails');
    let gtmBasketData :any = null;

    if (selector && selector.getAttribute(DATA_LAYER_ANALYTICS_LABEL)) {
        try {
            gtmBasketData = JSON.parse(selector.getAttribute(DATA_LAYER_ANALYTICS_LABEL));
        } catch (error) {
            gtmBasketData = null;
        }

        reportOnLoadBasketDetails({ analyticsOnBasketLoadDetails: gtmBasketData });
    }
}

/**
 * @description Init GTM events
 * @returns {void}
 */
export function init() {
    eventBus.on('dialogshow', reportDialog);

    /**
     * @description
     * @event "navigation.click.event"
     * @listens "navigation.click.event"
     * @returns {void}
     */
    eventBus.on('navigation.click.event', gtmObject => {
        if (!gtmObject.eventLabel && gtmObject.eventLabel !== '') {
            gtmObject.eventLabel = viewDevicesMap[getActiveViewtypeName()];
        }

        report(gtmObject);
    });

    /**
     * @description
     * @event "gtm.click.event"
     * @listens "gtm.click.event"
     * @returns {void}
     */
    eventBus.on('gtm.click.event', gtmObject => {
        report(gtmObject);
    });

    /**
     * @description
     * @event "gtm.datalayer.event"
     * @listens "gtm.datalayer.event"
     * @returns {void}
     */
    eventBus.on('gtm.datalayer.event', gtmObject => {
        report(gtmObject);
    });

    /**
     * @description
     * @event "product.added.to.cart"
     * @listens "product.added.to.cart"
     * @returns {void}
     */
    eventBus.on('product.added.to.cart', response => {
        reportOnLoadBasketDetails(response);
    });

    /**
     * @description Listen Cart changes
     * @event "cart.updated"
     * @listens "cart.updated"
     * @returns {void}
     */
    eventBus.on('cart.updated', response => {
        reportOnLoadBasketDetails(response);
    });

    /**
     * @description Listen Edit product in cart event
     * @event "product.updated"
     * @listens "product.updated"
     * @returns {void}
     */
    eventBus.on('product.updated', response => {
        reportOnLoadBasketDetails(response);
    });

    /**
     * @description Listen minicart events
     * @event "minicart.updated"
     * @listens "minicart.updated"
     * @returns {void}
     */
    eventBus.on('minicart.updated', response => {
        reportOnLoadBasketDetails(response);
    });

    eventBus.on('virtual.page.load', (analyticsPageData?: Record<string, any>) => {
        const basketDataSelector :any = document.getElementById('gtmVirtualBasketLoadDetails');
        let gtmBasketData :any = null;

        if (basketDataSelector && basketDataSelector.getAttribute(DATA_LAYER_ANALYTICS_LABEL)) {
            try {
                gtmBasketData = JSON.parse(basketDataSelector.getAttribute(DATA_LAYER_ANALYTICS_LABEL));
            } catch (error) {
                gtmBasketData = null;
            }

            reportOnLoadBasketDetails({ analyticsOnBasketLoadDetails: gtmBasketData });
        }

        const pageDataSelector :any = document.getElementById('gtmVirtualPageLoadDetails');
        let onLoadPageDetails :any = null;

        if (analyticsPageData) {
            onLoadPageDetails = analyticsPageData;
        } else if (pageDataSelector && pageDataSelector.getAttribute(DATA_LAYER_ANALYTICS_LABEL)) {
            try {
                onLoadPageDetails = JSON.parse(pageDataSelector.getAttribute(DATA_LAYER_ANALYTICS_LABEL));
            } catch (error) {
                onLoadPageDetails = null;
            }
        }

        if (onLoadPageDetails) {
            const viewTypesMap = {
                sm: 'small',
                md: 'medium',
                lg: 'large',
                xl: 'x-large'
            };
            const viewType = getActiveViewtypeName();

            onLoadPageDetails.event = 'pageLoad';
            onLoadPageDetails.designSize = viewTypesMap[viewType];

            report(onLoadPageDetails);
        }
    });

    /**
     * @description Listen minicart events
     * @event "minicart.show"
     * @listens "minicart.show"
     * @returns {void}
     */
    eventBus.on('minicart.showed', (skipEvent) => {
        reportMinicart(skipEvent);

        report({
            event: 'module',
            pageLevel1: 'Mini basket'
        });
    });

    /**
     * @description Listen minicart events
     * @event "minicart.rendered"
     * @listens "minicart.rendered"
     * @returns {void}
     */
    eventBus.on('minicart.rendered', (skipEvent) => {
        reportMinicart(skipEvent);
    });

    /**
     * @description Listen order confirmation event
     * @event "show.order.confirmation"
     * @listens "show.order.confirmation"
     * @returns {void}
     */
    eventBus.on('show.order.confirmation', (data) => {
        if (!data) {
            return;
        }

        report({ event: 'purchase', ...data });
    });

    /**
     * @description Tracks in dataLayer an information about product impressions on product tile init
     * @event "product.tile.init"
     * @listens "product.tile.init"
     * @returns {void}
     */
    eventBus.on('product.tile.init', tile => {
        const impression = tile.data('analytics');

        if (!impression) {
            return;
        }

        impression.productID = tile.data('pid');

        if (impression && !hasBeenImpressed(impression.productID || impression.id)) {
            setTilePosition(impression, impression.productID);

            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            impressionsDebounce.push(impression);

            // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
            reportImpression();
        }
    });
    /**
     * @description Tracks in dataLayer an information about product impressions on wishlist products init
     * @event "wishlist.products.init"
     * @listens "wishlist.products.init"
     * @returns {void}
     */
    eventBus.on('wishlist.products.init', (products, step) => {
        const impressions = getWishlistImpressions(products);

        if (!impressions.length) {
            return;
        }

        const event = {
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                impressions: impressions
            }
        };

        if (step && (step === 'browsing')) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'event' does not exist on type '{ ecommer... Remove this comment to see the full error message
            event.event = 'productImpression';
        }

        report({ ecommerce: null });
        report(event);
    });

    /**
     * @description Tracks in dataLayer an information about quick view opening
     * @event "product.tile.qv.open"
     * @listens "product.tile.qv.open"
     * @returns {void}
     */
    eventBus.on('product.tile.qv.open', qvData => {
        report({ ecommerce: null });
        report({
            event: 'productView',
            ecommerce: {
                detail: {
                    actionField: { list: 'Product quick view' },
                    products: [qvData.gtmInfo]
                }
            }
        });
    });

    /**
     * @description Tracks in dataLayer an information about product details loading
     * @event "product.tile.qv.open"
     * @listens "product.tile.qv.open"
     * @returns {void}
     */
    eventBus.on('product.details.open', products => {
        const productsData = products.map((product) => product.data('analytics'));
        const gtmProductViewDataJson = localStorageWrapper.getItem('gtmProductViewData');
        let gtmProductViewData = {} as any;

        try {
            gtmProductViewData = JSON.parse(gtmProductViewDataJson);
        } catch (error) {
            gtmProductViewData = {
                productTilePosition: '',
                productTileList: ''
            };
        }

        productsData.forEach((productData) => {
            productData.position = gtmProductViewData?.productTilePosition || '';
        });

        report({ ecommerce: null });
        report({
            event: 'productView',
            ecommerce: {
                detail: {
                    actionField: { list: gtmProductViewData?.productTileList || '' },
                    products: [...productsData]
                }
            }
        });

        localStorageWrapper.removeItem('gtmProductViewData');
    });

    /**
     * @description Tracks in dataLayer an information about product attributes select
     * @event "product.attribute.select"
     * @listens "product.attribute.select"
     * @returns {void}
     */
    eventBus.on('product.attribute.select', product => {
        const productData = product.gtmInfo;
        const gtmProductViewDataJson = localStorageWrapper.getItem('gtmProductViewData');
        let gtmProductViewData = {} as any;

        try {
            gtmProductViewData = JSON.parse(gtmProductViewDataJson);
        } catch (error) {
            gtmProductViewData = {
                productTilePosition: '',
                productTileList: ''
            };
        }

        productData.position = gtmProductViewData?.productTilePosition || '';

        report({ ecommerce: null });
        report({
            event: 'productView',
            ecommerce: {
                detail: {
                    actionField: { list: gtmProductViewData?.productTileList || '' },
                    products: [productData]
                }
            }
        });

        localStorageWrapper.removeItem('gtmProductViewData');
    });

    /**
     * @description Tracks in dataLayer an information about quick view opening on editing product step
     * @event "product.tile.qv.open.edit"
     * @listens "product.tile.qv.open.edit"
     * @returns {void}
     */
    eventBus.on('product.tile.qv.open.edit', editEl => {
        if (!editEl) {
            return;
        }

        const productInfo = editEl.data('analytics');

        if (!productInfo) {
            return;
        }

        report({ ecommerce: null });
        report({
            ecommerce: {
                detail: {
                    actionField: { list: 'Product quick view' },
                    products: [productInfo]
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about product tile link click
     * @event "tile.product.link.click"
     * @listens "tile.product.link.click"
     * @returns {void}
     */
    eventBus.on('tile.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about cart product tile link click
     * @event "cart.product.link.click"
     * @listens "cart.product.link.click"
     * @returns {void}
     */
    eventBus.on('cart.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about minicart product tile link click
     * @event "minicart.product.link.click"
     * @listens "minicart.product.link.click"
     * @returns {void}
     */
    eventBus.on('minicart.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about product details link click
     * @event "detail.product.link.click"
     * @listens "detail.product.link.click"
     * @returns {void}
     */
    eventBus.on('detail.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about order tile link click
     * @event "orderdetail.product.link.click"
     * @listens "orderdetail.product.link.click"
     * @returns {void}
     */
    eventBus.on('orderdetail.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about searchbox product tile link click
     * @event "searchbox.product.link.click"
     * @listens "searchbox.product.link.click"
     * @returns {void}
     */
    eventBus.on('searchbox.product.link.click', link => {
        trackProductClick(link);
    });
    /**
     * @description Tracks in dataLayer an information about wishlist product tile link click
     * @event "wishlist.product.link.click"
     * @listens "wishlist.product.link.click"
     * @returns {void}
     */
    eventBus.on('wishlist.product.link.click', link => {
        trackProductClick(link);
    });

    /**
     * @description Tracks in dataLayer an information about adding products to cart
     * @event "product.added.to.cart"
     * @listens "product.added.to.cart"
     * @returns {void}
     */
    eventBus.on('product.added.to.cart', (cart, widget, listOfWidgets, subProduct) => {
        reportProductAddedToCart(cart, widget, listOfWidgets, subProduct);
    });

    /**
     * @description Tracks in dataLayer an information about adding products to cart
     * @event "just.added.product.to.cart"
     * @listens "just.added.product.to.cart"
     * @returns {void}
     */
    eventBus.on('just.added.product.to.cart', (cart, widget, listOfWidgets, subProduct) => {
        reportProductAddedToCart(cart, widget, listOfWidgets, subProduct);
    });

    /**
     * @description Tracks in dataLayer an information about adding products to cart trough incrementing on cart page
     * @event "product.cart.qty.increment"
     * @listens "product.cart.qty.increment"
     * @returns {void}
     */
    eventBus.on('product.cart.qty.increment', (widget, qty) => {
        const tileInfo = widget.currentGtmInfo || widget.data('analytics');

        if (!tileInfo) {
            return;
        }

        if (qty || !tileInfo.quantity) {
            tileInfo.quantity = qty || 1;
        }

        report({
            event: 'addToCart',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                add: {
                    products: [].concat(tileInfo)
                }
            }
        });

        report({ ecommerce: null });
    });

    /**
     * @description Tracks in dataLayer an information about adding products to cart trough decrementing on cart page
     * @event "product.cart.qty.decrement"
     * @listens "product.cart.qty.decrement"
     * @returns {void}
     */
    eventBus.on('product.cart.qty.decrement', (widget, qty) => {
        const tileInfo = widget.currentGtmInfo || widget.data('analytics');

        if (!tileInfo) {
            return;
        }

        if (qty || !tileInfo.quantity) {
            tileInfo.quantity = qty || 1;
        }

        report({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                remove: {
                    products: [].concat(tileInfo)
                }
            }
        });

        report({ ecommerce: null });
    });
    /**
     * @description Tracks in dataLayer an information about updates on cart
     * @event "cart.updated"
     * @listens "cart.updated"
     * @returns {void}
     */
    eventBus.on('cart.updated', cart => {
        if (cart && cart.items) {
            cart.items.forEach(item => {
                if (item.gtmInfo) {
                    item.gtmInfoStr = JSON.stringify(item.gtmInfo);
                }
            });
        }
    });
    /**
     * @description Tracks in dataLayer an information about removing products from cart
     * @event "cart.remove.product"
     * @listens "cart.remove.product"
     * @returns {void}
     */
    eventBus.on('cart.remove.product', cart => {
        if (!cart.removeProductLink) {
            return;
        }

        const tileInfo = cart.removeProductLink.data('analytics');

        if (!tileInfo) {
            return;
        }

        tileInfo.quantity = parseInt(cart.removeProductLink.data('quantity'), 10) || 1;
        delete tileInfo.list;

        report({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                remove: {
                    products: [tileInfo]
                }
            }
        });

        report({ ecommerce: null });
    });
    /**
     * @description Tracks in dataLayer an information about removing products from minicart
     * @event "minicart.remove.product"
     * @listens "minicart.remove.product"
     * @returns {void}
     */
    eventBus.on('minicart.remove.product', minicart => {
        if (!minicart.removeProductLink) {
            return;
        }

        const tileInfo = minicart.removeProductLink.data('analytics');

        if (!tileInfo) {
            return;
        }

        tileInfo.quantity = parseInt(minicart.removeProductLink.data('quantity'), 10) || 1;

        report({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: pageProperties.currentCurrency,
                remove: {
                    products: [tileInfo]
                }
            }
        });

        report({ ecommerce: null });
    });
    /**
     * @description Tracks in dataLayer an information about customer's redirect to checkout
     * @event "cart.page.submitted"
     * @listens "cart.page.submitted"
     * @returns {void}
     */
    /* eslint-disable sonarjs/cognitive-complexity */
    eventBus.on('cart.page.submitted', (CartMgr, checkoutType) => {
        const RESTRICTED_KEYS = ['list'];

        if (!CartMgr) {
            return;
        }

        const products: any[] = [];
        const items = CartMgr.cart?.items ? CartMgr.cart.items : CartMgr.data('items');
        const currencyCode = CartMgr.cart.currencyCode;

        if (items && items.length) {
            items.forEach(item => {
                const product = { quantity: item.quantity };
                let { gtmInfo } = item;

                if (typeof gtmInfo === 'string') {
                    try {
                        gtmInfo = JSON.parse(gtmInfo);
                    } catch (err) {
                        gtmInfo = {};
                    }
                }

                Object.keys(gtmInfo).forEach((gtmInfoKey) => {
                    if (!RESTRICTED_KEYS.includes(gtmInfoKey)) {
                        product[gtmInfoKey] = gtmInfo[gtmInfoKey];
                    }
                });

                products.push(product);
            });
        }

        let checkoutMethod = 'Checkout';

        if (checkoutType === 'paypal') {
            checkoutMethod = 'PayPal';
        }

        if (checkoutType === 'applepay') {
            checkoutMethod = 'Apple Pay';
        }

        report({ ecommerce: null });
        report({
            event: 'checkout',
            checkout_method: checkoutMethod,
            ecommerce: {
                currencyCode: currencyCode,
                checkout: {
                    actionField: {
                        step: 1
                    },
                    products
                }
            }
        });
    });

    /**
     * @description Tracks in dataLayer an information about customer's redirect to checkout
     * @event "cart.page.submitted"
     * @listens "cart.page.submitted"
     * @returns {void}
     */
    /* eslint-disable sonarjs/cognitive-complexity */
    eventBus.on('account.activated', (optedIn, email) => {
        const timestamp = new Date();
        const event = {
            event: 'data-layer-event',
            eventCategory: 'Key interactions',
            eventAction: 'Account Activation',
            eventLabel: optedIn ? 'Opted in' : '',
            email_id: email,
            Timestamp: Math.floor(timestamp.getTime() / 1000)
        };

        report(event);
    });

    /* eslint-enable sonarjs/cognitive-complexity */
    /**
     * @description Tracks in dataLayer an information about selected shipping method
     * @event "cart.update.shipping.method"
     * @listens "cart.update.shipping.method"
     * @returns {void}
     */
    eventBus.on('cart.update.shipping.method', (newShippingMethodName, currencyCode) => {
        if (shippingMethodName === newShippingMethodName) {
            return;
        }

        shippingMethodName = newShippingMethodName;

        report({ ecommerce: null });
        report({
            event: 'checkout_option',
            ecommerce: {
                currencyCode: currencyCode,
                checkout: {
                    actionField: {
                        step: 2,
                        option: shippingMethodName
                    }
                }
            }
        });
    });

    /**
     * @description GTM event on checkout step activation
     * @event "checkout.step.loaded"
     * @listens "checkout.step.loaded"
     * @returns {void}
     */
    eventBus.on('checkout.step.loaded', (order, step) => {
        const stepMap = {
            shipping: 2,
            billing: 3,
            summary: 4
        };
        const currencyCode = order.currencyCode;
        const products = [];

        // @ts-expect-error Argument of type 'any' is not assignable to parameter of type 'never'.
        order.items.items.forEach(el => products.push(el.gtmInfo));

        const checkout = {
            actionField: {
                step: stepMap[step]
            },

            products: products
        };

        if (stepMap[step] === 2) {
            // @ts-expect-error Property 'option' does not exist on type '{ step: any; }'.
            checkout.actionField.option = order.shipping[0]?.selectedShippingMethod?.displayName;
        } else if (stepMap[step] === 3) {
            // @ts-expect-error Property 'option' does not exist on type '{ step: any; }'.
            checkout.actionField.option = order.billing.payment?.selectedPaymentInstruments[0]?.name || '';
        }

        report({ ecommerce: null });
        report({
            event: 'checkout',
            ecommerce: {
                currencyCode,
                checkout
            }
        });
    });

    /**
     * @description Tracks in dataLayer an information about customer's filling a shipping step
     * @event "checkout.shipping.step.submitted"
     * @listens "checkout.shipping.step.submitted"
     * @returns {void}
     */
    eventBus.on('checkout.shipping.step.submitted', checkoutMgr => {
        if (checkoutMgr.order && checkoutMgr.order.gtmInfo) {
            shippingMethodName = get(checkoutMgr, 'order.shipping.0.selectedShippingMethod.displayName');

            if (!shippingMethodName) {
                const applicableShippingMethods = get(checkoutMgr, 'order.shipping.0.applicableShippingMethods') || [];

                applicableShippingMethods.forEach(applicableShippingMethod => {
                    if (applicableShippingMethod && applicableShippingMethod.selected) {
                        shippingMethodName = applicableShippingMethod.displayName;
                    }
                });
            }

            report({ ecommerce: null });
            report({
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 2,
                            option: shippingMethodName
                        },
                        products: checkoutMgr.order.gtmInfo.products
                    }
                }
            });
        }
    });
    /**
     * @description Tracks in dataLayer an information about changed payment method
     * @event "checkout.paymentmethod.changed"
     * @listens "checkout.paymentmethod.changed"
     * @returns {void}
     */
    eventBus.on('checkout.paymentmethod.changed', (orderInfo) => {
        if (!orderInfo) {
            return;
        }

        report({ ecommerce: null });
        report({
            event: 'checkout_option',
            ecommerce: {
                currencyCode: orderInfo.currencyCode,
                checkout_option: {
                    actionField: {
                        step: 3,
                        option: orderInfo.name
                    }
                }
            }
        });
    });
    /**
     * @description Tracks in dataLayer an information about customer's filling a billing step
     * @event "checkout.billing.step.submitted"
     * @listens "checkout.billing.step.submitted"
     * @returns {void}
     */
    eventBus.on('checkout.billing.step.submitted', checkoutMgr => {
        if (checkoutMgr.order && checkoutMgr.order.gtmInfo) {
            let paymentMethod = get(checkoutMgr, 'order.billing.payment.selectedPaymentInstruments.0.name');

            if (!paymentMethod) {
                paymentMethod = get(checkoutMgr, 'order.billing.payment.applicablePaymentMethods.0.name') || '';
            }

            report({ ecommerce: null });
            report({
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 3,
                            option: paymentMethod
                        },
                        products: checkoutMgr.order.gtmInfo.products
                    }
                }
            });
        }
    });
    /**
     * @description Tracks in dataLayer an information about customer's submitting summary step
     * @event "checkout.summary.step.submitted"
     * @listens "checkout.summary.step.submitted"
     * @returns {void}
     */
    eventBus.on('checkout.summary.step.submitted', checkoutMgr => {
        if (checkoutMgr.order && checkoutMgr.order.gtmInfo) {
            report({ ecommerce: null });
            report({
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 4
                        },
                        products: checkoutMgr.order.gtmInfo.products
                    }
                }
            });
        }
    });

    /**
     * @description Tracks in dataLayer an information about quick view opening on editing product step
     * @event "product.tile.qv.open.edit"
     * @listens "product.tile.qv.open.edit"
     * @returns {void}
     */
    eventBus.on('osano.events', eventName => {
        report({
            event: eventName
        });
    });

    eventBus.on('ideas.search.terms.selected', searchItem => {
        const searchItemNode = searchItem.get();
        let subLabel = '';

        if (searchItemNode) {
            subLabel = searchItemNode.innerText;
        }

        report({
            event: DATA_LAYER_EVENT_LABEL,
            eventCategory: 'Search',
            eventAction: 'Ideas search',
            eventLabel: 'Suggested Search',
            eventSublabel: subLabel
        });
    });

    /**
     * @description Tracks apply filters
     * @event "apply.refinements.menu"
     * @listens "apply.refinements.menu"
     * @returns {void}
     */
    eventBus.on('apply.refinements.menu', (appliedRefinements, isIdeasSearch) => {
        if (!appliedRefinements || typeof isIdeasSearch === 'undefined') {
            return;
        }

        const analyticsData = appliedRefinements.reduce((refinementsData, refinement) => {
            if (refinement.prefs().gtmEventLabel && refinement.prefs().gtmEventSublabel) {
                refinementsData.push({
                    eventLabel: refinement.prefs().gtmEventLabel,
                    eventSublabel: refinement.prefs().gtmEventSublabel
                });
            }

            return refinementsData;
        }, []);

        if (analyticsData.length) {
            analyticsData.forEach((data) => {
                report({
                    event: DATA_LAYER_EVENT_LABEL,
                    eventCategory: isIdeasSearch ? 'Ideas interaction' : 'Filter interaction',
                    eventAction: 'Apply',
                    eventLabel: data.eventLabel,
                    eventSublabel: data.eventSublabel
                });
            });
        }
    });

    /**
     * @description Tracks moved product to WL event
     * @event "product.moved.to.wishlist"
     * @listens "product.moved.to.wishlist"
     * @returns {void}
     */
    eventBus.on('product.moved.to.wishlist', productData => {
        const addToWishListProduct = 'Add to wishlist - product';
        const addToWishListIdea = 'Add to wishlist - idea';
        const addToWishListLineItem = 'Move to wishlist';

        if (!productData.productName) {
            return;
        }

        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmPageContext' does not exist on type 'Window... Remove this comment to see the full error message
        const { gtmPageContext } = window;
        let eventAction = '';

        if (gtmPageContext && gtmPageContext.pageType === 'cart') {
            eventAction = addToWishListLineItem;
        } else {
            eventAction = productData.isProductSet ? addToWishListIdea : addToWishListProduct;
        }

        report({
            event: DATA_LAYER_EVENT_LABEL,
            eventCategory: 'Wishlist interaction',
            eventAction,
            eventLabel: productData.productName
        });
    });

    eventBus.on('category.search.link.selected', categoryItem => {
        const categoryLinkItemNode = categoryItem.get();
        let subLabel = '';

        if (categoryLinkItemNode) {
            subLabel = categoryLinkItemNode.innerText;
        }

        report({
            event: DATA_LAYER_EVENT_LABEL,
            eventCategory: 'Navigation',
            eventAction: 'Category links',
            eventLabel: viewDevicesMap[getActiveViewtypeName()],
            eventSublabel: subLabel
        });
    });

    eventBus.on('select.search.suggestion.item', selectedItem => {
        const sublabel = selectedItem.data('gtmEventSublabel');
        const label = selectedItem.data('gtmEventLabel');

        if (!sublabel) {
            return;
        }

        report({
            event: DATA_LAYER_EVENT_LABEL,
            eventCategory: 'Search',
            eventAction: 'Search suggestions',
            eventLabel: label || '',
            eventSublabel: sublabel
        });
    });

    /**
     * @description Tracks open just added cart modal
     * @event "open.just.added.cart"
     * @listens "open.just.added.cart"
     * @returns {void}
     */
    eventBus.on('open.just.added.cart', () => {
        report({
            event: 'module',
            pageLevel1: 'add to cart popup'
        });
    });

    /**
     * @description Tracks open reset password modal
     * @event "open.reset.password.modal"
     * @listens "open.reset.password.modal"
     * @returns {void}
     */
    eventBus.on('open.reset.password.modal', () => {
        report({
            event: 'module',
            pageLevel1: 'Password reset pop up'
        });
    });
}
