// eslint-disable-next-line spellcheck/spell-checker
/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */
/* sonar-ignore-start */

import widgetsMgr from 'widgets/widgetsMgr';
import ContextLoader from 'widgets/global/ContextLoader';
import Accordion from 'widgets/global/Accordion';
import AccordionItem from 'widgets/global/AccordionItem';
import Button from 'widgets/global/Button';
import ListAccessibility from 'widgets/global/ListAccessibility';
import SwipeToClose from 'widgets/global/SwipeToClose';
import DismissBlock from 'widgets/global/DismissBlock';
import Form from 'widgets/forms/Form';
import BasicForm from 'widgets/forms/BasicForm';
import AjaxForm from 'widgets/forms/AjaxForm';
import BasicInput from 'widgets/forms/BasicInput';
import InputEmail from 'widgets/forms/InputEmail';
import InputPassword from 'widgets/forms/InputPassword';
import InputCheckbox from 'widgets/forms/InputCheckbox';
import InputText from 'widgets/forms/InputText';
import InputTextarea from 'widgets/forms/InputTextarea';
import InputHidden from 'widgets/forms/InputHidden';
import InputTel from 'widgets/forms/InputTel';
import MaskableInput from 'widgets/forms/MaskableInput';
import Tabs from 'widgets/global/Tabs';
import TabPanel from 'widgets/global/TabPanel';
import InputSelect from 'widgets/forms/InputSelect';
import Label from 'widgets/global/Label';
import EmailSubscribe from 'widgets/forms/ajax/EmailSubscribe';
import ConfirmDialog from 'widgets/global/ConfirmDialog';
import Carousel from 'widgets/global/Carousel';
import Video from 'widgets/global/Video';
import BackToTop from 'widgets/global/BackToTop';
import Modal from 'widgets/global/Modal';
import InputRadio from 'widgets/forms/InputRadio';
import ConsentTracking from 'widgets/global/ConsentTracking';
import EmitBusEvent from 'widgets/global/EmitBusEvent';
import AjaxFormResponseContainer from 'widgets/forms/AjaxFormResponseContainer';
import Combobox from 'widgets/forms/Combobox';
import SearchBox from 'widgets/search/SearchBox';
import FocusHighlighter from 'widgets/global/FocusHighlighter';
import StickyHeader from 'widgets/global/StickyHeader';
import ButtonWithIcon from 'widgets/global/ButtonWithIcon';
import AccessibilityFocusTrapMixin from 'widgets/global/AccessibilityFocusTrapMixin';
import AccessibilityFocusMixin from 'widgets/global/AccessibilityFocusMixin';
import SoftKeyboardToggleMixin from 'widgets/global/SoftKeyboardToggleMixin';
import ProductTile from 'widgets/product/ProductTile';
import ProductOptionMixin from 'widgets/product/ProductOptionMixin';
import AlternativeImage from 'widgets/product/AlternativeImage';
import Disclosure from 'widgets/global/Disclosure';
import GlobalAlerts from 'widgets/global/GlobalAlerts';
import GlobalModal from 'widgets/global/GlobalModal';
import InputStepper from 'widgets/forms/InputStepper';
import ProcessButton from 'widgets/global/ProcessButton';
import HeroCarousel from 'widgets/global/HeroCarousel';

widgetsMgr.addWidgetsList('global.widgets', () => [
    ['contextLoader', ContextLoader],
    ['label', Label],
    ['carousel', Carousel],
    ['video', Video],
    ['button', Button],
        ['buttonWithIcon', ButtonWithIcon, 'button'],
        ['processButton', ProcessButton, 'button'],
    ['listAccessibility', ListAccessibility],
        ['refinementsPanel', Modal, 'listAccessibility'],
        ['refinementsPanel', SwipeToClose, 'refinementsPanel'],
        ['accordion', Accordion, 'listAccessibility'],
    ['accordionItem', AccordionItem],
    ['swipeToClose', SwipeToClose],
    ['dismissBlock', DismissBlock],
    ['backtotop', BackToTop],
    ['consentTracking', ConsentTracking],
    ['emitBusEvent', EmitBusEvent],
    ['focusHighlighter', FocusHighlighter],
    ['stickyHeader', StickyHeader],
    ['basicForm', BasicForm],
        ['form', Form, 'basicForm'],
            ['ajaxform', AjaxForm, 'basicForm'],
                ['emailSubscribe', EmailSubscribe, 'ajaxform'],
    ['basicInput', BasicInput],
        ['inputPassword', InputPassword, 'basicInput'],
        ['inputCheckbox', InputCheckbox, 'basicInput'],
        ['inputSelect', InputSelect, 'basicInput'],
        ['inputText', InputText, 'basicInput'],
            ['inputEmail', InputEmail, 'inputText'],
            ['inputTextarea', InputTextarea, 'inputText'],
            ['inputTel', InputTel, 'inputText'],
                ['inputTel', MaskableInput, 'inputTel'],
        ['inputRadio', InputRadio, 'basicInput'],
        ['inputHidden', InputHidden, 'basicInput'],
        ['inputStepper', InputStepper, 'basicInput'],
    ['modal', Modal],
        ['modal', AccessibilityFocusTrapMixin, 'modal'],
        ['globalModal', GlobalModal, 'modal'],
        ['confirmDialog', ConfirmDialog, 'modal'],
    ['combobox', Combobox],
        ['searchBox', SearchBox, 'combobox'],
            ['searchBox', AccessibilityFocusTrapMixin, 'searchBox'],
            ['searchBox', SoftKeyboardToggleMixin, 'searchBox'],
            ['searchBox', Modal, 'searchBox'],
    ['tabs', Tabs],
    ['tabPanel', TabPanel],
    ['productTile', ProductTile],
        ['productTile', AlternativeImage, 'productTile'],
        ['productTile', ProductOptionMixin, 'productTile'],
    ['ajaxFormResponseContainer', AjaxFormResponseContainer, 'modal'],
    ['disclosure', Disclosure],
    ['globalAlerts', GlobalAlerts],
    ['heroCarousel', HeroCarousel]
]);

const hasContext = (context) => window.contexts.includes(context);

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('header'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MenuPanel').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MenuPanelToggle').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MenuBarItem').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/HamburgerMenuItem').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/CountrySelector').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/CountrySelectorMenuItem').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MegaMenu').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MenuButton').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/cart/Minicart').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/cart/MinicartDialog').then(d => d.default)
    ]).then(deps => {
        const [
            MenuPanel,
            MenuPanelToggle,
            MenuBarItem,
            HamburgerMenuItem,
            CountrySelector,
            CountrySelectorMenuItem,
            MegaMenu,
            MenuButton,
            Minicart,
            MinicartDialog
        ] = deps;

        return {
            listId: 'header.widgets',
            widgetsDefinition: () => [
                ['menuPanelToggle', MenuPanelToggle],
                ['countrySelector', CountrySelector],
                // listAccessibility
                    ['megaMenu', MegaMenu, 'listAccessibility'],
                    ['menuBarItem', MenuBarItem, 'listAccessibility'],
                    ['hamburgerMenuItem', HamburgerMenuItem, 'listAccessibility'],
                        ['countrySelectorMenuItem', CountrySelectorMenuItem, 'hamburgerMenuItem'],
                            ['countrySelectorMenuItem', CountrySelector, 'countrySelectorMenuItem'],
                    ['menuButton', MenuButton, 'listAccessibility'],
                // swipeToClose
                    ['menuPanel', MenuPanel, 'swipeToClose'],
                ['minicart', Minicart],
                // modal
                ['minicartDialog', MinicartDialog, 'modal']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('content'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'content.widgets' */ 'widgets/content/ContactUs').then(d => d.default)
    ]).then(deps => {
        const [ContactUs] = deps;

        return {
            listId: 'content.widgets',
            widgetsDefinition: () => [
                ['contactUs', ContactUs]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('home'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductImages').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductVideoMixin').then(d => d.default)
    ]).then(deps => {
        const [ProductImages, ProductVideoMixin] = deps;

        return {
            listId: 'homepage.widgets',
            widgetsDefinition: () => [
                ['productImages', ProductImages],
                    ['productImages', AccessibilityFocusTrapMixin, 'productImages'],
                    ['productImages', ProductVideoMixin, 'productImages']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductDetail').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/AddAllToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/RecentlyViewedMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductSet').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductImages').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductVideoMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ColorSwatch').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/AddToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/forms/ajax/BackInStock').then(d => d.default)
    ]).then(deps => {
        const [ProductDetail, AddAllToCartMixin, RecentlyViewedMixin, ProductSet, ProductImages, ProductVideoMixin, ColorSwatch, AddToCartMixin, BackInStock] = deps;

        return {
            listId: 'product.widgets',
            widgetsDefinition: () => [
                ['productDetail', ProductDetail],
                    ['productDetail', AddToCartMixin, 'productDetail'],
                    ['productDetail', ProductOptionMixin, 'productDetail'],
                    // Used for backInStock functionality
                    ['productDetail', Disclosure, 'productDetail'],
                    ['productDetail', RecentlyViewedMixin, 'productDetail'],
                ['productSet', ProductSet],
                    ['productSet', AddAllToCartMixin, 'productSet'],
                    ['productSet', RecentlyViewedMixin, 'productSet'],
                ['productImages', ProductImages],
                    ['productImages', AccessibilityFocusTrapMixin, 'productImages'],
                    ['productImages', ProductVideoMixin, 'productImages'],
                ['colorSwatch', ColorSwatch],
                // eslint-disable-next-line spellcheck/spell-checker
                // basicForm / ajaxform
                ['backInStock', BackInStock, 'ajaxform']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('plp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/ProductListingMgr').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/RefinementsPanelToggle').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/Refinement').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/NoSearchResultsForm').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductImages').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductVideoMixin').then(d => d.default)
    ]).then(deps => {
        const [
            ProductListingMgr,
            RefinementsPanelToggle,
            Refinement,
            NoSearchResultsForm,
            ProductImages,
            ProductVideoMixin
        ] = deps;

        return {
            listId: 'search.widgets',
            widgetsDefinition: () => [
                // tabs
                    ['productListingMgr', ProductListingMgr, 'tabs'],
                ['refinementsPanelToggle', RefinementsPanelToggle],
                ['refinement', Refinement],
                // basicForm
                    ['noSearchResultsForm', NoSearchResultsForm, 'basicForm'],
                ['productImages', ProductImages],
                ['productImages', AccessibilityFocusTrapMixin, 'productImages'],
                ['productImages', ProductVideoMixin, 'productImages']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/PaymentsList').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/AddPaymentMethodForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/checkout/InputCreditCardNumber').then(d => d.default)
    ]).then(deps => {
        const [
            PaymentsList,
            AddPaymentMethodForm,
            InputCreditCardNumber
        ] = deps;

        return {
            listId: 'account.payment.widgets',
            widgetsDefinition: () => [
                ['paymentsList', PaymentsList],
                ['addPaymentMethodForm', AddPaymentMethodForm, 'ajaxform'],
                ['inputCreditCardNumber', InputCreditCardNumber, 'basicInput']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/AddressList').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/OrderHistoryMgr').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AddressBookForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/PasswordResetForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AddressForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/GoogleAutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/global/SessionExpiration').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/order/OrderProductSummary').then(d => d.default)
    ]).then(deps => {
        const [
            AddressList,
            OrderHistoryMgr,
            AddressBookForm,
            PasswordResetForm,
            AddressForm,
            LoginForm,
            AutocompleteField,
            GoogleAutocompleteField,
            SessionExpiration,
            OrderProductSummary
        ] = deps;

        return {
            listId: 'account.widgets',
            widgetsDefinition: () => [
                ['addressList', AddressList],
                ['orderHistoryMgr', OrderHistoryMgr],
                    ['sessionExpiration', SessionExpiration, 'modal'],
                // eslint-disable-next-line spellcheck/spell-checker
                // basicForm / ajaxform
                    ['addressForm', AddressForm, 'ajaxform'],
                    ['addressBookForm', AddressBookForm, 'addressForm'],
                    ['loginForm', LoginForm, 'ajaxform'],
                    ['passwordResetForm', PasswordResetForm, 'ajaxform'],
                // inputText
                ['autocompleteField', AutocompleteField, 'inputText'],
                    ['googleAutocompleteField', GoogleAutocompleteField, 'autocompleteField'],
                ['orderProductSummary', OrderProductSummary]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('cart'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/cart/CartMgr').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/CouponForm').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/cart/PromoCodeDisclosure').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/cart/CheckoutButtons').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/global/SessionExpiration').then(d => d.default)
    ]).then(deps => {
        const [CartMgr, CouponForm, PromoCodeDisclosure, CheckoutButtons, SessionExpiration] = deps;

        return {
            listId: 'cart.widgets',
            widgetsDefinition: () => [
                ['cartMgr', CartMgr],
                ['checkoutButtons', CheckoutButtons],
                // modal
                    ['sessionExpiration', SessionExpiration, 'modal'],
                // basicForm / form / ajaxForm
                    ['couponform', CouponForm, 'ajaxform'],
                // disclosure
                    ['promoCodeDisclosure', PromoCodeDisclosure, 'disclosure']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/CheckoutLoginForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordion').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItem').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItemCREDIT_CARD').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItemZERO_PAYMENT').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/global/SessionExpiration').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/content/ContactUs').then(d => d.default)
    ]).then(deps => {
        const [
            LoginForm,
            CheckoutLoginForm,
            PaymentAccordion,
            PaymentAccordionItem,
            PaymentAccordionItemCREDITCARD,
            PaymentAccordionItemZEROPAYMENT,
            SessionExpiration,
            ContactUs
        ] = deps;

        return {
            listId: 'checkout.widgets',
            widgetsDefinition: () => [
                // eslint-disable-next-line spellcheck/spell-checker
                // basicForm / form / ajaxform
                ['loginForm', LoginForm, 'ajaxform'],
                    ['checkoutLoginForm', CheckoutLoginForm, 'loginForm'],
                // modal
                    ['sessionExpiration', SessionExpiration, 'modal'],
                // accordion
                    ['paymentAccordion', PaymentAccordion, 'accordion'],
                // accordionItems
                    ['paymentAccordionItem', PaymentAccordionItem, 'accordionItem'],
                        ['paymentAccordionItemCREDIT_CARD', PaymentAccordionItemCREDITCARD, 'paymentAccordionItem'],
                            ['paymentAccordionItemCREDIT_CARD', AccessibilityFocusMixin, 'paymentAccordionItemCREDIT_CARD'],
                        ['paymentAccordionItemZERO_PAYMENT', PaymentAccordionItemZEROPAYMENT, 'paymentAccordionItem'],
                ['contactUs', ContactUs]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/CheckoutMgr').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/ShippingStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/CheckoutSummaryMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/CheckoutStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/SummaryStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/BillingStepMixin').then(d => d.default)
    ]).then(deps => {
        const [
            CheckoutMgr,
            ShippingStepMixin,
            CheckoutSummaryMixin,
            CheckoutStepMixin,
            SummaryStepMixin,
            BillingStepMixin
        ] = deps;

        return {
            listId: 'checkout.manager.widgets',
            widgetsDefinition: () => [
                ['checkoutMgr', CheckoutMgr],
                    ['checkoutMgr', AccessibilityFocusMixin, 'checkoutMgr'],
                    ['checkoutMgr', CheckoutStepMixin, 'checkoutMgr'],
                    ['checkoutMgr', ShippingStepMixin, 'checkoutMgr'],
                    ['checkoutMgr', CheckoutSummaryMixin, 'checkoutMgr'],
                    ['checkoutMgr', SummaryStepMixin, 'checkoutMgr'],
                    ['checkoutMgr', BillingStepMixin, 'checkoutMgr']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/account/PasswordResetForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/AddressCheckoutForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/ShippingForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/BillingForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/InputCreditCardNumber').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/RadioSelector').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/AutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/GoogleAutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/AddressForm').then(d => d.default)
    ]).then(deps => {
        const [
            PasswordResetForm,
            AddressCheckoutForm,
            ShippingForm,
            BillingForm,
            InputCreditCardNumber,
            RadioSelector,
            AutocompleteField,
            GoogleAutocompleteField,
            AddressForm
        ] = deps;

        return {
            listId: 'checkout.form.widgets',
            widgetsDefinition: () => [
                ['passwordResetForm', PasswordResetForm, 'ajaxform'],
                ['addressForm', AddressForm, 'ajaxform'],
                    ['addressCheckoutForm', AddressCheckoutForm, 'addressForm'],
                        ['addressCheckoutForm', AccessibilityFocusMixin, 'addressCheckoutForm'],
                        ['shippingForm', ShippingForm, 'addressCheckoutForm'],
                        ['billingForm', BillingForm, 'addressCheckoutForm'],
                // basicInput
                ['inputCreditCardNumber', InputCreditCardNumber, 'basicInput'],
                ['autocompleteField', AutocompleteField, 'inputText'],
                    ['googleAutocompleteField', GoogleAutocompleteField, 'autocompleteField'],
                // inputRadio
                    ['radioSelector', RadioSelector, 'inputRadio']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('orderconfirmation'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'orderconfirmation.widgets' */ 'widgets/order/OrderProductSummary').then(d => d.default)
    ]).then(deps => {
        const [OrderProductSummary] = deps;

        return {
            listId: 'content.widgets',
            widgetsDefinition: () => [
                ['orderProductSummary', OrderProductSummary]
            ]
        };
    })
});

/* sonar-ignore-end */
