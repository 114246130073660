import { copyTextToClipboard } from 'core/toolbox/util';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description {ProductSocialLinks} implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof ProductSocialLinks} ProductSocialLinks class
 */
export default function (Widget) {
    /**
     * @class ProductSocialLinks
     * @augments Widget
     */
    class ProductSocialLinks extends Widget {
        /**
         * @description Process share click event
         * @param {object} el - DOM element
         */
        socialShareClick(el) {
            if (el.data('isIdea')) {
                this.eventBus().emit('gtm.datalayer.event', {
                    event: 'data-layer-event',
                    eventCategory: 'Social share',
                    eventAction: 'Idea',
                    eventLabel: el.data('gtmEventLabel')
                });
            }
        }

        /**
         * @description Copy product url to clipboard
         * @returns {void}
         */
        copyToClipboard() {
            copyTextToClipboard(this.prefs().productUrl);

            /**
             * @description Global event to show alert
             * @event "alert.show"
             */
            this.eventBus().emit('alert.show', {
                accessibilityAlert: this.prefs().accessibilityCopiedToClipboard
            });
        }
    }

    return ProductSocialLinks;
}
