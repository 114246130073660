/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Base ProcessButton implementation
 * @param Button Base widget for extending
 * @returns Process Button class
 */
export default function (Button: ReturnType<typeof import('widgets/global/Button').default>) {
    /**
     * @class ProcessButton
     * @augments Button
     * @classdesc ProcessButton widget, which could be used in different contextes (html-markup, inside widgets etc).
     * <br>Could be also used to trigger parent's widget method
     * @property {string} data-widget - Widget name `processButton`
     * @property {string} data-widget-event-click - Event listener to call Parent's widget method
     * @property {string} data-event-click - Event listener method `handleClick` for click event on widget
     * @property {string} data-processing-label - Processing Label
     * @property {string} data-processing-class - Processiong Class
     * @example
     * // use this code to display widget
     * <button type="submit" class="btn btn-block btn-primary"
     *     data-widget="button"
     *     data-widget-event-click="handleSubmit"
     *     data-event-click.prevent="handleClick"
     *     data-processing-label="${Resource.msg('button.processing', 'common', null)}"
     *     data-processing-class="m-processing"
     *     data-id="submitButton"
     * >
     *     <div class="container">
     *         ${Resource.msg('button.text.loginform', 'login', null)}
     *     </div>
     * </button>
     */
    class ProcessButton extends Button {
        currentText = '';

        isBusy = false;

        prefs() {
            return {
                processingLabel: 'processing...',
                processingClass: 'm-processing',
                ...super.prefs()
            };
        }

        /**
         * @description Start Progress
         * @param val - Value to set as a text of the Button
         */
        startProcess(val?: string) {
            if (this.isBusy) {
                return;
            }

            this.busy();
            this.currentText = this.getText();
            this.setText(val || this.prefs().processingLabel);
            this.ref('self').addClass(this.prefs().processingClass);
        }

        /**
         * @description Stop Progress
         * @param val - Value to set as a text of the Button
         */
        stopProcess(val?: string) {
            this.unbusy();
            this.setText(val || this.currentText);
            this.ref('self').removeClass(this.prefs().processingClass);
        }

        /**
         * @description Get Text
         * @returns {string} Button Text
         */
        getText() {
            return this.ref('container').getText();
        }

        /**
         * @description Set Text
         * @param {(string|undefined)} val - Value to set as a text of the Button
         * @returns {refElement} Reference element
         */
        setText(val = '') {
            return this.ref('container').setText(val);
        }
    }

    return ProcessButton;
}
