type productOptionType = {
    id: '',
    selectedValueId: '',
    customOptionValue: ''
};
type InputTextarea = InstanceType<ReturnType<typeof import('widgets/forms/InputTextarea').default>>;

/**
 * @description Base ProductOptionMixin implementation
 *
 * This class is not intended to have a separate DOM representation.
 *
 * @param ProductDetail Base widget for extending
 * @returns Add to Cart mixin widget
 */
export default function (ProductDetail: ReturnType<typeof import('widgets/product/ProductDetail').default>) {
    /**
     * @class ProductOptionMixin
     * @augments ProductDetail
     * @subcategory product
     * @classdesc Represents product set component with the next features:
     * 1. Handle error in the custom option field
     * 2. Get selected option for the current product
     */
    class ProductOptionMixin extends ProductDetail {
        /**
         * @description Handle custom option field error
         * @param input Custom Option text field
         * @param error Error text
         */
        handleCustomOptionError(input: InputTextarea, error: string) {
            if (!error) { return; }

            this.getById('addToCart', (addToCartBtn) => {
                const isInputValid = input.isValid();

                addToCartBtn.ref('self').toggleAvailability(isInputValid);
            });
        }

        /**
         * @description Get custom option value
         * @param optionId Product option ID
         * @returns Result
         */
        getCustomOptionValue(optionId: string) {
            if (!optionId) { return null; }

            let customOptionValue = '';

            this.getById('option-' + optionId, (option) => {
                option.getById('customOptionText', (textArea: InputTextarea) => {
                    customOptionValue = textArea.getValue();
                });
            });

            return customOptionValue;
        }

        /**
         * @description Validate custom field and return its validation result
         * @param optionId Product option ID
         * @returns field validation result
         */
        isCustomOptionValid(optionId: string) {
            if (!optionId) { return false; }

            let isValid = true;

            this.getById('option-' + optionId, (option) => {
                option.getById('customOptionText', (textArea: InputTextarea) => {
                    isValid = textArea.validate();
                });
            });

            return isValid;
        }

        /**
         * @description Get selected product options
         * @returns Array with product options objects or in case invalid option value - null
         */
        getSelectedProductOptions(): Array<Record<string, unknown>|null> {
            const productOptions = this.prefs().productOptions || [];

            const options = productOptions.map((option) => {
                const result = {
                    optionId: option.id,
                    selectedValueId: option.selectedValueId,
                    customOptionValue: option.customOptionValue
                };

                if (result.customOptionValue) { return result; }

                if (!option.values) { return result; }

                const selectedCustomOption = option.values.find((value) => {
                    return value.isCustomOption && value.isCustomOptionSelected;
                });

                if (selectedCustomOption) {
                    // @ts-expect-error ts-migrate(2339) Define correct type
                    result.customOptionValue = this.getCustomOptionValue(result.optionId);

                    return this.isCustomOptionValid(result.optionId) ? result : null;
                }

                return result;
            });

            return options;
        }

        /**
         * @description Validate product options
         * @param selectedOptions Selected product options
         * @returns Result of the validation product options
         */
        validateProductOptions(selectedOptions: Array<Record<string, unknown>>): boolean {
            return selectedOptions.every(option => option);
        }

        /**
         * @description Returns additional product data object
         * @param productOption - Product option
         * @returns result
         */
        getAdditionalProductData(productOption?: productOptionType): Record<string, unknown> {
            return {
                customOptionValue: productOption ? this.getCustomOptionValue(productOption.id) : '',
                selectedOptionValueId: productOption ? productOption.selectedValueId : '',
                optionId: productOption ? productOption.id : ''
            };
        }
    }

    return ProductOptionMixin;
}
