/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */
const GTM_CATEGORY_SELECTOR = 'gtmEventCategory';
const GTM_EVENT_SELECTOR = 'gtmEventAction';
const GTM_LABEL_SELECTOR = 'gtmEventLabel';
const GTM_SUBLABEL_SELECTOR = 'gtmEventSublabel';
const GTM_LABEL_NAV = 'Navigation';
const GTM_EVENT_NAME = 'data-layer-event';
const CLICK_EVENT_ID = 'navigation.click.event';

/**
 * @description GtmDataLayer content implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof GtmDataLayer} GtmDataLayer class
 */
export default function (Widget) {
    /**
     * @class Footer
     * @augments Widget
     */
    class GtmDataLayer extends Widget {
        init() {
            super.init();

            if (this.prefs().gtmOnloadEvent) {
                this.eventBus().emit('gtm.datalayer.event', {
                    event: GTM_EVENT_NAME,
                    eventCategory: this.prefs()[GTM_CATEGORY_SELECTOR],
                    eventAction: this.prefs()[GTM_EVENT_SELECTOR],
                    eventSublabel: this.prefs()[GTM_LABEL_SELECTOR]
                });
            }
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        breadcrumbClick(el) {
            this.eventBus().emit(CLICK_EVENT_ID, {
                event: GTM_EVENT_NAME,
                eventCategory: GTM_LABEL_NAV,
                eventAction: 'Top navigation',
                eventSublabel: el.data(GTM_SUBLABEL_SELECTOR)
            });
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        footerLinkClick(el) {
            this.eventBus().emit(CLICK_EVENT_ID, {
                event: GTM_EVENT_NAME,
                eventCategory: GTM_LABEL_NAV,
                eventAction: 'Footer',
                eventSublabel: el.data(GTM_SUBLABEL_SELECTOR)
            });
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        categoryTileClick(el) {
            this.eventBus().emit(CLICK_EVENT_ID, {
                event: GTM_EVENT_NAME,
                eventCategory: GTM_LABEL_NAV,
                eventAction: 'Category grid',
                eventSublabel: el.data(GTM_SUBLABEL_SELECTOR)
            });
        }

        /**
         * @description Workshops Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        ideaWorkshopClick(el) {
            this.eventBus().emit(CLICK_EVENT_ID, {
                event: GTM_EVENT_NAME,
                eventCategory: GTM_LABEL_NAV,
                eventAction: 'Content tiles',
                eventSublabel: el.data(GTM_SUBLABEL_SELECTOR)
            });
        }

        /**
         * @description Workshops Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        viewAllWorkshopsClick() {
            const productName = window?.gtmProductData?.productName;

            this.eventBus().emit(CLICK_EVENT_ID, {
                event: GTM_EVENT_NAME,
                eventCategory: 'Key interactions',
                eventAction: 'View workshops',
                eventLabel: productName || ''
            });
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        searchSuggestionClick(el) {
            this.eventBus().emit('gtm.click.event', {
                event: GTM_EVENT_NAME,
                eventCategory: 'Search',
                eventAction: 'Search suggestions',
                eventLabel: el.data(GTM_LABEL_SELECTOR),
                eventSublabel: el.data(GTM_SUBLABEL_SELECTOR)
            });
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        downloadPdfClick(el) {
            const isAuthorized = el.data('isAuthorised');

            this.eventBus().emit('gtm.click.event', {
                event: GTM_EVENT_NAME,
                eventCategory: 'PDF download',
                eventAction: isAuthorized ? 'Download Initiated' : 'Log in to download',
                eventLabel: el.data(GTM_LABEL_SELECTOR).toString()
            });
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        showRelatedIdeasClick(el) {
            this.eventBus().emit(CLICK_EVENT_ID, {
                event: GTM_EVENT_NAME,
                eventCategory: 'Key interactions',
                eventAction: 'Show ideas',
                eventLabel: el.data(GTM_LABEL_SELECTOR)
            });
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        artisanClick(el) {
            this.eventBus().emit(CLICK_EVENT_ID, {
                event: GTM_EVENT_NAME,
                eventCategory: 'Artisan interaction',
                eventAction: this.prefs().gtmArtisanEventAction || '',
                eventLabel: el.data(GTM_LABEL_SELECTOR) || el.attr('title') || 'View All'
            });
        }
    }

    return GtmDataLayer;
}
