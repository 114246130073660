import {
    SMALL,
    MEDIUM,
    LARGE,
    EXTRA_LARGE,
    getViewType
} from 'widgets/toolbox/viewtype';

const stickyHeaderHeightMap = {
    [SMALL]: 52,
    [MEDIUM]: 52,
    [LARGE]: 104,
    [EXTRA_LARGE]: 64
};

/**
 * Gets height of sticky header based on current view type
 *
 * @returns {number} height of sticky header
 */
export function getStickyHeaderHeight() {
    let stickyHeaderHeight = stickyHeaderHeightMap[getViewType()];

    if (window.contexts.includes('pdp')) {
        stickyHeaderHeight += 60;
    }

    return stickyHeaderHeight;
}

/**
 * Returns object with element offset
 *
 * @param {HTMLElement|null} element - DOM element
 * @returns {{ top: number; left: number; }} page scroll position
 */
export function elementOffset(element) {
    const offset = { top: 0, left: 0 };

    if (element) {
        const docElem = document.documentElement;
        const elemBox = element.getBoundingClientRect();

        offset.top = elemBox.top + window.pageYOffset - docElem.clientTop;
        offset.left = elemBox.left + window.pageXOffset - docElem.clientLeft;
    }

    return offset;
}

/**
 * Check if element is in viewport
 *
 * @param {HTMLElement} [element] - HTML Element
 * @param {boolean} [fullyVisible] - true if element should be fully visible
 * @returns {boolean} true if element is in viewport
 */
export function elementInViewport(element, fullyVisible = false) {
    if (!element) {
        return false;
    }

    const stickyHeaderHeight = getStickyHeaderHeight();

    let top = elementOffset(element).top;
    const left = elementOffset(element).left;
    const width = element.clientWidth;
    const height = element.clientHeight;

    let wHeight = window.innerHeight;
    let wWidth = window.innerWidth;
    let wYOffset = window.pageYOffset;
    let wXOffset = window.pageXOffset;

    if (wXOffset === null) {
        wHeight = window.document.documentElement.clientHeight;
        wWidth = window.document.documentElement.clientWidth;
        wYOffset = window.document.documentElement.scrollTop;
        wXOffset = window.document.documentElement.scrollLeft;
    }

    top -= stickyHeaderHeight;
    wHeight -= stickyHeaderHeight;

    if (fullyVisible) {
        return (
            top > wYOffset
            && left > wXOffset
            && (top + height) < (wYOffset + wHeight)
            && (left + width) < (wXOffset + wWidth)
        );
    }

    return (
        top < (wYOffset + wHeight)
        && left < (wXOffset + wWidth)
        && (top + height) > wYOffset
        && (left + width) > wXOffset
    );
}

/**
 * @description Scroll window to provided element
 * @param {HTMLElement|undefined} element - HTML Element
 * @param {boolean} [countHeaderHeight] - add to top header height
 * @param {{behavior: string|undefined, top: number|undefined, left: number|undefined}} options scroll options
 * @returns {void}
 */
export function scrollWindowTo(element, countHeaderHeight = false, options = { behavior: 'smooth', top: 0, left: 0 }) {
    if (!element) {
        return;
    }

    const top = element.getBoundingClientRect().top + window.pageYOffset + (options.top || 0);
    const left = 0 + (options.left || 0);

    if (countHeaderHeight) {
        const stickyHeaderHeight = getStickyHeaderHeight();

        window.scrollTo({ top: top - stickyHeaderHeight, left: left, behavior: options.behavior });
    } else {
        window.scrollTo({ top: top, left: left, behavior: options.behavior });
    }
}
