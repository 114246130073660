import { getJSONByUrl } from 'widgets/toolbox/ajax';
import { RefElement } from 'widgets/toolbox/RefElement';
import { scrollElementTo } from 'widgets/toolbox/scroll';
import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {ReturnType<typeof import('widgets/global/Modal').default>} Modal
 */
/**
 * @param Combobox Base widget for extending
 * @returns SearchBox widget
 */
export default function (Combobox: ReturnType<typeof import('widgets/forms/Combobox').default>) {
    /**
     * @category widgets
     * @subcategory search
     * @class SearchBox
     * @augments Combobox
     * @classdesc Search suggestion box. Handles input, shows and interacts with suggestions box.
     * <br>Implements `SFCC search suggestions` provider functionality in `getSuggestions` method
     * <br>It also override combobox logic - when we select suggestion the default action not fill input, but navigate to
     * <br>suggestion that implemented as links (reference combobox should have listbox items).
     * <br>If user interactive with keyboard and select link form should not submitted.
     * Represents Refinement component with next features:
     * 1. Allow send search request to the server and process response
     * 2. Allow handle server error
     * 3. Allow manage search box state(open/close)
     * 4. Allow handle input event
     * 5. Allow handle active/selected search suggestions state
     * 6. Allow clear input field
     * @property {string} data-widget - Widget name `searchBox`
     * @property {string} data-url - URL to obtain serach suggestions from server based on customer's input
     * @property {boolean} data-close-on-tab - If true - `tab` keypress will close listbox
     * @property {string} data-widget-event-closesearch - An event, fired when `close` element was pressed
     * @property {string} data-event-keydown - event handler for `keydown` event
     * @property {boolean} data-close-from-outside - config, which shows, if combobox should be closed when click outside
     * @property {number} data-show-spinner-delay - config delay in ms to show spinner
     * @property {string} data-ref-first-focus-element - reference to first focus element for focus trap
     * @property {string} data-ref-last-focus-element - reference to last focus element for focus trap
     * @example <caption>Example of SearchBox widget usage</caption>
     * <div
     *     data-widget="searchBox"
     *     data-url="${URLUtils.url('SearchServices-GetSuggestions')}"
     *     data-close-on-tab="false"
     * >
     *     <form>
     *         ... serach input
     *     </form>
     *     ...
     *     <div data-ref="listbox" data-event-mouseenter="markHover" data-event-mouseleave="unMarkHover">
     *         <div role="none" class="b-suggestions-inner" data-ref="listboxInner"></div>
     *     </div>
     *     <script data-ref="template" type="template/mustache">
     *         <div data-ref="listboxInner">
     *             ... search suggestions
     *         </div>
     *     </script>
     * </div>
     */
    class SearchBox extends Combobox {
        lastSearchedTerm = '';

        selectedIndex = 0;

        resultsCount = 0;

        goingByLink = false;

        timeout: any;

        longLoadingTimer: (() => void) | undefined;

        prefs() {
            return {
                showSpinnerDelay: 500,
                outlineSpace: 5,
                classesClearEnabled: 'm-visible',
                classesLoadingSuggestions: 'm-loading',
                classesLoadingSuggestionsLong: 'm-loading-long',
                url: '',
                disableRendering: true,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @listens "page.show.searchbox"
         * @returns {void}
         */
        init(): void {
            super.init();
            this.eventBus().on('page.show.searchbox', 'openSearch');
        }

        /**
         * @description Shows suggestions popup in initial state.
         * <br>Initial state can be a slot/asset markup, rendered in backed
         * <br>and placed in `searchSuggestionsInitial` template
         * @param setInputFocus - if we need to focus search input
         * @returns Promise object represents search default state rendering result
         */
        showInDefaultState(setInputFocus = true): Promise<void> {
            if (setInputFocus) {
                this.focusInput();
            }

            this.lastSearchedTerm = '';

            return this.render('searchSuggestionsInitial', undefined, this.ref('listboxInner'))
                .then(() => {
                    this.selectedIndex = -1;
                    this.resultsCount = this.getRealItemsCount();
                    this.openListbox();
                });
        }

        /**
         * @description Send request to the server
         * @param {string} query - requested search query
         * @returns {Promise<object>} Promise object represents server response for search suggestions
         */
        getSuggestions(query) {
            this.toggleSpinner(true);

            return getJSONByUrl(this.prefs().url, { q: query })
                .then(response => this.processResponse(query, <{ suggestions: { total: number }}>response))
                .catch(this.handleError.bind(this));
        }

        /**
         * @description Process response from the server
         * @param query - customer's search query
         * @param response - backend response with suggestions
         * @param response.suggestions - suggestions
         * @param response.suggestions.total - suggestion total
         */
        processResponse(query: string, response: { suggestions: { total: number }}): void {
            if (response && response.suggestions) {
                if (this.ref('field').val() !== query) {
                    // if value changed during request, we do not show outdated suggestions
                    return;
                }

                if (document.activeElement !== this.ref('field').get()) {
                    this.toggleSpinner(false);
                    this.showInDefaultState();

                    return;
                }

                this.renderResult(response).then(() => {
                    this.afterSuggestionsUpdate(query, response.suggestions.total);
                });
            } else {
                this.showInDefaultState();
            }

            this.toggleSpinner(false);
        }

        /**
         * @description Render server response result
         * @param {object} response - Backend response with suggestions
         * @returns {Promise<void>} Promise object represents search response rendering result
         */
        renderResult(response) {
            return this.render(undefined, response, this.ref('listboxInner')).then(() => {
                timeout(() => {
                    const listbox = this.ref('listbox').get();

                    if (listbox) {
                        scrollElementTo(listbox);
                    }
                }, 10);
            });
        }

        /**
         * @description Handle server error
         * @param query - customer's search query
         */
        handleError(query: string): Promise<void> {
            return this.render('templateError', {}, this.ref('listboxInner')).then(() => {
                this.toggleSpinner(false);
                this.afterSuggestionsUpdate(query, 0);
            });
        }

        /**
         * @description Clear listbox
         */
        afterCloseListbox(): void {
            this.ref('listboxInner').empty();
        }

        /**
         * @description Handle active item
         * @param selectedItem - search suggestion item selected by customer
         */

        activateItem(selectedItem: RefElement): void {
            // We do not need default combobox behavior.
            // Instead of pasting suggestion value we go by the link
            // if we have selected item in listbox
            if (selectedItem) {
                this.ref('field').val(selectedItem.attr('data-suggestion-value'));
                this.goingByLink = true;
                window.location.assign(selectedItem.attr('href').toString());
            }
        }

        /**
         * @description Handle input
         * @listens dom#keydown
         */
        handleInput(): void {
            const inputValue = this.ref('field');
            let inputLength = 0;

            if (inputValue.length) {
                inputLength = (inputValue.prop('value') && inputValue.prop('value').length) || 0;
            }

            if (inputLength >= this.prefs().minChars && inputLength <= this.prefs().maxChars) {
                this.updateListbox();
            } else {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }

                this.showInDefaultState();
            }

            this.toggleClearButton(!!this.ref('field').val());
        }

        /**
         * @description Toggle spinner
         * @param isShown - Indicated is spinner display
         */
        toggleSpinner(isShown: boolean): void {
            const listbox = this.ref('listbox');

            listbox.attr('aria-busy', String(isShown));
            listbox.toggleClass(this.prefs().classesLoadingSuggestions, isShown);

            if (isShown) {
                this.longLoadingTimer = timeout(() => {
                    this.ref('listbox').addClass(this.prefs().classesLoadingSuggestionsLong);
                }, this.prefs().showSpinnerDelay);
            } else {
                if (this.longLoadingTimer) {
                    this.longLoadingTimer();
                }

                this.ref('listbox').removeClass(this.prefs().classesLoadingSuggestionsLong);
            }
        }

        /**
         * @description Handle selected item
         * @param selectedItem - search suggestion item selected by customer
         */
        afterItemSelected(selectedItem: RefElement): void {
            const item = selectedItem.get();

            if (item) {
                const listbox = this.ref('listbox').get();
                const top = (item.offsetTop - this.prefs().outlineSpace);

                if (listbox) {
                    scrollElementTo(listbox, top);
                }
            }
        }

        /**
         * @description Executes when user clicks on product details link in the search box results.
         * Usually used by analytics etc.
         * @emits "searchbox.product.link.click"
         * @param link - product link
         */
        onProductLinkClick(link: RefElement) {
            /**
             * @description Event to click product link in search box
             * @event "searchbox.product.link.click"
             */
            this.eventBus().emit('searchbox.product.link.click', link);
        }

        // Form functionality

        /**
         * @description Submit form simplified handler
         * @param _ event source element
         * @param event event instance
         */
        handleSubmit(_: RefElement, event: Event): void {
            const inputVal = this.ref('field').val();

            if (this.goingByLink || !inputVal) {
                event.preventDefault();
            }

            if (!inputVal) {
                this.focusInput();
            } else {
                this.closeListbox();
            }
        }

        /**
         * @description Clears search input and show suggestions from default state. Optionally sets focus to search field.
         * @listens dom#click
         * @param setInputFocus - if we need to focus input
         */
        clearInput(setInputFocus: boolean): void {
            this.toggleSpinner(false);
            const searchInput = this.ref('field');

            searchInput.val('');

            if (setInputFocus) {
                this.focusInput();
            }

            this.toggleClearButton(false);
            this.showInDefaultState(setInputFocus);
        }

        /**
         * @description Toggle clear button
         * @param isInputHasValue - is search input has value
         */
        toggleClearButton(isInputHasValue: boolean): void {
            this.ref('clearButton').toggleClass(this.prefs().classesClearEnabled, isInputHasValue);
        }

        /**
         * @description Gets the real count of suggestions items.
         * <br>Could be used in case, when no backend suggestion count are set.
         * <br>As an example: case of rendering content assets with
         * <br>unknown suggestions set
         * @returns - a number of listbox inner elements
         */
        getRealItemsCount(): number {
            const listboxInner = this.ref('listboxInner').get();

            return listboxInner
                ? listboxInner.querySelectorAll('[data-suggestion-value]').length
                : 0;
        }

        /**
         * @description Cancel handler
         */
        cancel(): void {
            this.closeSearch();
        }

        /**
         * @description Open search modal
         * @listens "page.show.searchbox"
         */
        openSearch(): void {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'showModal' does not exist on type 'Searc... Remove this comment to see the full error message
            return this.showModal({
                attributes: {
                    'data-tau': 'search_dialog'
                }
            }, this.showInDefaultState.bind(this));
        }

        /**
         * @description Set focus to input
         */
        focusInput(): void {
            // iOS could disable focus method on HTMLInputElement https://bugs.webkit.org/show_bug.cgi?id=195884
            // it is done to not annoy the user with software keyboard.
            // To show keyboard we should never hide input and parent with display none.
            // Also focus should be set without delay.
            const input = this.ref('field').get();

            if (!input) {
                return;
            }

            input.focus();
        }

        /**
         * @description Used to notify concerning widget to close suggestions popup and do all other actions
         * @emits SearchBox#closesearch
         * @param [refEl] - refEl
         */
        closeSearch(refEl?: RefElement): void {
            if (refEl && refEl.data('stop-click') === true) {
                return;
            }

            /**
             * @description Event to close search box
             * @event SearchBox#closesearch
             */
            this.emit('closesearch');

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'closeModal' does not exist on type 'Sear... Remove this comment to see the full error message
            this.closeModal();
            this.closeListbox();
            this.clearInput(false);
        }

        /**
         * @description Toggle search state
         * @param ref search
         */
        toggleSearch(ref: RefElement): void {
            if (ref.attr('aria-expanded') === 'true') {
                this.closeSearch();
            } else {
                this.openSearch();
            }
        }

        /**
         * @description Hide search
         * @returns Current widget instance
         */
        hide(): this {
            super.hide();
            this.closeListbox();
            this.clearInput(false);

            return this;
        }
    }

    return SearchBox;
}
