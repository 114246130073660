/**
 * @param BasicStickyHeader Base widget for extending
 * @returns StickyHeader widget
 */
export default function (BasicStickyHeader: ReturnType<typeof import('widgets/global/StickyHeader').default>) {
    /**
     * @category widgets
     * @subcategory global
     * @class StickyHeader
     * @augments Widget
     * @classdesc StickyHeader helper. Should be on element before StickyHeader to observe intersection
     * Could be reimplemented with getBoundClientRect.top and scroll listener.
     * The main purpose is to define anchor for StickyHeader and toggle StickyHeader.
     * @example <caption>Example of StickyHeader widget usage</caption>
     * <div data-widget="stickyHeader"></div>
     */
    class StickyHeader extends BasicStickyHeader {
        /**
         * @description Toggle sticky header state
         * @param {object} entry Anchor for apply sticky header
         * @returns {void}
         */
        toggleStickyState(entry) {
            super.toggleStickyState(entry);

            this.eventBus().emit(entry.intersectionRatio < 1 ? 'header.stuck' : 'header.unstuck');
        }
    }

    return StickyHeader;
}
