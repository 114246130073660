import { getCookie } from 'widgets/toolbox/cookie';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

/**
 * @typedef {typeof import('core/global/StoreLocatorLink').default} StoreLocatorLink
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof StoreLocatorLink} StoreLocatorLink
 */

export default function (Widget) {
    class StoreLocatorLink extends Widget {
        prefs() {
            return {
                getPreferredStoreUrl: '',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.getPreferredStoreData()
                .then(data => {
                    if (data && data.name && data.url) {
                        this.renderPreferredStore(data);
                    }
                })
                .catch(error => console.log(error));

            this.eventBus().on('preferredstore.selected', 'savePreferredStoreData');
            this.eventBus().on('update.preferred.store', 'savePreferredStoreData');
            this.eventBus().on('preferredstore.selected', 'renderPreferredStore');
        }

        /**
         * @description renders store link with preferred store data
         *
         * @param {object} param - rendering data
         * @param {string} param.name - store name
         * @param {string} param.url - store link
         */
        renderPreferredStore({ name, url }) {
            this.render('storeHeaderTemplate', { url, name }, this.ref('storeHeaderContainer'));
        }

        /**
        * @description Save preferred store data to the local storage
        * @param {object} param - saving data
        * @param {any} param.name - store name
        * @param {any} param.url - store link
        */
        savePreferredStoreData({ name = null, url = null }) {
            localStorageWrapper.removeItem('preferedStoreData');
            localStorageWrapper.setItem('currentSid', getCookie('sid'));
            localStorageWrapper.setItem('preferedStoreData', JSON.stringify({ name, url }));
        }

        /**
         * @description Do call to get prefered store from the server
         * @param {string}url URL to get prefered store
         * @returns {Promise<any>} Fetching result promise
         */
        loadPreferedStoreDataPromise(url) {
            return getJSONByUrl(url)
                .then((result) => {
                    if (!result.error) {
                        this.savePreferredStoreData({ name: result.preferredStoreName, url: result.preferredStoreURL });

                        return Promise.resolve({ name: result.preferredStoreName, url: result.preferredStoreURL });
                    } else {
                        return Promise.resolve(null);
                    }
                })
                // eslint-disable-next-line no-console
                .catch(error => console.log(error));
        }

        /**
         * @description Returns fetching result promise
         * @returns {Promise<any>} Promise result
         */
        getPreferredStoreData() {
            return new Promise((resolve, reject) => {
                getJSONByUrl(this.prefs().getPreferredStoreUrl, { getStoreIdText: 'true' }).then((responseData) => {
                    const preferedStoreDataString = localStorageWrapper.getItem('preferedStoreData');
                    let preferedStoreData = preferedStoreDataString ? JSON.parse(preferedStoreDataString) : '';

                    if (responseData) {
                        preferedStoreData = responseData;
                        this.savePreferredStoreData({ name: preferedStoreData.name, url: preferedStoreData.url });
                    }

                    const isSameSession = localStorageWrapper.getItem('currentSid') === getCookie('sid');

                    if (preferedStoreData && isSameSession) {
                        return resolve(preferedStoreData);
                    }

                    const url = this.prefs().getPreferredStoreUrl;

                    if (!url) {
                        return reject(new Error('no URL provided'));
                    }

                    return this.loadPreferedStoreDataPromise(url)
                        .then((loadPreferedStoreData) => {
                            return resolve(loadPreferedStoreData);
                        });
                });
            });
        }
    }

    return StoreLocatorLink;
}
