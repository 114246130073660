import { PausableTimeout } from 'widgets/toolbox/PausableTimeout';
import { TWidget } from 'widgets/Widget';

/**
 * @description Base GlobalAlerts implementation.
 * @param Widget Base widget for extending
 * @returns GlobalAlerts class
 */
export default function (Widget: TWidget) {
    /**
     * @class GlobalAlerts
     * @augments Widget
     * @classdesc Global Alerts with accessibility alert role possibility.
     * Acts as a popup with delayed disappearing. Popup will render user action description.
     * @property {boolean} data-widget - Widget name `globalAlerts`
     * @property {number} data-timeout - Value of hide timeout in milliseconds
     * @property {object} data-global-alerts - Global alerts localization messages
     * Possible values are: `error500`
     * @example
     * // use this code to display GlobalAlerts widget
     * <div
     *     data-widget="globalAlerts"
     *     data-timeout="4000"
     *     data-global-alerts='{
     *         "error500": "${Resource.msg('alert.error500', 'global', null)}""
     *     }'
     *
     * >
     *     <div data-ref="item" hidden="hidden" role="alert">
     *         ... alert message will be shown here
     *     </div>
     * </div>
     */
    class GlobalAlerts extends Widget {
        prefs() {
            return {
                timeout: 4000,
                globalAlerts: {},
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization, onDestroy hook to clear pausable timeout.
         * Listening `show.alert` event to display a relevant Global Alert
         * @listens "alert.show"
         * @listens "alert.error"
         * @returns {void}
         */
        init() {
            super.init();
            this.onDestroy(() => this.disposePausableTimeout());
            /**
             * @description Event for Global Alert, contains data to be shown inside Global Alert popover
             * @event "alert.show"
             */
            this.eventBus().on('alert.show', 'showAlert');
            /**
             * @description Event for Global Alert, contains data to be shown inside Global Alert popover as an error message
             * @event "alert.error"
             */
            this.eventBus().on('alert.error', 'showError');
        }

        /**
         * @description Shows global errors like 500 response
         * @param {{errorCode: number}} data - Error data
         * @returns {void}
         */
        showError(data) {
            if (data.errorCode) {
                const accessibilityAlert = this.prefs().globalAlerts[`error${data.errorCode}`];

                this.showAlert({
                    accessibilityAlert
                });
            }
        }

        /**
         * @description Dispose pausable timeout and clear it resources
         * @returns {void}
         */
        disposePausableTimeout() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'pausableTimeout' does not exist on type ... Remove this comment to see the full error message
            if (this.pausableTimeout) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'pausableTimeout' does not exist on type ... Remove this comment to see the full error message
                this.pausableTimeout.destroy();

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'pausableTimeout' does not exist on type ... Remove this comment to see the full error message
                this.pausableTimeout = undefined;
            }
        }

        /**
         * @description Show Global Alert by starting pausable timeout
         * @param {{accessibilityAlert: string}} data - Message to be shown and pronounced
         * @returns {void}
         */
        showAlert(data) {
            if (data && data.accessibilityAlert) {
                if (this.shown) {
                    this.hideAlert();
                }

                this.ref('item')
                    .setText(data.accessibilityAlert)
                    .show();
                this.shown = true;

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'pausableTimeout' does not exist on type ... Remove this comment to see the full error message
                this.pausableTimeout = new PausableTimeout(() => {
                    this.hideAlert();
                }, this.prefs().timeout);
            }
        }

        /**
         * @description Hide Global Alert and clear timeout
         * @returns {void}
         */
        hideAlert() {
            this.ref('item')
                .setText('')
                .hide();
            this.shown = false;
            this.disposePausableTimeout();
        }

        /**
         * @description Pauses Global Alert displaying.
         * Pauses hide alert with pausable timeout.
         * @returns {void}
         */
        pauseAlert() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'pausableTimeout' does not exist on type ... Remove this comment to see the full error message
            if (this.pausableTimeout) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'pausableTimeout' does not exist on type ... Remove this comment to see the full error message
                this.pausableTimeout.pause();
            }
        }

        /**
         * @description Resumes Global Alert displaying.
         * Resumes hide alert with pausable timeout.
         * @returns {void}
         */
        resumeAlert() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'pausableTimeout' does not exist on type ... Remove this comment to see the full error message
            if (this.pausableTimeout) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'pausableTimeout' does not exist on type ... Remove this comment to see the full error message
                this.pausableTimeout.resume();
            }
        }
    }

    return GlobalAlerts;
}
