/**
 * @typedef {ReturnType<typeof import('widgets/Widget').default>} Widget
 */

/**
 * @description Base CartLineItem implementation
 * @param {Class} Widget Base widget for extending
 * @returns {Class} Product Detail widget
 */
export default function (Widget) {
    /**
     * @class CartLineItem
     * @augments Widget
     * @classdesc Represents CartLineItem component
     *
     * @property {number|string} data-pid - product ID
     * @property {number} data-selected-quantity - selected quantity
     * @category widgets
     * @subcategory product
     * @example
     *
     * <main
     *     role="main"
     *     class="l-pdp-main"
     *     data-widget="CartLineItem"
     *     data-analytics="${JSON.stringify(product.gtmInfo)}"
     *     data-pid="${product.id}"
     *     data-text-added-to-wishlist="${Resource.msg('button.added.to.wishlist', 'wishlist', null)}"
     *     data-accessibility-alerts='{
     *         "quantitychanged": "${Resource.msg('alert.quantitychanged', 'product', null)}",
     *         "variationselected": "${Resource.msg('alert.variationselected', 'product', null)}",
     *         "optionselected": "${Resource.msg('alert.optionselected', 'product', null)}",
     *         "addedtocart": "${Resource.msg('alert.addedtocart', 'product', null)}",
     *         "addedtowishlist": "${Resource.msg('alert.addedtowishlist', 'product', null)}"
     *     }'
     * >
     *     PDP content
     * </main>
     */
    class CartLineItem extends Widget {
        prefs() {
            return {
                descriptions: 'descriptions',
                pid: '',
                selectedQuantity: 0,
                accessibilityAlerts: {
                    quantitychanged: '',
                    optionselected: '',
                    variationselected: ''
                },
                ...super.prefs()
            };
        }
    }

    return CartLineItem;
}
