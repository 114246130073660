import { IRefElement } from 'widgets/toolbox/RefElement';
import { scrollIntoView } from 'widgets/toolbox/scroll';
import { get } from 'widgets/toolbox/util';
import { TWidget } from 'widgets/Widget';

export type TValidationConfig = {
    mandatory: boolean,
    minLength: number,
    maxLength: number,
    errors: Record<string, string>;
    patterns: Record<string, string>,
    compareWith: string,
    validOptions: Array<{ value: string; label: string; }>
};

export type TCompareWithObj = {
    widgetToMatchOpts: {
        field?: string;
        msg?: string;
        equality?: string;
    };
    widgetToMatch: InstanceType<TWidget>;
}

/**
 * @description Base BasicInput implementation
 * @param Widget Base widget for extending
 * @returns Basic Input class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory forms
     * @class BasicInput
     * @augments Widget
     * @classdesc Basic Input Widget (like abstract ancestor), contains basic validation and input management logic.
     * Supposes valid unified input markup. Contains also error messaging.
     * Usually is not used directly, and relevant subclasses should be used.
     * @property {boolean} data-skip-validation - if input needs to skip validation
     * @property {string} data-classes-error-state - classes for input's error state
     * @property {string} data-classes-valid-state - classes for input's valid state
     * @property {string} data-classes-disabled - classes for disabled input
     * @property {string} data-classes-locked - classes for locked input (disabled + readonly)
     * @property {string} data-classes-wrapper-error-state - classes for input wrapper, when input in error state
     * @property {string} data-classes-wrapper-valid-state - classes for input wrapper, when input in valid state
     * @property {object} data-validation-config - validation rules and error messages for input
     */
    class BasicInput extends Widget {
        initValue = '';

        widgetsToMatch: Array<TCompareWithObj> | undefined

        error = '';

        disabled = false;

        locked = false;

        prefs() {
            return {
                skipValidation: false,
                classesErrorState: 'm-invalid',
                classesValidState: 'm-valid',
                classesDisabled: 'm-disabled',
                classesLocked: 'm-locked',
                classesWrapperErrorState: 'm-invalid',
                classesWrapperValidState: 'm-valid',
                validationConfig: <TValidationConfig> {},
                ...super.prefs()
            };
        }

        init() {
            this.initValue = this.getValue();

            if (!this.id && this.ref('field').attr('name')) {
                this.id = <string> this.ref('field').attr('name');
            }

            this.disabled = this.ref('field').attr('disabled') === 'disabled';
            this.widgetsToMatch = [];
        }

        /**
         * @description Get input value
         * @returns - return input name
         */
        getValue(): string {
            return <string>(this.ref('field').val());
        }

        /**
         * @description Get input name
         * @returns - return input name
         */
        getName(): string {
            return <string> this.ref('field').attr('name');
        }

        /**
         * @description Set focus to input
         */
        focus(): void {
            const field = this.ref('field').get();

            if (field) {
                field.focus();
            }
        }

        /**
         * @description Set focus to input and scroll element into viewport
         */
        setFocus(): void {
            const elementToScroll = this.ref('self').get();

            if (elementToScroll) {
                scrollIntoView(elementToScroll);
            }

            this.focus();
        }

        /**
         * @description Blur (unfocus) an input
         */
        blur(): void {
            const field = this.ref('field').get();

            if (field) {
                field.blur();
            }
        }

        /**
         * @description Set input value
         * "checkRanges" method needs to validate min\max length after changing input value by JS
         * https://stackoverflow.com/questions/53226031/html-input-validity-not-checked-when-value-is-changed-by-javascript
         * @param [newVal] - set this value to input
         * @param [silently] - if set to `true` - input
         * should not be validated against a new value and no events should be fired
         */
        setValue(newVal: (string | number | undefined) = '', silently: (boolean | undefined) = false): void {
            const refField = this.ref('field');

            refField.val(String(newVal));
            this.checkRanges(refField);

            if (!silently) {
                this.update();
            }
        }

        /**
         * @description Check min/max length when value is being set and if it out of boundaries, set custom validity.
         * After setting value via code, a min/max length rule does not work until the value is changed by user.
         * @param refField - Field ref object
         * */
        checkRanges(refField: IRefElement): void {
            const value: string = <string>(refField.val());
            const field = refField.get();
            const rangesError = this.getRangesError(value);

            if (field instanceof HTMLInputElement) {
                field.setCustomValidity(rangesError);
            }
        }

        /**
         * @description Check ranges and return appropriate error message or empty string
         * @param val - Value that is being set
         * @returns Ranges error message or empty string
         */
        getRangesError(val: string): string {
            if (!val) {
                return '';
            }

            const refField = this.ref('field');
            const field = refField.get();

            if (field instanceof HTMLInputElement) {
                const validationConfig = this.prefs().validationConfig;
                const valueLength = String(val).length;

                if (field.minLength && valueLength && valueLength < field.minLength) {
                    return get(validationConfig, 'errors.minLength', '');
                } else if (field.maxLength && valueLength > field.maxLength) {
                    return get(validationConfig, 'errors.maxLength', '');
                }
            }

            return '';
        }

        /**
         * @description Updates custom validity state
         */
        updateCustomValidityState(): void {
            const field = this.ref('field');
            const validity = field.getValidity();

            if (validity && validity.state.customError) {
                this.checkRanges(field);
            }
        }

        /**
         * @description Validate input and trigger `change` event
         * @emits BasicInput#change
         */
        update(): void {
            this.validate();
            /**
             * @description Event, indicates input value was changed
             * @event BasicInput#change
            */
            this.emit('change', this);
        }

        /**
         * @description Clears input error
         * @emits BasicInput#inputstatechanged
         */
        clearError(): void {
            this.ref('field').removeClass(this.prefs().classesErrorState);
            this.ref('field').removeClass(this.prefs().classesValidState);
            this.ref('self').removeClass(this.prefs().classesWrapperErrorState);
            this.ref('self').removeClass(this.prefs().classesWrapperValidState);
            this.ref('errorFeedback').hide();
            /**
             * @description Event, indicates input state was changed
             * @event BasicInput#inputstatechanged
            */
            this.emit('inputstatechanged', this);
        }

        /**
         * @description Set/unset error state into input (message and classes)
         * @param [error] error message - if defined, will set error state, and valid state - otherwise
         * @emits BasicInput#inputstatechanged
         */
        setError(error: string): void {
            if (error) {
                this.ref('field').removeClass(this.prefs().classesValidState);
                this.ref('self').removeClass(this.prefs().classesWrapperValidState);
                this.ref('field').addClass(this.prefs().classesErrorState);
                this.ref('self').addClass(this.prefs().classesWrapperErrorState);
                this.ref('errorFeedback').setText(error).show();
            } else {
                this.ref('field').removeClass(this.prefs().classesErrorState);
                this.ref('self').removeClass(this.prefs().classesWrapperErrorState);
                this.ref('field').addClass(this.prefs().classesValidState);
                this.ref('self').addClass(this.prefs().classesWrapperValidState);
                this.ref('errorFeedback').hide();
            }

            this.emit('inputstatechanged', this, error);
        }

        /**
         * @description Indicates, that input value is (is not) valid against HTML5 native constraints (input patterns, min/max etc).
         * Also cares about case, when some field value should match another field value.
         * Sets error message in case of input is not valid. Message is taken from JSON `validationConfig` data-attribute
         * @returns is input valid or not
         */
        isValid(): boolean {
            const field = this.ref('field');
            const value = this.getValue();

            this.updateCustomValidityState();

            // eslint-disable-next-line prefer-const
            let { state, msg } = field.getValidity()
                || { msg: '', state: ({ valid: true } as ValidityState) };
            let valid = state.valid;

            const validationStateMsg = this.getValidationStateMsg(state);

            if (validationStateMsg) {
                msg = validationStateMsg;
            }

            if (valid) {
                const matchingResult = this.checkMatching();

                msg = matchingResult.msg;
                valid = matchingResult.valid;
            }

            // Added for cases when autocomplete ignores standard length validation
            if (valid && value) {
                msg = this.getRangesError(value);
                valid = !msg;
            }

            if (valid && value) {
                const patternsCheckingResult = this.checkPatterns();

                msg = patternsCheckingResult.msg;
                valid = patternsCheckingResult.valid;
            }

            this.error = msg;

            return valid;
        }

        /**
         * @description Get message by state validity
         * @param state Validity state
         */
        getValidationStateMsg(state: ValidityState) : string {
            const validation = this.prefs().validationConfig;

            if ((state.patternMismatch || state.typeMismatch)) {
                return validation.errors.parse || validation.errors.security;
            } else if ((state.rangeOverflow || state.rangeUnderflow || state.tooLong || state.tooShort)) {
                if (state.rangeOverflow || state.tooLong) {
                    return validation.errors.maxLength;
                } else if (state.rangeUnderflow || state.tooShort) {
                    return validation.errors.minLength;
                }
            } else if (state.valueMissing) {
                return validation.errors.required;
            }

            return '';
        }

        /**
         * @description Check that field`s value match to an other field`s value
         * @returns Result object
         */
        checkMatching() {
            const result = {
                valid: true,
                msg: ''
            };

            if (this.widgetsToMatch && this.widgetsToMatch.length) {
                const invalidWidget = this.getInvalidCompareWithWidget();

                if (invalidWidget) {
                    result.msg = invalidWidget.widgetToMatchOpts.msg || '';
                    result.valid = false;
                }
            }

            return result;
        }

        /**
         * @description Check field patterns
         * @returns {object} Result object
         */
        checkPatterns() {
            const fieldValue = this.getValue();
            const validation = this.prefs().validationConfig;
            const result = {
                valid: true,
                msg: ''
            };

            if (validation.patterns) {
                const patternNames = Object.keys(validation.patterns);

                for (let index = 0; index < patternNames.length; index++) {
                    const patternName = patternNames[index];
                    const pattern = validation.patterns[patternName];
                    const patternRegExp = new RegExp(pattern);

                    if (!patternRegExp.test(fieldValue)) {
                        result.msg = validation.errors[patternName];
                        result.valid = false;
                    }
                }
            }

            return result;
        }

        /**
         * @description Triggers input validation process
         * @returns {boolean} input validation result
         */
        validate(): boolean {
            if (!this.shown || this.disabled || this.prefs().skipValidation) {
                return true;
            }

            const valid = this.isValid();

            if (valid) {
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
                this.setError();
            } else {
                this.setError(this.error);
            }

            return valid;
        }

        /**
         * @description Disables an input
         * @returns {this} `this` instance for chaining
         */
        disable(): this {
            this.disabled = true;
            this.ref('field').disable();
            this.ref('self').addClass(this.prefs().classesDisabled);

            return this;
        }

        /**
         * @description Enables an input
         * @returns {this} `this` instance for chaining
         */
        enable(): this {
            this.disabled = false;
            this.ref('field').enable();
            this.ref('self').removeClass(this.prefs().classesDisabled);

            return this;
        }

        /**
         * @description Locks an input (adds `readonly` attribute)
         * @returns {void}
         */
        lock(): void {
            this.locked = true;
            this.ref('field').attr('readonly', true);
            this.ref('self').addClass(this.prefs().classesLocked);
        }

        /**
         * @description Unlocks an input (removes `readonly` attribute)
         * @returns {void}
         */
        unlock(): void {
            this.locked = false;
            this.ref('field').attr('readonly', false);
            this.ref('self').removeClass(this.prefs().classesLocked);
        }

        /**
         * @description Checks if input is disabled
         * @returns {boolean} result
         */
        isDisabled(): boolean {
            return !!this.disabled;
        }

        /**
         * @description Saves on widget level target widget for comparison validation to use it further in `isValid` method
         * @param widgetToMatch cmp
         * @param options to compare
         */
        setMatchCmp(widgetToMatch: BasicInput, options: {
            [x: string]: string | undefined;
        } = {}): void {
            if (this.widgetsToMatch) {
                this.widgetsToMatch.push({
                    widgetToMatch: widgetToMatch,
                    widgetToMatchOpts: options
                });
            }
        }

        /**
         * @description To be either included or not into the form submission to server.
         * @returns result
         */
        skipSubmission(): boolean {
            return false;
        }

        /**
         * @description Get invalid widget according to compareWith validation rules
         */
        getInvalidCompareWithWidget(): TCompareWithObj | null {
            if (this.widgetsToMatch) {
                return this.widgetsToMatch.find(widget => {
                    const equality = widget.widgetToMatchOpts.equality;
                    const matchValue = widget.widgetToMatch.data('getValue');

                    return (equality && matchValue !== this.getValue())
                        || (!equality && matchValue === this.getValue());
                }) || null;
            }

            return null;
        }
    }

    return BasicInput;
}
