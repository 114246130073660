import { getJSONByUrl, submitFormJson, submitFormJsonWithAbort } from 'widgets/toolbox/ajax';
import { getUrlParams, timeout } from 'widgets/toolbox/util';
import { scrollWindowTo } from 'widgets/toolbox/scroll';

import { getCookie } from 'widgets/toolbox/cookie';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';
import { appendParamToURL } from 'widgets/toolbox/util';
/**
 * @typedef {ReturnType<typeof import('widgets/cart/CartMgr').default>} BaseCartMgr
 * @typedef {InstanceType<ReturnType<typeof import('core/cart/CartShippingTabs').default>>} cartShippingTabs
 * @typedef {ReturnType<typeof import('widgets/forms/InputStepper').default>} InputStepper
 */

/**
 * @description CartMgr implementation
 * @param {BaseCartMgr} BaseCartMgr BaseCartMgr implementation
 * @returns {typeof CartMgr} CartMgr class
 */
export default function (BaseCartMgr) {
    /**
     * @class CartMgr
     * @augments BaseCartMgr
     * @classdesc CartMgr implementation
     */
    class CartMgr extends BaseCartMgr {
        prefs() {
            return {
                selectStoreUrl: '',
                changeShippingUrlTriggers: '',
                activePanelTrigger: '',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.eventBus().on('preferredstore.selected', 'onPreferredStoreSelected');
            this.eventBus().on('product.added.to.cart', 'onProductAdedToCart');
            this.eventBus().on('push.datalayer.paypal', 'pushDatalayerPaypal');
            this.eventBus().on('push.datalayer.applepay', 'pushDatalayerApplepay');
            this.eventBus().emit('refresh.applepay');

            if (this.prefs().items.length) {
                this.triggerTabClick();
            }

            const { changeStore } = getUrlParams();

            if (changeStore) {
                /**
                 * @description Event dispatched, when Change Store should be triggered
                 * @event module:events#dialogshow
                 */
                timeout(() => {
                    this.eventBus().emit('dialogshow', {
                        url: this.prefs().selectStoreUrl,
                        wrapperClasses: 'm-stock_search b-stock_search',
                        windowClasses: 'b-stock_search-window',
                        headerClasses: 'b-stock_search-header',
                        bodyClasses: 'b-stock_search-body',
                        contentType: 'html'
                    });
                });
            }

            if (this.cart?.valid?.error && this.cart?.valid?.gtmError) {
                this.onCartLoadGlobalEvents(this.cart);
            }
        }

        /**
         * @description Renders a cart when product was added for ex. from product tile
         * @param {object} data object with a cart model from response
         * @returns {void}
         */
        onProductAdedToCart(data) {
            const cart = data && data.cartModel;

            this.renderCart(data.cartModel || data)
                .then(() => scrollWindowTo(this.ref('cartContainer').get(), true));

            this.triggerTabClick(cart);
        }

        /**
         * @description Renders cart on shipping changed between home and click&collect delivery
         * @param {cartShippingTabs} cartShippingTabs cart shipping tabs widget
         * @param {object} data cart model from server response
         * @returns {void}
         */
        onShippingChanged(cartShippingTabs, data) {
            this.renderCart(data.cart);
        }

        /**
         * @description Workaround to trigger __SYSTEM__Applepay-GetRequest call, we set random value to dwapupreq cookie
         */
        appendApplepayCookies() {
            let cookies = document.cookie;

            const randomId = Math.random() * (1000000 - 1) + 1;

            cookies += '; dwapupreq=' + randomId + '; ';
            document.cookie = cookies;
            document.cookie = 'dwapupreq=' + randomId;
        }

        /**
         * @description Re-render cart after another click&collect store selected
         * @returns {void}
         */
        onPreferredStoreSelected() {
            this.appendApplepayCookies();
            getJSONByUrl(this.prefs().cartGetUrl)
                .then(response => {
                    this.renderCart(response);
                });
        }

        /**
         * @description Cart load global events
         * @param {object} cart - cart JSON
         * @returns {void}
         */
        onCartLoadGlobalEvents(cart) {
            this.eventBus().emit('gtm.datalayer.event', {
                event: 'data-layer-event',
                eventCategory: 'Error messages',
                eventAction: 'Product availability',
                eventLabel: cart.valid.gtmError
            });
        }

        /**
         * @description Renders Cart object and saves it as a current cart state
         * Adds some information into Cart object prior to rendering,
         * to make the whole object mustache template friendly
         * @param {object} cart - server response resp
         * @returns {Promise<object | void>} Promise object represents cart rendering result
         */
        renderCart(cart) {
            if (!cart) {
                return Promise.reject(new Error());
            }

            this.cart = cart;
            /**
             * @description Event, indicates that cart content was updated
             * @event "cart.updated"
             */
            this.eventBus().emit('cart.updated', { cartModel: cart });

            return this.render('cartTemplate', cart, this.ref('cartContainer')).then(() => {
                (cart.itemsIds || []).forEach((/** @type {string} */ id) => {
                    this.eventBus().emit('wishlist.updated', id);
                });

                if (cart?.valid?.error && cart?.valid?.gtmError) {
                    this.onCartLoadGlobalEvents(cart);
                }
            });
        }

        /**
         * @description A callback, which is executed, when customer agreed to remove product in confirmation popup
         * @param {object} button - Button, pressed in confirmation modal
         * @param {boolean} [movedToWishlist] - Indicates if product was moved to wishlist
         * @emits "cart.remove.product"
         * @returns {Promise<object|null>} Promise object represents server response for product deletion
         */
        // @ts-ignore
        confirmedRemoveProduct(button, movedToWishlist = false) {
            const attributes = button.attributes;

            if (!attributes) {
                return Promise.resolve({ error: true });
            }

            const url = attributes.url;
            const pid = attributes.pid;
            const uuid = attributes.uuid;

            if (!movedToWishlist) {
                this.showProgressBar();
            }

            let removePromise = submitFormJson(url, {
                pid: pid,
                uuid: uuid
            }, 'POST')
                .then((response) => {
                    if (response.error) {
                        throw Error(response.error);
                    }

                    if (response.basket) {
                        localStorageWrapper.removeItem('cartItemIds');
                        localStorageWrapper.setItem('currentSid', getCookie('sid'));
                        localStorageWrapper.setItem('cartItemIds', JSON.stringify(response.basket.itemsIds || []));
                    }

                    response.basket.isProductRemoved = true;

                    this.confirmationRemoveCallback(response.basket, movedToWishlist);
                    this.eventBus().emit('cart.remove.product', this);

                    return response;
                })
                .then((response) => {
                    this.triggerTabClick(response.basket);
                });

            if (!movedToWishlist) {
                removePromise = removePromise
                    .catch(error => {
                        this.renderCartWithItemLevelActionError(uuid, error.message);

                        return error;
                    })
                    .finally(() => {
                        this.hideProgressBar();
                    });
            }

            return removePromise;
        }

        /**
         * @description Handle tab change
         * @param {object} resp - responce with cart model
         * @returns {void}
         */
        triggerTabClick(resp) {
            this.eventBus().emit('refresh.applepay');

            if (this.prefs().activePanelTrigger) {
                const shipping = (resp && resp.activeShipping) || this.prefs().activePanelTrigger;
                let changeShippingUrl = this.prefs().changeShippingUrlTriggers;

                changeShippingUrl = appendParamToURL(changeShippingUrl, 'shippingTab', shipping);

                getJSONByUrl(changeShippingUrl)
                    .then(response => {
                        if (response.success) {
                            /**
                             * actual tab change happens later on cart re-render
                             * in event handler using `onRefresh` method of `CartShippingTabs`
                             * with preselected tab panel name based on `shipping` variable
                             */
                            // @ts-ignore
                            this.renderCart(response.cart);

                            this.eventBus().emit('gtm.datalayer.event', {
                                event: 'data-layer-event',
                                eventCategory: 'Key interactions',
                                eventAction: 'Delivery choice',
                                eventLabel: shipping === 'homeDelivery' ? 'Home delivery' : 'Click & collect'
                            });
                        }
                    });
            }
        }

        /**
         * @description Updates quantity of chosen product in Cart page
         * @param {inputStepper} quantityStepper - Select quantity input
         * @returns {Promise<object|null>} Promise object represents server response with quantity updating result
         */
        updateQty(quantityStepper) {
            this.showProgressBar();
            // @ts-expect-error TS2339: Property 'numItems' does not exist on type 'TCart'.
            const qtyBeforeUpdate = this.cart.numItems;
            const uuid = quantityStepper.data('uuid');
            const currentQuantity = quantityStepper.currentValue;
            const isQuantityBelowMinValue = currentQuantity < quantityStepper.config.lineItemMinOrderQuantity;

            if ((currentQuantity === 0) || isQuantityBelowMinValue) {
                this.removeProduct(quantityStepper);
                this.hideProgressBar();

                return Promise.resolve(null);
            }

            const requestObject = submitFormJsonWithAbort(quantityStepper.data('action'), {
                pid: quantityStepper.data('pid'),
                uuid: uuid,
                quantity: quantityStepper.getValue()
            }, 'POST', false);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateQtyRequests' does not exist on typ... Remove this comment to see the full error message
            this.updateQtyRequests.push(requestObject);

            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            return requestObject.promise
                .then((resp) => {
                    // @ts-expect-error TS2571: Object is of type 'unknown'.
                    const isQuantityIncreased = resp.numItems > qtyBeforeUpdate;

                    // @ts-expect-error TS2571: Object is of type 'unknown'.
                    resp.isQuantityIncreased = isQuantityIncreased;

                    this.renderCartResponse(resp);

                    // @ts-expect-error TS2571: Object is of type 'unknown'.
                    if (resp.numItems > qtyBeforeUpdate) {
                        // @ts-expect-error TS2571: Object is of type 'unknown'.
                        this.eventBus().emit('product.cart.qty.increment', quantityStepper, resp.numItems - qtyBeforeUpdate);
                    } else {
                        // @ts-expect-error TS2339: Property 'numItems' does not exist on type 'TCart'.
                        this.eventBus().emit('product.cart.qty.decrement', quantityStepper, qtyBeforeUpdate - resp.numItems);
                    }

                    this.accessibilityAlert(this.prefs().accessibilityAlerts.quantitychanged);

                    return resp;
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        return Promise.resolve(null);
                    }

                    if (error && error.error && error.errorMessage) {
                        this.render('errorTemplate', { message: error.errorMessage }, this.ref('errorMsgs'));
                    } else {
                        this.renderCartWithItemLevelActionError(uuid, error.message);
                    }

                    return Promise.resolve(null);
                })
                .then((resp) => {
                    this.hideProgressBar();
                    this.triggerTabClick(resp);
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateQtyRequests' does not exist on typ... Remove this comment to see the full error message
                    const index = this.updateQtyRequests.indexOf(requestObject);

                    if (index > -1) {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateQtyRequests' does not exist on typ... Remove this comment to see the full error message
                        this.updateQtyRequests.splice(index, 1);
                    }
                });
        }

        /**
         * @description Trigger pushing event to datalayer for Paypal
         * @returns {void}
         */
        pushDatalayerPaypal() {
            this.eventBus().emit('cart.page.submitted', this, 'paypal');
        }

        /**
         * @description Trigger pushing event to datalayer for Paypal
         * @returns {void}
         */
        pushDatalayerApplepay() {
            this.eventBus().emit('cart.page.submitted', this, 'applepay');
        }
    }

    return CartMgr;
}
