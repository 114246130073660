// @ts-nocheck
import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/product/AddToCartMixin').default>} AddToCartMixin
 * @typedef {ReturnType<typeof import('widgets/global/ProcessButton').default>} ProcessButton
 */

/**
 * @description just added product to cart mixin widget
 * @param {AddToCartMixin} AddToCartMixin Base widget for extending
 * @returns {typeof JustAddedProductToCartMixin} ProductDetail class
 */
export default function (AddToCartMixin) {
    class JustAddedProductToCartMixin extends AddToCartMixin {
        /**
         * @description Handles add to cart button click
         *
         * @param {ProcessButton} button Add Product response
         */
        addToCart(button) {
            const addToCartBtnPrefs = button.prefs();

            if (addToCartBtnPrefs.isSelectStore) {
                this.eventBus().emit('dialogshow', addToCartBtnPrefs.modalConfig);

                return;
            }

            if (!addToCartBtnPrefs.justAddedRecommendationAddToCart) {
                super.addToCart(button);

                return;
            }

            this.addJustAddedProductRecommendation(button);
        }

        /**
         * @description Handles response from server once product added to cart
         *
         * @param {object} response Add Product response
         * @returns {void}
         */
        postAddProduct(response) {
            if (response && response.redirectUrl) {
                window.location.assign(response.redirectUrl);

                return;
            }

            if (!response.error && response.cart) {
                this.saveCartItems(response.cart.itemsIds);
            }

            if (!response.error && response.subscriptionUUID) {
                this.eventBus().emit('added.subscription.product.line.item');
            }

            if (response.justAddedProductCart && !this.isIdeaProduct(response) && !response.isBuyAgainProduct) {
                this.showJustAddedProductCart(response);

                if (response.availability) {
                    this.postAddProductAvailability(response);
                }
            } else {
                super.postAddProduct(response);
            }
        }

        /**
         * @description Handles add to cart button click
         * @param {ProcessButton} button Add Product response
         * @returns {Promise<object|null>} Promise object represents server response for session continuation
         */
        addJustAddedProductRecommendation(button) {
            if (this.prefs().processingRequest) {
                return Promise.resolve(null);
            }

            if (!this.prefs().readyToOrder) {
                this.showCartMessage(this.prefs().textSelectOptions, true);

                return Promise.resolve(null);
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'stock' does not exist on type 'AddToCart... Remove this comment to see the full error message
            if (this.stock && this.stock < this.prefs().selectedQuantity) {
                this.showCartMessage(this.prefs().textStockLimit, true);

                return Promise.resolve(null);
            }

            const selectedOptions = this.getSelectedProductOptions();
            const isSelectedOptionsValid = this.validateProductOptions(selectedOptions);

            if (!isSelectedOptionsValid) {
                return Promise.resolve(null);
            }

            this.setPref('processingRequest', true);
            button.startProcess();

            this.showProgressBar();

            const addToCartBtnPrefs = button.prefs();
            const pid = this.prefs().currentProductId || addToCartBtnPrefs.pid;
            let justAddedCartProductsID = [];

            this.eventBus().emit('just.added.cart.mgr.execute', (justAddedCartMgr) => {
                justAddedCartProductsID = [...justAddedCartMgr.getJustAddedItemsID()];
            });

            const stringifiedJustAddedProducts = JSON.stringify(justAddedCartProductsID);

            return submitFormJson(addToCartBtnPrefs.addToCartUrl, {
                pid: pid,
                pids: stringifiedJustAddedProducts,
                quantity: this.prefs().selectedQuantity || addToCartBtnPrefs.selectedQuantity,
                options: JSON.stringify(selectedOptions),
                childProducts: this.prepareChildProducts(this.prefs().bundledProducts)
            })
                .then((response) => {
                    this.setPref('processingRequest', false);
                    button.stopProcess();
                    this.hideProgressBar();
                    this.postAddProduct(response);

                    return response;
                })
                .catch(() => {
                    this.setPref('processingRequest', false);
                    button.stopProcess();
                    this.hideProgressBar();
                    this.showCartMessage(this.prefs().textNetworkError, true);
                });
        }

        /**
         * @description shows cart quick view
         *
         * @param {object} response - result of the adding to cart call
         * @param {object} response.cart - cart model
         * @param {string} response.pid - added product identifier
         */
        showJustAddedProductCart(response) {
            const cartModel = response.cart;
            const productID = response.pid;
            const subscriptionID = response.subscriptionID;

            cartModel.action = response.action;

            if (response.error && this.prefs().showMessageOnProductAdd) {
                this.eventBus().emit('product.addedTocart.with.error', response);
                this.showCartMessage(response.message, response.error);

                return;
            }

            if (!cartModel || !cartModel.numItems) {
                return;
            }

            this.showProgressBar();

            this.eventBus().emit('show.just.added.cart', { productID, cartModel, subscriptionID });

            cartModel.showMinicart = false;
            this.eventBus().emit('cart.updated', { cartModel }, this);

            if (window.contexts.includes('cart')) {
                this.eventBus().emit('product.added.to.cart', { cartModel }, this);
            } else if (response.subscriptionID) {
                this.eventBus().emit('just.added.product.to.cart', { cartModel }, this, null, response.subscriptionID);
            } else {
                this.eventBus().emit('just.added.product.to.cart', { cartModel }, this);
            }

            this.hideProgressBar();
        }

        /**
         * @description checks that is idea product
         *
         * @param {object} response - result of the adding to cart call
         * @param {object} response.cart - cart model
         * @param {string} response.pid - added product identifier
         * @returns {boolean} result
         */
        isIdeaProduct(response) {
            const { pid, cart } = response;

            const productModel = cart.items.find(item => item.id === pid);

            return productModel && productModel.isIdea;
        }
    }

    return JustAddedProductToCartMixin;
}
