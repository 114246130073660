import { submitFormJson, errorFallbackMessage } from 'widgets/toolbox/ajax';
import { scrollWindowTo, scrollToTop } from 'widgets/toolbox/scroll';
import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/global/Button').default>} Button
 */
/**
 * @param BasicForm Base widget for extending
 * @returns Ajax Form class
 */
export default function (BasicForm: ReturnType<typeof import('widgets/forms/BasicForm').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class AjaxForm
     * @augments BasicForm
     * @classdesc Represents AjaxForm component with next features:
     * 1. Allow submit and handle submit Form
     * 2. Allow handle server response
     * 3. Allow handle form errors (form level and field level) that comes from backend
     * AjaxForm widget should contain {@link Button} widgets that implement submit Form button.
     * Widget has next relationship:
     * * Handle Form submit using method {@link AjaxForm#handleSubmit} by event {@link Button#event:click} from {@link Button} widget.
     * @example <caption>Example of AjaxForm widget usage</caption>
     * <form
     *     data-widget="ajaxform"
     *     data-event-submit.prevent="handleSubmit" novalidate
     *     action="${URLUtils.url('Account-SavePassword')}" method="POST
     * >
     *     <div class="alert alert-danger" hidden="hidden" data-ref="errorMessageLabel"></div>
     *     ... form fields
     *     <input type="hidden" name="${pdict.csrf.tokenName}" value="${pdict.csrf.token}"/>
     *     <div>
     *         <button
     *              type="submit"
     *              name="save"
     *              data-widget="button"
     *              data-widget-event-click="handleSubmit"
     *              data-event-click.prevent="handleClick"
     *              data-id="submitButton"
     *         >
     *              ${Resource.msg('button.save','account',null)}
     *         </button>
     *     </div>
     * </form>
     * @property {string} data-widget - Widget name `ajaxform`
     * @property {string} data-event-submit - Event listener for form submission
     */
    class AjaxForm extends BasicForm {
        /**
         * @description Handles submit Form
         * @returns {Promise<object|null>} Promise object represents server response for shipping methods updating
         */
        handleSubmit() {
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
            if (this.isChildrenValid() && !this.submitting) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitting' does not exist on type 'Ajax... Remove this comment to see the full error message
                this.submitting = true;
                this.showProgressBar();
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'busy' does not exist on type 'Widget'.
                this.getById(this.prefs().submitButton, submitButton => submitButton.busy());
                this.ref(this.prefs().errorMessageLabel).hide();

                return submitFormJson(

                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | RefElement' is not assi... Remove this comment to see the full error message
                    this.getFormUrl(),
                    this.getFormFields(),
                    this.ref('self').attr('method') === 'GET' ? 'GET' : 'POST'
                )
                    .then((response) => {
                        this.onSubmitted(response);

                        return response;
                    })
                    .catch(this.onError.bind(this))
                    .finally(this.afterSubmission.bind(this));
            }

            return Promise.resolve(null);
        }

        /**
         * @description Handles server response
         * @emits AjaxForm#submit
         * @param {object} data Server JSON response once form submitted
         * @param {string} [data.success] - If form submission was success
         * @param {string} [data.redirectUrl] - if not empty - redirect to specified location should be executed
         * @param {boolean|string|string[]} [data.error] - error messages/message from server
         * @param {object[]} [data.fields] - form fields with errors
         * @returns {void}
         */
        onSubmitted(data) {
            if (data.success && data.redirectUrl) {
                window.location.assign(data.redirectUrl);
            } else if (data.error) {
                if (data.redirectUrl) {
                    window.location.assign(data.redirectUrl);
                } else {
                    this.setError(Array.isArray(data.error) ? data.error.join('<br/>') : data.error);
                }
            } else if (data.fields) {
                Object.entries(data.fields).forEach(([name, errorMsg]) => {
                    this.getById(name, (input) => {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setError' does not exist on type 'Widget... Remove this comment to see the full error message
                        input.setError(errorMsg);
                    });
                });
            } else {
                timeout(() => {
                    /**
                     * @description Event to submit AjaxForm
                     * @event AjaxForm#submit
                     */
                    this.emit('submit', data);
                });
            }
        }

        /**
         * @description Handles an error, which happens during request (for ex. 500 response)
         * @param {Error} e - error, which happened during request
         * @returns {void}
         */
        onError(e) {
            this.setError(e.message || errorFallbackMessage);
            const errorElement = this.ref(this.prefs().errorMessageLabel).get();

            if (errorElement) {
                scrollWindowTo(errorElement, true);
            } else {
                scrollToTop();
            }
        }

        /**
         * @description Changes Form state after submission
         * @returns {void}
         */
        afterSubmission() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'unbusy' does not exist on type 'Widget'.
            this.getById(this.prefs().submitButton, submitButton => submitButton.unbusy());
            this.hideProgressBar();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitting' does not exist on type 'Ajax... Remove this comment to see the full error message
            this.submitting = false;
        }

        /**
         * @description Set Form or Form fields errors
         * @param {string} msg - Generic error message, if no custom message - show generic one
         * @param {boolean} [scrollToError] - defines using of scroll to error
         * @param {string} refElem - RefElement id
         * @returns {void}
         */
        setError(msg, scrollToError = true, refElem = 'errorMessageLabel') {
            const errorMessageLabel = this.ref(refElem)
                .setText(msg || '')
                .show();

            if (!scrollToError) {
                return;
            }

            const element = errorMessageLabel.get();

            if (element) {
                scrollWindowTo(element, true);
            } else {
                scrollToTop();
            }
        }
    }

    return AjaxForm;
}
