const temporaryStorage = {};

export default {
    setItem: (key, value) => {
        try {
            window.localStorage.setItem(key, value);
        } catch (error) {
            temporaryStorage[key] = value;
        }
    },
    getItem: (key) => {
        let value;

        try {
            value = window.localStorage.getItem(key);
        } catch (error) {
            value = temporaryStorage[key];
        }

        return value;
    },
    removeItem: (key) => {
        try {
            window.localStorage.removeItem(key);
        } catch (error) {
            delete temporaryStorage[key];
        }
    }
};
