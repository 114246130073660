/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/getAttributeNames#Polyfill
 *
 * Needed for MS Edge and IE
 */
if (Element.prototype.getAttributeNames === undefined) {
    Element.prototype.getAttributeNames = function getAttributeNames() {
        const attributes = this.attributes;
        const length = attributes.length;
        const result = [];

        result.length = length;

        for (let i = 0; i < length; i += 1) {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
            result[i] = attributes[i].name;
        }

        return result;
    };
}
