/* eslint-disable indent */
const hasContext = (context: string) => window.contexts.includes(context);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('storelocator'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'storelocator.widgets' */ './widgets/storelocator/StoreSearchForm').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ './widgets/storelocator/SearchByPlace').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ './widgets/storelocator/StoreLocatorMgr').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ './widgets/storelocator/LatLngDistance').then(d => d.default)
    ]).then(deps => {
        const [
            StoreSearchForm,
            SearchByPlace,
            StoreLocatorMgr,
            LatLngDistance
        ] = deps;

        return {
            listId: 'storelocator.widgets',
            widgetsDefinition: () => [
                ['storeSearchForm', StoreSearchForm],
                ['searchByPlace', SearchByPlace, 'inputText'],
                ['storeLocatorMgr', StoreLocatorMgr],
                ['latLngDistance', LatLngDistance]
            ]
        };
    })
});
