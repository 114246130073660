/**
 * @module wishlistItemsMgr
 * @category widgets
 * @subcategory toolbox
 * @description Represents wishlistItemsMgr component with next features:
 * 1. Allow getting items in the wish list
 * 2. Allow setting items from the wish list to the local storage
 * @example <caption>Example of WishlistItemsMgr module usage</caption>
 * import { WishlistItemsMgr } from 'widgets/toolbox/WishlistItemsMgr';
 *
 *  init() {
 *      super.init();
 *      wishlistItemsMgr.isItemInWishlist(this.prefs().pid, this.prefs().productOptions)
 *          .then((state) => {
 *              this.changeStateWishlistButton(state);
 *          })
 *      .catch(error => console.log(error));
 *  }
 */
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import { getCookie, setCookie } from 'widgets/toolbox/cookie';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

const SHADOW_CHANGES_COOKIE_NAME = 'wishlistShadowChangesNormalized';

export class WishlistItemsMgr {
    static instance: WishlistItemsMgr | undefined;

    private loadWishlistItemsPromise: Promise<void> | undefined;

    /**
     * @description Returns instance of WishlistItemsMgr
     * @returns Instance of WishlistItemsMgr
     */
    static getInstance(): WishlistItemsMgr {
        if (WishlistItemsMgr.instance) {
            return WishlistItemsMgr.instance;
        }

        WishlistItemsMgr.instance = new WishlistItemsMgr();

        return WishlistItemsMgr.instance;
    }

    /**
     * @description Check if product in wish list
     * @param productId Target product id
     * @param productOptions Product options
     * @returns Result
     */
    isItemInWishlist(productId: string, productOptions?: Array<Record<string, unknown>>): Promise<boolean> {
        return this.getWishlistItems()
            .then((response) => {
                let result = false;

                if (!response) {
                    return Promise.resolve(result);
                }

                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                const items = response.wishlistItems;
                // TODO: This is a temporary solution.Implement support few options on product
                const option = productOptions ? productOptions[0] : null;
                const item = items?.find(wishlistItem => wishlistItem.id === String(productId));

                if (!item) {
                    return Promise.resolve(result);
                }

                const itemOptionId = item.optionId;
                const selectedValueId = item.selectedValueId;

                result = true;

                if (option && itemOptionId && selectedValueId) {
                    result = option.id === itemOptionId && option.selectedValueId === selectedValueId;
                }

                return Promise.resolve(result);
            });
    }

    /**
     * @description Checks if wish list contains items
     * @returns Indicates if items in the wish list
     */
    isItemsInWishList(): Promise<boolean> {
        return this.getWishlistItems()
            // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
            .then(result => Promise.resolve(result?.wishlistItems?.length > 0));
    }

    /**
     * @description Do call to get items in the wish list from the server
     * @param url URL to get items in the wish list
     * @returns Fetching result promise
     */
    loadWishListItems(url:string): Promise<void | Record<string, unknown>> {
        return getJSONByUrl(url)
            .then((result) => {
                this.saveWishListItems(result.wishlistItems);

                return Promise.resolve(result);
            })
            // eslint-disable-next-line no-console
            .catch(error => console.log(error));
    }

    /**
    * @description Set wish list items to the local storage
    * @param {object} wishlistItems Object contains items in the wish list
    */
    saveWishListItems(wishlistItems) {
        localStorageWrapper.setItem('currentSid', getCookie('sid'));
        localStorageWrapper.setItem('wishlistItems', JSON.stringify(wishlistItems) || []);
    }

    /**
     * @description Update local storage with new items in the wish list
     * @param {object} wishlistItems Object contains items in the wish list
     */
    updateWishlistItems(wishlistItems) {
        localStorageWrapper.removeItem('wishlistItems');
        this.saveWishListItems(wishlistItems);
    }

    /**
     * @description normalizes wishlist items
     * @returns {boolean} result
     */
    normalizeWishListItems() {
        let isDataNormalized = true;

        const wishlistShadowChangesCookie = getCookie(SHADOW_CHANGES_COOKIE_NAME);

        if (!wishlistShadowChangesCookie) {
            return isDataNormalized;
        }

        try {
            isDataNormalized = JSON.parse(wishlistShadowChangesCookie);
        } catch (e) {
            return isDataNormalized;
        }

        setCookie(
            SHADOW_CHANGES_COOKIE_NAME, 'true', undefined, true
        );

        return isDataNormalized;
    }

    /**
     * @description Returns fetching result promise
     * @returns {Promise<object>} Promise result
     */
    getWishlistItems() {
        return new Promise((resolve, reject) => {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'sfccData' does not exist on type 'Window... Remove this comment to see the full error message
            const url = window.sfccData.wishlistActionUrls?.getItems || null;

            if (!url) {
                return reject(new Error('no URL provided'));
            }

            if (this.loadWishlistItemsPromise) {
                return resolve(this.loadWishlistItemsPromise);
            }

            const items = localStorageWrapper.getItem('wishlistItems');
            const isSameSession = localStorageWrapper.getItem('currentSid') === getCookie('sid');
            const isDataNormalized = this.normalizeWishListItems();

            if (!!items && isSameSession && isDataNormalized) {
                return resolve({ wishlistItems: JSON.parse(items) });
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadWishlistItemsPromise' does not exist... Remove this comment to see the full error message
            this.loadWishlistItemsPromise = this.loadWishListItems(url);

            return resolve(this.loadWishlistItemsPromise);
        });
    }
}
WishlistItemsMgr.instance = undefined;
