/**
 * @module scroll
 * @category widgets
 * @subcategory toolbox
 * @description Represents scroll component with next features:
 * 1. Allow to scroll element into view
 * 2. Allow to scroll element to provided coordinate
 * 3. Allow to scroll window to provided element
 * 4. Allow to scroll window to top
 * 5. Allow to get scroll postion
 * 6. Allow to add callback on scroll event
 * @example <caption>Example of scroll module usage</caption>
 * import { scrollWindowTo, scrollToTop } from 'widgets/toolbox/scroll';
 *
 * scrollWindowTo(errorElement, true);
 */

import { debounce } from 'widgets/toolbox/debounce';
import {
    SMALL,
    MEDIUM,
    LARGE,
    EXTRA_LARGE,
    getViewType
} from 'widgets/toolbox/viewtype';

const stickyHeaderHeightMap = {
    [SMALL]: 64,
    [MEDIUM]: 64,
    [LARGE]: 80,
    [EXTRA_LARGE]: 80
};

/**
 * @description Scroll element into view
 * @param {HTMLElement|undefined} element - HTML Element
 * @returns {void}
 */
export function scrollIntoView(element) {
    if (!element) {
        return;
    }

    if ('scrollBehavior' in document.documentElement.style) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
        element.scrollIntoView(false);
    }
}

/**
 * @description Scroll element to provided coordinate
 * @param {HTMLElement} element - HTML Element
 * @param {number} [top] - top coordinate
 * @param {number} [left] - left coordinate
 * @returns {void}
 */
export function scrollElementTo(element, top = 0, left = 0) {
    if (element && typeof element.scrollTo === 'function') {
        element.scrollTo({ top: top, left: left, behavior: 'smooth' });
    }
}

/**
 * @description Scroll window to provided element
 * @param {HTMLElement|undefined} element - HTML Element
 * @param {boolean} [countHeaderHeight] - add to top header height
 * @returns {void}
 */
export function scrollWindowTo(element, countHeaderHeight = false) {
    if (!element) {
        return;
    }

    const headerHeight = stickyHeaderHeightMap[getViewType()];
    const top = element.getBoundingClientRect().top + window.pageYOffset;
    const left = 0;

    if (countHeaderHeight) {
        window.scrollTo({ top: top - headerHeight, left: left, behavior: 'smooth' });
    } else {
        window.scrollTo({ top: top, left: left, behavior: 'smooth' });
    }
}

/**
 * @description Scroll window to top
 * @returns {void}
 */
export function scrollToTop() {
    window.scrollTo(0, 0);
}

const DEBOUNCE_INTERVAL = 20;
/**
 * @type {((arg0: {currentPosition: number, lastScrollPosition: number, diff: number}) => void)[]}
 */
const scrollHandlers = [];

/**
 * @description Get scroll postion
 * @returns {number} Scroll postion
 */
export function getScrollPosition() {
    if (document.documentElement.scrollTop !== undefined) {
        return document.documentElement.scrollTop;
    }

    if (document.scrollingElement && document.scrollingElement.scrollTop !== undefined) {
        return document.scrollingElement.scrollTop;
    }

    return -1;
}

let lastScrollPosition = 0;

const debounceScroll = debounce(() => {
    const currentPosition = getScrollPosition();

    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
    scrollHandlers.forEach(handler => handler({
        currentPosition,
        lastScrollPosition,
        diff: currentPosition - lastScrollPosition
    }));
    lastScrollPosition = currentPosition;
}, DEBOUNCE_INTERVAL, true, true);

/**
 * @description Add callback on scroll event
 * @param {(arg0: {currentPosition: number, lastScrollPosition: number, diff: number}) => void} callback - on scroll callback
 * @returns {Function} result
 */
export function onScroll(callback) {
    if (!scrollHandlers.length) {
        window.addEventListener('scroll', debounceScroll, { passive: true });
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    scrollHandlers.push(callback);

    return () => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        scrollHandlers.splice(scrollHandlers.indexOf(callback), 1);

        if (!scrollHandlers.length) {
            window.removeEventListener('scroll', debounceScroll);
        }
    };
}
