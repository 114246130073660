import { getContentByUrl } from 'widgets/toolbox/ajax';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {ReturnType<typeof import('widgets/forms/InputStepper').default>} InputStepper
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Cart Mixin implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {Widget} Cart Mixin class
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory cart
     * @class CartMixin
     * @augments Widget
     * @classdesc CartMixin component on Cart page. Renders the whole cart template on each update e.g. quantity/coupon/product edit etc.
     * Widget manages next actions:
     * 1. Edit product in a cart
     */
    class CartMixin extends Widget {
        prefs() {
            return {
                classesDisabled: 'm-disabled',
                ...super.prefs()
            };
        }

        /**
         * @description Handler for "Remove" product button in a Cart
         * @param {refElement|inputStepper} removeButton - Clicked "Remove" product button or qtyStepper if value was 0
         * @returns {void}
         */
        removeProduct(removeButton) {
            const InputStepper = /** @type {InputStepper} */ (this.getConstructor('InputStepper'));
            const url = removeButton.data('removeAction') || removeButton.data('action');
            const pid = removeButton.data('pid');
            const uuid = removeButton.data('uuid');
            const isEproduct = removeButton.data('isEproduct');
            const priceList = removeButton.data('priceList');
            const priceSales = removeButton.data('priceSales');
            const isSubscriptionProduct = removeButton.data('isSubscriptionProduct');
            const qtyStepperID = `quantityStepper-${uuid}`;
            let currentQuantityStepperValue = 1;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'removeProductLink' does not exist on typ... Remove this comment to see the full error message
            this.removeProductLink = removeButton;
            removeButton.addClass(this.prefs().classesDisabled);

            if (removeButton instanceof InputStepper) {
                currentQuantityStepperValue = parseInt(removeButton.getValue(), 10);
            } else {
                this.getById(qtyStepperID, (stepper) => {
                    currentQuantityStepperValue = parseInt(stepper.getValue(), 10);
                });
            }

            this.getById('confirmDialog', (/** @type {confirmDialog} */ confirmDialog) => {
                confirmDialog.showModal({
                    productName: removeButton.data('name'),
                    attributes: {
                        url: url,
                        pid: pid,
                        uuid: uuid,
                        isEProduct: isEproduct,
                        priceList: priceList,
                        priceSales: priceSales,
                        showCrossedPrice: (priceList && priceSales),
                        currentValue: currentQuantityStepperValue,
                        isSubscriptionProduct: isSubscriptionProduct
                    }
                });
            });
        }

        /**
         * @description Shows progress bar
         * @returns {void}
         */
        showProgressBar() {
            this.ref('cartContainer').attr('aria-busy', 'true');
        }

        /**
         * @description Hides progress bar
         * @returns {void}
         */
        hideProgressBar() {
            this.ref('cartContainer').attr('aria-busy', 'false');
        }

        /**
         * @description Handler for "Edit" product button in a Cart
         * @param {refElement} editBtn - Target "Edit" button
         * @emits "product.tile.qv.open.edit"
         * @returns {void}
         */
        editProduct(editBtn) {
            this.showProgressBar();
            const uuid = editBtn.data('uuid');

            getContentByUrl(editBtn.data('href'), { uuid })
                .then((response) => {
                    this.getById('editProductModal', (editProductModal) => editProductModal.showModal({
                        body: response,
                        attributes: {
                            'data-tau-unique': 'edit_product_dialog'
                        }
                    }));
                    this.eventBus().emit('product.tile.qv.open.edit', editBtn);
                })
                .catch(error => {
                    this.renderCartWithItemLevelActionError(uuid, error.message);
                })
                .finally(() => {
                    this.hideProgressBar();
                });
        }
    }

    return CartMixin;
}
