import { getContentByUrl } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/global/Modal').default>} Modal
 */

/**
 * @description just added to cart modal
 * @param {Modal} Modal Base widget for extending
 * @returns {typeof JustAddedToCartModal} JustAddedToCartModal widget
 */
export default function (Modal) {
    class JustAddedToCartModal extends Modal {
        prefs() {
            return {
                cartViewContentLink: '',
                showOnlyAddedProduct: true,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.queuedProcessingProduct = [];

            if (!window.contexts.includes('cart')) {
                this.initEvents();
            }
        }

        /**
         * @description inits widget events
         */
        initEvents() {
            this.eventBus().on('show.just.added.cart', 'showJustAddedProductCart');
            this.eventBus().on('hide.just.added.cart', 'onCloseModal');
        }

        /**
         * @description shows retrieved modal content
         *
         * @param {object} data - request data
         * @param {string} data.productID - cart line item identifier
         */
        showJustAddedProductCart(data) {
            const { productID } = data;
            const { subscriptionID } = data;
            const { pidsObj } = data;

            if (this.queuedProcessingProduct.includes(productID)) {
                this.queuedProcessingProduct.push(productID);
            } else {
                this.queuedProcessingProduct = [productID];
            }

            if (this.isOpened()) {
                if (!this.prefs().showOnlyAddedProduct) {
                    this.eventBus().emit('just.added.cart.update', data);
                }

                return;
            }

            getContentByUrl(this.prefs().cartViewContentLink, {
                pids: JSON.stringify([productID]),
                subscriptionID: subscriptionID,
                pidsObj: JSON.stringify(pidsObj)
            })
                .then((response) => {
                    const isQueuedProcessingProduct = this.queuedProcessingProduct.every((processingProductID) => {
                        return processingProductID === data.productID;
                    });

                    if (isQueuedProcessingProduct && this.queuedProcessingProduct.length === 1) {
                        this.showModal({ body: response });
                        this.queuedProcessingProduct.pop();
                    }

                    if (this.queuedProcessingProduct.length > 1) {
                        this.queuedProcessingProduct.pop();
                    }
                });
        }

        /**
         * @description Cancel Handler
         * @emits Modal#cancel
         */
        cancel() {
            super.cancel();
        }

        /**
         * @description handles on modal close event
         */
        onCloseModal() {
            this.closeModal();
        }

        /**
         * @description shows just added modal
         * @param {object} data - show modal data
         */
        showModal(data) {
            super.showModal(data);

            this.eventBus().emit('open.just.added.cart');
        }
    }

    return JustAddedToCartModal;
}
