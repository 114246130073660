/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */

const hasContext = (/** @type {string} */ctx) => window.contexts.includes(ctx);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ './widgets/checkout/SummaryStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ './widgets/checkout/PaymentAccordionItemPCIPal').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ './widgets/checkout/PcipalPopup').then(d => d.default)
    ]).then(deps => {
        const [
            SummaryStepMixin,
            PaymentAccordionItemPCIPal,
            PcipalPopup
        ] = deps;

        return {
            listId: 'checkout.pcipal',
            widgetsDefinition: () => [
                ['checkoutMgr', SummaryStepMixin, 'checkoutMgr'],
                ['paymentAccordionItemPCIPal', PaymentAccordionItemPCIPal, 'paymentAccordionItem'],
                ['pcipalPopup', PcipalPopup, 'modal']
            ]
        };
    })
});
