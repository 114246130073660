/**
 * @typedef {ReturnType<typeof import('widgets/forms/ajax/EmailSubscribe').default>} BaseEmailSubscribe
 */

/**
 * @description EmailSubscribe implementation
 * @param {BaseEmailSubscribe} BaseEmailSubscribe Base widget for extending
 * @returns {typeof EmailSubscribe} EmailSubscribe class
 */
export default function (BaseEmailSubscribe) {
    /**
     * @class EmailSubscribe
     * @augments BaseEmailSubscribe
     */
    class EmailSubscribe extends BaseEmailSubscribe {
        /**
         * @description Handles server response
         * @emits "alert.show"
         * @param {object} data Server JSON response once form submitted
         * @returns {void}
         */
        onSubmitted(data) {
            if (data.success) {
                this.ref('formContent').hide();

                if (data.isAlreadySubscribed) {
                    this.ref('subscribeText').setText('');
                }

                this.ref('successMessage')
                    .setText(data.msg)
                    .show();

                /**
                 * @description Global event to show alert
                 * @event "alert.show"
                 */
                this.eventBus().emit('alert.show', {
                    accessibilityAlert: this.prefs().accessibilityAnnouncementMsg
                });
            } else {
                if (data.fieldErrors) {
                    Object.entries(data.fieldErrors).forEach(([name, errorMsg]) => {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setError' does not exist on type 'Widget... Remove this comment to see the full error message
                        this.getById(name, formField => formField.setError(errorMsg));
                    });
                }

                if (data.msg) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setError' does not exist on type 'Widget... Remove this comment to see the full error message
                    this.getById(this.prefs().signupEmail, inputEmail => inputEmail.setError(data.msg));
                }
            }
        }
    }

    return EmailSubscribe;
}
