/**
 * @typedef {ReturnType<typeof import('widgets/search/SearchList').default>} SearchList
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @param {SearchList} SearchList Base widget for extending
 * @returns {typeof CategorySearchList} IdeasSearchList widget
 */
export default function (SearchList) {
    /**
     * @class IdeasSearchList
     * @augments SearchList
     */
    class CategorySearchList extends SearchList {
        /**
         * @description fires select search item event
         * @param {RefElement} searchItem - selected search item
         */
        selectSearchItemEvent(searchItem) {
            this.eventBus().emit('category.search.link.selected', searchItem);
        }
    }

    return CategorySearchList;
}
