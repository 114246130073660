// @ts-nocheck
/**
 * @typedef {ReturnType<typeof import('widgets/global/WishlistCarousel').default>} Carousel
 */

/**
 * @param {Carousel} Carousel Base widget for extending
 * @returns {typeof WishlistCarousel} WishlistCarousel class
 */

export default function (Carousel) {
    /**
     * @category widgets
     * @subcategory global
     * @class WishlistCarousel
     * @augments Carousel
     * @classdesc Represents WishlistCarousel component with next features:
     * @property {string} data-autoplay-infinite
 */

    class WishlistCarousel extends Carousel {
        /**
         * @description Widget initialization
        */
        init() {
            super.init();

            this.eventBus().on('product.removedFromWishlist', 'refreshCarousel');
        }

        refreshCarousel(itemsLength, pid) {
            this.ref('wishlistItem-' + pid).remove();
        }
    }

    return WishlistCarousel;
}
