import { pageProperties, report } from './utils/base';
import { init as initEvents } from './components/events';
import { reportProductImpressions, reportPromoImpressions } from './components/impressions';
import { getActiveViewtypeName } from 'widgets/toolbox/viewtype';
import { getJSONByUrl } from 'widgets/toolbox/ajax';

let pageType;

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */
/**
 * @description clientGTM
 * @module clientGTM
 * @category widgets
 * @subcategory gtm
 */

/**
 * @description Add full page and user info on page loading
 * @returns {void}
 */
function reportBasketLoadDetails() {
    const selector = document.getElementById('gtmBasketLoadDetails');

    if (selector) {
        const getBasketLoadDataUrl = selector.getAttribute('data-get-basket-load-data-url');

        if (getBasketLoadDataUrl) {
            getJSONByUrl(getBasketLoadDataUrl).then(response => {
                if (response && response.analyticsOnBasketLoadDetails) {
                    const basketLoadDetails = {
                        event: 'basketLoad',
                        basket: Object.assign({ context: 'page_load' }, response.analyticsOnBasketLoadDetails)
                    };

                    report(basketLoadDetails);
                }
            });
        }
    }
}

/**
 * @description Add subscription data on page loading
 * @returns {void}
 */
function reportSubscriptionDetails() {
    const selector = document.getElementById('gtmSubscriptionData');

    if (selector) {
        // @ts-expect-error Argument of type 'string | null' is not assignable to parameter of type 'string'.
        const subscriptionData = JSON.parse(selector.getAttribute('data-value'));
        const accountSubscriptionType = selector.getAttribute('data-type-account');
        const event :any = { event: 'data-layer-event', eventCategory: 'Key interactions' };

        if (accountSubscriptionType === 'true') {
            event.eventAction = 'Account Activation';
        } else {
            event.eventAction = 'Newsletter signup';
        }

        Object.assign(event, subscriptionData);

        report(event);
    }
}

/**
 * @description Add full page and user info on page loading
 * @returns {void}
 */
function reportPageLoadDetails() {
    const selector = document.getElementById('gtmPageLoadDetails');

    if (selector) {
        // @ts-expect-error Argument of type 'string | null' is not assignable to parameter of type 'string'.
        const onLoadPageDetails = JSON.parse(selector.getAttribute('data-value'));
        const viewTypesMap = {
            sm: 'small',
            md: 'medium',
            lg: 'large',
            xl: 'x-large'
        };
        const viewType = getActiveViewtypeName();

        onLoadPageDetails.event = 'pageLoad';
        onLoadPageDetails.designSize = viewTypesMap[viewType];
        pageType = onLoadPageDetails.pageType;

        report(onLoadPageDetails);
    }
}

/**
 * @description Init GTM callback
 * @returns {void}
 */
function init() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmUserData' does not exist on type 'Win... Remove this comment to see the full error message
    if (pageProperties.currentCurrency === '' && window.gtmUserData) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmUserData' does not exist on type 'Win... Remove this comment to see the full error message
        pageProperties.currentCurrency = window.gtmUserData.currencyCode;
    }

    initEvents();
    reportBasketLoadDetails();
    reportPageLoadDetails();
    reportSubscriptionDetails();
    reportProductImpressions();

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmDelayedEvent' does not exist on type ... Remove this comment to see the full error message
    if (window.gtmDelayedEvent) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmDelayedEvent' does not exist on type ... Remove this comment to see the full error message
        report(window.gtmDelayedEvent);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmDelayedEvent' does not exist on type ... Remove this comment to see the full error message
        delete window.gtmDelayedEvent;
    }

    reportPromoImpressions();
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
if (window.dataLayer) {
    if (document.readyState === 'loading') {
        /**
         * @description Init gtm once dom content loaded
         * @listens dom:DOMContentLoaded
         */
        document.addEventListener('DOMContentLoaded', () => {
            setTimeout(() => {
                init();
            }, 0);
        }, { once: true });
    } else {
        init();
    }
}
