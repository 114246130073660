function CarouselSimpleClassCreator(Carousel: ReturnType<typeof import('widgets/global/Carousel').default >) {
    /**
     * @category widgets
     * @subcategory plugin_page_designer_ext
     * @class CarouselSimple
     * @augments Widget
     * @classdesc Represents Carousel component with next features:
     * 1. Allow to set carousel direction based on the view type
     * 2. Allow to use pagination for carousel rendered by mustache template
     * 3. Allow to scroll carousel to the next/previous/custom( index can be passed to the method) page index
     * 4. Allow to scroll by on the page click(can be used for carousel with thumbnails)
     * 5. Allow to scroll to custom element's position
     * 6. Allow to scroll to focused element
     * 7. Support mousemove, touchmove, mouseup, mousedown, keydown event so we can use carousel even on touch devices.
     * Also we can control carouse with keyboard
     * 8. Allow to get carousel images
     *
     * <br>Uses as a basis slider from here (ScrollCarousel.js):
     * <br>https://github.com/dimanech/aria-components/tree/master/cartridge1/js/components/carousels/carousel
     * @property {string} data-widget - Widget name `carousel`
     * @property {string} data-elem-prev-button - Previous button element
     * @property {string} data-elem-next-button - Next button element
     * @property {string} data-elem-carousel-track - Carousel inner element
     * @property {string} data-direction - Carousel direction - an object, contains direction per viewport
     * @example <caption>Example of Carousel widget usage</caption>
     * <div
     *     data-widget="carouselSimple"
     *     data-elem-prev-button="elemPrevButton"
     *     data-elem-next-button="elemNextButton"
     *     data-elem-carousel-track="elemCarouselTrack"
     *     data-direction='{
     *         "small": "horizontal",
     *         "medium": "horizontal",
     *         "large": "vertical",
     *         "extraLarge": "vertical"
     *     }'
     * >
     *     <button
     *         class="carousel__ctrl _prev"
     *         data-elem-prev-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemPrevButton"
     *         data-event-click="scrollToPrevPageSimple"
     *     >Prev</button>
     *     <div
     *         class="carousel__track"
     *         data-elem-carousel-track
     *         data-ref="elemCarouselTrack"
     *         data-event-scroll="onScroll"
     *         data-event-touchstart="onScroll"
     *         data-event-mousedown.prevent="onMouseDown"
     *         data-event-mouseup="onMouseUp"
     *     >
     *         <isloop items="${slotcontent.content}" var="contentAsset">
     *             <div class="box _single" tabindex="0">
     *                 <isprint value="${contentAsset.custom.body}" encoding="off" />
     *             </div>
     *         </isloop>
     *     </div>
     *     <button
     *         class="carousel__ctrl _next"
     *         data-elem-next-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemNextButton"
     *         data-event-click="scrollToNextPageSimple"
     *     >Next</button>
     *     <div class="pagination" data-ref="pagination"></div>
     *     <script type="template/mustache" data-ref="template">
     *         <div class="pagination" data-ref="pagination">
     *             {{${'#'}pagination}}
     *             <button
     *                 class="page"
     *                 data-page="{{page}}"
     *                 tabindex="-1"
     *                 data-event-click.prevent="handlePaginationClick"
     *             >
     *             </button>
     *             {{/pagination}}
     *         </div>
     *     </script>
     * </div>
     */
    class CarouselSimple extends Carousel {
        defaultLeft!: number;

        initCarousel() {
            super.initCarousel();

            this.defaultLeft = parseInt(getComputedStyle(this.ref('pagination').get() as Element).getPropertyValue('left'), 10);
        }

        /**
         * @description Collects carousel data and emits event
         * TODO: It should not be hard coded to product carousel name.
         * @param actionType Type of slide action
         * @param targetPageIndex Target slide index
         */
        onCarouselClick(actionType, targetPageIndex) {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get() as Element;
            const elemSlide = elemCarouselTrack.children[targetPageIndex];

            const productTileName = (elemSlide?.querySelector('[data-product-name]') as HTMLElement)?.dataset?.productName;
            const textBlockValue = elemSlide?.querySelectorAll('h1, h2, h3')[0]?.textContent;

            this.eventBus().emit('simpleCarousel.click', actionType, productTileName || textBlockValue);
        }

        /**
         * @description Scroll carousel to the next page
         * @param _el Source of Keydown event
         * @param event event instance if DOM event
         * @listens dom#click
         */
        scrollToNextPageSimple(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            const pageIndex = this.getCurrentPageIndex() + 1;

            super.scrollToNextPage();
            this.onCarouselClick('next_slide', pageIndex);
        }

        /**
         * @description Scroll carousel to the previous page
         * @param _el Source of Keydown event
         * @param event event instance if DOM event
         * @listens dom#click
         */
        scrollToPrevPageSimple(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            const pageIndex = this.getCurrentPageIndex() - 1;

            super.scrollToPrevPage();
            this.onCarouselClick('prev_slide', pageIndex);
        }

        /**
         * @description Scroll carousel to the page index
         * @param pageIndex Page index scroll to
         */
        scrollToPage(pageIndex) {
            super.scrollToPage(pageIndex);
            this.onCarouselClick('pagination_click', pageIndex);

            return this;
        }

        /**
         * @description Added next and prev slides and detect position of first pagination dot
         */
        setActivePagination() {
            super.setActivePagination();

            const pageIndex = super.getCurrentPageIndex();
            const paginationContent = this.ref('pagination').get() as HTMLElement;

            [].slice.call((paginationContent?.children)).forEach((element: Element) => {
                const dataPage = parseInt(((element && element.getAttribute('data-page')) || '0'), 10);

                // @ts-ignore Expected Error
                element.classList.toggle(this.prefs().pageCurrentClass, dataPage === pageIndex);
            });

            paginationContent.style.left = (1 - pageIndex) * this.defaultLeft + 'px';

            this.setPageIndex();
        }

        /**
         * @description set current slide index in carousel
         */
        setPageIndex() {
            // @ts-ignore Expected Error
            this.ref('setPageIndex').setHtml((super.getCurrentPageIndex() + 1).toString());
        }
    }

    return CarouselSimple;
}

export type TCarouselSimple = ReturnType<typeof CarouselSimpleClassCreator>;

export type TCarouselSimpleInstance = InstanceType<TCarouselSimple>;

export default CarouselSimpleClassCreator;
