import { timeout } from '../toolbox/util';

const keyCode = Object.freeze({
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    UP: 38,
    DOWN: 40
});
/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @param BasicInput Base widget for extending
 * @returns InputStepper class
 */
export default function (BasicInput: ReturnType<typeof import('widgets/forms/BasicInput').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputStepper
     * @augments BasicInput
     * @classdesc Widget used as a quantity selector for product with +/- buttons functionality.
     * - Uses `minValue` and `maxValue` properties to restrict allowed input range.
     * - Handles keyboard input, allows step +/- configuration.
     * - Uses mustache template for dynamic rendering.
     * @property {boolean} data-skip-validation - if input needs to skip validation
     * @property {number} [data-update-delay] - delay to emit change event
     * @property {string} data-attr-url - url for update product quantity
     * @property {string} data-line-item-min-order-quantity - LineItem min order quantity.
     * Used on cart/minicart to call remove product from cart method and set correct value after cancel remove.
     * @example <caption>Example HTML structure for InputStepper</caption>
     * <div
     *      data-widget="inputStepper"
     *      data-attr-url="${product.quantities.actionUrl}"
     *      data-widget-event-change="changeAttribute"
     *      data-widget-event-change="changeAttribute"
     *      data-skip-validation="true"
     *      data-id="quantity-${selectId}"
     * >
     *      <label
     *          class="b-sr_only"
     *          for="quantity-${selectId}"
     *      >
     *          ${Resource.msg('label.quantity', 'common', null)}
     *      </label>
     *      <div class="b-stepper">
     *          <div
     *              class="b-stepper-button"
     *              role="button"
     *              tabindex="-1"
     *              data-ref="buttonDecrease"
     *              data-event-click="decrement"
     *          >
     *              <isinclude template="common/icons/standalone/minus"/>
     *          </div>
     *          <input
     *              id="quantity-${selectId}"
     *              class="b-stepper-input"
     *              aria-describedby="quantity-${selectId}-error"
     *              aria-valuemax="${product.quantities.max}"
     *              aria-valuemin="${product.quantities.min}"
     *              data-step="${product.quantities.step}"
     *              aria-valuenow="${product.quantities.currentValue}"
     *              value="${product.quantities.currentValue}"
     *              name="qty-${selectId}"
     *              required="required"
     *              type="number"
     *              data-ref="field"
     *              data-tau="product_quantity"
     *              data-event-input="handleInput"
     *              data-event-change="handleChange"
     *              data-event-blur="handleBlur"
     *              ${product.availability && product.availability.isOutOfStock ? 'disabled' : ''}
     *          />
     *          <div
     *                  class="b-stepper-button"
     *                  role="button"
     *                  tabindex="-1"
     *                  data-ref="buttonIncrease"
     *                  data-event-click="increment"
     *          >
     *              <isinclude template="common/icons/standalone/plus"/>
     *          </div>
     *      </div>
     *      <div
     *          role="alert"
     *          class="b-variations_item-error"
     *          id="quantity-${selectId}-error"
     *          data-ref="errorFeedback"
     *          hidden="hidden"
     *      ></div>
     *      <script type="template/mustashe" data-ref="template">
     *          .... dynamic content
     *      </script>
     */
    class InputStepper extends BasicInput {
        prefs() {
            return {
                updateDelay: 500,
                attrUrl: '',
                ...super.prefs()
            };
        }

        /**
         * @description Setup init options for InputStepper
         * @returns {void}
         */
        initOptions() {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'minValue' does not exist on type 'InputS... Remove this comment to see the full error message
            this.minValue = this.getMinValue();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxValue' does not exist on type 'InputS... Remove this comment to see the full error message
            this.maxValue = this.getMaxValue();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'incrementStep' does not exist on type 'I... Remove this comment to see the full error message
            this.incrementStep = parseInt(this.ref('field').data('step').toString(), 10) || 1;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
            this.currentValue = parseInt(this.getValue(), 10);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isBusy' does not exist on type 'InputSte... Remove this comment to see the full error message
            this.isBusy = false;
        }

        /**
         * @description Initial widget logic
         * @returns {void}
         */
        init() {
            super.init();

            this.initOptions();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
            this.setInputValue(this.currentValue);
            this.updateState();
        }

        /**
         * @description Updates widget options and state
         * @returns {void}
         */
        update() {
            this.initOptions();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
            this.setInputValue(this.filterInput(this.currentValue), false);
            this.updateState();
        }

        /**
         * @description Get input value for ajax call. Need for PDP consistency only.
         * Should not be in generic component
         * @returns {this} current instance
         */
        getSelectedOptions() {
            return this;
        }

        /**
         * @description Handle keyboard events on input
         * @param {refElement} _ element
         * @param {KeyboardEvent} event Event
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;
            const multiplier = 5;

            // For TS lint only

            // @ts-expect-error ts-migrate(2551) FIXME: Property 'minValue' does not exist on type 'InputS... Remove this comment to see the full error message
            if (this.minValue === undefined || this.maxValue === undefined) { return; }

            switch (event.keyCode) {
                case keyCode.UP:
                    this.increment();
                    preventEventActions = true;
                    break;
                case keyCode.DOWN:
                    this.decrement();
                    preventEventActions = true;
                    break;
                case keyCode.PAGEUP:

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
                    this.setInputValue(this.filterInput(this.currentValue + multiplier));
                    preventEventActions = true;
                    break;
                case keyCode.PAGEDOWN:

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
                    this.setInputValue(this.filterInput(this.currentValue - multiplier));
                    preventEventActions = true;
                    break;
                case keyCode.HOME:

                    // @ts-expect-error ts-migrate(2551) FIXME: Property 'minValue' does not exist on type 'InputS... Remove this comment to see the full error message
                    this.setInputValue(this.minValue);
                    preventEventActions = true;
                    break;
                case keyCode.END:

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxValue' does not exist on type 'InputS... Remove this comment to see the full error message
                    this.setInputValue(this.maxValue);
                    preventEventActions = true;
                    break;
                default:
                    break;
            }

            if (preventEventActions) {
                event.stopPropagation();
                event.preventDefault();
            }
        }

        /**
         * @description Handle input event - filter input and pass to value and aria-now
         * @returns {void}
         */
        handleInput() {
            const value = this.getValue();
            const onlyDigitsValue = value.replace(/\D/g, '');

            // remove submitting timeout even if input value will be invalid and never trigger update

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submittingTimeout' does not exist on typ... Remove this comment to see the full error message
            if (this.submittingTimeout) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'submittingTimeout' does not exist on typ... Remove this comment to see the full error message
                this.submittingTimeout();
            }

            if (onlyDigitsValue !== value && onlyDigitsValue === '') {
                this.ref('field').val('');
            } else {
                this.setInputValue(this.filterInput(onlyDigitsValue));
            }
        }

        /**
         * @description Handle input change event
         * @returns {void}
         */
        handleChange() {
            this.updateState();
        }

        /**
         * @description Handle input blur event
         * @returns {void}
         */
        handleBlur() {
            if (!Number.isNaN(this.getValue())) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
                this.setInputValue(this.currentValue);
            }
        }

        /**
         * @description Increment button handler
         * @returns {void}
         */
        increment() {
            const value = parseInt(this.getValue(), 10);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'incrementStep' does not exist on type 'I... Remove this comment to see the full error message
            this.setInputValue(this.filterInput(value + this.incrementStep));
        }

        /**
         * @description Decrement button handler
         * @returns {void}
         */
        decrement() {
            const value = parseInt(this.getValue(), 10);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'incrementStep' does not exist on type 'I... Remove this comment to see the full error message
            this.setInputValue(this.filterInput(value - this.incrementStep));
        }

        /**
         * @description Filter input and set max, min, middle, current value in case of overflow, underflow,
         * corrupted input
         * @param {string|number} value value of input
         * @returns {number} filtered and processed input value
         */
        filterInput(value) {
            if (value === '' || value === '-') {
                return undefined;
            }

            const inputValue = (typeof value === 'string') ? parseInt(value, 10) : value;

            if (typeof inputValue !== 'number' || Number.isNaN(inputValue)) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
                return this.currentValue;
            }

            let result = inputValue;

            // @ts-expect-error ts-migrate(2551) FIXME: Property 'minValue' does not exist on type 'InputS... Remove this comment to see the full error message
            if (inputValue < this.minValue) {
                // @ts-expect-error ts-migrate(2551) FIXME: Property 'minValue' does not exist on type 'InputS... Remove this comment to see the full error message
                result = this.minValue;
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxValue' does not exist on type 'InputS... Remove this comment to see the full error message
            } else if (inputValue > this.maxValue) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxValue' does not exist on type 'InputS... Remove this comment to see the full error message
                result = this.maxValue;
            }

            // @ts-expect-error ts-migrate(2551) FIXME: Property 'minValue' does not exist on type 'InputS... Remove this comment to see the full error message
            if (!Number.isFinite(this.minValue)) {
                result = inputValue;
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxValue' does not exist on type 'InputS... Remove this comment to see the full error message
            } else if (!Number.isFinite(this.maxValue)) {
                result = inputValue;
            }

            return result;
        }

        /**
         * @description Set filtered or raw value to input value prop and attribute and aria-valuenow
         * @param {number} value value of input
         * @param {boolean} isUpdateRequired - flag to determine if change update is needed
         * @returns {void}
         */
        setInputValue(value, isUpdateRequired = true) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isBusy' does not exist on type 'InputSte... Remove this comment to see the full error message
            if (this.isBusy || Number.isNaN(value) || value === undefined) {
                return;
            }

            // We should always set values since it work like filter and override any incorrect input
            this.ref('field').val(value.toString());
            this.ref('field').attr('value', value.toString());
            this.ref('field').attr('aria-valuenow', value.toString());

            // Dispatch change only if value is actually changed

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
            if (this.currentValue === value) {
                return;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
            this.currentValue = value;

            this.updateState();
            this.updateActionUrl();

            if (isUpdateRequired) {
                this.dispatchChange();
            }
        }

        /**
         * @description Dispatch change event
         * @returns {void}
         */
        dispatchChange() {
            this.emit('dismiss', this);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submittingTimeout' does not exist on typ... Remove this comment to see the full error message
            if (this.submittingTimeout) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'submittingTimeout' does not exist on typ... Remove this comment to see the full error message
                this.submittingTimeout();
            }

            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
            const delay = parseInt(this.prefs().updateDelay, 10);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'submittingTimeout' does not exist on typ... Remove this comment to see the full error message
            this.submittingTimeout = timeout(() => this.emit('change', this), delay);
        }

        /**
         * @description Update product url with current quantity
         * @returns {void}
         */
        updateActionUrl() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
            const url = this.prefs().attrUrl.replace(/quantity=\d*/g, 'quantity=' + this.currentValue);

            this.setPref('attrUrl', url);
        }

        /**
         * @description Update all buttons state depending of input value and state
         * @returns {void}
         */
        updateState() {
            const input = this.ref('field').get();

            if ((input && input.getAttribute('disabled')) !== null) {
                this.toggleButtonsState(this.ref('buttonIncrease'), true);
                this.toggleButtonsState(this.ref('buttonDecrease'), true);

                return;
            }

            this.toggleButtonsState(
                this.ref('buttonDecrease'),

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
                (this.currentValue <= this.minValue && Number.isFinite(this.minValue))
            );

            this.toggleButtonsState(
                this.ref('buttonIncrease'),

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentValue' does not exist on type 'In... Remove this comment to see the full error message
                (this.currentValue >= this.maxValue && Number.isFinite(this.maxValue))
            );
        }

        /**
         * @description Freeze component in case of async calls so user would not
         * be able to change value during call
         * @param {boolean} isBusy is input in busy state
         * @returns {void}
         */
        toggleBusy(isBusy) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isBusy' does not exist on type 'InputSte... Remove this comment to see the full error message
            this.isBusy = isBusy;
            this.ref('field').attr('readonly', isBusy ? 'readonly' : null);
            this.ref('self').attr('aria-busy', isBusy.toString());
            this.toggleButtonsState(this.ref('buttonIncrease'), isBusy);
            this.toggleButtonsState(this.ref('buttonDecrease'), isBusy);
        }

        /**
         * @description Update single button state
         * @param {refElement} button button control
         * @param {boolean} isDisabled is button disabled
         * @returns {void}
         */
        toggleButtonsState(button, isDisabled) {
            if (isDisabled) {
                button.attr('disabled', 'disabled');
            } else {
                button.attr('disabled', null);
            }
        }

        /**
         * @description Prepare min value from attributes
         * @returns {number} min value
         */
        getMinValue() {
            const min = this.ref('field').attr('aria-valuemin').toString();
            const minParsed = parseInt(min, 10);

            return (min && !Number.isNaN(minParsed)) ? minParsed : Infinity;
        }

        /**
         * @description Prepare max value from attributes
         * @returns {number} max value
         */
        getMaxValue() {
            const max = this.ref('field').attr('aria-valuemax').toString();
            const maxParsed = parseInt(max, 10);

            return (max && !Number.isNaN(maxParsed)) ? maxParsed : Infinity;
        }
    }

    return InputStepper;
}
