/**
 * @typedef {ReturnType<typeof import('widgets/global/Disclosure').default>} Disclosure
 */

/**
 * @description Base Social Disclosure implementation
 * @param {Disclosure} Disclosure Base widget for extending
 * @returns {typeof ProductSocialDisclosure} RecentlyViewedMixin
 */
export default function (Disclosure) {
    /**
     * @class ProductSocialDisclosure
     * @augments Disclosure
     */
    class ProductSocialDisclosure extends Disclosure {
    }

    return ProductSocialDisclosure;
}
