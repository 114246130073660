/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof SearchList} IdeasSearchList widget
 */
export default function (Widget) {
    /**
     * @class IdeasSearchList
     * @augments Widget
     */
    class SearchList extends Widget {
        /**
         * @description global search item event
         * @param {RefElement} element - selected search item
         */
        // eslint-disable-next-line no-unused-vars
        selectSearchItemEvent(element) {
        }

        /**
         * @description handles selection of search item
         * @param {RefElement} element - selected search item
         */
        selectSearchItem(element) {
            this.selectSearchItemEvent(element);

            window.location.assign(element.attr('href').toString());
        }
    }

    return SearchList;
}
