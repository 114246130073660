import EventBus from 'widgets/toolbox/eventBus';
import { TWidget } from 'widgets/Widget';

/**
 * @category widgets
 * @subcategory toolbox
 * @class EventBusWrapper
 * @classdesc Represents EventBusWrapper component with next features:
 * 1. Allow subscribing action on an event (subscribe once allow as well)
 * 2. Allow emitting event
 * @example EventBusWrapper imported in Widget.js file and you can use EventBusWrapper in your own widget as `this.eventBus()`
 * <caption>Example of EventBusWrapper widget usage for emitting a global event</caption>
 *
 * this.eventBus().emit('rendering.applied');
 *
 * <caption>Example of EventBusWrapper widget usage for subscribe action on a global event</caption>
 *
 * this.eventBus().on('minicart.updated', 'renderCart');
 */
class EventBusWrapper {
    widgetInstance: InstanceType<TWidget>;

    /**
     * @description Define widget instance
     * @param widgetInstance usually this of widget
     */
    constructor(widgetInstance: InstanceType<TWidget>) {
        this.widgetInstance = widgetInstance;
    }

    /**
     * @description Subscribe action on event
     * @param eventName name of the event
     * @param methodName name of the method to call if/when event happens
     */
    on(eventName: string, methodName: string): void {
        let fn: (...args: any[]) => void = (...args): void => {
            this.widgetInstance[methodName].call(this.widgetInstance, ...args);
        };

        EventBus.addListener(eventName, fn);
        this.widgetInstance.onDestroy(() => {
            if (fn) {
                EventBus.removeListener(eventName, fn);
                // @ts-ignore for clean up variable
                fn = undefined;
            }
        });
    }

    /**
     * @description Subscribe action once on event
     * @param eventName name of event
     * @param methodName name of method to call if/when event happen
     */
    once(eventName: string, methodName: string) {
        let onceFn = (...args) => {
            EventBus.removeListener(eventName, onceFn);
            this.widgetInstance[methodName].call(this.widgetInstance, ...args);
            // @ts-ignore for clean up variable
            onceFn = undefined;
        };

        EventBus.addListener(eventName, onceFn);
        this.widgetInstance.onDestroy(() => {
            if (onceFn) {
                EventBus.removeListener(eventName, onceFn);
                // @ts-ignore for clean up variable
                onceFn = undefined;
            }
        });
    }

    /**
     * @description Emit event
     * @param event - event name
     * @param  args Arguments passed to the event emitter
     * @returns {void}
     */
    emit(event: any, ...args: any[]): void {
        EventBus.emit(event, ...args);
    }
}
export default EventBusWrapper;
