/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 * @typedef {InstanceType<ReturnType<typeof import('core/forms/BasicInput').default>>} basicInput
 */

/**
 * @description AccountDeleteForm implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof AccountDeleteForm} ProfileForm class
 */
export default function (Widget) {
    /**
     * @class AccountDeleteForm
     * @augments Widget
     */
    class AccountDeleteForm extends Widget {
        init() {
            super.init();
        }

        /**
         * @description Unsubscribe customer
         * @param {refElement} checkbox - checkbox
         * @returns {void}
         */
        deletionConfirmationCheckbox(checkbox) {
            if (checkbox.prop('checked')) {
                this.getById('submitButton', el => el.enable());
            } else {
                this.getById('submitButton', el => el.disable());
            }
        }
    }

    return AccountDeleteForm;
}
