/**
 * @typedef {ReturnType<typeof import('widgets/global/Button').default>} BaseButton
 */

/**
 * @description Extend Basic Button implementation
 * @param {BaseButton} BaseButton Base Button implementation
 * @returns {Class} Button Form class
 */
export default function (BaseButton) {
    /**
     * @class Button
     * @augments BaseButton
     * @classdesc Custom input implementation.
     */
    class Button extends BaseButton {
        /**
         * @description Returns Widget configuration object
         * @returns {object} Widget configuration object
         */
        prefs() {
            return {
                classesDisabled: 'm-disabled',
                ...super.prefs()
            };
        }

        /**
         * @description Method to mark element as `active`
         * @returns {Button} result
         */
        activate() {
            this.ref('self').addClass(this.prefs().classesActive);
            this.ref('self').removeClass(this.prefs().classesDisabled);

            return this;
        }

        /**
         * @description Method to mark element as `inactive`
         * @returns {Button} result
         */
        deactivate() {
            this.ref('self').removeClass(this.prefs().classesActive);
            this.ref('self').addClass(this.prefs().classesDisabled);

            return this;
        }

        /**
         * @param {(boolean|undefined)} state true to show/false to hide/undefined to auto
         */
        toggleActive(state) {
            this.ref('self').toggleClass(this.prefs().classesActive, state);
            this.ref('self').toggleClass(this.prefs().classesDisabled, !state);
        }

        /**
         * @description Check if a button has activated state
         * @returns {boolean} result
         */
        isActive() {
            return this.ref('self').hasClass(this.prefs().classesActive) || !this.ref('self').hasClass(this.prefs().classesDisabled);
        }
    }

    return Button;
}
