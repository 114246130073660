import { RefElement } from 'widgets/toolbox/RefElement';
import { getViewType } from 'widgets/toolbox/viewtype';
import { TWidget } from 'widgets/Widget';

const keyCode = Object.freeze({
    ENTER: 13
});

function VideoExtClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory plugin_page_designer_ext
     * @class VideoExt
     * @augments Widget
     * @classdesc Represents VideoExt component with next features:
     * 1. Change url source on viewport resize
     * 2. Allow play or pause the video by clicking on it when native controls are hidden (only for HTML5 video tag)
     *
     * <div class="b-video_banner m-vertical_${pdict.verticalAlignment}" data-widget="videoExt" data-player-type="${pdict.playerType}">
     *      <div class="b-video_banner-container">
     *         <isprint value="${
     *              pdict.regions.videoBannerText
     *              .setClassName('b-video_banner-text_block')
     *              .render()}" encoding="off"
     *          />
     *          <figure class="b-video_banner-poster ${pdict.posterVisibility}" data-event-click="onPlay" data-ref="poster">
     *              <isprint value="${pdict.image.setClassName('b-image_tile-picture').render()}" encoding="off" />
     *              <figcaption>
     *                  <span class="b-video_banner-title"><isprint value="${pdict.posterTitle}"/></span>
     *                  <button
     *                     type="button"
     *                     aria-label="${Resource.msg('video.play.button','pagedesigner',null)}"
     *                     class="b-video_banner-ctrl"
     *                  >
     *                  </button>
     *              </figcaption>
     *          </figure>
     *          <iframe
     *              class="b-video_banner-item"
     *              data-ref="elemSource"
     *              data-id-video="${pdict.idVideo}"
     *              data-id-video-mobile="${pdict.idVideoMobile}"
     *              data-url="${pdict.url}"
     *              data-mobile-url="${pdict.urlMobile}"
     *              src="${pdict.url}"
     *              frameborder="0"
     *              allow="${pdict.extraAttributes}"
     *              allowfullscreen
     *              aria-hidden="${Boolean(pdict.image)}"
     *          >
     *          </iframe>
     *      </div>
     * </div>
     *
     * or
     *
     * <div class="b-video_banner m-vertical_${pdict.verticalAlignment}" data-widget="videoExt" data-player-type="${pdict.playerType}">
     *      <figure class="b-video_banner-poster ${pdict.posterVisibility}" data-event-click="onPlay" data-ref="poster">
     *           <isprint value="${pdict.image.setClassName('b-image_tile-picture').render()}" encoding="off" />
     *           <figcaption>
     *               <span class="b-video_banner-title"><isprint value="${pdict.posterTitle}"/></span>
     *               <button
     *                  type="button"
     *                  aria-label="${Resource.msg('video.play.button','pagedesigner',null)}"
     *                  class="b-video_banner-ctrl"
     *              >
     *              </button>
     *           </figcaption>
     *      </figure>
     *      <video
     *          class="b-video_banner-item m-external"
     *          data-ref="elemVideo"
     *          ${pdict.extraAttributes}
     *          aria-hidden="${Boolean(pdict.image)}"
     *      >
     *              <source
     *                  data-ref="elemSource"
     *                  data-url="${pdict.url}"
     *                  data-mobile-url="${pdict.urlMobile}"
     *                  src="${pdict.url}"
     *                  type="video/mp4"
     *              />
     *      </video>
     * </div>
     */

    class VideoExt extends Widget {
        // @ts-ignore Expected Error
        playerType: RefElement;

        poster!: RefElement;

        loaded = false;

        elemVideo;

        elemSource!: RefElement;

        // @ts-ignore Expected Error
        url: RefElement;

        // @ts-ignore Expected Error
        mobileUrl: RefElement;

        // @ts-ignore Expected Error
        iframeId: RefElement;

        prefs() {
            return {
                playingCSSClass: 'm-playing',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            // @ts-ignore Expected Error
            this.playerType = this.ref('self').data('player-type');
            this.poster = this.ref('poster');
            this.elemVideo = this.ref('elemVideo');
            this.elemSource = this.ref('elemSource');
            // @ts-ignore Expected Error
            this.url = this.elemSource.data('url');
            // @ts-ignore Expected Error
            this.mobileUrl = this.elemSource.data('mobileUrl');
            // @ts-ignore Expected Error
            this.iframeId = this.elemSource.data('id-video');

            this.bindObserver();
            this.eventBus().on('viewtype.change', 'onViewtypeChange');
            window.addEventListener('orientationchange', this.onViewtypeChange.bind(this));
            this.ref('self').addClass('m-inited');

            window.addEventListener('load', this.contentBoxWidth.bind(this));
            window.addEventListener('orientationchange', () => {
                setTimeout(this.contentBoxWidth.bind(this), 100);
            });
            this.eventBus().on('viewtype.change', 'contentBoxWidth');
        }

        /**
         * @description sets and loads video if it is in the intersection
         */
        bindObserver() {
            const element = this.ref('self').get();

            if (!element) {
                return;
            }

            const animatedObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const isAutoplay = String(this.url).indexOf('autoplay=1') >= 0 || this.elemVideo?.hasAttr('autoplay');

                    if (entry.isIntersecting && (!this.poster.length || isAutoplay) && !this.loaded) {
                        this.setVideoSource();
                        this.hidePoster();
                    }

                    if (!entry.isIntersecting) {
                        this.pauseYtVideo();
                    }
                });
            }, {
                rootMargin: '0px 0px -25%'
            });

            animatedObserver.observe(element);
        }

        /**
         * @description sets video source and additional attributes
         */
        setVideoSource() {
            if (getViewType() === 'small' && window.orientation === 0 && this.mobileUrl) {
                this.elemSource.attr('src', this.mobileUrl);
                // @ts-ignore Expected Error
                this.iframeId = this.elemSource.data('id-video-mobile');
            } else {
                this.elemSource.attr('src', this.url);
            }

            // @ts-ignore Expected Error
            if (this.playerType === 'url') {
                this.elemSource.attr('src', this.elemSource.attr('src') + '#t=0.001'); // Fix IOS not showing video preview
            } else {
                this.elemSource.attr('src', this.elemSource.attr('src') + '&enablejsapi=1');
                this.loaded = true;
            }

            this.loadVideo();
        }

        /**
         * @description Executed when widget is re-rendered
         */
        onRefresh() {
            super.onRefresh();
            this.setVideoSource();
            this.ref('self').addClass('m-inited');
        }

        /**
         * @description Reload video when src is changed
         */
        loadVideo() {
            if (this.elemVideo && this.elemVideo.get()) {
                this.elemVideo.get()?.load();
            }
        }

        /**
         * @description Hide poster on click and show video
         */
        hidePoster() {
            this.poster.hide();

            // @ts-ignore Expected Error
            if (this.playerType === 'url') {
                this.elemVideo.attr('aria-hidden', 'false');
            } else {
                this.elemSource.attr('aria-hidden', 'false');
            }
        }

        /**
         * @description Change video src on viewport change
         */
        onViewtypeChange() {
            const isMobileUrl = getViewType() === 'small' && window.orientation === 0 && this.mobileUrl;

            if (this.elemSource) {
                this.elemSource.attr('src', isMobileUrl ? this.mobileUrl : this.url);
            }

            this.loadVideo();
        }

        /**
         * @description Click over poster
         */
        onPlay() {
            let videoName;

            this.setVideoSource();

            // @ts-ignore Expected Error
            if (this.playerType !== 'url') {
                // @ts-ignore Expected Error
                this.elemSource.attr('src', this.elemSource.attr('src').replace('autoplay=0', 'autoplay=1'));

                videoName = this.iframeId;
                this.ref('play').hide();
            } else {
                const videoUrl = this.elemVideo.get() as HTMLVideoElement;

                videoUrl?.play();

                videoName = this.elemSource.attr('src');
            }

            this.eventBus().emit('videoPoster.click', this.playerType, videoName);
            this.hidePoster();
        }

        /**
         * @param _el event source element
         * @param event event instance if DOM event
         */
        handleKeydown(_el, event) {
            if (!event) {
                return;
            }

            if (event.keyCode === keyCode.ENTER) {
                event.preventDefault();
                event.stopPropagation();
                this.onPlay();
            }
        }

        /**
         * @description Start video
         */
        playPauseVideo() {
            const player = this.elemVideo.get();

            if (!player) {
                return;
            }

            if (player.paused || player.ended) {
                player.play();
                this.ref('playButton').addClass(this.prefs().playingCSSClass);
            } else {
                player.pause();
                this.ref('playButton').removeClass(this.prefs().playingCSSClass);
            }
        }

        contentBoxWidth() {
            const items = this.ref('self').get()?.querySelectorAll('.js-heading, .js-subheading');

            items?.forEach((item) => {
                if (item.parentElement) {
                    item.parentElement.style.width = '';
                    item.parentElement.style.width = Math.ceil(item.getBoundingClientRect().width) + 'px';
                }
            });
        }

        pauseYtVideo() {
            const player = this.elemSource.get() as HTMLIFrameElement;

            if (player) {
                player.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            }
        }
    }

    return VideoExt;
}

export type TVideoExt = ReturnType<typeof VideoExtClassCreator>;

export type TVideoExtInstance = InstanceType<TVideoExt>;

export default VideoExtClassCreator;
