/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof GlobalECountrySelector} GlobalECountrySelector
 */

export default function (Widget) {
    class GlobalECountrySelector extends Widget {
        prefs() {
            return {
                rootMargin: '500px',
                classesInit: 'm-init',
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.observe();
            import(/* webpackChunkName: 'gle-script-loader' */ '../../legacy/globaleScriptLoader')
                .then(() => this.initGlobalECountrySelector());
        }

        /**
         * @description Initialise Global-E country switcher
         * @returns {void}
         */
        initGlobalECountrySelector() {
            import(/* webpackChunkName: 'gle-country-selector' */ '../../legacy/globaleCountrySwitcher');
        }

        observe() {
            let observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    this.ref('self').addClass(this.prefs().classesInit);
                    observer.disconnect();
                }
            }, {
                threshold: 1,
                rootMargin: this.prefs().rootMargin
            });

            observer.observe(this.ref('self').get());

            this.onDestroy(() => {
                observer.disconnect();
            });
        }
    }

    return GlobalECountrySelector;
}
