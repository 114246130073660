import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns StickyElement widget
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class StickyElement
     * @augments Widget
     * @classdesc StickyElement helper.
     */
    class StickyElement extends Widget {
        prefs() {
            return {
                bodyClass: '',
                toggleCondition: 'intersectsWithScreen',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            const stickyElement = this.ref('self').get();

            if (!stickyElement) {
                return;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'Sticky... Remove this comment to see the full error message
            this.observer = new IntersectionObserver(([entry]) => this.toggleStickyState(entry), { threshold: 1 });

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'Sticky... Remove this comment to see the full error message
            this.observer.observe(stickyElement);
        }

        /**
         * @description Toggle sticky header state
         * @param {object} entry Anchor for apply sticky header
         * @returns {void}
         */
        toggleStickyState(entry) {
            document.body.classList.toggle(
                this.prefs().bodyClass,
                !!this.callIfExists(this.prefs().toggleCondition, entry)
            );
        }

        intersectsWithScreen(entry) {
            return entry.intersectionRatio < 1;
        }

        intersectsWithTopOfScreen(entry) {
            return entry?.boundingClientRect?.top < 0;
        }

        /**
         * @description Destroy widget logic
         * @returns {void}
         */
        destroy() {
            const stickyElement = this.ref('self').get();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'Sticky... Remove this comment to see the full error message
            if (this.observer && stickyElement) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'Sticky... Remove this comment to see the full error message
                // eslint-disable-next-line spellcheck/spell-checker
                this.observer.unobserve(stickyElement);

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'Sticky... Remove this comment to see the full error message
                this.observer.disconnect();
            }
        }
    }

    return StickyElement;
}
