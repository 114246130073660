/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicForm').default>} BaseBasicForm
 * @typedef {InstanceType<ReturnType<typeof import('core/forms/BasicInput').default>>} basicInput
 */

/**
 * @description Extend Basic Form implementation
 * @param {BaseBasicForm} BaseBasicForm Base Form implementation
 * @returns {typeof BasicForm} Form class
 */
export default function (BaseBasicForm) {
    /**
     * @class BasicForm
     * @augments BaseBasicForm
     * @classdesc Custom Form implementation.
     */
    class BasicForm extends BaseBasicForm {
        prefs() {
            return {
                classesBusy: 'm-busy',
                ...super.prefs()
            };
        }

        /**
         * @description Set aria-busy attribute value true
         * @returns {void}
         */
        showProgressBar() {
            this.ref('self').addClass(this.prefs().classesBusy).attr('aria-busy', 'true');
        }

        /**
         * @description Set aria-busy attribute value false
         * @returns {void}
         */
        hideProgressBar() {
            this.ref('self').removeClass(this.prefs().classesBusy).attr('aria-busy', 'false');
        }

        /**
         * @description Indicates that at least one form input has active shown errors
         * @returns {boolean} check result
         */
        hasActiveErrors() {
            const BasicInput = this.getConstructor('basicInput');
            let activeError = false;

            this.eachChild((itemComponent) => {
                if (itemComponent instanceof BasicInput
                    && itemComponent.error
                ) {
                    activeError = true;

                    return true;
                }

                return false;
            });

            return activeError;
        }

        /**
         * @description Get form inputs, which has active shown errors
         * @returns {Array<basicInput>} an array of form inputs with errors
         */
        getActiveErrors() {
            const BasicInput = this.getConstructor('basicInput');
            const activeErrors = [];

            this.eachChild((itemComponent) => {
                if (itemComponent instanceof BasicInput
                    && itemComponent.error
                ) {
                    activeErrors.push(itemComponent);
                }
            });

            return activeErrors;
        }
    }

    return BasicForm;
}
