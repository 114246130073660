import { TWidget } from 'widgets/Widget';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Base Button implementation
 * @param Widget - widget for extending
 * @returns Button widget
 */
export default function (Widget: TWidget) {
    /**
     * @class Button
     * @augments Widget
     * @classdesc Button widget, which could be used in different contextes (html-markup, inside widgets etc).
     * <br>Could be also used to trigger parent's widget method
     * @property {string} data-widget - Widget name `button`
     * @property {string} data-widget-event-click - Event listener to call Parent's widget method
     * @property {string} data-event-click - Event listener method `handleClick` for click event on widget
     * @property {boolean} data-prevent-action - Prevent action flag
     * @classdesc Represents Button component with the next features:
     * 1. Activate\deactivate button visibility on the storefront
     * 2. Enable\disable button visibility on the storefront
     * 3. Handles keydown by enter\space keyboard button
     * 4. Sets button error
     * 5. Marks button as pressed\unpressed
     *
     * @example <caption>Example of Button widget usage</caption>
     * <button type="submit" class="btn btn-block btn-primary"
     *     data-widget="button"
     *     data-widget-event-click="handleSubmit"
     *     data-event-click.prevent="handleClick"
     *     data-id="submitButton"
     * >
     *     ${Resource.msg('button.text.loginform', 'login', null)}
     * </button>
     */
    class Button extends Widget {
        /**
         * @description Returns Widget configuration object
         * @returns {object} Widget configuration object
         */
        prefs() {
            return {
                classesError: 'm-error',
                classesActive: 'm-active',
                preventAction: false,
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization logic
         * @returns {void}
         */
        init() {
            super.init();

            if (!this.id) {
                this.id = String(this.config.id);
            }

            // @ts-expect-error ts-migrate(2551) FIXME: Property 'disabled' does not exist on type 'Button... Remove this comment to see the full error message
            this.disabled = this.ref('self').attr('disabled') === 'disabled';
        }

        /**
         * @description Handles button click
         * @listens dom:click
         * @emits Button#click
         * @returns {void}
         */
        handleClick() {
            if (!this.prefs().preventAction) {
                /**
                 * @description Emits button click event
                 * @event Button#click
                 */
                this.emit('click', this);
            }
        }

        /**
         * @description Returns button value
         * @returns {string|refElement} result
         */
        getValue() {
            return this.ref('self').val();
        }

        /**
         * @param {(string|number|boolean|undefined)} val - Value to set into widget's val attribute
         * @returns {string|refElement} result
         */
        setValue(val = '') {
            this.setError();

            return this.ref('self').val(val);
        }

        /**
         * @description Returns button text
         * @returns {string} result
         */
        getText() {
            return this.ref('self').getText();
        }

        /**
         * @param {(string|undefined)} val - Value to set as a text of the Button
         * @returns {refElement} result
         */
        setText(val = '') {
            return this.ref('self').setText(val);
        }

        /**
         * @description Sets button text error
         * @param {(string|undefined)} err - Sets `err` as a Button text and
         * if `err` is not empty - adds error class to Button
         * @returns {void}
         */
        setError(err = '') {
            this.setText(err);

            if (err) {
                this.ref('self').addClass(this.prefs().classesError);
            }
        }

        /**
         * @description Method to mark element as `active`
         * @returns {Button} result
         */
        activate() {
            this.ref('self').addClass(this.prefs().classesActive);

            return this;
        }

        /**
         * @description Method to mark element as `inactive`
         * @returns {Button} result
         */
        deactivate() {
            this.ref('self').removeClass(this.prefs().classesActive);

            return this;
        }

        /**
         * @param {(boolean|undefined)} state true to show/false to hide/undefined to auto
         */
        toggleActive(state) {
            this.ref('self').toggleClass(this.prefs().classesActive, state);
        }

        /**
         * @description Check if a button has activated state
         * @returns {boolean} result
         */
        isActive() {
            return this.ref('self').hasClass(this.prefs().classesActive);
        }

        /**
         * @description Disables button
         * @returns {Button} result
         */
        disable() {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'disabled' does not exist on type 'Button... Remove this comment to see the full error message
            this.disabled = true;
            this.ref('self').disable();

            return this;
        }

        /**
         * @description Enables button
         * @returns {Button} result
         */
        enable() {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'disabled' does not exist on type 'Button... Remove this comment to see the full error message
            this.disabled = false;
            this.ref('self').enable();

            return this;
        }

        /**
         * @description Marks button as busy
         * @returns {void}
         */
        busy() {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'isBusy' does not exist on type 'Button'.... Remove this comment to see the full error message
            this.isBusy = true;
            this.ref('self').attr('aria-busy', 'true');
        }

        /**
         * @description Marks button as unbusy
         * @returns {void}
         */
        unbusy() {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'isBusy' does not exist on type 'Button'.... Remove this comment to see the full error message
            this.isBusy = false;
            this.ref('self').attr('aria-busy', 'false');
        }

        /**
         * @description Marks button as selected
         * @returns {void}
         */
        select() {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'selected' does not exist on type 'Button... Remove this comment to see the full error message
            this.selected = true;
            this.ref('self').attr('aria-selected', 'true');
        }

        /**
         * @description Marks button as unselected
         * @returns {void}
         */
        unselect() {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'selected' does not exist on type 'Button... Remove this comment to see the full error message
            this.selected = false;
            this.ref('self').attr('aria-selected', 'false');
        }

        /**
         * @description Method to mark button as `pressed`.
         * Used only for button with 2 different states when pressed and not pressed. Ex:
         * Play / Paused; Favorite / Not favorite, etc.
         * @returns {Button} result
         */
        press() {
            this.ref('self').attr('aria-pressed', 'true');

            return this;
        }

        /**
         * @description Method to mark button as `unpressed`. Used only in buttons with 2 states.
         * @returns {Button} result
         */
        unpress() {
            this.ref('self').attr('aria-pressed', 'false');

            return this;
        }

        /**
         * @description Returns button name
         * @returns {string|refElement} result
         */
        getName() {
            return this.ref('self').attr('name');
        }

        /**
         * @description Focus to element
         * @returns {Button} - return instance for chaining
         */
        focus() {
            this.ref('self').focus();

            return this;
        }

        /**
         * @description Sets button to be accountable with `tab` button navigation
         * @returns {Button} - return instance for chaining
         */
        setTabIndex() {
            this.ref('self').attr('tabindex', '0');

            return this;
        }

        /**
         * @description Sets button to be not accountable with `tab` button navigation
         * @returns {Button} - return instance for chaining
         */
        unsetTabIndex() {
            this.ref('self').attr('tabindex', '-1');

            return this;
        }

        /**
         * @description Keydown Event handler
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @listens dom:keydown
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.handleClick();
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        /**
         * @description Method to add class to widget container. Allows chaining.
         * @param {string} className a class name to add to widget container
         * @returns {Button} result
         */
        addClass(className) {
            this.ref('self').addClass(className);

            return this;
        }

        /**
         * @description Method to remove class from widget container. Allows chaining.
         * @param {string} className a class name to remove from widget container
         * @returns {Button} result
         */
        removeClass(className) {
            this.ref('self').removeClass(className);

            return this;
        }
    }

    return Button;
}
