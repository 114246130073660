/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */
/* sonar-ignore-start */
const hasContext = (ctx) => window.contexts.includes(ctx);

import widgetsMgr from 'widgets/widgetsMgr';
import GlobalECountrySelector from './widgets/global/GlobalECountrySelector';

widgetsMgr.addWidgetsList('global.globale', () => [
    ['globalECountrySelector', GlobalECountrySelector]

]);

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ './widgets/checkout/GlobalEIFrame').then(d => d.default)]).then(deps => {
        const [
            GlobalEIFrame
        ] = deps;

        return {
            listId: 'checkout.globale',
            widgetsDefinition: () => [
                ['globalEIFrame', GlobalEIFrame]
            ]
        };
    })
});

/* sonar-ignore-end */
