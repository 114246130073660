const ariaChecked = 'aria-checked';

/**
 * @description Base InputCheckbox implementation
 * @param BasicInput Base widget for extending
 * @returns Input Checkbox class
 */
export default function (BasicInput: ReturnType <typeof import('widgets/forms/BasicInput').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputCheckbox
     * @augments BasicInput
     * @classdesc Checkbox input implementation. Represents input `checkbox` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * @property {string} data-widget - Widget name `inputCheckbox`
     * @example <caption>InputCheckbox definition in dynamicforms.json</caption>
     * ...
     * // fields -> input -> checkbox
     * checkbox: {
     *     'label.after': true,
     *     'element.type': 'checkbox'
     * },
     * ...
     * // fields -> generic -> setAsDefault
     * setAsDefault: {
     *     extends: 'fields.input.checkbox',
     *     'label.text': 'form.address.setDefault'
     * },
     * ...
     * @example <caption>Insertion of InputCheckbox inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.setAsDefault, pdict.addressFormOptions).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputCheckbox</caption>
     * <div data-widget="inputCheckbox" class="b-form_section" data-id="dwfrm_login_rememberMe" data-validation-config='{"patterns":{},"errors":{},"mandatory":false}'>
     *     <div class="b-checkbox">
     *         <input type="checkbox" data-ref="field" class="b-checkbox-input" id="dwfrm_login_rememberMe" name="dwfrm_login_rememberMe" value="true"
     *             data-tau="login_rememberMe" data-event-blur="validate" aria-describedby="dwfrm_login_rememberMe-error"
     *         >
     *         <svg class="b-checkbox-icon" width="22" height="22" viewBox="0 0 24 24" focusable="false">
     *             <path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"></path>
     *         </svg>
     *         <label class="b-form_section-label" for="dwfrm_login_rememberMe">Remember Me</label>
     *     </div>
     *     <div role="alert" class="b-form_section-message" data-ref="errorFeedback" id="dwfrm_login_rememberMe-error" hidden="hidden"></div>
     * </div>
     */
    class InputCheckbox extends BasicInput {
        /**
         * @description Get value of a checkbox input. Returns empty string if checkbox is not checked.
         * @returns {string} result
         */
        getValue() {
            if (this.ref('field').prop('checked')) {
                return super.getValue();
            }

            return '';
        }

        /**
         * @description Set value to checkbox input. By fact - checks it if set value is not empty.
         * @param {string} newVal New value to set.
         * @param {boolean} silently If true - there will be no event fired, that indicates input value change.
         * @emits BasicInput#change
         * @returns {void}
         */
        setValue(newVal = '', silently = false) {
            this.ref('field').prop('checked', !!newVal);

            if (!silently) {
                this.emit('change', this);
            }
        }

        /**
         * @description Checks the checkbox. Also updates accessibility properties of input accordingly.
         * @emits BasicInput#change
         * @returns {void}
         */
        check() {
            this.ref('field').attr(ariaChecked, 'true');
            this.ref('field').prop('checked', true);

            this.emit('change', this);
        }

        /**
         * @description Uncheck the checkbox. Also updates accessibility properties of input accordingly.
         * @emits BasicInput#change
         * @returns {void}
         */
        uncheck() {
            this.ref('field').attr(ariaChecked, 'false');
            this.ref('field').prop('checked', false);

            this.emit('change', this);
        }

        /**
         * @description Update accessibility `aria-checked` depending on checkbox state.
         * @returns {void}
         */
        updateAriaChecked() {
            if (this.ref('field').prop('checked')) {
                this.ref('field').attr(ariaChecked, 'true');
            } else {
                this.ref('field').attr(ariaChecked, 'false');
            }
        }

        /**
         * @description Used to handle change checkbox state.
         * Usually used in dynamic forms config to handle `change` event on checkbox like:
         * @example
         * ...
         * element: {
         *     attributes: {
         *         'data-event-change': 'handleChange'
         *     }
         * }
         * ...
         * @emits BasicInput#change
         * @returns {void}
         */
        handleChange() {
            this.updateAriaChecked();
            this.emit('change');
        }
    }

    return InputCheckbox;
}
