import { TWidget } from 'widgets/Widget';

/**
 * @param Widget - widget for extending
 * @returns Tab Panel widget
 */
export default function (Widget: TWidget) {
    /**
     * @class TabPanel
     * @augments Widget
     * @property {string} data-widget - Widget name `tabPanel`
     * @classdesc Represents a TabPanel widget with the next features:
     * 1. Toggle active\inactive tab panel state
     *
     * @example <caption>Example of TabPanel widget usage</caption>
     * <div data-widget="tabs">
     *     <ul class="nav nav-tabs nav-fill">
     *         <li class="nav-item">
     *             <a
     *                 data-widget="button"
     *                 data-panel-name="login"
     *                 data-widget-event-click="handleTabClick"
     *                 data-id="button-login"
     *                 data-event-click.prevent="handleClick"
     *                 href="#login" role="tab"
     *             >
     *                 ${Resource.msg('link.header.login.module', 'login', null)}
     *             </a>
     *         </li>
     *         <li class="nav-item">
     *             <a
     *                 data-widget="button"
     *                 data-panel-name="register"
     *                 data-widget-event-click="handleTabClick"
     *                 data-id="button-register"
     *                 data-event-click.prevent="handleClick"
     *                 href="#register" role="tab"
     *             >
     *                 ${Resource.msg('link.header.register.module', 'login', null)}
     *             </a>
     *         </li>
     *     </ul>
     *     <div class="tab-content">
     *         <div id="login" role="tabpanel" data-widget="tabPanel">
     *             ... tab content
     *             <isinclude template="account/components/loginForm" />
     *             <isinclude template="account/components/oauth" />
     *         </div>
     *         <div id="register" role="tabanel" data-widget="tabPanel">
     *             ... tab content
     *             <isinclude template="account/components/registerForm" />
     *         </div>
     *     </div>
     * </div>
     */
    class TabPanel extends Widget {
        isActive = false;

        prefs() {
            return {
                classesActive: 'm-active',
                ...super.prefs()
            };
        }

        /**
         * @description Refresh widget handler
         */
        onRefresh(): void {
            this.toggleActive(this.isActive);
        }

        /**
         * @description Activates tab panel
         */
        activate(): void {
            this.toggleActive(true);
        }

        /**
         * @description Deactivates tab panel
         */
        deactivate(): void {
            this.toggleActive(false);
        }

        /**
         * @param state true to show/false to hide/undefined to auto
         */
        toggleActive(state: (boolean | undefined)): void {
            this.isActive = this.ref('self')
                .toggleClass(this.prefs().classesActive, !!state)
                .hasClass(this.prefs().classesActive);
        }
    }

    return TabPanel;
}
