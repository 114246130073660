import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns SoftKeyboardToggleMixin class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class SoftKeyboardToggleMixin
     * @augments Widget
     * @classdesc Represents SoftKeyboardToggleMixin component with next features:
     * 1. Hides software keyboard on touches outside the keyboard to avoid iOS issues.
     * 2. This class is not intended to have a separate DOM representation.
     * @property {string} data-widget - Widget name "searchBoxToggle"
     * @property {string} data-input - Target input
     */
    class SoftKeyboardToggleMixin extends Widget {
        prefs() {
            return {
                input: '',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            super.init();

            const input = this.ref('input').get();

            if (input) {
                this.ev('focus', this.hideKeyboardOnOutsideTouch, input);
                this.ev('blur', this.hideKeyboardReset, input);
            }
        }

        /**
         * @description Hide keyboard
         * @param {HTMLElement} el - dom element,
         * @param {Event} event - dom event
         * @returns {void}
         */
        hideKeyboard(el, event) {
            const input = this.ref('input').get();

            if (input && (event.target !== input)) {
                input.blur();
            }
        }

        /**
         * @description Hide keyboard on outside touch
         * @returns {void}
         */
        hideKeyboardOnOutsideTouch() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchStartDisposables' does not exist on... Remove this comment to see the full error message
            this.touchStartDisposables = this.ev('touchstart', this.hideKeyboard, window);
        }

        /**
         * @description Remove event listeners
         * @returns {void}
         */
        hideKeyboardReset() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchStartDisposables' does not exist on... Remove this comment to see the full error message
            const disposables = this.touchStartDisposables;

            if (typeof disposables !== 'undefined') {
                disposables.forEach(fn => fn());
            }
        }
    }

    return SoftKeyboardToggleMixin;
}
