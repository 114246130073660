import { loadScript } from 'widgets/toolbox/util';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Osano ecents implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {Osano} Osano class
 */
export default function (Widget) {
    /**
     * @class Osano
     * @augments Widget
     */
    class Osano extends Widget {
        prefs() {
            return {
                scriptUrl: '',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize Osano
         * @returns {void}
         */
        init() {
            super.init();

            if (this.prefs().scriptUrl) {
                this.loadOsanoScript().then(() => {
                    this.addListenersForEventsOsano();
                });

                this.allowOnSaveEvents = false;
            } else {
                this.addListenersForEventsOsano();
            }
        }

        /**
         * @description Additional JS and GTM events
         */
        addListenersForEventsOsano() {
            if (!window.Osano) {
                return;
            }

            // On page init event
            window.Osano.cm.addEventListener('osano-cm-initialized', () => {
                const osanoWidget = document
                    .getElementsByClassName('osano-cm-storage-policy osano-cm-content__link osano-cm-link')[0];

                osanoWidget.setAttribute('onclick',
                    'window.Osano.cm.showDrawer("osano-cm-dom-info-dialog-open"); window.Osano.cm.hideDialog(); return false;');
                osanoWidget.innerHTML = 'Update Cookie Preference';

                this.allowOnSaveEvents = true;
            });

            // On script blocked event
            window.Osano.cm.addEventListener('osano-cm-script-blocked', () => {
                this.handleOsanoAnalytic('osano_script_blocked');
            });

            // On preferences saved event
            window.Osano.cm.addEventListener('osano-cm-consent-saved', this.processOsanoSaveEvent.bind(this));
        }

        /**
         * @description push GTM events
         */
        processOsanoSaveEvent() {
            if (!this.allowOnSaveEvents) {
                return;
            }

            const ACCEPT_KEY = 'ACCEPT';
            const consentObject = window.Osano.cm.getConsent();

            if (consentObject.ANALYTICS === ACCEPT_KEY) {
                this.handleOsanoAnalytic('analytics_consent');
            }

            if (consentObject.PERSONALIZATION === ACCEPT_KEY) {
                this.handleOsanoAnalytic('personalization_consent');
            }

            if (consentObject.MARKETING === ACCEPT_KEY) {
                this.handleOsanoAnalytic('marketing_consent');
            }

            if (consentObject.ESSENTIAL === ACCEPT_KEY) {
                this.handleOsanoAnalytic('essential_consent');
            }
        }

        /**
         * @description push GTM events
         * @param {string} key - gtm event key
         */
        handleOsanoAnalytic(key) {
            this.eventBus().emit('osano.events', key);
        }

        /**
         * @description Load script
         * @returns {Promise} loaded script
         */
        loadOsanoScript() {
            if (window.Osano) {
                return Promise.resolve(window.Osano);
            }

            return loadScript(this.prefs().scriptUrl);
        }
    }

    return Osano;
}
