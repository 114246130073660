/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */

const hasContext = (/** @type {string} */ctx) => window.contexts.includes(ctx);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => false, // hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.paypal' */ './widgets/account/PayPalProfileCard').then(d => d.default),
        import(/* webpackChunkName: 'account.paypal' */ './widgets/common/PayPalBaseButton').then(d => d.default),
        import(/* webpackChunkName: 'cart.paypal' */ './widgets/common/PayPalBillingAgreementMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.paypal' */ './widgets/account/PayPalAccountBAButton').then(d => d.default)
    ]).then(deps => {
        const [
            PayPalProfileCard,
            PayPalBaseButton,
            PayPalBillingAgreementMixin,
            PayPalAccountBAButton
        ] = deps;

        return {
            listId: 'account.paypal',
            widgetsDefinition: () => [
                ['paypalAccountBAButton', PayPalBaseButton],
                ['paypalAccountBAButton', PayPalBillingAgreementMixin, 'paypalAccountBAButton'],
                ['paypalAccountBAButton', PayPalAccountBAButton, 'paypalAccountBAButton'],
                ['paypalProfileCard', PayPalProfileCard]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('cart'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'cart.paypal' */ './widgets/common/PayPalBaseButton').then(d => d.default),
        import(/* webpackChunkName: 'cart.paypal' */ './widgets/common/PayPalBillingAgreementMixin').then(d => d.default),
        import(/* webpackChunkName: 'cart.paypal' */ './widgets/cart/PayPalCartBaseButton').then(d => d.default),
        import(/* webpackChunkName: 'cart.paypal' */ './widgets/cart/PayPalCartButton').then(d => d.default),
        import(/* webpackChunkName: 'cart.paypal' */ './widgets/cart/PayPalCartBAButton').then(d => d.default),
        import(/* webpackChunkName: 'cart.paypal' */ './widgets/cart/PayPalCartStaticButton').then(d => d.default)
    ]).then(deps => {
        const [
            PayPalBaseButton,
            PayPalBillingAgreementMixin,
            PayPalCartBaseButton,
            PayPalCartButton,
            PayPalCartBAButton,
            PayPalCartStaticButton] = deps;

        return {
            listId: 'cart.paypal',
            widgetsDefinition: () => [
                ['paypalCartButton', PayPalBaseButton],
                    ['paypalCartButton', PayPalCartBaseButton, 'paypalCartButton'],
                    ['paypalCartButton', PayPalCartButton, 'paypalCartButton'],
                ['paypalCartBAButton', PayPalBaseButton],
                    ['paypalCartBAButton', PayPalBillingAgreementMixin, 'paypalCartBAButton'],
                    ['paypalCartBAButton', PayPalCartBaseButton, 'paypalCartBAButton'],
                    ['paypalCartBAButton', PayPalCartBAButton, 'paypalCartBAButton'],
                ['paypalCartStaticButton', PayPalBaseButton],
                    ['paypalCartStaticButton', PayPalCartStaticButton, 'paypalCartStaticButton']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.paypal' */ './widgets/checkout/PaymentAccordionItemPayPal').then(d => d.default),
        import(/* webpackChunkName: 'checkout.paypal' */ './widgets/checkout/PayPalBillingForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.paypal' */ './widgets/common/PayPalBaseButton').then(d => d.default),
        import(/* webpackChunkName: 'checkout.paypal' */ './widgets/common/PayPalBillingAgreementMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.paypal' */ './widgets/billing/PayPalBillingButton').then(d => d.default),
        import(/* webpackChunkName: 'checkout.paypal' */ './widgets/billing/PayPalBillingBAButton').then(d => d.default),
        import(/* webpackChunkName: 'checkout.paypal' */ './widgets/billing/PayPalSavedAccountsList').then(d => d.default)
    ]).then(deps => {
        const [
            PaymentAccordionItemPayPal,
            PayPalBillingForm,
            PayPalBaseButton,
            PayPalBillingAgreementMixin,
            PayPalBillingButton,
            PayPalBillingBAButton,
            PayPalSavedAccountsList
        ] = deps;

        return {
            listId: 'checkout.paypal',
            widgetsDefinition: () => [
                ['paymentAccordionItemPayPal', PaymentAccordionItemPayPal, 'paymentAccordionItem'],
                ['billingForm', PayPalBillingForm, 'billingForm'],
                ['paypalBillingButton', PayPalBaseButton],
                    ['paypalBillingButton', PayPalBillingButton, 'paypalBillingButton'],
                ['paypalBillingBAButton', PayPalBaseButton],
                    ['paypalBillingBAButton', PayPalBillingAgreementMixin, 'paypalBillingBAButton'],
                    ['paypalBillingBAButton', PayPalBillingBAButton, 'paypalBillingBAButton'],
                ['paypalSavedAccountsList', PayPalSavedAccountsList, 'inputSelect']
            ]
        };
    })
});
