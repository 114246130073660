import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class ContextLoader
     * @augments Widget
     * @classdesc ContextLoader Widget - used to initialize context for the page for async loading dependent widgets lists (widgets inside wouldn't be initialized)
     * @property {string} data-widget - Widget name `contextLoader`
     * @property {string} data-context - context id to be added
     * @property {string} data-init-on-load - add context during widget initialization
     * @property {string} data-init-on-viewport - add context when widget in viewport
     * @property {string} data-loaded - override loading status
     * @example <caption>Example of ContextLoader widget usage</caption>
     * <div
     *     data-widget="contextLoader"
     *     data-context="myContext"
     *     data-init-on-viewport="true"
     * >
     * </div>
     */
    class ContextLoader extends Widget {
        loaded = false;

        observer: IntersectionObserver | undefined;

        prefs() {
            return {
                initOnLoad: false,
                initOnViewport: false,
                loaded: false,
                context: '',
                ...super.prefs()
            };
        }

        init() {
            this.loaded = this.prefs().loaded;

            if (!this.prefs().context) {
                throw new Error('Context must be initialized!');
            }

            if (this.prefs().initOnLoad) {
                this.loadContext();
            } else if (this.prefs().initOnViewport) {
                this.observer = new IntersectionObserver(([entry]) => this.onIntersection(entry), {
                    threshold: 0.1, // 10% of element became visible
                    rootMargin: '200px' // add margin around viewport
                });
                const widgetElement = this.ref('self').get();

                if (widgetElement) {
                    this.observer.observe(widgetElement); // start observing widget's DOM entry point
                }
            }
        }

        /**
         * @param {IntersectionObserverEntry | undefined} [entry] entry from observer
         */
        onIntersection(entry) {
            if (entry && entry.isIntersecting && this.observer) {
                this.observer.disconnect();
                this.observer = undefined;
                this.loadContext();
            }
        }

        loadContext() {
            if (this.loaded) {
                return;
            }

            if (this.prefs().context) {
                this.eventBus().emit('context.add', this.prefs().context);
                this.loaded = true;
            }
        }
    }

    return ContextLoader;
}
