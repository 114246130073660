import { loadTemplateEngine } from 'widgets/toolbox/render';

function initMustacheGlobals() {
    loadTemplateEngine().then(Mustache => {
        const originalRender = Mustache.render;

        Mustache.render = function (template, view, partials) {
            view = view || {};

            const tags = Mustache.tags || ['{{', '}}'];

            /**
             * @description A helper to render stringified JSON
             * @returns {Function} render function
             */
            view.stringifyJSON = function () {
                return function (obj, render) {
                    const property = obj
                        .replace(tags[0], '')
                        .replace(tags[1], '');

                    if (this && this[property] && typeof this[property] === 'object') {
                        this[property] = JSON.stringify(this[property]);
                    }

                    return render(obj);
                };
            };

            return originalRender(template, view, partials);
        };
    });
}

/**
 * @description Global entry point for any global actions
 * @returns {void}
 */
export function init() {
    initMustacheGlobals();
}
