// @ts-nocheck
import { submitFormJsonWithAbort, submitFormJson } from 'widgets/toolbox/ajax';

import { getCookie } from 'widgets/toolbox/cookie';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

/**
 * @typedef {ReturnType<typeof import('widgets/cart/CartMgr').default>} CartMgr
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {ReturnType<typeof import('widgets/forms/InputStepper').default>} InputStepper
 */

/**
 * @description CartMgr implementation
 * @param {CartMgr} CartMgr BaseCartMgr implementation
 * @returns {typeof JustAddedToCartMgr} JustAddedToCartMgr class
 */
export default function (CartMgr) {
    class JustAddedToCartMgr extends CartMgr {
        prefs() {
            return {
                showMessage: '',
                hideMessage: '',
                classesActive: 'm-active',
                classesDisabled: 'm-disabled',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            const { items, subscriptionItem } = this.prefs();

            this.justAddedToCartItems = items;

            if (subscriptionItem) {
                this.justAddedToCartItems.push(subscriptionItem[0]);
            }

            this.initEvents();
        }

        /**
         * @description inits widget events
         */
        initEvents() {
            this.eventBus().on('just.added.cart.update', 'updateJustAddedCart');
            this.eventBus().on('just.added.cart.mgr.execute', 'executeCallback');
        }

        /**
         * @description Basically injects self in scope of callback and executes it
         * Used in case of access to `ProductListingMgr` from other widgets, not directly related
         * @param {Function} cb callback to execute
         * @returns {void}
         */
        executeCallback(cb) {
            if (cb) {
                cb.apply(null, [this]);
            }
        }

        /**
         * @description updates just added cart
         *
         * @param {object} data - update data
         * @param {object} data.cartModel - just added cart model
         */
        updateJustAddedCart(data) {
            const { cartModel } = data;

            this.renderCart(cartModel);
        }

        /**
         * @description gets just added product items id
         *
         * @returns {Array.<string>} product items id
         */
        getJustAddedItemsID() {
            return this.justAddedToCartItems.map((item) => item.id);
        }

        /**
         * @description removes just added product from the just added cart
         *
         * @param {string} pid - product id
         */
        removeJustAddedProduct(pid) {
            this.justAddedToCartItems = this.justAddedToCartItems.filter((item) => item.id !== pid);
        }

        /**
         * @description adds just added product to the just added cart
         *
         * @param {object} product - added product item
         */
        addJustAddedProduct(product) {
            this.justAddedToCartItems.push(product);
        }

        /**
         * @description adds just added product recommendation before ajax handling
         *
         * @param {string} productID - added product item
         */
        addShadowJustAddedProduct(productID) {
            this.justAddedToCartItems.push({
                id: productID,
                shadow: true
            });
        }

        /**
         * @description Handler for "Remove" product button in a Cart
         * @param {(refElement|InputStepper)} removeButton - Clicked "Remove" product button or qtyStepper if value was 0
         * @returns {void}
         */
        removeProduct(removeButton) {
            const InputStepper = (this.getConstructor('InputStepper'));
            const url = removeButton.data('removeAction') || removeButton.data('action');
            const pid = removeButton.data('pid');
            const uuid = removeButton.data('uuid');
            const qtyStepperID = `quantityStepper-${uuid}`;
            const removedLineItemID = `cart_item_${uuid}`;

            let currentQuantityStepperValue = 1;
            let lineItemMinOrderQuantity = null;

            if (removeButton instanceof InputStepper) {
                currentQuantityStepperValue = parseInt(removeButton.getValue(), 10);

                lineItemMinOrderQuantity = removeButton.prefs().lineItemMinOrderQuantity;
            } else {
                this.getById(removedLineItemID, (removedLineItem) => {
                    removedLineItem.getById(qtyStepperID, (stepper) => {
                        currentQuantityStepperValue = parseInt(stepper.getValue(), 10);
                    });
                });
            }

            removeButton.addClass(this.prefs().classesDisabled);
            this.removeButton = removeButton;
            this.removeProductLink = removeButton;

            this.removeJustAddedProduct(pid.toString());

            this.confirmedRemoveProduct({
                attributes: {
                    url: url,
                    pid: pid,
                    uuid: uuid,
                    currentValue: currentQuantityStepperValue,
                    minOrderQuantity: lineItemMinOrderQuantity
                }
            }).then(() => {
                if (!this.justAddedToCartItems.length) {
                    this.closeJustAddedModal();
                }

                removeButton.removeClass(this.prefs().classesDisabled);
            });
        }

        /**
         * @description A callback, which is executed, when customer agreed to remove product in confirmation popup
         * @param {refElement} button - Button, pressed in confirmation modal
         * @param {boolean} [movedToWishlist] - Indicates if product was moved to wishlist
         * @emits "cart.remove.product"
         * @returns {Promise} Promise object represents server response for product deletion
         */
        confirmedRemoveProduct(button, movedToWishlist = false) {
            const attributes = button.attributes;

            if (!attributes) {
                return Promise.resolve({ error: true });
            }

            const url = attributes.url;
            const pid = attributes.pid;
            const uuid = attributes.uuid;
            const justAddedProductIDs = this.getJustAddedItemsID();
            const stringifiedJustAddedProductIDs = JSON.stringify(justAddedProductIDs);

            if (!movedToWishlist) {
                this.showProgressBar();
            }

            let removePromise = submitFormJson(url, {
                pid: pid,
                pids: stringifiedJustAddedProductIDs,
                uuid: uuid
            }, 'POST')
                .then((response) => {
                    if (response.error) {
                        throw Error(response.error);
                    }

                    if (response.basket) {
                        localStorageWrapper.removeItem('cartItemIds');
                        localStorageWrapper.setItem('currentSid', getCookie('sid'));
                        localStorageWrapper.setItem('cartItemIds', JSON.stringify(response.basket.itemsIds || []));
                    }

                    if (response.deletedProductId) {
                        this.eventBus().emit('remove.product.line.item', response);
                    }

                    response.basket.isProductRemoved = true;

                    this.confirmationRemoveCallback(response.basket, movedToWishlist);

                    this.eventBus().emit('cart.remove.product', this);

                    return response;
                });

            if (!movedToWishlist) {
                removePromise = removePromise
                    .catch(error => {
                        this.renderCartWithItemLevelActionError(uuid, error.message);

                        return error;
                    })
                    .finally(() => {
                        this.hideProgressBar();
                    });
            }

            return removePromise;
        }

        closeJustAddedModal() {
            this.eventBus().emit('hide.just.added.cart');
        }

        /**
         * @description Updates quantity of chosen product in Cart page
         * @param {inputStepper} quantityStepper - Select quantity input
         * @returns {Promise<object|null>} Promise object represents server response with quantity updating result
         */
        updateQty(quantityStepper) {
            this.showProgressBar();
            const uuid = quantityStepper.data('uuid');
            const currentQuantity = quantityStepper.currentValue;
            const isQuantityBelowMinValue = currentQuantity < quantityStepper.config.lineItemMinOrderQuantity;
            const qtyBeforeUpdate = this.cart.numItems;

            if ((currentQuantity === 0) || isQuantityBelowMinValue) {
                this.removeProduct(quantityStepper);
                this.hideProgressBar();

                return Promise.resolve(null);
            }

            const justAddedProductIDs = this.getJustAddedItemsID();
            const stringifiedJustAddedProductIDs = JSON.stringify(justAddedProductIDs);

            const requestObject = submitFormJsonWithAbort(quantityStepper.data('action'), {
                pid: quantityStepper.data('pid'),
                pids: stringifiedJustAddedProductIDs,
                uuid: uuid,
                quantity: quantityStepper.getValue()
            }, 'POST', false);

            this.updateQtyRequests.push(requestObject);

            Promise.all(this.updateQtyRequests.map(req => req.promise)).then(() => {
                this.eventBus().emit('finalize.attribute.change');
            });

            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            return requestObject.promise
                .then((resp) => {
                    const isQuantityIncreased = resp.numItems > qtyBeforeUpdate;

                    resp.isQuantityIncreased = isQuantityIncreased;

                    this.renderCartResponse(resp);

                    if (isQuantityIncreased) {
                        this.eventBus().emit('product.cart.qty.increment', quantityStepper, resp.numItems - qtyBeforeUpdate);
                    } else {
                        this.eventBus().emit('product.cart.qty.decrement', quantityStepper, qtyBeforeUpdate - resp.numItems);
                    }

                    this.accessibilityAlert(this.prefs().accessibilityAlerts.quantitychanged);

                    return resp;
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        return Promise.resolve(null);
                    }

                    if (error && error.error && error.errorMessage) {
                        this.render('errorTemplate', { message: error.errorMessage }, this.ref('errorMsgs'));
                    } else {
                        this.renderCartWithItemLevelActionError(uuid, error.message);
                    }

                    return Promise.resolve(null);
                })
                .finally(() => {
                    this.hideProgressBar();

                    const index = this.updateQtyRequests.indexOf(requestObject);

                    if (index > -1) {
                        this.updateQtyRequests.splice(index, 1);
                    }
                });
        }

        toggleJustAddedProduct() {
            const hiddenAddedProducts = this.ref('hiddenAddedProducts');

            if (hiddenAddedProducts.prop('hidden')) {
                hiddenAddedProducts.show();
                this.ref('showHiddenProductBtn').addClass(this.prefs().classesActive);
                this.ref('showHiddenProductBtnText').setText(this.prefs().hideMessage);
            } else {
                hiddenAddedProducts.hide();
                this.ref('showHiddenProductBtn').removeClass(this.prefs().classesActive);
                this.ref('showHiddenProductBtnText').setText(this.prefs().showMessage.replace(/[0-9]+/gm, this.justAddedToCartItems.length));
            }
        }
    }

    return JustAddedToCartMgr;
}
