/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputText').default>} BaseInputText
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Custom InputSelect implementation
 * @param {BaseInputText} BaseInputText Base InputSelect implementation
 * @returns {typeof InputText} Input Select class
 */
export default function (BaseInputText) {
    /**
     * @class InputText
     * @augments BaseInputText
     * @classdesc Custom Text input implementation.
     */
    class InputTextTypeNumber extends BaseInputText {
        /**
         * @description Check provided number against allowed range
         */
        correctNumRange() {
            const val = this.getValue();

            if (val || val === 0) {
                if (val < this.prefs().min) {
                    this.setValue(this.prefs().min, true);
                } else if (val > this.prefs().max) {
                    this.setValue(this.prefs().max, true);
                }
            }
        }
    }

    return InputTextTypeNumber;
}
