import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns Image widget
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Image
     * @augments Widget
     */
    class Image extends Widget {
        /**
         * @description Is fallback image used
         */
        isFallback = false;

        prefs() {
            return {
                refImg: 'self',
                fallbackSrc: '',
                ...super.prefs()
            };
        }

        init() {
            const eImg = <HTMLImageElement> this.ref(this.prefs().refImg).get();

            // Handle error for case when widget is initialized after image loading
            if (eImg && eImg.complete && !eImg.naturalWidth) {
                this.loadingError();
            }
        }

        /**
         * @description Image loading error handling
         */
        loadingError(): void {
            if (!this.isFallback && this.prefs().fallbackSrc) {
                this.ref(this.prefs().refImg).prop('src', this.prefs().fallbackSrc);
                this.isFallback = true;
            } else {
                this.ref(this.prefs().refImg).prop('hidden', true);
            }
        }
    }

    return Image;
}
