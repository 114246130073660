/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicInput').default>} BaseBasicInput
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Extend Basic Inputimplementation
 * @param {BaseBasicInput} BaseBasicInput Base Input implementation
 * @returns {typeof InputText} Input class
 */
export default function (BaseBasicInput) {
    /**
     * @class InputText
     * @augments BaseBasicInput
     * @classdesc Custom input implementation.
     */
    class BasicInput extends BaseBasicInput {
        prefs() {
            const prefs = super.prefs();

            if (prefs.validationConfig) {
                prefs.validationConfig.errors = prefs.validationConfig?.errors || {};
            } else {
                prefs.validationConfig = { errors: {} };
            }

            return prefs;
        }

        /**
         * @description Handle required attr on an input
         * @param {boolean} [val] attribure value
         * @returns {this} `this` instance for chaining
         */
        setRequired(val) {
            this.ref('field').prop('required', val);

            return this;
        }
    }

    return BasicInput;
}
