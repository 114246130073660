const DATA_FIELD = '[data-ref="field"]';

/**
 * @description Base InputRadio implementation
 * @param BasicInput Base widget for extending
 * @returns Input Radio class
 */
export default function (BasicInput: ReturnType<typeof import('widgets/forms/BasicInput').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputRadio
     * @augments BasicInput
     * @classdesc Radio input implementation. Represents input `radio` element together with widget-related HTML markup.
     * @property {string} data-widget - Widget name `inputRadio`
     * @example <caption>InputRadio HTML markup example</caption>
     * <fieldset class="b-form-set">
     *     <legend class="b-form-set_label">Shipping method</legend>
     *     <div class="b-form_section" data-widget="inputRadio" data-id="shippingMethodList"
     *         data-select-shipping-method-url="https://..."
     *         data-action-url="https://..."
     *         data-widget-event-change="selectShippingMethod" data-shipment-uuid="3fbc2a6f7e32e2b1755e9099bd"
     *     >
     *         <div class="b-option_switch">
     *             <div class="b-option_switch-inner">
     *                 <input id="shippingMethod-001" class="b-option_switch-input"
     *                     name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio"
     *                     value="001" data-value="001" data-ref="field" data-event-change="update" checked=""
     *                 >
     *                 <div class="b-option_switch-icon"></div>
     *                 <label class="b-option_switch-label" for="shippingMethod-001">
     *                     <div class="b-option_switch-label_surface">
     *                         <span class="b-option_switch-name">...</span>
     *                         <div class="b-price">...</div>
     *                     </div>
     *                     <span class="b-option_switch-description">...</span>
     *                 </label>
     *             </div>
     *         </div>
     *         <div class="b-option_switch">
     *             <div class="b-option_switch-inner">
     *                 <input id="shippingMethod-002" class="b-option_switch-input"
     *                     name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio"
     *                     value="001" data-value="001" data-ref="field" data-event-change="update" checked=""
     *                 >
     *                 <div class="b-option_switch-icon"></div>
     *                 <label class="b-option_switch-label" for="shippingMethod-001">
     *                     <div class="b-option_switch-label_surface">
     *                         <span class="b-option_switch-name">...</span>
     *                         <div class="b-price">...</div>
     *                     </div>
     *                     <span class="b-option_switch-description">...</span>
     *                 </label>
     *             </div>
     *         </div>
     *     <div role="alert" class="b-form_section-message" data-ref="errorFeedback" hidden=""></div>
     * </fieldset>
     */
    class InputRadio extends BasicInput {
        /**
         * @description method to get `value` of the radio
         * @returns {string} value of selected radio input
         */
        getValue() {
            const el = (this.ref('self').get());

            if (el) {
                return Array.from(el.querySelectorAll(DATA_FIELD))
                    .reduce((val, elField) => {
                        const tmpEl = <HTMLInputElement>elField;

                        return val || ((tmpEl.checked || '') && tmpEl.value);
                    }, '');
            }

            return '';
        }

        /**
         * @description method to get `data-value` value of the radio
         * @returns {string} `data-value` value
         */
        getDataValue() {
            const el = (this.ref('self').get());

            if (el) {
                return Array.from(el.querySelectorAll(DATA_FIELD))
                    .reduce((val, elInner) => {
                        const tmpEl = <HTMLInputElement>elInner;

                        return val || ((tmpEl.checked || '') && (tmpEl.getAttribute('data-value') || ''));
                    }, '');
            }

            return '';
        }

        /**
         * @description Overridden implementation of parent validate() method
         * @returns {boolean} true in all cases
         */
        validate() {
            return true;
        }

        /**
         * @description Method to select radio input
         * @param {string} value - value to be set to radio button
         * @returns {void}
         */
        setValue(value) {
            const el = (this.ref('self').get());

            if (el) {
                Array.from(el.querySelectorAll(DATA_FIELD))
                    .forEach((elField) => {
                        const tmpEl = <HTMLInputElement>elField;

                        if (tmpEl.value === value) {
                            tmpEl.checked = true;
                            tmpEl.setAttribute('checked', 'checked');
                        } else {
                            tmpEl.checked = false;
                            tmpEl.removeAttribute('checked');
                        }
                    });
            }
        }

        /**
         * @description Method to get dataset of checked or first radio input
         * @returns {null|object} dataset object of first or checked radio input, or null if no 'self' exist
         */
        getDataSet() {
            const checked = this.getChecked();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'dataset' does not exist on type 'Element... Remove this comment to see the full error message
            return checked ? checked.dataset : checked;
        }

        /**
         * @description Method to get checked (or first) radio input
         * @returns {null|HTMLElement} first or checked inputs ref element if self exists, null if not
         */
        getChecked() {
            const el = this.ref('self').get();

            if (el) {
                /** @type {HTMLInputElement} */
                const currentEl = (Array.from(el.querySelectorAll(DATA_FIELD))
                    .filter(field => {
                        const tmpField = <HTMLInputElement>field;

                        return tmpField.checked;
                    })[0] || el.querySelectorAll(DATA_FIELD)[0]);

                return currentEl;
            }

            return null;
        }
    }

    return InputRadio;
}
