/**
 * @module render
 * @category widgets
 * @subcategory toolbox
 * @description Represents render helpers that used for rendering Mustache template into variable
 * @example <caption>Example of usage</caption>
 * import { render } from 'widgets/toolbox/render';
 *
 * render(template, data).then((renderedString) => {
 *     // process "renderedString"
 * });
 */

/**
 * @description Load mustache library
 * @returns {Promise} Promise that resolved with mustache instance
 */
export function loadTemplateEngine() {
    return import(/* webpackChunkName: 'dynamic-render' */'mustache').then(d => d.default);
}

/**
 * @description Render method that render template with provided data
 * @param {string} template string
 * @param {object} data - object with data to use for rendering
 * @returns {Promise<string>} promise that resolved with rendered string
 */
export function render(template, data) {
    return loadTemplateEngine().then((Mustache) => {
        return Mustache.render(template || '', data);
    });
}
