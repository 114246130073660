import { submitFormJson, getJSONByUrl } from 'widgets/toolbox/ajax';
import { WishlistItemsMgr } from '../toolbox/wishlistItemsMgr';
const ALERT_EVENT = 'alert.show';
const WISHLIST_UPDATE_EVENT = 'wishlist.updated';
const wishlistItemsMgr = WishlistItemsMgr.getInstance();

type ILabel = InstanceType<ReturnType<typeof import('widgets/global/Label').default>>;
type IButtonWithIcon = InstanceType<ReturnType<typeof import('widgets/global/ButtonWithIcon').default>>;
type responseType = {
    success: string,
    itemAlreadyExists: boolean,
    msg: string,
    pid: string,
    error: boolean,
    wishlistItems: Array<string>
};

/**
 * @param ProductDetail Base widget for extending
 * @returns Add to Wishlist Mixin class
 */
export default function (ProductDetail: ReturnType<typeof import('widgets/product/ProductDetail').default>) {
    /**
     * @category widgets
     * @subcategory wishlist
     * @class AddToWishlistMixin
     * @augments ProductDetail
     * @classdesc Add to wishlist mixin with next features:
     * 1. Represents `Add to wishlist` button together with related functionality
     * <br>*As far as it's a mixin, separate DOM widget is not needed.*
     * 2. Allows to add products to wishlist from PDP, QuickView
     * 3. Communicates to user operation results in both cases: error and success
     *
     * Widget has next relationship:
     * * Listens click on `add button` that instance of {@link Button} to handle addition to wish list.
     * <br>Also uses component methods to change button text, activate/deactivate button
     * * Uses methods of `add message` that instance of {@link Label} to set/hide/show messsage
     * @property {string} data-pid - product id
     * @property {string} [data-text-network-error=Network Error] - Network error message
     * @property {string} [data-add-to-wishlist-msg=addToWishlistMsg] - ID of {@link Label} component that represents `add msg`
     * @property {string} [data-add-to-wishlist-btn=addToWishlist] -  ID of {@link Button} component that represents `add button`
     * @property {string} data-text-added-to-wishlist - Added to wishlist message
     * @property {boolean} [add-to-wishlist-hide-texts=false] - Added to wishlist message
     * @property {string} data-original-wishlist-button-message - original message for `add to wishlist` button
     * @property {boolean} data-show-alert-if-product-in-wishlist - Indicates, if we need to show active icon for wishlist button,
     * in case if request returns, that iteam already in wishlist
     * @property {string} [data-class-added-to-wishlist] - Added to wishlist class
     */
    class AddToWishlistMixin extends ProductDetail {
        prefs() {
            return {
                pid: '',
                textNetworkError: 'Network Error',
                addToWishlistMsg: 'addToWishlistMsg',
                addToWishlistBtn: 'addToWishlist',
                textAddedToWishlist: '',
                addToWishlistHideTexts: false,
                originalWishlistButtonMessage: '',
                showAlertIfProductInWishlist: false,
                getWishlistItemsUrl: '',
                // @ts-expect-error ts-migrate(2783) FIXME: 'accessibilityAlerts' is specified more than once,... Remove this comment to see the full error message
                accessibilityAlerts: {
                    ...super.prefs().accessibilityAlerts,
                    addedtowishlist: '',
                    removedfromwishlist: ''
                },
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            super.init();
            wishlistItemsMgr.isItemInWishlist(this.prefs().pid, this.prefs().productOptions)
                .then((state: boolean) => {
                    this.changeStateWishlistButton(state);
                })
                // eslint-disable-next-line no-console
                .catch(error => console.log(error));
            this.eventBus().on(WISHLIST_UPDATE_EVENT, 'updateStateWishlistButton');
        }

        /**
         * @description Activate Wish List button
         * @param addToWishlistBtn Target Wish List button
         */
        activateWishListButton(addToWishlistBtn: IButtonWithIcon) {
            addToWishlistBtn
                .activate()
                .press();

            if (!this.prefs().addToWishlistHideTexts) {
                addToWishlistBtn.setText(this.prefs().textAddedToWishlist);
            }
        }

        /**
         * @description Deactivate Wish List button
         * @param addToWishlistBtn Target Wish List button
         */
        deactivateWishListButton(addToWishlistBtn: IButtonWithIcon) {
            addToWishlistBtn
                .deactivate()
                .unpress()
                .enable();

            if (!this.prefs().addToWishlistHideTexts) {
                addToWishlistBtn.setText(this.prefs().originalWishlistButtonMessage);
            }
        }

        /**
         * @description Update state Wish List button after some actions
         * @param updatedProductId Product ID that was updated in the Wish List
         */
        updateStateWishlistButton(updatedProductId: string) {
            if (!updatedProductId) { return; }

            this.getById(this.prefs().addToWishlistBtn, (addToWishlistBtn: IButtonWithIcon) => {
                if (String(addToWishlistBtn.config.productId) !== updatedProductId) { return; }

                wishlistItemsMgr.isItemInWishlist(updatedProductId)
                    .then((state: boolean) => {
                        if (state && !addToWishlistBtn.isActive()) {
                            this.activateWishListButton(addToWishlistBtn);
                        }

                        if (!state && addToWishlistBtn.isActive()) {
                            this.deactivateWishListButton(addToWishlistBtn);
                        }
                    });
            });
        }

        /**
         * @description Change state of Add to Wishlist button
         * @param state Ids product currently in the wishlist
         */
        changeStateWishlistButton(state: boolean) {
            this.getById<IButtonWithIcon>(this.prefs().addToWishlistBtn, (addToWishlistBtn) => {
                if (state) {
                    this.activateWishListButton(addToWishlistBtn);
                } else {
                    this.deactivateWishListButton(addToWishlistBtn);
                }
            });
        }

        /**
         * @description Removes product from wish list.Change button state
         * @param button Target button
         */
        removeProductFromWishlist(button: IButtonWithIcon) {
            button.busy();
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'sfccData' does not exist on type 'Window... Remove this comment to see the full error message
            const removeProductFromWishlistUrl = window.sfccData.wishlistActionUrls?.removeFromWishlist;

            getJSONByUrl(removeProductFromWishlistUrl, {
                pid: this.prefs().pid || ''
            }).then((/** @type {{success: boolean, wishlistItems: string}} */ response) => {
                if (!response.success) {
                    return;
                }

                this.changeStateWishlistButton(false);
                /**
                 * @description Global event to show alert
                 * @event "alert.show"
                 */
                this.eventBus().emit(ALERT_EVENT, {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'removedfromwishlist' does not exist on t... Remove this comment to see the full error message
                    accessibilityAlert: this.prefs().accessibilityAlerts.removedfromwishlist
                });
                wishlistItemsMgr.updateWishlistItems(response.wishlistItems);
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                this.eventBus().emit('product.removedFromWishlist', response.wishlistItems.length > 0, response.pid);
                this.eventBus().emit(WISHLIST_UPDATE_EVENT, response.pid);
                this.eventBus().emit('gtm.datalayer.event', {
                    event: 'data-layer-event',
                    eventCategory: 'Wishlist interaction',
                    eventAction: button.data('isProductSet') ? 'Remove from wishlist - idea' : 'Remove from wishlist - product',
                    eventLabel: button.data('productName')
                });
            })
                .finally(() => {
                    button.unbusy();
                });
        }

        /**
         * @description Handle click on the button
         * @listens dom#click
         * @param button Target button
         */
        wishlistButtonClickHandler(button: IButtonWithIcon) {
            if (button.isActive()) {
                this.removeProductFromWishlist(button);
            } else {
                this.addToWishlist(button);
            }
        }

        /**
         * @description afterUpdateProduct handler
         * @param {{wishlistItems: []}} response Response object
         * @emits "product.updated"
         */
        afterUpdateProduct(response) {
            super.afterUpdateProduct(response);

            if (response.wishlistItems) {
                wishlistItemsMgr.updateWishlistItems(response.wishlistItems);
            }
        }

        /**
         * @description Update Product View
         * @param {{id: string, options: []}} product - Product object
         * @returns {void}
         */
        updateProductView(product) {
            super.updateProductView(product);
            this.renderWishListButton(product);
        }

        /**
         * @description Show error/success message
         * @param {string} msg - Message string
         * @param {boolean} [error=false] Error flag
         * @returns {void}
         */
        showWishlistMessage(msg, error = false) {
            const addToWishlistMsg = this.getById<ILabel>(this.prefs().addToWishlistMsg, (labelAddToWishlistMsg) => {
                return labelAddToWishlistMsg;
            });

            if (!addToWishlistMsg) {
                return;
            }

            if (error) {
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                addToWishlistMsg.setError(msg).show();
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                addToWishlistMsg.deactivate();
            } else {
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                addToWishlistMsg.setText(msg).show();
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                addToWishlistMsg.activate();
            }
        }

        /**
         * @description Hide wishlist error/success messages. Usually used when PDP variation updated.
         */
        hideWishlistMessage() {
            this.getById<ILabel>(this.prefs().addToWishlistMsg, (labelAddToWishlistMsg) => {
                labelAddToWishlistMsg.setText('').hide().deactivate();
            });
        }

        /**
         * @description Show error/success message
         * @param {button} addButton - Message string
         * @listens dom#click
         * @returns {Promise<object>} Promise object represents server response for product updating
         */
        addToWishlist(addButton) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'sfccData' does not exist on type 'Window... Remove this comment to see the full error message
            const addToWishlistUrl = window.sfccData.wishlistActionUrls?.addToWishlist;
            const options = this.prefs().productOptions;
            let customOptionValue = '';
            let optionId = '';
            let optionVal = '';

            if (options && options.length) {
                // TODO: This is a temporary solution.Implement support few options on product
                optionId = options[0].id;
                optionVal = options[0].selectedValueId;
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'getCustomOptionValue' does not exist on ... Remove this comment to see the full error message
                customOptionValue = this.getCustomOptionValue(options[0].id);
            }

            addButton.busy();
            this.getById<ILabel>(this.prefs().addToWishlistMsg, (labelAddToWishlistMsg) => labelAddToWishlistMsg.hide());

            return submitFormJson(addToWishlistUrl, {
                pid: this.prefs().pid || '',
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
                qty: this.prefs().selectedQuantity || '',
                optionId: optionId,
                optionVal: optionVal,
                customOptionValue: customOptionValue
            })
                .then(response => {
                    // @ts-expect-error ts-migrate(2322) FIXME: Define correct type for the response
                    this.handleSuccess(response);

                    return response;
                })
                .catch(() => {
                    this.showWishlistMessage(this.prefs().textNetworkError, true);
                })
                .finally(() => {
                    addButton.unbusy();
                });
        }

        /**
         * @description Handle success response
         * @param response - Response object
         */
        handleSuccess(response: responseType) {
            const addToWishlistBtn = <IButtonWithIcon> this.getById(this.prefs().addToWishlistBtn, (element) => element);

            if (!addToWishlistBtn) {
                return;
            }

            if (response.success) {
                this.activateWishListButton(addToWishlistBtn);
                // eslint-disable-next-line spellcheck/spell-checker
                // @ts-expect-error ts-migrate(2322) FIXME: Define correct type for the response
                const accessibilityAlert = this.prefs().accessibilityAlerts.addedtowishlist;

                /**
                 * @description Global event to show alert
                 * @event "alert.show"
                 */
                this.eventBus().emit(ALERT_EVENT, {
                    accessibilityAlert
                });
                this.eventBus().emit('product.moved.to.wishlist', {
                    isProductSet: addToWishlistBtn.data('isProductSet'),
                    productName: addToWishlistBtn.data('productName')
                });
            } else if (response.itemAlreadyExists) {
                this.deactivateWishListButton(addToWishlistBtn);

                if (this.prefs().showAlertIfProductInWishlist) {
                    /**
                     * @description Global event to show alert
                     * @event "alert.show"
                     */
                    this.eventBus().emit(ALERT_EVENT, {
                        accessibilityAlert: response.msg
                    });
                }
            }

            if (!this.prefs().addToWishlistHideTexts) {
                this.showWishlistMessage(response.msg, response.error);
            }

            wishlistItemsMgr.updateWishlistItems(response.wishlistItems);
            this.eventBus().emit('product.addedToWishlist', response.wishlistItems.length > 0);
            this.eventBus().emit(WISHLIST_UPDATE_EVENT, response.pid);
        }

        /**
         * @description Method to revert initial state for `Add to Wishlist` button and messages
         * Usually used when re-rendering PDP/QuickView after variation change
         *
         * @param product Product object
         * @param product.id Product id
         * @param product.options Product options
         */
        renderWishListButton(product: {id: string, options: []}): void {
            this.setPref('pid', product.id);
            wishlistItemsMgr.isItemInWishlist(product.id, product.options)
                .then((state: boolean) => {
                    this.changeStateWishlistButton(state);
                });
        }
    }

    return AddToWishlistMixin;
}
