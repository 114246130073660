/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputPassword').default>} BaseInputPassword
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Custom InputPassword implementation
 * @param {BaseInputPassword} BaseInputPassword Base InputPassword implementation
 * @returns {typeof InputPassword} Input Password class
 */
export default function (BaseInputPassword) {
    /**
     * @class InputPassword
     * @augments BaseInputPassword
     * @classdesc Custom Password input implementation.
     */
    class InputPassword extends BaseInputPassword {
        prefs() {
            return {
                dynamicHelpText: false,
                helpTextRef: 'helpText',
                definePasswordStrengthCheck: false,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            if (this.prefs().dynamicHelpText) {
                this.ref(this.prefs().helpTextRef).hide();
            }

            if (this.prefs().definePasswordStrengthCheck && !this.passwordEstimator) {
                import(/* webpackChunkName: 'zxcvbn' */'zxcvbn')
                    .then((zxcvbn) => {
                        this.passwordEstimator = zxcvbn.default;
                    });
            }
        }

        /**
         * @description Listener for `input` event.
         * Usually used to display Show/Hide mask button when entered needed amount of symbols into password field.
         * @listens dom#input
         * @param {RefElement} el event source element
         * @returns {void}
         */
        onPasswordInput(el) {
            super.onPasswordInput(el);

            if (this.prefs().dynamicHelpText) {
                this.ref(this.prefs().helpTextRef).show();
            }
        }

        /**
         * @description Handle click on button Show/Hide mask. Toggle type based on input type.
         * @listens dom#click
         * @returns {void}
         */
        toggleMask() {
            const input = this.ref('field');
            const inputType = input.attr('type');
            const showButton = this.ref('showButton');

            if (inputType === 'password') {
                input.attr('type', 'text');
                showButton.attr('aria-pressed', 'true');
            } else {
                input.attr('type', 'password');
                showButton.attr('aria-pressed', 'false');
            }
        }

        /**
         * @description checks password strength
         * @returns {boolean} result
         */
        checkPasswordStrength() {
            if (!this.passwordEstimator) {
                return false;
            }

            this.passwordStrengthScore = this.passwordEstimator(this.getValue()).score;

            return this.isStrongPassword();
        }

        /**
         * @description Verifies if entered password meets the strength requirements
         * @param {string} val - entered password
         * @returns {boolean} check result
         */
        validatePasswordChars(val) {
            const valChars = val.split('');
            const isCharsValid = this.checkLowerCaseAmount(valChars)
                && this.checkUpperCaseAmount(valChars)
                && this.checkNumberAmount(valChars)
                && this.checkSpecialCharsAmount(valChars);

            if (this.prefs().passwordStrengthEnabled) {
                return isCharsValid && this.isStrongPassword();
            } else if (isCharsValid) {
                return true;
            }

            return false;
        }

        /**
         * @description Verifies if entered password is valid and meets all password constraints
         * @returns {boolean} check result
         */
        isValid() {
            if (!super.isValid()) {
                return false;
            }

            if (this.widgetsToMatch && this.widgetsToMatch.length) {
                const invalidWidget = this.getInvalidCompareWithWidget();

                if (!invalidWidget) {
                    return this.validatePasswordChars(this.getValue());
                }
            }

            return true;
        }
    }

    return InputPassword;
}
