/**
 * @description Base InputTel implementation
 * @param MaskableInput Base widget for extending
 * @returns Input Tel class
 */
export default function (MaskableInput: ReturnType<typeof import('./MaskableInput').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputTel
     * @augments MaskableInput
     * @classdesc Input type Tel implementation. Represents input `tel` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * @property {string} data-widget - Widget name `inputTel`
     * @example <caption>Insertion of InputSelect inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.phone, pdict.addressFormOptions).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputPhone</caption>
     *   <div data-widget="inputTel" data-mask="0-000-000-0000"
     *       class="b-form_section m-required m-invalid"
     *       data-id="dwfrm_shipping_shippingAddress_addressFields_phone"
     *       data-validation-config="... validation config"
     *   >
     *       <label class="b-form_section-label" for="dwfrm_shipping_shippingAddress_addressFields_phone">
     *           <span class="b-form_section-required" aria-hidden="true">
     *               *
     *           </span>
     *           Phone Number
     *       </label>
     *   <input data-ref="field" id="dwfrm_shipping_shippingAddress_addressFields_phone"
     *       type="tel" class="b-input m-invalid" placeholder="" aria-describedby="dwfrm_shipping_shippingAddress_addressFields_phone-error"
     *       name="dwfrm_shipping_shippingAddress_addressFields_phone" required="" value="" maxlength="20"
     *       aria-required="true" minlength="5" autocomplete="tel" data-event-blur="validate"
     *   >
     *   <div role="alert" class="b-form_section-message" data-ref="errorFeedback" id="dwfrm_shipping_shippingAddress_addressFields_phone-error">This field is required.</div>
     *       <span id="dwfrm_shipping_shippingAddress_addressFields_phone-caption" class="b-form_section-caption">
     *           Example: 1-888-123-4567
     *       </span>
     *   </div>
     */
    class InputTel extends MaskableInput {
        /**
         * @description Overloaded `setValue` in order to trigger mask re-validation
         * @param {(string|number|undefined)} [newVal] - set this value to input
         * @param {(boolean|undefined)} [silently] - if set to `true` - input should not be validated against a new value
         * @returns {void}
         */
        setValue(newVal = '', silently = false) {
            super.setValue(newVal, silently);

            if (this.mask) {
                this.mask.unmaskedValue = String(newVal);
            }
        }

        /**
         * @description Get input value
         * @returns {string} - return input name
         */
        getValue() {
            const value = this.mask
                ? this.mask.value
                : super.getValue();

            return value.replace(/(^\+)|\D/g, '$1');
        }
    }

    return InputTel;
}
