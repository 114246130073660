/**
 * @typedef {ReturnType<typeof import('widgets/global/BackToTop').default>} BackToTopBase

/**
 * @description BackToTop widget
 * @param {BackToTopBase} BackToTopBase Base widget for extending
 * @returns {typeof BackToTop} BackToTop class
 */

export default function (BackToTopBase) {
    /**
     * @class BackToTop
     * @augments BackToTopBase
     */
    class BackToTop extends BackToTopBase {
        /**
     * @description Initialize widget logic
     * @returns {void}
     */
        doInit() {
            this.attachScrollListener();
            this.toggleButtonVisibility();
        }
    }

    return BackToTop;
}
