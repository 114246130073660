import { getCookie } from 'widgets/toolbox/cookie';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

/**
 * @typedef {typeof import('core/global/AccountHeaderLink').default} AccountHeaderLink
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof AccountHeaderLink} AccountHeaderLink
 */

export default function (Widget) {
    class AccountHeaderLink extends Widget {
        prefs() {
            return {
                getCustomerNameUrl: '',
                promtDelay: 2000,
                promtDuration: 10000,
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.getCustomerName()
                .then(name => {
                    if (localStorageWrapper.getItem('previous_sid') !== getCookie('sid')) {
                        localStorageWrapper.setItem('new_promt_session', '1');
                    }

                    localStorageWrapper.setItem('previous_sid', getCookie('sid'));

                    if (name) {
                        this.renderAccountHeader(name);
                        this.checkSavedBasket();
                        this.eventBus().on('product.added.to.cart', 'checkSavedBasket');
                        this.eventBus().on('cart.updated', 'checkSavedBasket');
                        this.eventBus().on('product.updated', 'checkSavedBasket');
                        this.eventBus().on('minicart.updated', 'checkSavedBasket');
                    }
                });
            this.eventBus().on('update.customer.name', 'saveCustomerName');
        }

        /**
         * @description Render Account Header link
         *
         * @param {string} name - Customer name
         */
        renderAccountHeader(name) {
            this.render('accountHeaderTemplate', { name }, this.ref('accountNameContainer'));
        }

        /**
        * @description Save save customer name to the local storage
        * @param {any} name - customer name
        */
        saveCustomerName(name = null) {
            localStorageWrapper.removeItem('customerName');
            localStorageWrapper.setItem('currentSid', getCookie('sid'));
            localStorageWrapper.setItem('customerName', JSON.stringify({ name }));
        }

        /**
         * @description Do call to get customer name from the server
         * @param {string}url - URL to get customer name
         * @returns {Promise<any>} Fetching result promise
         */
        loadCustomerNamePromise(url) {
            return getJSONByUrl(url)
                .then((result) => {
                    this.saveCustomerName(result.name);

                    return Promise.resolve(result.name);
                })
                // eslint-disable-next-line no-console
                .catch(error => console.log(error));
        }

        /**
         * @description Returns fetching result promise
         * @returns {Promise<object>} Promise result
         */
        getCustomerName() {
            return new Promise((resolve, reject) => {
                const customerName = localStorageWrapper.getItem('customerName');
                const customerData = customerName ? JSON.parse(customerName) : '';
                const isSameSession = localStorageWrapper.getItem('currentSid') === getCookie('sid');

                if (customerData && isSameSession) {
                    return resolve(customerData.name);
                }

                const url = this.prefs().getCustomerNameUrl;

                if (!url) {
                    return reject(new Error('no URL provided'));
                }

                this.loadCustomerName = this.loadCustomerNamePromise(url);

                return resolve(this.loadCustomerName);
            });
        }

        /**
         * @description Checks whether the saved cart prompt should be displayed during the next user session
         */
        checkSavedBasket() {
            try {
                const customerData = JSON.parse(localStorageWrapper.getItem('customerName'));
                const cartItemIds = JSON.parse(localStorageWrapper.getItem('cartItemIds'));

                if (customerData && customerData.name && cartItemIds && cartItemIds.length > 0) {
                    localStorageWrapper.setItem('saved_basket_promt', '1');
                } else {
                    localStorageWrapper.removeItem('saved_basket_promt');
                }
            } catch (e) {
                localStorageWrapper.removeItem('saved_basket_promt');
            }
        }

        /**
         * @description Check if login promt can be shown
         * @returns {boolean} Promise result
         */
        isLoginPromtAvailable() {
            return !window.sessionStorage.getItem('login_promt_shown') && document.documentElement.dataset.action !== 'Login-Show';
        }

        /**
         * @description Check if saved basket promt can be shown
         * @returns {boolean} Promise result
         */
        isSavedBasketPromtAvailable() {
            /**
            * Show a message when all of the following are true:
            * - this is a new user session
            * - the previous session was for a registered user with a saved basket
            * - this is not a login page
            */
            return localStorageWrapper.getItem('new_promt_session')
                && localStorageWrapper.getItem('saved_basket_promt')
                && document.documentElement.dataset.action !== 'Login-Show';
        }

        /**
        * @description Close login promt
        */
        closeLoginPromt() {
            this.has('loginPromt', loginPromt => {
                loginPromt.hide();
            });
        }
    }

    return AccountHeaderLink;
}
