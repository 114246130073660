import { isSmallView } from 'widgets/toolbox/viewtype';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Youtube VideoPlayer implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof VideoPlayerHTML5} VideoPlayer class
 */
export default function (Widget) {
    /**
     * @class VideoPlayer
     * @augments Widget
     */
    class VideoPlayerHTML5 extends Widget {
        prefs() {
            return {
                videoUrls: [],
                playingCSSClass: 'm-playing',
                ...super.prefs()
            };
        }

        init() {
            this.player = this.ref('player').get();
            this.viewType = '';

            this.renderSources();
            window.addEventListener('load', this.contentBoxWidth.bind(this));
            window.addEventListener('orientationchange', () => {
                setTimeout(this.contentBoxWidth.bind(this), 100);
            });
            window.addEventListener('orientationchange', this.renderSources.bind(this));
            this.eventBus().on('viewtype.change', 'contentBoxWidth');
            this.eventBus().on('viewtype.change', 'renderSources');
        }

        parseVideos() {
            let videoUrls = this.prefs().videoUrls;

            if (videoUrls.constructor.name === 'Object') {
                videoUrls = Object.values(videoUrls);
            }

            const videos = videoUrls.map((item) => {
                const obj = {};

                [obj.url, obj.device = 'desktop'] = item.split('#');
                obj.type = obj.url.split('.').pop();

                return obj;
            });

            return videos;
        }

        renderSources() {
            const videos = this.parseVideos();
            let sources = '';
            let viewtype = 'desktop';

            if (isSmallView() && (window.orientation === 0)) {
                viewtype = 'mobile';
            }

            if (this.viewType === viewtype) {
                return false;
            }

            this.viewType = viewtype;

            videos.forEach(item => {
                if (item.device === viewtype) {
                    sources += `<source src="${item.url}" type="video/${item.type}" />`;
                }
            });

            this.player.innerHTML = sources;

            this.player.load();
            this.player.play();

            return this;
        }

        /**
         * @description Start video
         */
        playPauseVideo() {
            if (!this.player) {
                return;
            }

            if (this.player.paused || this.player.ended) {
                this.player.play();
                this.ref('playButton').addClass(this.prefs().playingCSSClass);
            } else {
                this.player.pause();
                this.ref('playButton').removeClass(this.prefs().playingCSSClass);
            }
        }

        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.playPauseVideo();
                    preventEventActions = true;
                    break;
                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        contentBoxWidth() {
            const items = this.ref('self').get()?.querySelectorAll('.js-heading, .js-subheading');

            items?.forEach((item) => {
                if (item.parentElement) {
                    item.parentElement.style.width = '';
                    item.parentElement.style.width = Math.ceil(item.getBoundingClientRect().width) + 'px';
                }
            });
        }
    }

    return VideoPlayerHTML5;
}
