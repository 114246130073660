/**
 * @typedef {ReturnType<typeof import('widgets/global/ProcessButton').default>} BasicProcessButton
 */

/**
 * @description ProcessButton implementation
 * @param {BasicProcessButton} BasicProcessButton Base widget for extending
 * @returns {typeof ProcessButton} ProcessButton class
 */
export default function (BasicProcessButton) {
    /**
     * @class ProcessButton
     * @augments BasicProcessButton
     */
    class ProcessButton extends BasicProcessButton {
        prefs() {
            return {
                processFinishLabel: '',
                lockAfterFinish: false,
                ...super.prefs()
            };
        }

        /**
         * @description Enable button
         * @returns {void}
         */
        enable() {
            this.ref('self').prop('disabled', false);
        }

        /**
         * @description Disable button
         * @returns {void}
         */
        disable() {
            this.ref('self').prop('disabled', 'disabled');
        }

        /**
         * @description Stop Progress
         * @param {string} val - Value to set as a text of the Button
         */
        stopProcess(val) {
            this.unbusy();
            this.setText(val || this.prefs().processFinishLabel || this.currentText);
            this.ref('self').removeClass(this.prefs().processingClass);

            if (this.prefs().lockAfterFinish) {
                this.disable();
            }
        }
    }

    return ProcessButton;
}
