/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */
/* sonar-ignore-start */

import WidgetsMgr from 'widgets/widgetsMgr';

const hasContext = (ctx) => window.contexts.includes(ctx);

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('datasheets'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'datasheets.widgets' */ './widgets/forms/ajax/DatasheetsForm').then(d => d.default)
    ]).then(deps => {
        const [
            DatasheetsForm
        ] = deps;

        return {
            listId: 'datasheets.core',
            widgetsDefinition: () => [
                ['datasheetsForm', DatasheetsForm, 'ajaxform']
            ]
        };
    })
});

/* sonar-ignore-end */
