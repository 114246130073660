/**
 * @typedef {ReturnType<typeof import('widgets/forms/Form').default>} BaseForm
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description IdeasSearchForm implementation
 * @param {BaseForm} BaseForm Base widget for extending
 * @returns {typeof IdeasSearchForm} IdeasSearchForm class
 */
export default function (BaseForm) {
    /**
     * @class IdeasSearchForm
     * @augments BaseForm
     */
    class IdeasSearchForm extends BaseForm {
        /**
         * @description Handle submit Form
         * @emits BasicForm#submit
         * @param {refElement} _el event source element
         * @param {(Event|undefined)} event event instance if DOM event
         * @returns {void}
         */
        handleSubmit(_el, event) {
            const ideasSearchInput = this.getById('ideasSearchInput', searchInput => searchInput);

            if (ideasSearchInput && ideasSearchInput.getValue() === '') {
                ideasSearchInput.focus();

                event?.preventDefault();

                return;
            }

            super.handleSubmit(_el, event);
        }
    }

    return IdeasSearchForm;
}
