import { setCookie } from 'widgets/toolbox/cookie';
import { interval } from 'widgets/toolbox/util';

const DEFAULT_LOADING_COUNT = 10;
const DEFAULT_INTERVAL_PERIOD = 100;
const TEST_RECORD_ID = 'C424039';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Exponea Segmentation implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof ExponeaSegmentation} ExponeaSegmentation class
 */
export default function (Widget) {
    /**
     * @class ExponeaSegmentation
     * @augments Widget
     */
    class ExponeaSegmentation extends Widget {
        prefs() {
            return {
                exponeaCookieName: '',
                exponeaCookieLifeTime: 360,
                exponeaSegmentationDebugMode: false,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize ExponeaSegmentation
         * @returns {void}
         */
        init() {
            super.init();

            this.exponeaDimensionsLoadCount = DEFAULT_LOADING_COUNT;
            this.exponeaDimensionsInitialize = this.initExponeaDimensions();
        }

        /**
         * @description Checks that segmentation data initialized
         * @returns {boolean} result
         */
        get exponeaSegmentation() {
            return window.exponea && window.exponea.dimensions;
        }

        /**
         * @description inits exponea dimensions object
         * @returns {Function} interval callback
         */
        initExponeaDimensions() {
            return interval(() => {
                if (this.exponeaSegmentation || !this.exponeaDimensionsLoadCount) {
                    if (this.prefs().exponeaSegmentationDebugMode) {
                        this.initDebugSegmentationData();
                    }

                    this.initExponeaSegmentation();
                    this.exponeaDimensionsFinalize();
                }

                this.exponeaDimensionsLoadCount -= 1;
            }, DEFAULT_INTERVAL_PERIOD);
        }

        /**
         * @description finalizes exponea dimension initializing
         */
        exponeaDimensionsFinalize() {
            if (this.exponeaDimensionsInitialize) {
                this.exponeaDimensionsInitialize();
                this.exponeaDimensionsInitialize = undefined;
            }
        }

        /**
         * @description Inits exponea segmentation cookie
         */
        initExponeaSegmentation() {
            if (!this.exponeaSegmentation) {
                setCookie(
                    this.prefs().exponeaCookieName,
                    '',
                    0,
                    true
                );

                return;
            }

            const segmentationData = { ...window.exponea.dimensions };

            var category = segmentationData.sfcc_dominant_category;

            if (category !== 'None') {
                if (segmentationData) {
                    setCookie(
                        this.prefs().exponeaCookieName,
                        encodeURIComponent(JSON.stringify(segmentationData)),
                        this.prefs().exponeaCookieLifeTime,
                        true
                    );
                }
            }
        }

        initDebugSegmentationData() {
            window.exponea.identify({ registered: TEST_RECORD_ID });
        }
    }

    return ExponeaSegmentation;
}
