let resolveYTPlayerFn = null;
let initialized = false;

const initializationPromise = new Promise((resolve) => {
    resolveYTPlayerFn = resolve;
});

window.onYouTubeIframeAPIReady = () => {
    resolveYTPlayerFn();
};

export function initYoutubePlayerApi() {
    if (!initialized) {
        const youtubePlayerApiUrl = 'https://www.youtube.com/iframe_api';
        const scriptHadBeingInited = Array.from(document.querySelectorAll('script')).some((script) => {
            return script.src === youtubePlayerApiUrl;
        });

        if (!scriptHadBeingInited) {
            const tag = document.createElement('script');
            tag.src = youtubePlayerApiUrl;
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
        }

        initialized = true;
    }

    return initializationPromise;
}
