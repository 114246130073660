/* eslint-disable spellcheck/spell-checker */

import { submitCORS } from 'core/toolbox/ajax';
import { appendParamsToUrl } from 'widgets/toolbox/util';
import { appendParamToURL } from 'core/toolbox/util';
import { getCookie } from 'widgets/toolbox/cookie';

/**
 * @typedef {ReturnType<typeof import('widgets/search/SearchBox').default>} SearchBoxBase
 */

const PRODUCT_TYPE_PRODUCT = 'product';
const PRODUCT_TYPE_IDEA = 'idea';

/**
 * @description Get stars rating for product
 * @param {number} productRating raw product rating
 * @returns {Array<object>} stars product rating
 */
function getStarsProductRating(productRating) {
    return [1, 2, 3, 4, 5].map((currentValue, index) => {
        const starObj = {
            position: index > 0 ? index * 20 : 0
        };

        if (productRating >= currentValue) {
            starObj.isFull = true;
        } else if ((productRating % 1 > 0) && (Math.ceil(productRating) >= currentValue)) {
            starObj.isHalf = true;
        } else {
            starObj.isEmpty = true;
        }

        return starObj;
    });
}

/**
 * @description SearchBox widget
 * @param {SearchBoxBase} SearchBoxBase Base widget for extending
 * @returns {typeof SearchBox} SearchBox class
 */

export default function (SearchBoxBase) {
    /**
     * @class SearchBox
     * @augments SearchBoxBase
     */
    class SearchBox extends SearchBoxBase {
        prefs() {
            return {
                bloomreachConfig: {
                    suggestionsURL: '',
                    productsURL: '',
                    accountId: '',
                    authKey: '',
                    catalogViews: ''
                },
                phrasesCount: 5,
                productsCount: 4,
                ideasCount: 4,
                viewAllProductsLink: '',
                viewAllIdeasLink: '',
                currencySymbol: null,
                priceFree: 'FREE',
                ...super.prefs()
            };
        }

        /**
         * @description Init widget method
         * @returns {void}
         */
        init() {
            super.init();

            this.suggestions = null;
            this.products = null;
            this.posinset = -1;
            this.posinsetAllProductsLink = 0;
            this.posinsetAllIdeasLink = 0;
        }

        /**
         * @description Send request to the server
         * overloaded method to get product suggestions from Bloomreach
         * @param {string} query - requested search query
         * @returns {Promise<void>} Promise object represents server response for search suggestions
         */
        getSuggestions(query) {
            this.getBloomreachSuggestions(query);

            return Promise.resolve();
        }

        /**
         * @description Returns common data for Bloomreach requests
         * @param {object} bloomreachConfig Bloomreach config data
         * @param {string} query search query
         * @returns {object} common data for Bloomreach requests
         */
        getBloomreachCommonRequestParams(bloomreachConfig, query) {
            return {
                account_id: bloomreachConfig.accountId,
                auth_key: bloomreachConfig.authKey,
                _br_uid_2: getCookie('_br_uid_2'),
                url: window.location.href,
                ref_url: document.referrer,
                request_id: Date.now().toString(),
                q: query
            };
        }

        /**
         * @description Performs CORS request to Bloomreach to get search suggestions
         * @param {string} query query string
         * @returns {void}
         */
        getBloomreachSuggestions(query) {
            if (this.prefs().bloomreachConfig.suggestionsURL) {
                const bloomreachConfig = this.prefs().bloomreachConfig;
                const bloomreachURL = appendParamsToUrl(bloomreachConfig.suggestionsURL, {
                    ...this.getBloomreachCommonRequestParams(bloomreachConfig, query),
                    catalog_views: this.prefs().bloomreachConfig.catalogViews,
                    request_type: 'suggest'
                });

                submitCORS(bloomreachURL)
                    .then(response => {
                        this.suggestions = response;
                        this.getBloomreachProducts(query);
                    })
                    .catch(() => {
                        this.getBloomreachProducts(query);
                    });
            }
        }

        /**
         * @description Performs CORS request to Bloomreach to get product suggestions.
         * Only products `ideas` should be in scope
         * @param {string} query query string
         * @returns {void}
         */
        getBloomreachProducts(query) {
            if (this.prefs().bloomreachConfig.productsURL) {
                const bloomreachConfig = this.prefs().bloomreachConfig;
                let bloomreachURL = appendParamsToUrl(bloomreachConfig.productsURL, {
                    ...this.getBloomreachCommonRequestParams(bloomreachConfig, query),
                    search_type: 'keyword',
                    request_type: 'search',
                    rows: 10,
                    start: 0,
                    fl: 'pid,thumb_image,title,url'
                });

                bloomreachURL = appendParamToURL(bloomreachURL, 'fq', 'isSFCC:"Yes"', true);
                bloomreachURL = appendParamToURL(bloomreachURL, 'fq', 'isidea:"Yes"', true);

                submitCORS(bloomreachURL)
                    .then(response => {
                        this.products = response;
                        this.renderSuggestionsAndProducts(query);
                    })
                    .catch(() => {
                        this.renderSuggestionsAndProducts(query);
                    });
            }
        }

        /**
         * @description Get suggestion search phrases from Bloomreach response
         * @returns {Array<Object>} search phrases
         */
        getPhrasesFromResponse() {
            let phrases = [];

            let responsePhrases = this.suggestions?.suggestionGroups;

            if (responsePhrases && responsePhrases.length) {
                responsePhrases = responsePhrases[0]?.querySuggestions;
            }

            if (responsePhrases && responsePhrases.length) {
                responsePhrases.splice(this.prefs().phrasesCount);

                phrases = responsePhrases.map((querySuggestion) => {
                    this.posinset += 1;

                    return {
                        posinset: this.posinset,
                        value: querySuggestion.query
                    };
                });
            }

            return phrases;
        }

        /**
         * @description Get either products or ideas from Bloomreach response
         * @param {string} productType what to get - either products or ideas
         * @returns {Array<Object>} search phrases
         */
        /* eslint-disable sonarjs/cognitive-complexity */
        getProductsFromResponse(productType = PRODUCT_TYPE_PRODUCT) {
            let products = [];
            let responseProducts = [];

            if (productType === PRODUCT_TYPE_PRODUCT) {
                responseProducts = this.suggestions?.suggestionGroups;

                if (responseProducts && responseProducts.length) {
                    responseProducts = responseProducts[0]?.searchSuggestions;
                }
            } else if (productType === PRODUCT_TYPE_IDEA) {
                responseProducts = this.products?.response?.docs;
            }

            if (responseProducts && responseProducts.length) {
                if (productType === PRODUCT_TYPE_PRODUCT) {
                    responseProducts = responseProducts.filter(responseProduct => !responseProduct.isidea);
                }

                responseProducts.splice(
                    productType === PRODUCT_TYPE_PRODUCT
                        ? this.prefs().productsCount : this.prefs().ideasCount
                );

                products = responseProducts.map((responseProduct) => {
                    this.posinset += 1;

                    let listPrice = '';
                    let salesPrice = '';

                    if (this.prefs().currencySymbol) {
                        listPrice = this.prefs().currencySymbol + (parseFloat(responseProduct.price) || 0).toFixed(2);
                        salesPrice = responseProduct.price === responseProduct.sale_price
                            ? '' : this.prefs().currencySymbol + (parseFloat(responseProduct.sale_price) || 0).toFixed(2);
                    }

                    if (responseProduct.price === 0.01) {
                        listPrice = this.prefs().priceFree;
                    }

                    const ratings = getStarsProductRating(responseProduct.BazaarvoiceStarRating || 0);
                    const reviewsCount = responseProduct.NoBazaarvoiceReviews || 0;

                    return {
                        posinset: this.posinset,
                        productName: responseProduct.title,
                        url: responseProduct.sfcc_deeplink || responseProduct.url,
                        id: responseProduct.pid,
                        gtmInfoStr: '',
                        image: {
                            url: responseProduct.sfcc_image || responseProduct.thumb_image,
                            alt: responseProduct.title
                        },
                        product: {
                            rating: responseProduct.NoBazaarvoiceReviews || 0
                        },
                        ratings,
                        listPrice,
                        salesPrice,
                        reviewsCount
                    };
                });

                if (productType === PRODUCT_TYPE_PRODUCT && products.length) {
                    this.posinset += 1;
                    this.posinsetAllProductsLink = this.posinset;
                } else if (productType === PRODUCT_TYPE_IDEA && products.length) {
                    this.posinset += 1;
                    this.posinsetAllIdeasLink = this.posinset;
                }
            }

            return products;
        }
        /* eslint-enable sonarjs/cognitive-complexity */

        /**
         * @description Fill in `suggestions` with phrases, products and ideas and renders result
         * @param {string} query search query entered by user
         * @returns {void}
         */
        renderSuggestionsAndProducts(query) {
            const suggestions = {
                key: query,
                phrases: this.getPhrasesFromResponse(),
                products: this.getProductsFromResponse(PRODUCT_TYPE_PRODUCT),
                ideas: this.getProductsFromResponse(PRODUCT_TYPE_IDEA),
                viewAllProductsLink: this.prefs().viewAllProductsLink.replace('__q__', query),
                viewAllIdeasLink: this.prefs().viewAllIdeasLink.replace('__q__', query),
                posinsetAllProductsLink: this.posinsetAllProductsLink,
                posinsetAllIdeasLink: this.posinsetAllIdeasLink
            };

            let totalCount = ['phrases', 'products', 'ideas'].reduce((acc, item) => {
                acc += suggestions[item].length;

                return acc;
            }, 0);

            if (suggestions.products.length) {
                totalCount++;
            }

            if (suggestions.ideas.length) {
                totalCount++;
            }

            this.renderResult(suggestions).then(() => {
                this.afterSuggestionsUpdate(query, totalCount);
            });
        }

        /**
         * @description Executes when user clicks on product details link in the search box results.
         * Usually used by analytics etc.
         * @emits "gtm.click.event"
         * @param {object} el - DOM element
         */
        onProductLinkClick(el) {
            this.eventBus().emit('gtm.click.event', {
                event: 'data-layer-event',
                eventCategory: 'Search',
                eventAction: 'Search suggestions',
                eventLabel: el.data('gtmEventLabel'),
                eventSublabel: el.data('gtmEventSublabel')
            });
        }

        /**
         * @description Selects combobox dropdown item
         * @param {object} selectedItem - item, selected by user
         * @returns {void}
         */
        selectItem(selectedItem) {
            super.selectItem(selectedItem);

            this.eventBus().emit('select.search.suggestion.item', selectedItem);
        }
    }

    return SearchBox;
}
