import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputTel').default>} BaseInputTel
 */

/**
 * @description Custom InputTel implementation
 * @param {BaseInputTel} BaseInputTel Base InputTel implementation
 * @returns {typeof InputTel} Input Tel class
 */
export default function (BaseInputTel) {
    /**
     * @class InputTel
     * @augments BaseInputTel
     * @classdesc Custom Tel input implementation.
     */
    class InputTel extends BaseInputTel {
        /**
         * @description Process and validate phone input
         * @param {object} el - DOM element
         * @param {object} event - Current event
         * @returns {void}
         */
        onInput(el, event) {
            timeout(() => {
                super.onInput(el, event);
            }, 100);
        }
    }

    return InputTel;
}
