/**
 * @typedef {ReturnType<typeof import('widgets/product/ProductTile').default>} BaseProductTile
 */

/**
 * @description Base ProductTile implementation
 * @param {BaseProductTile} BaseProductTile Base widget for extending
 * @returns {typeof ProductTile} ProductTile widget
 */
export default function (BaseProductTile) {
    /**
     * @class ProductTile
     * @augments BaseProductTile
     */
    class ProductTile extends BaseProductTile {
        /**
         * @description Trigger Quick View popup for this product tile
         * @returns {void}
         */
        triggerQuickView() {
            this.has('quickViewButton', quickViewButton => this.showQuickView(quickViewButton));
        }

        onProductLinkClick(el, event) {
            if (el.data('isProductSet')) {
                this.eventBus().emit('gtm.click.event', {
                    event: 'data-layer-event',
                    eventCategory: 'Search',
                    eventAction: 'Ideas search',
                    eventLabel: 'Suggested Search',
                    eventSublabel: el.data('gtmEventSublabel')
                });
            } else {
                super.onProductLinkClick(el, event);
            }

            if (this.prefs().gridItem) {
                window.sessionStorage.setItem('anchorTileID', String(this.id));
                window.sessionStorage.setItem('anchorTileOffset', String(this.ref('self').get()?.getBoundingClientRect().top));
            }
        }

        showQuickView(button) {
            /**
             * @description Event dispatched, when Global Modal should be triggered
             * @event module:events#dialogshow
             */
            this.eventBus().emit('dialogshow', {
                url: button.data('url'),
                wrapperClasses: button.data('wrapperClasses'),
                windowClasses: button.data('windowClasses'),
                headerClasses: button.data('headerClasses'),
                bodyClasses: button.data('bodyClasses'),
                ariaLabel: this.prefs().productName,
                attributes: {
                    'data-tau-unique': 'quick_view_dialog'
                }
            });

            this.onModalShow();
        }
    }

    return ProductTile;
}
