/* eslint-disable max-len */
/* eslint-disable indent */
const hasContext = (context: string) => window.contexts.includes(context);

import widgetsMgr from 'widgets/widgetsMgr';
import VideoExt from './widgets/page_designer/VideoExt';
import AnimationOnScroll from './widgets/page_designer/AnimationOnScroll';
import CtaExt from './widgets/page_designer/CtaExt';
import HotSpot from './widgets/page_designer/HotSpot';
import CarouselSimple from './widgets/page_designer/CarouselSimple';
import ProgressBar from './widgets/page_designer/ProgressBar';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('page_designer'),

    load: () => Promise.all([
        import(/* webpackChunkName: 'global.page_designer' */'./widgets/page_designer/EinsteinCarouselLoader').then(d => d.default)
    ]).then(deps => {
        const [EinsteinCarouselLoader] = deps;

        return {
            listId: 'global.page_designer',
            widgetsDefinition: () => [
                ['einsteinCarouselLoader', EinsteinCarouselLoader]
            ]
        };
    })
});

widgetsMgr.addWidgetsList('page_designer.global.core', () => [
    ['videoExt', VideoExt],
    ['animationOnScroll', AnimationOnScroll],
    ['ctaExt', CtaExt],
    ['hotSpot', HotSpot],
    ['carouselSimple', CarouselSimple, 'carousel'],
    ['progressBar', ProgressBar]
]);

// widgetsMgr.asyncLists.push({
//     getLoadingFlag: () => hasContext('page_designer'),
//     load: () => Promise.all([
//         import(/* webpackChunkName: 'extended.page_designer' */ './widgets/pagedesigner/YourWidget').then(d => d.default)
//     ]).then(deps => {
//         const [YourWidget] = deps;

//         return {
//             listId: 'extended.page_designer',
//             widgetsDefinition: () => [
//                 ['yourWidget', YourWidget]
//             ]
//         };
//     })
// });

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('page_designer'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'extended.page_designer' */ 'widgets/search/NoSearchResultsForm').then(d => d.default)
    ]).then(deps => {
        const [
            NoSearchResultsForm
        ] = deps;

        return {
            listId: 'extended.page_designer',
            widgetsDefinition: () => [
                ['noSearchResultsForm', NoSearchResultsForm, 'basicForm']
            ]
        };
    })
});
