/**
 * @typedef {ReturnType<typeof import('widgets/global/ContextLoader').default>} BaseContextLoader
 */

/**
 * @param {BaseContextLoader} BaseContextLoader Base widget for extending
 * @returns {typeof ContextLoader} ContextLoader class
 */

export default function (BaseContextLoader) {
    /**
     * @category widgets
     * @subcategory global
     * @class ContextLoader
     * @augments Widget
     */
    class ContextLoader extends BaseContextLoader {
        loaded = false;

        observer: IntersectionObserver | undefined;

        init() {
            this.loaded = this.prefs().loaded;

            if (!this.prefs().context && !this.prefs().inited) {
                throw new Error('Context must be initialized!');
            } else {
                this.setPref('inited', true);
            }

            if (this.prefs().initOnLoad) {
                this.loadContext();
            } else if (this.prefs().initOnViewport) {
                this.observer = new IntersectionObserver(([entry]) => this.onIntersection(entry), {
                    threshold: 0.01, // 1% of element became visible
                    rootMargin: '200px' // add margin around viewport
                });
                const widgetElement = this.ref('self').get();

                if (widgetElement) {
                    this.observer.observe(widgetElement); // start observing widget's DOM entry point

                    this.onDestroy(() => {
                        if (this.observer) {
                            this.observer.unobserve(widgetElement);
                            this.observer.disconnect();
                            this.observer = undefined;
                        }
                    });
                }
            }
        }
    }

    return ContextLoader;
}
