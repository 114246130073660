/**
 * @typedef {ReturnType<typeof import('widgets/product/AddToCartMixin').default>} BaseAddToCartMixin
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/ProcessButton').default>>} processButton
 * @typedef {InstanceType<ReturnType<typeof import('widgets/product/Availability').default>>} availability
 */
import { submitFormJson, getJSONByUrl } from 'widgets/toolbox/ajax';
import { getCookie } from 'widgets/toolbox/cookie';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

/**
 * @description Base AddToCartMixin implementation
 * <br>This class is not intended to have a separate DOM representation.
 * @param BaseAddToCartMixin Base widget for extending
 * @returns Add to Cart mixin widget
 */
export default function (BaseAddToCartMixin) {
    /**
     * @class AddToCartMixin
     * @augments BaseAddToCartMixin
     */
    class AddToCartMixin extends BaseAddToCartMixin {
        private loadCartItemsPromise: Promise<void> | undefined;

        prefs() {
            return {
                isCustomerSubscribed: false,
                isSubscriptionProduct: false,
                getCartItemIdsUrl: '',
                geOperated: false,
                plpPdpElement: false,
                bundleTile: false,
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         * @returns {void}
         */
        init() {
            super.init();

            if (this.prefs().isSubscriptionProduct && !this.prefs().isCustomerSubscribed) {
                this.isItemInCart(this.prefs().pid)
                    .then((state: boolean) => {
                        this.changeStateAddToCartButton(state);
                    });
            }

            if (this.prefs().isSubscriptionProduct && this.prefs().geOperated) {
                this.disableAddToCartButton(false);
            }
        }

        /**
         * @description Hides add to cart button if the product is out of stock and notify me when available functionality is enabled
         * @returns {void}
         */
        showBackInStockButton() {
            if (this.prefs().backInStockSubscriptionEnable) {
                this.getById('availability', (/** @type {availability} */availability) => {
                    availability.ref(this.prefs().backInStockBtn).show();
                });
            }
        }

        /**
         * @description Allows to toggle action buttons on PDP based on the received state
         * @param {RefElement} addToCartBtn Add to cart button
         * @param {boolean} state Indicated toggle state
         */
        toggleActionButtons(addToCartBtn, state = false) {
            if (state) {
                addToCartBtn.hide();
            } else {
                addToCartBtn.show();
            }
        }

        /**
         * @description Renders Add to Cart button
         * @param {object} addToCartButton add to cart button
         * @param {object} product productModel
         * @returns {void}
         */
        renderAddToCartButton(addToCartButton: any, product: any) {
            if (!product.available && product.availability.isOutOfStock) {
                addToCartButton.setPref('isOutOfStock', true);

                addToCartButton.setText(this.prefs().outOfStockLabel);

                addToCartButton.disable();
            } else if (product.availability.isReachedLimit) {
                this.getById(this.prefs().addToCartMsg, (addToCartMsg: any) => {
                    const msg = product.availability.messages[0];
                    const error = true;

                    addToCartMsg.render(
                        'template',
                        { msg, error },
                        addToCartMsg.ref('container')
                    ).then(() => addToCartMsg.show());
                });
                addToCartButton.setPref('isOutOfStock', true);

                addToCartButton.setText(this.getTextForLabel());

                addToCartButton.disable();
                this.toggleActionButtons(addToCartButton);
            } else if (product?.pdpAvailability?.disableA2CButton) {
                this.disableAddToCartButton();
            } else if (product.eProductData?.shippingAllowed === false) {
                addToCartButton.disable();
                addToCartButton.setText(this.prefs().unavailableShipmentLabel);
            } else {
                addToCartButton.setPref('isOutOfStock', false);
                addToCartButton.setPref('isSelectStore', false);
                addToCartButton.removeClass('m-store');

                addToCartButton.setText(this.getTextForLabel());

                addToCartButton.enable();
                this.toggleActionButtons(addToCartButton);
            }
        }

        /**
         * @description Render Add to Cart button
         * @param {object} product productModel
         * @returns {void}
         */
        renderAddToCart(product: any) {
            this.getById('addToCart', (addToCartBtn: any) => {
                this.renderAddToCartButton(addToCartBtn, product);
            });

            this.getById('addToCartSticky', (addToCartBtn: any) => {
                this.renderAddToCartButton(addToCartBtn, product);
            });
        }

        /**
         * @description Get label for button
         * @returns {string} label
         */
        getTextForLabel() {
            return this.prefs()[this.prefs().update ? 'updateLabel' : 'addToCartLabel'];
        }

        /**
         * @description Disable Add to cart Button
         * @param {boolean} showUnavailableLabel if we need to show unavailable label
         * @returns {void}
         */
        disableAddToCartButton(showUnavailableLabel = true) {
            ['addToCart', 'addToCartSticky'].forEach(btnId => {
                this.getById(btnId, (/** @type {processButton} */btn) => {
                    btn.disable();

                    if (showUnavailableLabel) {
                        const unavailableLabel = this.prefs().unavailableLabel;

                        if (unavailableLabel) {
                            btn.setText(unavailableLabel);
                        }
                    }
                });
            });
        }

        /**
         * @description Show message
         * @param {string} msg Message
         * @param {boolean} error Error flag
         */
        showCartMessage(msg, error = false) {
            super.showCartMessage(msg, error);

            const addToCartSticky = this.getById(this.prefs().addToCartMsgSticky, (addToCartMsgSticky :any) => addToCartMsgSticky);

            if (addToCartSticky) {
                addToCartSticky.render('template', { msg, error }, addToCartSticky.ref('container')).then(() => addToCartSticky.show());
            } else {
                this.eventBus().emit('alert.show', {
                    accessibilityAlert: msg
                });
            }
        }

        /**
         * @description Handles response from server once product added to cart
         * @param {object} response Add Product response
         * @returns {void}
         */
        postAddProduct(response) {
            if (response && response.redirectUrl) {
                window.location.assign(response.redirectUrl);

                return;
            }

            if (!response.error && response.cart && response.wishlistModel) {
                this.eventBus().emit('update.wishlist.items', response.wishlistModel);
                this.saveCartItems(response.cart.itemsIds);
            }

            super.postAddProduct(response);

            if (response && response.availability) {
                this.postAddProductAvailability(response);
            }
        }

        /**
         * @description Manages post add product availability information.
         * Product might not be available in add to cart moment, so backend returns us updated availability
         * object and this information should be reflected in availability section etc.
         * @param {object} response server response after add to cart action
         * @returns {void}
         */
        postAddProductAvailability(response) {
            if (
                !response.availability.available
                && !(response?.availability?.availableCC || response?.availability?.availableStore)
            ) {
                this.disableAddToCartButton(false);
            }

            const availability = this.getById('availability', (/** @type {availability} */availabilityLabel) => availabilityLabel);

            if (!availability) {
                return;
            }

            let pdpAvailability = availability.getAvailability() || {};

            if (typeof pdpAvailability === 'string') {
                pdpAvailability = JSON.parse(pdpAvailability);
            }

            Object.keys(pdpAvailability || {}).forEach(key => {
                if (key in response.availability) {
                    pdpAvailability[key] = response.availability[key];
                } else if (!response.availability.preserveKeys) {
                    delete pdpAvailability[key];
                }
            });

            Object.keys(response.availability || {}).forEach(key => {
                if (!(key in pdpAvailability)) {
                    pdpAvailability[key] = response.availability[key];
                }
            });

            availability.setAvailability(pdpAvailability);

            const product = {
                availability: response.availability,
                pdpAvailability
            };

            if (product?.pdpAvailability?.disableA2CButton) {
                this.disableAddToCartButton(false);
            }

            this.callIfExists('renderAvailability', product);
        }

        /**
         * @description Handles add to cart button click
         * @param {processButton} button Add Product response
         * @returns {Promise<object|null>} Promise object represents server response for session continuation
         */
        addToCart(button) {
            return this.checkRequestsAndAddToCart(button);
        }

        /**
         * @description Check active request to changing attributes then add to cart button click
         * @param {processButton} button Add Product response
         * @returns {Promise<object|null>} Promise object represents server response for session continuation
         */
        checkRequestsAndAddToCart(button) {
            if (!this.changeAttributeRequests || !this.changeAttributeRequests.length) {
                return this.addProductToCart(button);
            }

            Promise.all(this.changeAttributeRequests.map(req => req.promise)).then(() => {
                return this.addProductToCart(button);
            });

            return null;
        }

        /**
         * @description Update Product View
         * @param {any} product productModel
         */
        updateProductQuantityView(product) {
            super.updateProductQuantityView(product);
            this.updateBackInStockState(true, false);
            // Hide backInStock content on update product view

            this.hideContent();
            this.renderAddToCart(product);
        }

        /**
         * @description Add Linked Subscription data to request
         * @param {object} params request
         */
        addLinkedSubscriptionObj(params) {
            this.eachChild((child) => {
                if (child.prefs().isLinkedSubscription) {
                    child.getById('selectSubscriptionProduct', (subscriptionProduct) => {
                        if (subscriptionProduct.getValue() === 'on') {
                            params.subscriptionPidObj = JSON.stringify({
                                pid: child.prefs().linkedSubscriptionId,
                                qty: 1
                            });
                        }
                    });
                }
            });
        }

        /**
         * @description Handles add to cart button click
         * @param {processButton} button Add Product response
         * @returns {Promise<object|null>} Promise object represents server response for session continuation
         */
        addProductToCart(button) {
            if (this.prefs().processingRequest) {
                return Promise.resolve(null);
            }

            if (!this.prefs().readyToOrder) {
                this.showCartMessage(this.prefs().textSelectOptions, true);

                return Promise.resolve(null);
            }

            // @ ts-expect-error ts-migrate(2339) FIXME: Property 'stock' does not exist on type 'AddToCart... Remove this comment to see the full error message
            if (this.stock && this.stock < this.prefs().selectedQuantity) {
                this.showCartMessage(this.prefs().textStockLimit, true);

                return Promise.resolve(null);
            }

            const selectedOptions = this.getSelectedProductOptions();
            const isSelectedOptionsValid = this.validateProductOptions(selectedOptions);

            if (!isSelectedOptionsValid) {
                return Promise.resolve(null);
            }

            this.setPref('processingRequest', true);
            button.startProcess();

            this.showProgressBar();

            const addToCartBtnPrefs = button.prefs();
            const amount = parseInt(addToCartBtnPrefs.amount, 10);

            const params = {
                pid: this.prefs().currentProductId || addToCartBtnPrefs.pid,
                quantity: this.prefs().selectedQuantity || addToCartBtnPrefs.selectedQuantity,
                populateEVoucherData: addToCartBtnPrefs.populateEVoucherData,
                amount: !Number.isNaN(amount) && amount > 0 ? amount.toString() : '',
                options: JSON.stringify(selectedOptions),
                childProducts: this.prepareChildProducts(this.prefs().bundledProducts)
            };

            if (this.prefs().plpPdpElement || this.prefs().bundleTile) {
                this.getById('quantity', (quantity) => {
                    params.quantity = quantity.currentValue;
                });
            }

            this.addLinkedSubscriptionObj(params);

            return submitFormJson(addToCartBtnPrefs.addToCartUrl, params)
                .then((response) => {
                    this.setPref('processingRequest', false);
                    this.eventBus().emit('buy.again.added.to.cart', button);
                    response.isBuyAgainProduct = button.prefs().isBuyAgainProduct;
                    button.stopProcess();
                    this.hideProgressBar();

                    this.postAddProduct(response);

                    return response;
                })
                .catch(() => {
                    this.setPref('processingRequest', false);
                    button.stopProcess();
                    this.hideProgressBar();

                    this.showCartMessage(this.prefs().textNetworkError, true);
                });
        }

        /**
        * @description Set Cart items to the local storage
        * @param {object} cartItemIds - Object contains items in the Cart
        */
        saveCartItems(cartItemIds) {
            localStorageWrapper.removeItem('cartItemIds');
            localStorageWrapper.setItem('currentSid', getCookie('sid'));
            localStorageWrapper.setItem('cartItemIds', JSON.stringify(cartItemIds));
        }

        /**
         * @description Do call to get Cart items from the server
         * @param url URL to get items in the cart
         * @returns Fetching result promise
         */
        loadCartItems(url:string): Promise<void | Record<string, unknown>> {
            return getJSONByUrl(url)
                .then((result) => {
                    this.saveCartItems(result.cartItemIds);

                    return Promise.resolve(result);
                })
                // eslint-disable-next-line no-console
                .catch(error => console.log(error));
        }

        /**
         * @description Check if product in the Cart
         * @param productId Target product id
         * @returns Result
         */
        isItemInCart(productId: string): Promise<boolean> {
            return this.getCartItemIds()
                .then((response) => {
                    let result = false;

                    if (!response) {
                        return Promise.resolve(result);
                    }

                    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                    const itemId = response.cartItemIds && response.cartItemIds.find(cartItemId => cartItemId === String(productId));

                    if (!itemId) {
                        return Promise.resolve(result);
                    }

                    result = true;

                    return Promise.resolve(result);
                });
        }

        /**
         * @description Returns fetching result promise
         * @returns {Promise<object>} Promise result
         */
        getCartItemIds() {
            return new Promise((resolve, reject) => {
                if (this.loadCartItemsPromise) {
                    return resolve(this.loadCartItemsPromise);
                }

                const itemIds = JSON.parse(localStorageWrapper.getItem('cartItemIds'));
                const isSameSession = localStorageWrapper.getItem('currentSid') === getCookie('sid');

                if (!!itemIds && isSameSession) {
                    return resolve({ cartItemIds: itemIds });
                }

                const url = this.prefs().getCartItemIdsUrl;

                if (!url) {
                    return reject(new Error('no URL provided'));
                }

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadCartItemsPromise' does not exist... Remove this comment to see the full error message
                this.loadCartItemsPromise = this.loadCartItems(url);

                return resolve(this.loadCartItemsPromise);
            });
        }

        /**
         * @description Change state of Add to Basket button
         * @param state - Is product currently in the Cart
         */
        changeStateAddToCartButton(state: boolean) {
            this.getById('addToCart', (addToCartBtn) => {
                if (state) {
                    addToCartBtn.disable();
                    addToCartBtn.setText(this.prefs().addedToCartLabel);
                } else {
                    addToCartBtn.enable();
                    addToCartBtn.setText(this.prefs().addToCartLabel);
                }
            });

            this.getById('addToCartSticky', (addToCartBtn) => {
                if (state) {
                    addToCartBtn.disable();
                    addToCartBtn.setText(this.prefs().addedToCartLabel);
                } else {
                    addToCartBtn.enable();
                    addToCartBtn.setText(this.prefs().addToCartLabel);
                }
            });
        }
    }

    return AddToCartMixin;
}
