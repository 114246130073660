import { timeout } from 'widgets/toolbox/util';

const keyCode = Object.freeze({
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    UP: 38,
    DOWN: 40
});

/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputSelect').default>} BaseInputSelect
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Custom InputSelect implementation
 * @param {BaseInputSelect} BaseInputSelect Base InputSelect implementation
 * @returns {typeof InputSelect} Input Select class
 */
export default function (BaseInputSelect) {
    /**
     * @class InputSelect
     * @augments BaseInputSelect
     * @classdesc Custom Select input implementation.
     */
    class InputSelect extends BaseInputSelect {
        prefs() {
            return {
                custom: false,
                classesEmpty: 'm-empty',
                classesOpen: 'm-open',
                classesSelected: 'm-selected',
                classesFocus: 'm-focus',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            if (this.prefs().custom) {
                this.initCustom();
            }
        }

        onRefresh() {
            super.onRefresh();

            if (this.prefs().custom) {
                timeout(() => {
                    this.initCustom();
                }); // timeout to postpone initialisation until all references will be refreshed
            }
        }

        /**
         * @description This method provides ability to dynamically render HTML for widgets.
         * @param {string} templateRefId id of template
         * @param {object} data data to render
         * @param {RefElement} [renderTo] render into element
         * @param {string} [strToRender] pre-rendered template
         * @returns {Promise<any>} resolved if rendered or rejected if no found template promise
         */
        render(templateRefId = 'template', data = {}, renderTo = this.ref('self'), strToRender = '') {
            return super.render(templateRefId, data, renderTo, strToRender).then(() => {
                if (this.prefs().custom) {
                    this.initCustom();
                }
            });
        }

        /**
         * @description Init custom select
         * @returns {void}
         */
        initCustom() {
            const field = /** @type {HTMLSelectElement|undefined} */(this.ref('field').get());

            if (field) {
                if (field.customSelect && field.customSelect.container.parentNode) {
                    field.customSelect.destroy();
                }

                Promise.all([
                    // @ts-ignore
                    import(/* webpackChunkName: 'custom-select' */'custom-select')
                ]).then(([customSelect]) => {
                    customSelect.default(field, {
                        containerClass: 'b-select-container',
                        openerClass: 'b-select-opener b-select-input',
                        panelClass: 'b-select-panel',
                        optionClass: 'b-select-option',
                        optgroupClass: 'b-select-optgroup',
                        isSelectedClass: this.prefs().classesSelected,
                        isDisabledClass: this.prefs().classesDisabled,
                        isOpenClass: this.prefs().classesOpen,
                        hasFocusClass: this.prefs().classesFocus
                    });

                    if (field.customSelect) {
                        this.customSelect = field.customSelect;
                        this.configureCustomSelect();
                    }
                });
            }
        }

        /**
         * @description Configure custom select after initialization
         * @returns {void}
         */
        configureCustomSelect() {
            if (!this.customSelect) {
                return;
            }

            // Update opener attributes to pass W3C validation
            this.customSelect.opener.setAttribute('role', 'button');
            this.customSelect.opener.removeAttribute('aria-autocomplete');
            this.customSelect.opener.removeAttribute('aria-activedescendant');

            // Custom HTML for select options
            this.customSelect.panel.childNodes.forEach(customOption => {
                if (customOption.customSelectOriginalOption && customOption.customSelectOriginalOption.dataset.html) {
                    customOption.innerHTML = customOption.customSelectOriginalOption.dataset.html;
                }
            });

            this.updateSelectedOption();

            this.customSelect.select.addEventListener('change', () => {
                this.updateSelectedOption();
            });

            /**
             * @description Prevent scrolling of the window when navigating with the keyboard in the open drop-down menu
             * @param {KeyboardEvent} event - event object
             */
            const preventWindowScrolling = (event) => {
                switch (event.keyCode) {
                    case keyCode.SPACE:
                    case keyCode.PAGEUP:
                    case keyCode.PAGEDOWN:
                    case keyCode.END:
                    case keyCode.HOME:
                    case keyCode.UP:
                    case keyCode.DOWN:
                        event.preventDefault();
                        break;

                    default:
                        break;
                }
            };

            this.customSelect.opener.addEventListener('focus', () => {
                window.addEventListener('keydown', preventWindowScrolling, false);
            });

            this.customSelect.opener.addEventListener('blur', () => {
                window.removeEventListener('keydown', preventWindowScrolling, false);
            });

            if (this.customSelect.select.dataset.eventBlur) {
                this.customSelect.opener.addEventListener('blur', () => {
                    if (!this.customSelect.open) {
                        this.callIfExists(this.customSelect.select.dataset.eventBlur);
                    }
                });

                // Return focus to the opener after closing drop-down to emulate native select behavior
                this.customSelect.container.addEventListener('custom-select:close', () => {
                    this.customSelect.opener.focus();
                });
            }

            // Set value if it was changed before initialization of custom select
            if (this.customValue) {
                this.setValue(this.customValue, true);
            }
        }

        /**
         * @description Update selected option with custom markup
         * @returns {void}
         */
        updateSelectedOption() {
            const selectedOption = this.getSelectedOptions();

            if (selectedOption) {
                const selectedOptionEl = selectedOption.get();

                if (selectedOptionEl && selectedOptionEl.dataset.html) {
                    this.customSelect.opener.children[0].innerHTML = selectedOptionEl.dataset.html;
                }
            }
        }

        /**
         * @description Change selected value in the custom select
         * @param {string} [newVal] new value for custom select
         * @returns {void}
         */
        setCustomSelectValue(newVal) {
            if (this.customSelect) {
                this.customSelect.value = newVal;
            }

            this.customValue = newVal;
        }

        /**
         * @description Sets new value on select from selected option
         * @param {string} [newVal] new value
         * @param {boolean} [silently] validate after change
         * @returns {void}
         */
        setValue(newVal, silently) {
            super.setValue(newVal, silently);
            this.setCustomSelectValue(newVal);
        }

        /**
         * @description Disables an input
         * @returns {this} `this` instance for chaining
         */
        disable() {
            if (this.customSelect) {
                this.customSelect.disabled = true;
            }

            return super.disable();
        }

        /**
         * @description Enables an input
         * @returns {this} `this` instance for chaining
         */
        enable() {
            if (this.customSelect) {
                this.customSelect.disabled = false;
            }

            return super.enable();
        }
    }

    return InputSelect;
}
