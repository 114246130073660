/* eslint-disable indent */
const hasContext = (/** @type {string} */context) => window.contexts.includes(context);

import widgetsMgr from 'widgets/widgetsMgr';
import AddToWishlistMixin from './widgets/wishlist/AddToWishlistMixin';

widgetsMgr.addWidgetsList('global.wishlist', () => [
    ['productTile', AddToWishlistMixin, 'productTile']
]);

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('header'),

    load: () => Promise.all([
        import(/* webpackChunkName: 'wishlist.header' */'./widgets/header/WishlistIcon').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.header' */'./widgets/cart/WishlistCartMixin').then(d => d.default)
    ]).then(deps => {
        const [
            WishlistIcon,
            WishlistCartMixin
        ] = deps;

        return {
            listId: 'wishlist.header',
            widgetsDefinition: () => [
                ['wishlistIcon', WishlistIcon],
                ['minicartDialog', WishlistCartMixin, 'minicartDialog']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('cart'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'wishlist.cart' */'./widgets/cart/WishlistCartMixin').then(d => d.default)
    ]).then(deps => {
        const [WishlistCartMixin] = deps;

        return {
            listId: 'wishlist.cart.widgets',
            widgetsDefinition: () => [
                ['cartMgr', WishlistCartMixin, 'cartMgr'],
                ['cartLineItem', AddToWishlistMixin, 'cartLineItem']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('wishlist'),

    load: () => Promise.all([
        import(/* webpackChunkName: 'wishlist.wishlist' */'./widgets/wishlist/WishlistMgr').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.wishlist' */'./widgets/wishlist/WishListItem').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.wishlist' */'widgets/product/ProductOptionMixin').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.wishlist' */'widgets/product/AddToCartMixin').then(d => d.default)
    ]).then(deps => {
        const [WishlistMgr, WishListItem, AddToCartMixin, ProductOptionMixin] = deps;

        return {
            listId: 'wishlist.wishlist',
            widgetsDefinition: () => [
                ['wishlistMgr', WishlistMgr],
                ['wishListItem', WishListItem],
                    ['wishListItem', AddToCartMixin, 'wishListItem'],
                    ['wishListItem', ProductOptionMixin, 'wishListItem']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),

    load: () => Promise.resolve({
        listId: 'product.wishlist',
        widgetsDefinition: () => [
            ['productDetail', AddToWishlistMixin, 'productDetail'],
            ['productSet', AddToWishlistMixin, 'productSet']
        ]
    })
});
