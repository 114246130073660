import { TWidget } from 'widgets/Widget';
const keyCode = Object.freeze({
    END: 35,
    HOME: 36,
    LEFT: 37,
    RIGHT: 39
});

type TButton = ReturnType<typeof import('widgets/global/Button').default>;
type buttonInstance = InstanceType<TButton>;
type tabPanelInstance = InstanceType<ReturnType<typeof import('widgets/global/TabPanel').default>>;
type refElement = InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>;

/**
 * @description Base Tabs implementation
 * @param Widget - widget for extending
 * @returns Tabs widget
 */
export default function (Widget: TWidget) {
    /**
     * @class Tabs
     * @augments Widget
     *
     * @property {string} data-widget - Widget name `tabs`
     * @property {boolean} data-active-first - activate first tab and first tab panel
     * @property {string} data-active-panel - activate tab and tab panel by provided panel id
     * @property {boolean} data-auto-activation - if tabs list should follow accessibility `Tabs with Automatic Activation` feature
     * @property {boolean} data-use-url-anchor - in case if true, each tab change will add a related hash to document location.
     * @classdesc Represents tabbed contents displaying and navigation.
     * Has the next features:
     * 1. Mark selected tab as active
     * 2. Handles keydown by enter\space keyboard button to manipulate the active tab
     * 3. Sets focus to the current tab
     * 4. Adds hash to document location if enabled
     *
     * @example <caption>Example of Tabs widget usage</caption>
     * <div data-widget="tabs">
     *     <ul class="nav nav-tabs nav-fill">
     *         <li class="nav-item">
     *             <a
     *                 data-widget="button"
     *                 data-panel-name="login"
     *                 data-widget-event-click="handleTabClick"
     *                 data-id="button-login"
     *                 data-event-click.prevent="handleClick"
     *                 href="#login" role="tab"
     *             >
     *                 ${Resource.msg('link.header.login.module', 'login', null)}
     *             </a>
     *         </li>
     *         <li class="nav-item">
     *             <a
     *                 data-widget="button"
     *                 data-panel-name="register"
     *                 data-widget-event-click="handleTabClick"
     *                 data-id="button-register"
     *                 data-event-click.prevent="handleClick"
     *                 href="#register" role="tab"
     *             >
     *                 ${Resource.msg('link.header.register.module', 'login', null)}
     *             </a>
     *         </li>
     *     </ul>
     *     <div class="tab-content">
     *         <div id="login" role="tabpanel" data-widget="tabPanel">
     *             ... tab content
     *             <isinclude template="account/components/loginForm" />
     *             <isinclude template="account/components/oauth" />
     *         </div>
     *         <div id="register" role="tabanel" data-widget="tabPanel">
     *             ... tab content
     *             <isinclude template="account/components/registerForm" />
     *         </div>
     *     </div>
     * </div>
     */
    class Tabs extends Widget {
        panelNames: string[] = [];

        focusedTab = '';

        activePanel = '';

        prefs() {
            return {
                classesActive: 'm-active',
                activePanel: '',
                activeFirst: false,
                autoActivation: false,
                useUrlAnchor: false,
                ...super.prefs()
            };
        }

        /**
         * @description Refresh widget handler
         */
        onRefresh(): void {
            super.onRefresh();
            this.fulfillPanelNames();
        }

        /**
         * @description Widget initialization logic
         */
        init(): void {
            super.init();
            this.fulfillPanelNames();

            if (this.prefs().activePanel) {
                this.activatePanel(this.prefs().activePanel);
            } else if (this.prefs().useUrlAnchor) {
                this.handleUrlChange();
            } else if (this.prefs().activeFirst && this.panelNames && this.panelNames.length) {
                this.activatePanel(this.panelNames[0]);
            }
        }

        /**
         * @description Registers all available panels by adding their IDs into array.
         */
        fulfillPanelNames(): void {
            this.panelNames = [];
            const Button: TButton = <TButton> this.getConstructor('button');

            this.eachChild(child => {
                if (child instanceof Button && this.panelNames) {
                    this.panelNames.push(String(child.config.panelName));
                }
            });
        }

        /**
         * @param clickedButton - Widget, representing customer's tab clicked element
         */
        handleTabClick(clickedButton: InstanceType<TWidget>): void {
            this.activatePanel(String(clickedButton.config.panelName));
        }

        /**
         * @param panelName name of panel to activate
         * @param saveToPushState - If we need to save hash in history
         */
        activatePanel(panelName: string, saveToPushState = true): void {
            if (!this.panelNames) {
                return;
            }

            this.panelNames.forEach(id => {
                const isActive = id === panelName;

                if (isActive) {
                    this.activePanel = panelName;
                    this.focusedTab = panelName;
                }

                const currentTabPanel = this.toggleTabPanel(id, isActive, saveToPushState);

                if (!currentTabPanel && this.ref(id)) {
                    this.toggleActiveRefPanel(this.ref(id), isActive);

                    if (isActive && saveToPushState) {
                        this.handleUrlAnchor(id);
                    }
                }

                this.toggleButton(id, isActive);
            });
        }

        /**
         * @description Toggle tab panel
         * @param id - panel ID
         * @param isActive - Is current one should be active
         * @param saveToPushState - Is state should be added to browser history
         * @returns Tab instance
         */
        toggleTabPanel(id: string, isActive: boolean, saveToPushState: boolean): tabPanelInstance | undefined {
            return <tabPanelInstance> this.getById(id, (tabPanel: tabPanelInstance) => {
                tabPanel[isActive ? 'activate' : 'deactivate']();

                if (isActive && saveToPushState) {
                    this.handleUrlAnchor(id);
                }

                return tabPanel;
            });
        }

        /**
         * @description Toggle tab button
         * @param id - panel ID
         * @param isActive - Is current should be active
         */
        toggleButton(id: string, isActive: boolean): void {
            this.getById(`button-${id}`, (/** @type {buttonInstance} */ button: buttonInstance) => {
                if (isActive) {
                    button.activate();
                    button.select();
                } else {
                    button.deactivate();
                    button.unselect();
                }
            });
        }

        /**
         * @description In addition to tab switch, action will be reflected in URL anchor, if enabled.
         * This will further allow to select proper tab, when returning back from browser history.
         * @param tabName tab name to reflect in browser location hash.
         */
        handleUrlAnchor(tabName: string): void {
            if (this.prefs().useUrlAnchor) {
                window.history.pushState({
                    hashChangedOnly: true
                }, document.title, `#${tabName}`);
            }
        }

        /**
         * @description Triggers needed tab activation, when URL changed
         * in case of history push/replace state and using browser's `back` button.
         * @param saveToPushState - If we need to save hash in history
         */
        handleUrlChange(saveToPushState = true): void {
            if (document.location.hash) {
                const panelToActivate = this.panelNames
                    && this.panelNames.find(panelName => panelName === document.location.hash.replace('#', ''));

                if (panelToActivate) {
                    this.activatePanel(panelToActivate, saveToPushState);
                }
            }
        }

        /**
         * @description Method to toggle active panel, if it is not a separate widget `TabPanel`
         * but an ordinary `ref` element inside `Tabs` widget.
         * @param panel target panel name
         * @param state is target panel is selected or not
         */
        toggleActiveRefPanel(panel: refElement, state: boolean): void {
            panel.toggleClass(this.prefs().classesActive, state);
        }

        /**
         * @description Gets last focused panel name
         * <br>(focused panel is not necessarily active panel)
         * @returns Focused panel name if founded. Empty string otherwise
         */
        getLastFocusedTab(): string {
            return this.focusedTab
                ? this.focusedTab
                : this.activePanel || (this.panelNames && this.panelNames[0]) || '';
        }

        /**
         * @description Sets focus to panel with given name
         * <br>Uses `roving focus` accessibility feature
         * <br>https://www.w3.org/TR/wai-aria-practices/#kbd_roving_tabindex
         * @param tab Tab name to set focus to
         */
        setFocusToTab(tab: string): void {
            if (!tab) {
                return;
            }

            this.focusedTab = tab;

            if (this.panelNames) {
                this.panelNames.forEach(id => {
                    const isTargetTab = id === tab;

                    this.getById(`button-${id}`, (/** @type {buttonInstance} */ button: buttonInstance) => {
                        if (isTargetTab) {
                            button.focus().setTabIndex();
                        } else {
                            button.unsetTabIndex();
                        }
                    });
                });
            }

            if (this.prefs().autoActivation) {
                this.activatePanel(tab);
            }
        }

        /**
         * @description Sets focus to the very first panel
         */
        setFocusToFirstTab(): void {
            const firstTab = (this.panelNames
                && this.panelNames.length
                && this.panelNames[0])
                || '';

            this.setFocusToTab(firstTab);
        }

        /**
         * @description Sets focus to the very last panel
         */
        setFocusToLastTab(): void {
            const lastTab = (this.panelNames
                && this.panelNames.length
                && this.panelNames[this.panelNames.length - 1])
                || '';

            this.setFocusToTab(lastTab);
        }

        /**
         * @description Sets focus to previous panel. Loops focus, if first panel reached
         */
        setFocusToPreviousTab(): void {
            if (this.panelNames && this.panelNames.length) {
                const currentFocusedTab = this.getLastFocusedTab();
                const currentFocusedTabIndex = this.panelNames.indexOf(currentFocusedTab);
                const previousTab = currentFocusedTabIndex === 0
                    ? this.panelNames[this.panelNames.length - 1]
                    : this.panelNames[currentFocusedTabIndex - 1];

                this.setFocusToTab(previousTab);
            }
        }

        /**
         * @description Sets focus to next panel. Loops focus, if last panel reached
         */
        setFocusToNextTab(): void {
            if (this.panelNames && this.panelNames.length) {
                const currentFocusedTab = this.getLastFocusedTab();
                const currentFocusedTabIndex = this.panelNames.indexOf(currentFocusedTab);
                const nextTab = currentFocusedTabIndex === this.panelNames.length - 1
                    ? this.panelNames[0]
                    : this.panelNames[currentFocusedTabIndex + 1];

                this.setFocusToTab(nextTab);
            }
        }

        /**
         * @description Keydown Event handler
         * @param _ Source of keydown event
         * @param event  Event object
         */
        handleKeydown(_: HTMLElement, event: KeyboardEvent): void {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.HOME:
                    this.setFocusToFirstTab();
                    preventEventActions = true;
                    break;
                case keyCode.END:
                    this.setFocusToLastTab();
                    preventEventActions = true;
                    break;
                case keyCode.LEFT:
                    this.setFocusToPreviousTab();
                    preventEventActions = true;
                    break;
                case keyCode.RIGHT:
                    this.setFocusToNextTab();
                    preventEventActions = true;
                    break;
                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return Tabs;
}
