import { TWidget } from 'widgets/Widget';
import cssLoadChecker from 'widgets/toolbox/cssLoadChecker';
import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @param Widget Base widget for extending
 * @returns HeroCarousel class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class HeroCarousel
     * @augments Widget
     * @classdesc Represents HeroCarousel component with next features:
     * 1. Allow to use pagination for carousel rendered by mustache template
     * 2. Allow to use slides autoplay functionality
     * 3. Allow to use start/stop autoplay functionality
     * 4. Allow to change current slide to the next/previous/custom(index can be passed to the method) slide
     * 5. Support mousemove, touchmove, mouseup, mousedown, keydown event so we can use carousel even on touch devices
     * 6. Support for carousel control from the keyboard
     * @property {string} data-widget - widget name `heroCarousel`
     * @property {boolean} data-autoplay-enabled - enables/disables autoplay. If false, autoplay will be hidden
     * @property {boolean} data-autoplay-stopped - starts/stops autoplay. If true, autoplay will be shown but paused
     * @property {string} data-autoplay-duration - value for the duration of the autoplay slide change
     * @property {string} data-autoplay-label-start - autoplay button label text to start automatic slide change
     * @property {string} data-autoplay-label-stop - autoplay button label text to stop automatic slide change
     * @property {string} data-event-mousedown - Event listener for `touchStart` method
     * @property {string} data-event-touchstart - Event listener for `touchStart` method
     * <br>Uses as a basis slider from here (ScrollCarousel.js):
     * <br>https://github.com/dimanech/aria-components/tree/master/cartridge1/js/components/carousels/slider
     * @example <caption>Example of HeroCarousel widget usage</caption>
     * <div
     *     data-widget="heroCarousel"
     *     class="b-hero_carousel"
     *     role="region"
     *     aria-roledescription="carousel"
     *     aria-label="${Resource.msg('carousel.sliderLabel', 'carousel', null)}"
     *     data-event-mousedown.passive="touchStart"
     *     data-event-touchstart.passive="touchStart"
     *     data-tau="carousel_hero"
     *     data-autoplay-enabled="true"
     *     data-autoplay-stopped="false"
     *     data-autoplay-duration="8000"
     *     data-autoplay-label-start="${Resource.msg('carousel.startAutoplay', 'carousel', null)}"
     *     data-autoplay-label-stop="${Resource.msg('carousel.stopAutoplay', 'carousel', null)}"
     * >
     *     <button
     *         class="b-hero_carousel-ctrl m-prev"
     *         aria-label="${Resource.msg('carousel.previousSlide', 'carousel', null)}"
     *         aria-controls="slider-content"
     *         title="${Resource.msg('button.previous', 'common', null)}"
     *         data-ref="elemPrevButton"
     *         data-event-click="goToPrevSlide"
     *         data-tau="carousel_hero_prev"
     *     >
     *         <isinclude template="/common/icons/standalone/arrowCarouselLeft" />
     *     </button>
     *     <button
     *         class="b-hero_carousel-ctrl m-next"
     *         aria-label="${Resource.msg('carousel.nextSlide', 'carousel', null)}"
     *         aria-controls="slider-content"
     *         title="${Resource.msg('button.next', 'common', null)}"
     *         data-ref="elemNextButton"
     *         data-event-click="goToNextSlide"
     *         data-tau="carousel_hero_next"
     *     >
     *         <isinclude template="/common/icons/standalone/arrowCarouselRight" />
     *     </button>
     *     <div class="b-hero_carousel-pagination" data-ref="pagination">
     *         <div class="b-hero_carousel-pagination_content">
     *             <button
     *                 class="b-hero_carousel-autoplay"
     *                 data-ref="autoplay"
     *                 aria-pressed="false"
     *                 aria-label="${Resource.msg('carousel.stopAutoplay', 'carousel', null)}"
     *             >
     *                 <svg class="b-hero_carousel-autoplay_svg" width="36" height="36" viewBox="0 0 36 36" focusable="false" xmlns="http://www.w3.org/2000/svg">
     *                     <circle class="b-hero_carousel-autoplay_progress_back" cx="18" cy="18" r="16.5"></circle>
     *                     <circle class="b-hero_carousel-autoplay_progress" cx="18" cy="18" r="16.5" stroke-dasharray="104" transform="rotate(-90 18 18)"></circle>
     *                     <polygon class="b-hero_carousel-autoplay_play" points="14.4,12.2 14.4,23 25.1,17.5 "></polygon>
     *                     <polygon class="b-hero_carousel-autoplay_pause" points="14.5 12 14.5 24"></polygon>
     *                     <polygon class="b-hero_carousel-autoplay_pause" points="21.5 12 21.5 24"></polygon>
     *                 </svg>
     *             </button>
     *             <div class="b-hero_carousel-pagination_dots" role="group" data-ref="paginationDots" aria-label="${Resource.msg('carousel.chooseSlide', 'carousel', null)}"></div>
     *             <script type="template/mustache" data-ref="template">
     *                 <div class="b-hero_carousel-pagination_dots" role="group" data-ref="paginationDots" aria-label="${Resource.msg('carousel.chooseSlide', 'carousel', null)}">
     *                     {{${'#'}pagination}}
     *                     <button
     *                         class="b-hero_carousel-pagination_dot{{${'#'}isFirst}} m-current{{/isFirst}}"
     *                         aria-labelledby="slide-{{page}}"
     *                         data-page="{{page}}"
     *                         data-event-click="handlePaginationClick"
     *                         data-tau="carousel_hero_cta"
     *                     >
     *                         <svg class="b-hero_carousel-pagination_svg" height="18" width="18" viewBox="0 0 18 18">
     *                             <circle class="b-hero_carousel-pagination_dot_outline" cx="9" cy="9" r="7.5"></circle>
     *                         </svg>
     *                     </button>
     *                     {{/pagination}}
     *                 </div>
     *             </script>
     *         </div>
     *     </div>
     *     <div
     *         class="b-hero_carousel-track"
     *         id="slider-content"
     *         data-ref="elemCarouselTrack"
     *         data-event-touchstart.passive="touchStart"
     *         aria-atomic="false"
     *         aria-live="off"
     *     >
     *       <isset name="ContentModel" value="${require(' /cartridge/models/content')}" scope="page" />
     *       <isloop items="${slotcontent.content}" var="contentAsset" status="loopStatus">
     *           <div
     *               class="b-hero_carousel-item"
     *               role="group"
     *               aria-roledescription="slide"
     *               tabindex="0"
     *               data-label-delimiter="${Resource.msg('carousel.labelDelimiter', 'carousel', null)}"
     *               data-tau="carousel_hero_item"
     *           >
     *               <isprint value="${new ContentModel(contentAsset).getMarkup()}" encoding="off" />
     *           </div>
     *       </isloop>
     *     </div>
     * </div>
     */
    class HeroCarousel extends Widget {
        prefs() {
            return {
                autoplayEnabled: true,
                autoplayStopped: false,
                autoplayDuration: '3000',
                autoplayLabelStart: '',
                autoplayLabelStop: '',
                slideCurrentClass: 'm-current',
                slidePreviousClass: 'm-prev',
                slideNextClass: 'm-next',
                slideGrabbingClass: 'm-grabbing',
                slidePrefix: 'slide-',
                carouselInitialized: 'm-initialized',
                autoplayAnimated: 'm-animated',
                swipeMinShift: '16',
                swipeAngle: '30',
                clickShift: '1',
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         * @returns {void}
         */
        init() {
            super.init();
            // Async loading to not block other widget init
            timeout(() => {
                cssLoadChecker.get().then(() => this.initCarousel());
            }, 0);
        }

        /**
         * @description Initial carousel configuration
         * @returns {void}
         */
        initCarousel() {
            // Common carousel properties

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSlideIndex' does not exist on typ... Remove this comment to see the full error message
            this.currentSlideIndex = 0;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'blockedByAnimations' does not exist on t... Remove this comment to see the full error message
            this.blockedByAnimations = false;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesModel' does not exist on type 'Her... Remove this comment to see the full error message
            this.slidesModel = null;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'paginationDots' does not exist on type '... Remove this comment to see the full error message
            this.paginationDots = null;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isGrabbing' does not exist on type 'Hero... Remove this comment to see the full error message
            this.isGrabbing = false;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'startX' does not exist on type 'HeroCaro... Remove this comment to see the full error message
            this.startX = 0;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'startY' does not exist on type 'HeroCaro... Remove this comment to see the full error message
            this.startY = 0;

            // Autoplay properties

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayStopped' does not exist on type ... Remove this comment to see the full error message
            this.autoPlayStopped = this.prefs().autoplayStopped;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextSlideTimer' does not exist on type '... Remove this comment to see the full error message
            this.nextSlideTimer = null;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'remainingTime' does not exist on type 'H... Remove this comment to see the full error message
            this.remainingTime = null;

            this.initStructure();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isSlider' does not exist on type 'HeroCa... Remove this comment to see the full error message
            if (!this.isSlider) { return; }

            this.initPagination();
            this.goToSlide(0);

            if (this.prefs().autoplayEnabled) {
                this.initAutoplay();
            }

            this.ref('self').addClass(this.prefs().carouselInitialized);
        }

        /**
         * @description Initialize carousel structure with slides
         * @returns {void}
         */
        initStructure() {
            const track = this.ref('elemCarouselTrack').get();

            if (track) {
                if (track.children.length === 2) {
                    const initialContent = track.innerHTML;

                    track.innerHTML = initialContent + initialContent; // Clone slides
                }

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'slides' does not exist on type 'HeroCaro... Remove this comment to see the full error message
                this.slides = track.children;

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesTotal' does not exist on type 'Her... Remove this comment to see the full error message
                this.slidesTotal = this.slides.length;

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'slides' does not exist on type 'HeroCaro... Remove this comment to see the full error message
                for (let i = 0; i < this.slides.length; i++) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'slides' does not exist on type 'HeroCaro... Remove this comment to see the full error message
                    const slide = this.slides[i];

                    slide.setAttribute('id', (this.prefs().slidePrefix + i));
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    slide.setAttribute('aria-label', ((i + 1) + ' ' + track.getAttribute('data-label-delimiter')

                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesTotal' does not exist on type 'Her... Remove this comment to see the full error message
                        + ' ' + this.slidesTotal));
                }
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isSlider' does not exist on type 'HeroCa... Remove this comment to see the full error message
            this.isSlider = this.slidesTotal && this.slidesTotal > 1;
        }

        /**
         * @description Changes current slide to slide with provided index
         * @param {number} index Next slide index
         * @returns {void}
         */
        goToSlide(index) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'blockedByAnimations' does not exist on t... Remove this comment to see the full error message
            if (this.blockedByAnimations) { return; }

            if (this.prefs().autoplayEnabled) {
                this.endAutoplay();
            }

            const newSlideIndex = this.normalizeIndex(index);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesModel' does not exist on type 'Her... Remove this comment to see the full error message
            this.slidesModel = this.getSlidesModel(newSlideIndex);

            this.toggleAnimationMode(true);
            this.waitForTransitionEnd(() => this.toggleAnimationMode(false));

            this.applySlidesModel();
            this.setActivePagination(newSlideIndex);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSlideIndex' does not exist on typ... Remove this comment to see the full error message
            this.currentSlideIndex = newSlideIndex;

            if (this.prefs().autoplayEnabled) {
                this.startAutoplay(true);

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFocused' does not exist on type 'HeroC... Remove this comment to see the full error message
                this.isFocused = false;

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHover' does not exist on type 'HeroCar... Remove this comment to see the full error message
                this.isHover = false;
            }
        }

        /**
         * @description Blocks the ability to change slides until the animation is complete
         * @param {boolean} isAnimated - block/unblock changing current slide
         * @returns {void}
         */
        toggleAnimationMode(isAnimated) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'blockedByAnimations' does not exist on t... Remove this comment to see the full error message
            this.blockedByAnimations = isAnimated;
            this.ref('elemPrevButton').attr('aria-busy', isAnimated);
            this.ref('elemNextButton').attr('aria-busy', isAnimated);
        }

        /**
         * @description Assigns classes to slide elements in accordance with position of current slide
         * @returns {void}
         */
        applySlidesModel() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'slides' does not exist on type 'HeroCaro... Remove this comment to see the full error message
            if (!this.slides || !this.slidesModel) { return; }

            const allClasses = [this.prefs().slidePreviousClass, this.prefs().slideNextClass, this.prefs().slideCurrentClass];

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesTotal' does not exist on type 'Her... Remove this comment to see the full error message
            let n = this.slidesTotal || 0;

            while (n--) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'slides' does not exist on type 'HeroCaro... Remove this comment to see the full error message
                const slideElement = this.slides[n];

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesModel' does not exist on type 'Her... Remove this comment to see the full error message
                const slideClass = this.slidesModel[n];

                slideElement.classList.remove(...allClasses);

                if (slideClass) {
                    slideElement.classList.add(slideClass);
                }
            }
        }

        /**
         * @description Updates classes list of slide elements in accordance with updated index
         * @param {number} index - Updated slide index
         * @returns {Array} Array with updated classes
         */
        getSlidesModel(index) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesTotal' does not exist on type 'Her... Remove this comment to see the full error message
            const model = new Array(this.slidesTotal);
            const nextIndex = this.normalizeIndex(index + 1);
            const prevIndex = this.normalizeIndex(index - 1);
            const currentIndex = this.normalizeIndex(index);

            model.fill(this.prefs().slidePreviousClass, 0, currentIndex);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesTotal' does not exist on type 'Her... Remove this comment to see the full error message
            model.fill(this.prefs().slideNextClass, currentIndex, this.slidesTotal);

            model[currentIndex] = this.prefs().slideCurrentClass;
            model[nextIndex] = this.prefs().slideNextClass;
            model[prevIndex] = this.prefs().slidePreviousClass;

            return model;
        }

        /**
         * @description Normalizes slide index according to looped carousel approach
         * @param {number} index - Slide index
         * @returns {number} Normalized slide index
         */
        normalizeIndex(index) {
            let normalizedIndex = 0;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesTotal' does not exist on type 'Her... Remove this comment to see the full error message
            if (this.slidesTotal) {
                if (index < 0) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesTotal' does not exist on type 'Her... Remove this comment to see the full error message
                    normalizedIndex = (this.slidesTotal - 1);
                } else {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesTotal' does not exist on type 'Her... Remove this comment to see the full error message
                    normalizedIndex = index % this.slidesTotal;
                }
            }

            return normalizedIndex;
        }

        /**
         * @description Calls callback after the animation is ended
         * @listens HeroCarousel#transitionend
         * @param {Function} callback - function to be executed when the transition ends
         * @returns {void}
         */
        waitForTransitionEnd(callback) {
            const onEnd = (el, event) => {
                if (event && event.propertyName !== 'transform') { return; }

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitionFallbackTimer' does not exist ... Remove this comment to see the full error message
                if (this.transitionFallbackTimer) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitionFallbackTimer' does not exist ... Remove this comment to see the full error message
                    this.transitionFallbackTimer();
                }

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitionEndDisposable' does not exist ... Remove this comment to see the full error message
                if (this.transitionEndDisposable) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitionEndDisposable' does not exist ... Remove this comment to see the full error message
                    this.transitionEndDisposable.forEach(disposable => disposable());

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitionEndDisposable' does not exist ... Remove this comment to see the full error message
                    this.transitionEndDisposable = undefined;
                }

                callback();
            };

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitionEndDisposable' does not exist ... Remove this comment to see the full error message
            this.transitionEndDisposable = this.ev('transitionend', onEnd, this.ref('elemCarouselTrack').get());

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitionFallbackTimer' does not exist ... Remove this comment to see the full error message
            this.transitionFallbackTimer = timeout(onEnd, 800);
        }

        /**
         * @description Initialize carousel pagination
         * @returns {void}
         */
        initPagination() {
            this.has('paginationDots', paginationRefEl => {
                const pagination = paginationRefEl.get();

                if (pagination) {
                    // If empty pagination - we need to render it. Otherwise - create.
                    if (pagination.innerHTML === '') {
                        this.createPaginationElements();
                    } else {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'pagination' does not exist on type 'Hero... Remove this comment to see the full error message
                        this.pagination = Promise.resolve(pagination);
                    }

                    const paginationDots = this.ref('paginationDots').get();

                    if (paginationDots) {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'paginationDots' does not exist on type '... Remove this comment to see the full error message
                        this.paginationDots = paginationDots.children;
                    }
                }
            });
        }

        /**
         * @description Creates carousel pagination
         * @returns {void}
         */
        createPaginationElements() {
            const elemCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elemCarouselTrack) { return; }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'slidesTotal' does not exist on type 'Her... Remove this comment to see the full error message
            const pagination = new Array(this.slidesTotal).fill(0).map((_el, i) => ({ page: i, isFirst: i === 0 }));

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'pagination' does not exist on type 'Hero... Remove this comment to see the full error message
            this.pagination = new Promise((resolve) => {
                this.render(undefined, { pagination }, this.ref('paginationDots')).then(() => {
                    resolve(this.ref('paginationDots').get());
                    this.ref('pagination').addClass(this.prefs().carouselInitialized);
                });
            });
        }

        /**
         * @description Pagination click Event handler
         * @listens dom#click
         * @param {refElement} el - source of event
         * @returns {void}
         */
        handlePaginationClick(el) {
            if (el.hasClass(this.prefs().slideCurrentClass)) { return; }

            const nextSlideIndex = el.data('page');

            this.goToSlide(nextSlideIndex);
        }

        /**
         * @description Updates attributes/classes on previous and current pagination element
         * @param {number} index - Pagination dot index
         * @returns {void}
         */
        setActivePagination(index) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'paginationDots' does not exist on type '... Remove this comment to see the full error message
            if (this.paginationDots && this.paginationDots.length) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSlideIndex' does not exist on typ... Remove this comment to see the full error message
                const currentIndex = this.currentSlideIndex || 0;

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'paginationDots' does not exist on type '... Remove this comment to see the full error message
                this.paginationDots[currentIndex].classList.remove(this.prefs().slideCurrentClass);

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'paginationDots' does not exist on type '... Remove this comment to see the full error message
                this.paginationDots[currentIndex].removeAttribute('aria-disabled');

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'paginationDots' does not exist on type '... Remove this comment to see the full error message
                this.paginationDots[index].classList.add(this.prefs().slideCurrentClass);

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'paginationDots' does not exist on type '... Remove this comment to see the full error message
                this.paginationDots[index].setAttribute('aria-disabled', 'true');
            }
        }

        /**
         * @description Shows the next slide
         * @listens dom#click
         * @returns {void}
         */
        goToNextSlide() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSlideIndex' does not exist on typ... Remove this comment to see the full error message
            if (typeof this.currentSlideIndex !== 'undefined') {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSlideIndex' does not exist on typ... Remove this comment to see the full error message
                this.goToSlide(this.currentSlideIndex + 1);
            }
        }

        /**
         * @description Shows the previous slide
         * @listens dom#click
         * @returns {void}
         */
        goToPrevSlide() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSlideIndex' does not exist on typ... Remove this comment to see the full error message
            if (typeof this.currentSlideIndex !== 'undefined') {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentSlideIndex' does not exist on typ... Remove this comment to see the full error message
                this.goToSlide(this.currentSlideIndex - 1);
            }
        }

        /**
         * @description Adds mouse/touch listeners for swipe functionality
         * @listens HeroCarousel#touchstart
         * @param {HTMLElement|undefined} el - source of event
         * @returns {void}
         */
        addTouchListeners(el) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchMoveDisposable' does not exist on t... Remove this comment to see the full error message
            this.touchMoveDisposable = this.ev('touchmove', this.touchMove, el, false);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseMoveDisposable' does not exist on t... Remove this comment to see the full error message
            this.mouseMoveDisposable = this.ev('mousemove', this.touchMove, el, false);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseUpDisposable' does not exist on typ... Remove this comment to see the full error message
            this.mouseUpDisposable = this.ev('mouseup', this.touchEnd, el);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseLeaveDisposable' does not exist on ... Remove this comment to see the full error message
            this.mouseLeaveDisposable = this.ev('mouseleave', this.touchEnd, el);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchEndDisposable' does not exist on ty... Remove this comment to see the full error message
            this.touchEndDisposable = this.ev('touchend', this.touchEnd, el);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchCancelDisposable' does not exist on... Remove this comment to see the full error message
            this.touchCancelDisposable = this.ev('touchcancel', this.touchEnd, el, false);
            // call context menu on slide and close it should not be treated as mousemove

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'contextMenuDisposable' does not exist on... Remove this comment to see the full error message
            this.contextMenuDisposable = this.ev('contextmenu', this.touchEnd, el, false);
        }

        /**
         * @description Removes mouse/touch listeners for swipe functionality
         * @listens HeroCarousel#touchend
         * @returns {void}
         */
        removeTouchListeners() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchMoveDisposable' does not exist on t... Remove this comment to see the full error message
            if (this.touchMoveDisposable) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchMoveDisposable' does not exist on t... Remove this comment to see the full error message
                this.touchMoveDisposable.forEach(disposable => disposable());

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchMoveDisposable' does not exist on t... Remove this comment to see the full error message
                this.touchMoveDisposable = undefined;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseMoveDisposable' does not exist on t... Remove this comment to see the full error message
            if (this.mouseMoveDisposable) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseMoveDisposable' does not exist on t... Remove this comment to see the full error message
                this.mouseMoveDisposable.forEach(disposable => disposable());

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseMoveDisposable' does not exist on t... Remove this comment to see the full error message
                this.mouseMoveDisposable = undefined;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseUpDisposable' does not exist on typ... Remove this comment to see the full error message
            if (this.mouseUpDisposable) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseUpDisposable' does not exist on typ... Remove this comment to see the full error message
                this.mouseUpDisposable.forEach(disposable => disposable());

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseUpDisposable' does not exist on typ... Remove this comment to see the full error message
                this.mouseUpDisposable = undefined;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseLeaveDisposable' does not exist on ... Remove this comment to see the full error message
            if (this.mouseLeaveDisposable) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseLeaveDisposable' does not exist on ... Remove this comment to see the full error message
                this.mouseLeaveDisposable.forEach(disposable => disposable());

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'mouseLeaveDisposable' does not exist on ... Remove this comment to see the full error message
                this.mouseLeaveDisposable = undefined;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchEndDisposable' does not exist on ty... Remove this comment to see the full error message
            if (this.touchEndDisposable) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchEndDisposable' does not exist on ty... Remove this comment to see the full error message
                this.touchEndDisposable.forEach(disposable => disposable());

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchEndDisposable' does not exist on ty... Remove this comment to see the full error message
                this.touchEndDisposable = undefined;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchCancelDisposable' does not exist on... Remove this comment to see the full error message
            if (this.touchCancelDisposable) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchCancelDisposable' does not exist on... Remove this comment to see the full error message
                this.touchCancelDisposable.forEach(disposable => disposable());

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchCancelDisposable' does not exist on... Remove this comment to see the full error message
                this.touchCancelDisposable = undefined;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'contextMenuDisposable' does not exist on... Remove this comment to see the full error message
            if (this.contextMenuDisposable) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'contextMenuDisposable' does not exist on... Remove this comment to see the full error message
                this.contextMenuDisposable.forEach(disposable => disposable());

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'contextMenuDisposable' does not exist on... Remove this comment to see the full error message
                this.contextMenuDisposable = undefined;
            }
        }

        /**
         * @description Checks if swipe functionality is initialized according to provided touches angle
         * @param {number} x - clientX coordinate value
         * @param {number} y - clientY coordinate value
         * @returns {boolean} true if swipe is initialized
         */
        isSwipe(x, y) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'startX' does not exist on type 'HeroCaro... Remove this comment to see the full error message
            const diffX = x - this.startX;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'startY' does not exist on type 'HeroCaro... Remove this comment to see the full error message
            const diffY = y - this.startY;
            const angleDeg = (Math.atan2(Math.abs(diffY), Math.abs(diffX)) * 180) / Math.PI;

            if (!this.prefs().swipeAngle) {
                return false;
            }

            return angleDeg < +this.prefs().swipeAngle;
        }

        /**
         * @description Defines swipe properties at the start of the event.
         * Adds event listeners to check if the swipe event is true
         * @listens HeroCarousel#touchstart
         * @listens HeroCarousel#mousedown
         * @param {refElement} el - source of event
         * @param {object} event - DOM event
         * @returns {void}
         */
        touchStart(el, event) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isSlider' does not exist on type 'HeroCa... Remove this comment to see the full error message
            if (!this.isSlider) { return; }

            event.stopPropagation();
            event = event.changedTouches ? event.changedTouches[0] : event;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'startX' does not exist on type 'HeroCaro... Remove this comment to see the full error message
            this.startX = event.clientX;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'startY' does not exist on type 'HeroCaro... Remove this comment to see the full error message
            this.startY = event.clientY;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isMoving' does not exist on type 'HeroCa... Remove this comment to see the full error message
            this.isMoving = false;

            this.addTouchListeners(el.get());
        }

        /**
         * @description Shows the previous/next slide in case if the swipe event is true
         * @listens HeroCarousel#mouseup
         * @listens HeroCarousel#mouseleave
         * @listens HeroCarousel#touchend
         * @param {HTMLElement} el - source of event
         * @param {object} event - DOM event
         * @returns {void}
         */
        touchEnd(el, event) {
            this.removeTouchListeners();

            event = event.changedTouches ? event.changedTouches[0] : event;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isGrabbing' does not exist on type 'Hero... Remove this comment to see the full error message
            this.isGrabbing = false;
            this.ref('elemCarouselTrack').removeClass(this.prefs().slideGrabbingClass);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'startX' does not exist on type 'HeroCaro... Remove this comment to see the full error message
            const shiftWidth = this.startX - event.clientX;
            const isSwipe = this.isSwipe(event.clientX, event.clientY)
                && Math.abs(shiftWidth) >= (+this.prefs().swipeMinShift || 0);

            if (!isSwipe) { return; }

            if (shiftWidth > 0) {
                this.goToNextSlide();
            } else {
                this.goToPrevSlide();
            }
        }

        /**
         * @description Adds a class for showing grabbing cursor, prevents blocking of site scrolling, prevents child dragging.
         * @listens HeroCarousel#touchmove
         * @listens HeroCarousel#mousemove
         * @param {HTMLElement} el - source of event
         * @param {object} event - DOM event
         * @returns {void}
         */
        touchMove(el, event) {
            // To prevent starting touchMove on click event

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isMoving' does not exist on type 'HeroCa... Remove this comment to see the full error message
            if (!this.isMoving && (Math.abs(this.startX - event.clientX) < parseInt(this.prefs().clickShift, 10)

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'startY' does not exist on type 'HeroCaro... Remove this comment to see the full error message
                || Math.abs(this.startY - event.clientY) < parseInt(this.prefs().clickShift, 10))) {
                return;
            } else {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'isMoving' does not exist on type 'HeroCa... Remove this comment to see the full error message
                this.isMoving = true;
            }

            // To prevent child dragging and to add dragging cursor

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isGrabbing' does not exist on type 'Hero... Remove this comment to see the full error message
            if (!this.isGrabbing) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'isGrabbing' does not exist on type 'Hero... Remove this comment to see the full error message
                this.isGrabbing = true;
                this.ref('elemCarouselTrack').addClass(this.prefs().slideGrabbingClass);
            }

            const originalEvent = event;

            event = event.changedTouches ? event.changedTouches[0] : event;

            // To prevent blocking of site scrolling
            const isSwipe = this.isSwipe(event.clientX, event.clientY);

            // eslint-disable-next-line spellcheck/spell-checker
            if (isSwipe && originalEvent.cancelable) {
                originalEvent.preventDefault();
                originalEvent.stopPropagation();
            }
        }

        /**
         * @description Autoplay initialization
         * @returns {void}
         */
        initAutoplay() {
            this.ref('autoplay').addClass(this.prefs().carouselInitialized);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayStopped' does not exist on type ... Remove this comment to see the full error message
            if (this.autoPlayStopped) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayPaused' does not exist on type '... Remove this comment to see the full error message
                this.autoPlayPaused = true;
                this.togglePlayButtonState();

                return;
            }

            this.startAutoplay(true);
            this.addAutoplayListeners();
            this.initIntersectionObserver();
        }

        /**
         * @description Starts autoplay functionality with automatic slides changing
         * @listens dom#click
         * @param {boolean} [isForce] - true if autoplay starts forced (go to slide with animation/timer restart)
         * @returns {void}
         */
        startAutoplay(isForce) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayPaused' does not exist on type '... Remove this comment to see the full error message
            if ((!this.autoPlayPaused && !isForce) || this.autoPlayStopped) { return; }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextSlideTimer' does not exist on type '... Remove this comment to see the full error message
            if (this.nextSlideTimer) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextSlideTimer' does not exist on type '... Remove this comment to see the full error message
                this.nextSlideTimer();
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayPaused' does not exist on type '... Remove this comment to see the full error message
            this.autoPlayPaused = false;

            this.ref('elemCarouselTrack').attr('aria-live', 'off');

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'creationTime' does not exist on type 'He... Remove this comment to see the full error message
            this.creationTime = Date.now();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'remainingTime' does not exist on type 'H... Remove this comment to see the full error message
            if (this.remainingTime === null) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'remainingTime' does not exist on type 'H... Remove this comment to see the full error message
                this.remainingTime = parseInt(this.prefs().autoplayDuration, 10); // force to access by value
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextSlideTimer' does not exist on type '... Remove this comment to see the full error message
            this.nextSlideTimer = timeout(this.goToNextSlide.bind(this), this.remainingTime);

            this.startDotAnimation();
            this.togglePlayButtonState();
        }

        /**
         * @description Pause autoplay functionality
         * @listens dom#click
         * @returns {void}
         */
        pauseAutoplay() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayPaused' does not exist on type '... Remove this comment to see the full error message
            if (this.autoPlayPaused) { return; }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayPaused' does not exist on type '... Remove this comment to see the full error message
            this.autoPlayPaused = true;
            this.ref('elemCarouselTrack').attr('aria-live', 'polite');

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextSlideTimer' does not exist on type '... Remove this comment to see the full error message
            if (this.nextSlideTimer) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextSlideTimer' does not exist on type '... Remove this comment to see the full error message
                this.nextSlideTimer();
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'remainingTime' does not exist on type 'H... Remove this comment to see the full error message
            if (this.remainingTime) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'remainingTime' does not exist on type 'H... Remove this comment to see the full error message
                this.remainingTime -= (Date.now() - (this.creationTime || 0));
            }

            this.pauseDotAnimation();
            this.togglePlayButtonState();
        }

        /**
         * @description Disable autoplay functionality
         * @returns {void}
         */
        endAutoplay() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextSlideTimer' does not exist on type '... Remove this comment to see the full error message
            if (this.nextSlideTimer) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextSlideTimer' does not exist on type '... Remove this comment to see the full error message
                this.nextSlideTimer();
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'remainingTime' does not exist on type 'H... Remove this comment to see the full error message
            this.remainingTime = null;

            this.removeDotAnimation();
        }

        /**
         * @description Stops/starts autoplay. It can be unstopped only by using this method again.
         * @listens dom#click
         * @returns {void}
         */
        togglePlay() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayPaused' does not exist on type '... Remove this comment to see the full error message
            if (this.autoPlayPaused) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayStopped' does not exist on type ... Remove this comment to see the full error message
                this.autoPlayStopped = false;

                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
                this.startAutoplay();
            } else {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayStopped' does not exist on type ... Remove this comment to see the full error message
                this.autoPlayStopped = true;
                this.pauseAutoplay();
            }
        }

        /**
         * @description Toggles autoplay button attributes according to state(started/stopped).
         * @returns {void}
         */
        togglePlayButtonState() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayPaused' does not exist on type '... Remove this comment to see the full error message
            if (typeof this.autoPlayPaused !== 'undefined') {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayPaused' does not exist on type '... Remove this comment to see the full error message
                this.ref('autoplay').attr('aria-pressed', this.autoPlayPaused.toString());
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoPlayPaused' does not exist on type '... Remove this comment to see the full error message
            if (this.autoPlayPaused) {
                this.ref('autoplay').attr('aria-label', this.prefs().autoplayLabelStart);
            } else {
                this.ref('autoplay').attr('aria-label', this.prefs().autoplayLabelStop);
            }
        }

        /**
         * @description Toggles autoplay button attributes according to state(started/stopped).
         * @returns {void}
         */
        startDotAnimation() {
            const autoplayBtn = this.ref('autoplay').get();

            if (!autoplayBtn) { return; }

            autoplayBtn.style.animationDuration = `${this.prefs().autoplayDuration}ms`;
            autoplayBtn.style.animationPlayState = 'running';

            timeout(() => this.ref('autoplay').addClass(this.prefs().autoplayAnimated), 100);
        }

        /**
         * @description Pauses dot animation.
         * @returns {void}
         */
        pauseDotAnimation() {
            const autoplayBtn = this.ref('autoplay').get();

            if (!autoplayBtn) { return; }

            autoplayBtn.style.animationPlayState = 'paused';
        }

        /**
         * @description Removes dot animation.
         * @returns {void}
         */
        removeDotAnimation() {
            this.ref('autoplay').removeClass(this.prefs().autoplayAnimated);
        }

        /**
         * @description Adds autoplay listeners
         * @returns {void}
         */
        addAutoplayListeners() {
            const track = this.ref('elemCarouselTrack').get();

            this.ev('click', this.togglePlay, this.ref('autoplay').get());
            this.ev('focusin', this.autoPlayFocusIn, track);
            this.ev('focusout', this.autoPlayFocusOut, track);
            this.ev('mouseover', this.autoPlayMouseOver, track);
            this.ev('mouseleave', this.autoPlayMouseLeave, track);
        }

        /**
         * @description Pauses the autoplay functionality when the slide is in focus.
         * @listens HeroCarousel#focusin
         * @returns {void}
         */
        autoPlayFocusIn() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFocused' does not exist on type 'HeroC... Remove this comment to see the full error message
            if (this.isFocused) { return; }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFocused' does not exist on type 'HeroC... Remove this comment to see the full error message
            this.isFocused = true;
            this.pauseAutoplay();
        }

        /**
         * @description Starts the autoplay functionality when the slide loses focus.
         * @listens HeroCarousel#focusout
         * @returns {void}
         */
        autoPlayFocusOut() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFocused' does not exist on type 'HeroC... Remove this comment to see the full error message
            this.isFocused = false;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHover' does not exist on type 'HeroCar... Remove this comment to see the full error message
            if (!this.isHover) {
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
                this.startAutoplay();
            }
        }

        /**
         * @description Pauses the autoplay functionality when the slide is in hover.
         * @listens HeroCarousel#mouseover
         * @returns {void}
         */
        autoPlayMouseOver() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHover' does not exist on type 'HeroCar... Remove this comment to see the full error message
            if (this.isHover) { return; }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHover' does not exist on type 'HeroCar... Remove this comment to see the full error message
            this.isHover = true;
            this.pauseAutoplay();
        }

        /**
         * @description Starts the autoplay functionality when the slide loses hover.
         * @listens HeroCarousel#mouseleave
         * @returns {void}
         */
        autoPlayMouseLeave() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHover' does not exist on type 'HeroCar... Remove this comment to see the full error message
            this.isHover = false;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFocused' does not exist on type 'HeroC... Remove this comment to see the full error message
            if (!this.isFocused) {
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
                this.startAutoplay();
            }
        }

        /**
         * @description Attach IntersectionObserver to carousel to pause autoplay in case if carousel is not visible
         * @returns {void}
         */
        initIntersectionObserver() {
            const carousel = this.ref('self').get();

            if (!carousel) { return; }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'HeroCa... Remove this comment to see the full error message
            this.observer = new IntersectionObserver(
                ([entry]) => this.autoPlayVisible(entry.isIntersecting),
                { threshold: [0, 1] }
            );

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'HeroCa... Remove this comment to see the full error message
            this.observer.observe(carousel);
        }

        /**
         * @description IntersectionObserver handler that pause/start autoplay depending of carousel visibility
         * @param {boolean} isVisible do carousel in viewport
         * @returns {void}
         */
        autoPlayVisible(isVisible) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHover' does not exist on type 'HeroCar... Remove this comment to see the full error message
            if (this.isHover || this.isFocused) { return; }

            if (isVisible) {
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
                this.startAutoplay();
            } else {
                this.pauseAutoplay();
            }
        }

        /**
         * @description Destroy IntersectionObserver in case of component destroy
         * @returns {void}
         */
        destroy() {
            const carousel = this.ref('self').get();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'HeroCa... Remove this comment to see the full error message
            if (this.observer && carousel) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'HeroCa... Remove this comment to see the full error message
                // eslint-disable-next-line spellcheck/spell-checker
                this.observer.unobserve(carousel);
            }
        }
    }

    return HeroCarousel;
}
