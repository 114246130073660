import IMask from 'imask';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
*/

/**
 * @param InputText Base widget for extending
 * @returns Maskable Input widget
 */
export default function (InputText: ReturnType<typeof import('./InputText').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class MaskableInput
     * @augments InputText
     * @classdesc Adds mask functionality to a field
     * [Plugin page]{@link https://imask.js.org}
     * @property {string} data-mask - Mask pattern for field
     * @property {string} [data-mask-type] - Mask type. Currently two values are possible - String (default) and RegExp
     */
    class MaskableInput extends InputText {
        mask: any;

        prefs() {
            return {
                maskType: '',
                mask: '',
                ...super.prefs()
            };
        }

        /**
         * @description Init mask plugin
         * @returns {void}
         */
        init() {
            super.init();

            this.initMask();
        }

        /**
         * @description Initialize mask plugin
         * @returns {void}
         */
        initMask() {
            const field = this.ref('field').get();

            if (!field) {
                return;
            }

            this.mask = IMask(field, {
                mask: this.getMask()
            });
        }

        /**
         * @description Update mask on widget change
         * @returns {void}
         */
        onRefresh() {
            // Ref element object could contain link to old element at this moment
            setTimeout(() => {
                if (this.mask) {
                    this.mask.destroy();
                }

                this.initMask();
            });
        }

        /**
         * @description Get mask for field
         * @returns {string|RegExp} Field mask
         */
        getMask() {
            let mask;

            switch (this.prefs().maskType) {
                case 'RegExp':
                    mask = new RegExp(this.prefs().mask);
                    break;
                default:
                    mask = this.prefs().mask;
            }

            return mask;
        }
    }

    return MaskableInput;
}
