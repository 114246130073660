import { RefElement } from 'widgets/toolbox/RefElement';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description PoqWebCheckout implementation
 * @param {Widget} Widget Base widget for extending
 * @returns PoqWebCheckout class
 */
export default function (Widget) {
    /**
     * @class PoqWebCheckout
     * @augments Widget
     */
    class PoqWebCheckout extends Widget {
        init() {
            this.poqSendConf();
        }

        poqSend(button: RefElement) {
            if (button.data('poqAction') && 'PoqWebCheckout' in window) {
                if (button.data('poqIsDeeplink')) {
                    // @ts-expect-error Property 'PoqWebCheckout' does not exist on type..
                    window.PoqWebCheckout.send('deeplink', {
                        customData:
                            {
                                link: button.data('poqLink'),
                                isPDF: button.data('poqIsPdf'),
                                isExternal: button.data('poqIsExternal'),
                                dismissPresenting: button.data('poqDismissPresenting')
                            }
                    });
                } else {
                    // @ts-expect-error Property 'PoqWebCheckout' does not exist on type..
                    window.PoqWebCheckout.send(button.data('poqAction'));
                }

                // @ts-expect-error Property 'PoqWebCheckout' does not exist on type.
                window.PoqWebCheckout.options({ debug: true });
            }
        }

        poqSendConf() {
            const completedObject = this.ref('poqCompletedObjectData')?.data('completed-object');

            if (completedObject) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'PoqWebCheckout' does not exist on type 'Window... Remove this comment to see the full error message
                window.PoqWebCheckout.send('paymentcompleted', completedObject);
            }
        }
    }

    return PoqWebCheckout;
}
