import { TWidget } from 'widgets/Widget';

/**
 * @description Base Disclosure implementation
 * @param Widget Base widget for extending
 * @returns BackToTop class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Disclosure
     * @augments Widget
     * @classdesc Accessibility [Disclosure component](https://www.w3.org/TR/wai-aria-practices/#disclosure).
     * @property {string} data-widget - Widget name `disclosure`
     * @property {string} data-show-on-init - If we need to show disclosure content on init
     * @property {string} data-show-on-refresh - If we need to show disclosure on refresh (for ex. after re-render parent widget)
     * @example <caption>Typical Disclosure widget markup</caption>
     * <div
     *     data-widget="disclosure"
     *     data-show-on-init="true"
     *     data-show-on-refresh="true"
     * >
     *     <button
     *         type="button"
     *         data-event-click="toggleContent"
     *         aria-expanded="false"
     *         aria-controls="coupon-form"
     *         data-ref="disclosureButton"
     *     >Open me</button>
     *     <div
     *         data-ref="disclosureContent"
     *         id="coupon-form"
     *         hidden="hidden"
     *         class="m-hide"
     *     >
     *         .... content
     *     </div>
     * </div>
     */
    class Disclosure extends Widget {
        prefs() {
            return {
                disclosureButton: 'disclosureButton',
                disclosureContent: 'disclosureContent',
                showOnInit: false,
                showOnRefresh: false,
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.hideContent();

            if (this.prefs().showOnInit) {
                this.showContent();
            }
        }

        /**
         * @description Toggles disclosure content
         * @listens dom#click
         * @returns {void}
         */
        toggleContent() {
            this[this.shown ? 'hideContent' : 'showContent']();
        }

        /**
         * @description Happens on widget refresh and is needed to handle updated state
         * @returns {void}
         */
        onRefresh() {
            this[this.prefs().showOnRefresh ? 'showContent' : 'hideContent']();
        }

        /**
         * @description Show disclosure content and update related attributes
         * @returns {void}
         */
        showContent() {
            this.ref('disclosureContent').show().attr('aria-hidden', 'false');
            this.ref('disclosureButton').attr('aria-expanded', 'true');
            this.shown = true;
        }

        /**
         * @description Hide disclosure content and update related attributes
         * @returns {void}
         */
        hideContent() {
            this.ref('disclosureContent').hide().attr('aria-hidden', 'true');
            this.ref('disclosureButton').attr('aria-expanded', 'false');
            this.shown = false;
        }
    }

    return Disclosure;
}
