/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

import { TWidget } from 'widgets/Widget';

/**
 * @description Base AlternativeImage implementation
 * @param Widget Base widget for extending
 * @returns Alternative image view widget
 */
export default function (Widget: TWidget) {
    /**
     * @class AlternativeImage
     * @augments Widget
     * @classdesc Alternative image component that mixed to ProductTile widget.
     * It implement possibility to show alternative product image on tile in form of hover slider (on desktop)
     * and carousel with swapping (on mobile). It designed to be as lightweight as possible to not blow up very
     * heavy PLP page.
     * For this reasons in mind alternative image do not stored into markup it added dynamically on user
     * actions (hover, scroll). As approach for proper image size fetch (viewport, pixels density etc) we
     * use front-end url generation. As base for it we use basic image size - the most proper size that browser
     * choose from proposed sources. To get this image we use `currentSrc` HTMLImageElement property.
     * @property {string} data-widget - Widget name `productTile`
     * @property {string} data-alt-image-url - alternative image src
     */
    class AlternativeImage extends Widget {
        /**
         * @description Prepare alternative image viewport dependent src.
         * The trick is get already defined by browser image from set and prepare same image for alt.
         * If project has completely different images on breakpoints this should be extended with
         * update image src on viewport change.
         * @returns {string} alternative image url
         */
        getAlternativeImageSrc() {
            const altImageUrl = this.ref('alternativeViewContainer').attr('data-alt-image-url');
            const baseImage = this.ref('tileImage').get();

            if (!(baseImage instanceof HTMLImageElement) || !altImageUrl) {
                return '';
            }

            const baseImageUrl = baseImage.currentSrc || baseImage.src;
            const baseImageUrlParsed = new URL(baseImageUrl);
            const disQueryString = baseImageUrlParsed.search;

            return altImageUrl + disQueryString;
        }

        /**
         * @description Prepare alternative image viewport dependent alternative text
         * @returns {string} alternative image url
         */
        getAlternativeImageText() {
            const alternativeImageText = this.ref('alternativeViewContainer').attr('data-alt-image-title');

            if (typeof alternativeImageText === 'string') {
                return alternativeImageText;
            } else {
                return '';
            }
        }

        /**
         * @description Insert img tag to alternative image picture container
         * @returns {void}
         */
        insertAlternativeImage() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'imageInserted' does not exist on type 'A... Remove this comment to see the full error message
            if (this.imageInserted) {
                return;
            }

            const alternativeImageSrc = this.getAlternativeImageSrc();

            if (!alternativeImageSrc) {
                return;
            }

            const altImageElement = document.createElement('img');

            altImageElement.src = alternativeImageSrc;
            altImageElement.alt = this.getAlternativeImageText();
            const alternativeImagePicture = this.ref('alternativeViewPicture').get(0);

            if (!alternativeImagePicture) {
                return;
            }

            alternativeImagePicture.append(altImageElement);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'imageInserted' does not exist on type 'A... Remove this comment to see the full error message
            this.imageInserted = true;
        }

        /**
         * @description Executes hover logic
         * @returns {void}
         */
        handleHover() {
            this.insertAlternativeImage();
        }

        /**
         * @description Executes scroll pagination logic + image insertion. We use intersection observer
         * to easily handle rtl direction.
         * Otherwise the same could be done with `track.scrollLeft > (track.offsetWidth / 2))`
         * @returns {void}
         */
        handleScroll() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'Altern... Remove this comment to see the full error message
            if (this.observer) {
                return;
            }

            const track = this.ref('tileImageLink').get();
            const altImage = this.ref('alternativeViewPicture').get(0);

            if (!track || !altImage) {
                return;
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'Altern... Remove this comment to see the full error message
            this.observer = new IntersectionObserver(this.markAlternativeImageActive.bind(this), {
                root: track,
                threshold: 0.5
            });

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'observer' does not exist on type 'Altern... Remove this comment to see the full error message
            this.observer.observe(altImage);
        }

        /**
         * @description Handles alternative images active state
         * @param {Array<IntersectionObserverEntry>} entries - intersection observer entries
         * @returns {void}
         */
        markAlternativeImageActive(entries) {
            if (!entries || !entries[0]) {
                return;
            }

            this.ref('alternativeViewContainer').toggleClass('m-alt_active', entries[0].isIntersecting);
            this.insertAlternativeImage();
        }
    }

    return AlternativeImage;
}
