// TODO: freeze loaded sections during update with aria-busy
// TODO: investigate proper implementation of aria-live region for updated sections
// TODO: keep track that focus stays on the same place during PLP update

import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputTextarea').default>} InputTextarea
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/ProcessButton').default>>} processButton
 */

/**
 * @description Base AddToCartMixin implementation
 * <br>This class is not intended to have a separate DOM representation.
 * @param ProductDetail Base widget for extending
 * @returns Add to Cart mixin widget
 */
export default function (ProductDetail: ReturnType<typeof import('widgets/product/ProductDetail').default>) {
    /**
     * @class AddToCartMixin
     * @augments ProductDetail
     * @property {string} data-text-network-error - network error text
     * @property {string} data-add-to-cart-label - add to cart label
     * @property {string} data-pid - product id
     * @property {boolean} data-show-minicart-on-product-add - Show minicart on product added to cart
     * @property {boolean} data-show-message-on-product-add - Show message on product added to cart
     * @property {boolean} data-show-alert-on-product-add - Show Global Alert on product added to cart
     */
    class AddToCartMixin extends ProductDetail {
        prefs() {
            return {
                pid: '',
                processingRequest: false,
                textNetworkError: 'Network Error',
                addToCartBtn: 'addToCart',
                classesBusy: 'm-busy',
                showMinicartOnProductAdd: false,
                showMessageOnProductAdd: true,
                showAlertOnProductAdd: true,
                backInStockBtn: 'disclosureButton',
                backInStockForm: 'disclosureContent',
                backInStockSubscriptionEnable: false,
                zeroWidthSpaceSymbol: '\u200B',
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         * @returns {void}
         */
        init() {
            super.init();
            this.showBackInStockButton();

            // TODO: Move stock property to widget preferences
            this.has(this.prefs().addToCartBtn, (element) => {
                const stock = element.data('stock');

                if (stock) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'stock' does not exist on type 'AddToCart... Remove this comment to see the full error message
                    this.stock = stock;
                }
            });

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
            this.spacesLength = 0;
        }

        /**
         * @description Allows to toggle action buttons on PDP based on the received state
         * @param {RefElement} addToCartBtn Add to cart button
         * @param {boolean} state Indicated toggle state
         */
        toggleActionButtons(addToCartBtn, state = false) {
            const backInStockBtn = this.ref(this.prefs().backInStockBtn);

            if (state) {
                addToCartBtn.hide();
                backInStockBtn.show();
            } else {
                addToCartBtn.show();
                backInStockBtn.hide();
            }
        }

        /**
         * @description Show message
         * @param {string} msg Message
         * @param {boolean} error Error flag
         */
        showCartMessage(msg, error = false) {
            this.getById(this.prefs().addToCartMsg, (addToCartMsg) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
                if (typeof this.spacesLength === 'undefined') {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
                    this.spacesLength = 0;
                }
                // It's needed to force render previously added success message. If we try to add the same product
                // several times, success message alert will not be announced by a screen reader.

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
                msg = msg.concat(Array(++this.spacesLength).join(this.prefs().zeroWidthSpaceSymbol));

                addToCartMsg.render('template', { msg, error }, addToCartMsg.ref('container')).then(() => addToCartMsg.show());
            });
        }

        /**
         * @description Hides add to cart button if the product is out of stock and notify me when available functionality is enabled
         * @returns {void}
         */
        showBackInStockButton() {
            if (this.prefs().backInStockSubscriptionEnable) {
                this.getById('addToCart', (addToCartBtn) => {
                    addToCartBtn.hide();
                    this.ref(this.prefs().backInStockBtn).show();
                });
            }
        }

        /**
         * @description Handles response from server once product added to cart
         * @emits "product.added.to.cart"
         * @param {any} response Add Product response
         */
        postAddProduct(response) {
            if (!response.error && response.cart) {
                this.emit('productadded');
                const cartModel = response.cart;

                cartModel.showMinicart = this.prefs().showMinicartOnProductAdd;
                this.eventBus().emit('product.added.to.cart', cartModel, this);

                if (this.prefs().showAlertOnProductAdd) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'addedtocart' does not exist on type '{ q... Remove this comment to see the full error message
                    const accessibilityAlert = this.prefs().accessibilityAlerts.addedtocart;

                    this.eventBus().emit('alert.show', {
                        accessibilityAlert
                    });
                }
            }

            if (response.error || this.prefs().showMessageOnProductAdd) {
                this.eventBus().emit('product.addedTocart.with.error', response);
                this.showCartMessage(response.message, response.error);
            }
        }

        /**
         * @description Handles add to cart button click
         * @param {processButton} button Add Product response
         * @returns {Promise<object|null>} Promise object represents server response for session continuation
         */
        addToCart(button) {
            if (this.prefs().processingRequest) {
                return Promise.resolve(null);
            }

            if (!this.prefs().readyToOrder) {
                this.showCartMessage(this.prefs().textSelectOptions, true);

                return Promise.resolve(null);
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'stock' does not exist on type 'AddToCart... Remove this comment to see the full error message
            if (this.stock && this.stock < this.prefs().selectedQuantity) {
                this.showCartMessage(this.prefs().textStockLimit, true);

                return Promise.resolve(null);
            }

            const selectedOptions = this.getSelectedProductOptions();
            const isSelectedOptionsValid = this.validateProductOptions(selectedOptions);

            if (!isSelectedOptionsValid) {
                return Promise.resolve(null);
            }

            this.setPref('processingRequest', true);
            button.startProcess();

            this.showProgressBar();

            const addToCartBtnPrefs = button.prefs();

            return submitFormJson(addToCartBtnPrefs.addToCartUrl, {
                pid: this.prefs().currentProductId || addToCartBtnPrefs.pid,
                quantity: this.prefs().selectedQuantity || addToCartBtnPrefs.selectedQuantity,
                options: JSON.stringify(selectedOptions),
                childProducts: this.prepareChildProducts(this.prefs().bundledProducts)
            })
                .then((response) => {
                    this.setPref('processingRequest', false);
                    button.stopProcess();
                    this.hideProgressBar();

                    this.postAddProduct(response);

                    return response;
                })
                .catch(() => {
                    this.setPref('processingRequest', false);
                    button.stopProcess();
                    this.hideProgressBar();

                    this.showCartMessage(this.prefs().textNetworkError, true);
                });
        }

        showProgressBar() {
            this.ref('self').addClass(this.prefs().classesBusy);
        }

        hideProgressBar() {
            this.ref('self').removeClass(this.prefs().classesBusy);
        }

        /**
         * @description Update Product View
         * @param {any} product productModel
         */
        updateProductView(product) {
            super.updateProductView(product);
            this.updateBackInStockState(true, false);
            // Hide backInStock content on update product view

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'hideContent' does not exist on type 'Add... Remove this comment to see the full error message
            this.hideContent();
            this.renderAddToCart(product);
        }

        /**
         * @description Render Add to Cart button
         * @param {object} product productModel
         * @returns {void}
         */
        renderAddToCart(product) {
            this.getById('addToCart', (addToCartBtn) => {
                if (!product.available && product.availability.isOutOfStock) {
                    addToCartBtn.setPref('isOutOfStock', true);

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setText' does not exist on type 'Widget'... Remove this comment to see the full error message
                    addToCartBtn.setText(this.prefs().outOfStockLabel);

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'disable' does not exist on type 'Widget'... Remove this comment to see the full error message
                    addToCartBtn.disable();

                    if (product.backInStockSubscription.isEnabled) {
                        this.toggleActionButtons(addToCartBtn, true);
                    }
                } else if (product.availability.isReachedLimit) {
                    this.getById(this.prefs().addToCartMsg, (addToCartMsg) => {
                        const msg = product.availability.messages[0];
                        const error = true;

                        addToCartMsg.render(
                            'template',
                            { msg, error },
                            addToCartMsg.ref('container')
                        ).then(() => addToCartMsg.show());
                    });
                    addToCartBtn.setPref('isOutOfStock', true);

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setText' does not exist on type 'Widget'... Remove this comment to see the full error message
                    addToCartBtn.setText(this.prefs()[this.prefs().update ? 'updateLabel' : 'addToCartLabel']);

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'disable' does not exist on type 'Widget'... Remove this comment to see the full error message
                    addToCartBtn.disable();
                    this.toggleActionButtons(addToCartBtn);
                } else {
                    addToCartBtn.setPref('isOutOfStock', false);

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setText' does not exist on type 'Widget'... Remove this comment to see the full error message
                    addToCartBtn.setText(this.prefs()[this.prefs().update ? 'updateLabel' : 'addToCartLabel']);

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'enable' does not exist on type 'Widget'.
                    addToCartBtn.enable();
                    this.toggleActionButtons(addToCartBtn);
                }
            });
        }

        /**
         * @description Shows/Hides BackInStock components based on passed parameters
         * @param {boolean} showForm shows/hides Back in Stock form
         * @param {boolean} showSuccessBlock shows/hides success message after form submitting
         * @returns {void}
         */
        updateBackInStockState(showForm, showSuccessBlock) {
            this.ref('disclosureContent').toggleClass('m-success', showSuccessBlock);
            this.ref('contentBlock').toggle(showForm);
            this.ref('successBlock').toggle(showSuccessBlock);
        }

        /**
         * @description Handles backInStock form response.Show success message
         * @returns {void}
         */
        handleBackInStockFormSubmit() {
            this.updateBackInStockState(false, true);
        }

        /*
         * @description Prepare products for AJAX call to the server
         * @param {Array} bundledProducts Array with bundled product  objects
         * @returns {string} JSON formatted array with bundled product objects
         */
        prepareChildProducts(bundledProducts) {
            let result = bundledProducts;

            result = result.map((bundledProduct) => {
                return {
                    pid: bundledProduct.id,
                    quantity: bundledProduct.selectedQuantity
                };
            });

            return JSON.stringify(result);
        }
    }

    return AddToCartMixin;
}
