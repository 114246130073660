/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputTextarea').default>} BaseInputTextarea
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Custom InputSelect implementation
 * @param {BaseInputTextarea} BaseInputTextarea Base InputSelect implementation
 * @returns {typeof InputTextarea} Input Select class
 */
export default function (BaseInputTextarea) {
    /**
     * @class InputTextarea
     * @augments BaseInputTextarea
     * @classdesc Custom Text input implementation.
     */
    class InputTextarea extends BaseInputTextarea {
        /**
         * @description On `input` InputTextarea logic. Gives a possibility to check how many symbols are entered
         * and restricts further input in case if max allowed characters reached.
         * @listens dom#input
         * @returns {void}
         */
        onInput() {
            super.onInput();

            this.ref('maxcounter').setText(String(this.ref('field').val().length));
        }
    }

    return InputTextarea;
}
