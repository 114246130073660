/**
 * @module debounce
 * @category widgets
 * @subcategory toolbox
 * @description Represents debounce component with next features:
 * 1. Debounce function
 * 2. Throttle function
 * @example <caption>Example of debounce module usage</caption>
 * import { debounce } from 'widgets/toolbox/debounce';
 *
 * this.ev('resize', debounce(this.handleResize.bind(this), 200), window);
 */

/**
 * @description Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds.
 * If `invokeLeading` is passed, trigger the function on the leading edge, instead of the trailing.
 * If `invokeTrailing` is passed, trigger the function on the trailing edge additionally.
 * @param callbackFunction - callback
 * @param delay - timeout duration
 * @param [invokeLeading] - executing the debounce without waiting
 * @param [invokeTrailing]
 * execute trailing function after timeout: could be used in combination with 'invokeLeading' parameter
 * to execute both functions - leading and trailing ones
 * @returns Debounce function
 */
export function debounce<T>(
    callbackFunction: (args0: T) => void,
    delay: number,
    invokeLeading = false,
    invokeTrailing = false
): (args0: T) => void {
    /**
     * @type {number|null}
     */
    let timeout: number | null;
    const executeTrailing = !invokeLeading || invokeTrailing;

    return function debounceInner(this: any, ...args) {
        // Leading function execution
        if (invokeLeading && !timeout) {
            callbackFunction.apply(this, args);
        }

        // Set timeout for trailing function execution
        if (executeTrailing) {
            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = window.setTimeout(() => {
                timeout = null;
                callbackFunction.apply(this, args);
            }, delay);
        }
    };
}

/**
 * @description Creates and returns a new, throttled version of the passed function, that,
 *  when invoked repeatedly, will only actually call the original function at most once per every
 * `wait` milliseconds. Useful for rate-limiting events that occur faster than you can keep up with.
 * By default, `throttle` will execute the function as soon as you call it for the first time, and,
 * if you call it again any number of times during the `wait` period,as soon as that period is over.
 * If you'd like to disable the leading-edge call, pass `{leading: false}`, and if you'd like to disable
 * the execution on the trailing-edge, pass `{trailing: false}`.
 * @param func Function to execute
 * @param wait Execution period
 * @param [options] Configurations
 * @param [options.leading] leading timeout
 * @param [options.trailing] trailing timeout
 * @returns Throttled version of passed function
 */
export function throttle(
    func: () => unknown,
    wait: number,
    options: { leading: boolean; trailing: boolean; } = { leading: false, trailing: false }
): () => unknown {
    /** @type {number|null} */
    let timeout: number | null;
    /** @type {any} */
    let context: any;
    /** @type {any} */
    let args: any;
    /** @type {any} */
    let result: any;

    let previous = 0;

    const later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;

        result = func.apply(context, args);

        if (!timeout) {
            context = null;
            args = null;
        }
    };

    return function (this: any) {
        const now = Date.now();

        if (!previous && options.leading === false) { previous = now; }

        const remaining = wait - (now - previous);

        context = this;
        // eslint-disable-next-line prefer-rest-params
        args = arguments;

        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }

            previous = now;
            result = func.apply(context, args);

            if (!timeout) {
                context = null;
                args = null;
            }
        } else if (!timeout && options.trailing !== false) {
            timeout = window.setTimeout(later, remaining);
        }

        return result;
    };
}
