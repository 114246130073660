import { errorFallbackMessage } from 'widgets/toolbox/ajax';

const APPLICATION_JSON = 'application/json';
const TEXT_HTML = 'text/html';
const CONTENT_TYPE = 'content-type';
const FAILED_FETCH = 'Failed to fetch';
const FORM_URL_ENCODED = 'application/x-www-form-urlencoded';

/**
 * @description Get fetch
 * @returns {Promise<object>} Promise whose internal state matches the provided promise
 */
function getFetch(): Promise<unknown[]> {
    const dependencies: Promise<unknown>[] = [];

    if (window.fetch) {
        dependencies.push(Promise.resolve(window.fetch));
    } else {
        dependencies.push(import(/* webpackChunkName: 'fetch' */ 'whatwg-fetch'));
    }

    if (window.AbortController) {
        dependencies.push(Promise.resolve(window.AbortController));
    } else {
        dependencies.push(import(/* webpackChunkName: 'fetch' */ 'yet-another-abortcontroller-polyfill'));
    }

    return Promise.all(dependencies);
}

/**
 * @description Handles response by type and status
 * @param response - response data
 * @param type - content type to handle
 * @returns result
 */
function handleResponse(response: Response, type: string = APPLICATION_JSON): Promise<any> {
    const contentType = response.headers.get(CONTENT_TYPE);

    if (response.ok) {
        if (contentType && contentType.includes(APPLICATION_JSON) && (type === APPLICATION_JSON)) {
            return response.json();
        }

        if (contentType && contentType.includes(TEXT_HTML) && (type === TEXT_HTML)) {
            return response.text();
        }
    } else if (response.status === 404 || response.status === 500) {
        return response.json().then(errorJson => {
            return Promise.reject(errorJson);
        });
    }

    return response.json().then(errorJson => {
        return Promise.reject(errorJson);
    });
}

/**
 * @description Handle errors when response failed for some reason
 * @param error - error object
 * @returns result
 */
function handleResponseError(error: Error): Record<string, unknown> {
    let message = '';

    switch (error.message) {
        case FAILED_FETCH:
            message = errorFallbackMessage;
            break;
        default:
            message = error.message;
            break;
    }

    return {
        error: message,
        message: message
    };
}

/**
 * @description Form submission handler
 * @param {string} url url of resource
 * @param {string} method typeof request
 * @param {object} headers request headers
 * @param {object} body params for POST request
 * @returns Fetching result promise
 */
export const submitCORS = (
    url: string,
    method = 'GET',
    headers: { [x: string]: string; } = {},
    body: { [key: string]: string; } = {}
): Promise<Response | Record<string, unknown>> => {
    return getFetch().then(() => {
        const requestObject: RequestInit = {
            mode: 'cors',
            cache: 'no-cache',
            redirect: 'follow',
            method: method || 'GET',
            headers: {
                'Content-Type': headers.contentType || FORM_URL_ENCODED,
                Accept: headers.accept || APPLICATION_JSON
            }
        };

        if (method !== 'GET') {
            requestObject.body = JSON.stringify(body);
        }

        return Promise.resolve(fetch(url, requestObject)).then(handleResponse).catch(handleResponseError);
    });
};
