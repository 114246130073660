let tokenName: string | null = null;
let tokenValue: string | null = null;
let tokenElement: HTMLElement | null = null;

/**
 * @category widgets
 * @subcategory toolbox
 * @module token
 * @description Module represents functionality to get/set token name and value
 * @example <caption>Example of teken updating from some module</caption>
 * import token from 'widgets/toolbox/token';
 * ...
 * return submitFormJson(this.prefs().sessionContinueUrl, {}).then((response) => {
 *     token.name = response.csrf.tokenName;
 *     token.value = response.csrf.token;
 *     this.closeModal();
 *     this.countdownShown = false;
 * });
 * ...
 */
export default {
    /**
     * @description Token name
     */

    get name(): string | null {
        if (!tokenName) {
            const element = this.element;

            if (element && element.dataset.tokenName) {
                tokenName = element.dataset.tokenName;
            }
        }

        return tokenName;
    },

    set name(value: string | null) {
        tokenName = value;
    },

    /**
     * @description Token value
     */
    get value(): string | null {
        if (!tokenValue) {
            const element = this.element;

            if (element && element.dataset.tokenValue) {
                tokenValue = element.dataset.tokenValue;
            }
        }

        return tokenValue;
    },

    set value(value: string | null) {
        tokenValue = value;
    },

    /**
     * @description HTML element with token data
     * @private
     */
    get element(): HTMLElement | null {
        if (!tokenElement) {
            tokenElement = document.getElementById('csrf-token-element');
        }

        return tokenElement;
    }
};
