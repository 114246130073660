/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description LinkedSubscription implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof LinkedSubscription} LinkedSubscription class
 */
export default function (Widget) {
    /**
     * @class LinkedSubscription
     * @augments Widget
     */

    class LinkedSubscription extends Widget {
        init() {
            super.init();
            this.eventBus().on('remove.product.line.item', 'showLinkedSubscription');
            this.eventBus().on('added.subscription.product.line.item', 'hideLinkedSubscription');
        }

        showLinkedSubscription(response) {
            this.getById('selectSubscriptionProduct', (subscriptionProduct) => {
                subscriptionProduct.unselectCheckbox();
            });

            if (this.prefs().linkedSubscriptionId && response.deletedProductId === this.prefs().linkedSubscriptionId.toString()) {
                this.show();
            }
        }

        hideLinkedSubscription() {
            this.hide();
            this.getById('selectSubscriptionProduct', (subscriptionProduct) => {
                subscriptionProduct.unselectCheckbox();
            });
        }
    }

    return LinkedSubscription;
}
