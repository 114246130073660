import { debounce } from 'widgets/toolbox/debounce';
import { pageProperties, report, getProductList } from '../utils/base';
/**
 * @description GTM impressions
 * @module impressions
 * @category widgets
 * @subcategory gtm/components
 */
export let impressionsDebounce = [];
const impressedProducts = [];

/**
 * @description Checks if product is already impressed
 * @param {string} id - product ID
 * @returns {boolean} result
 */
export function hasBeenImpressed(id) {
    return impressedProducts.some(impressionId => impressionId === id);
}

/**
 * @description Returns wishlist products impression data
 * @param {Array} products - products data
 * @returns {Array} result
 */
export function getWishlistImpressions(products) {
    const impressions = [];

    if (!products || !products.length) {
        return impressions;
    }

    for (let index = 0; index < products.length; index++) {
        const product = products[index];

        if (product && !hasBeenImpressed(product.id)) {
            impressions.push({

                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
                list: 'Wishlist',

                // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                position: product.position,
                ...product.gtmInfo
            });
        }
    }

    if (!impressions.length) {
        return impressions;
    }

    return impressions;
}

/**
 * @description Reports GTM promo impressions on page load
 * @returns {void}
 */
export function reportPromoImpressions() {
    let promoImpressions = Array.from(document.querySelectorAll('.js-promo-impression'));

    if (promoImpressions.length) {
        promoImpressions = promoImpressions.map(impressionEl => {
            try {
                const promoDetails = JSON.parse(/** @type {HTMLElement} */ ((impressionEl) as any).dataset.promoDetails);

                let clickEl = impressionEl;

                if (impressionEl.hasAttribute('hidden') && impressionEl.nextElementSibling) {
                    clickEl = impressionEl.nextElementSibling;
                }

                /**
                 * @description Tracks to dataLayer an information about click on promotion details
                 * @listens dom:click
                 */
                clickEl.addEventListener('click', () => {
                    report({ ecommerce: null });
                    report({
                        event: 'promotionClick',
                        ecommerce: {
                            promoClick: {
                                promotions: [promoDetails]
                            }
                        }
                    });
                }, { passive: true, once: true });

                return promoDetails;
            } catch (e) {
                setTimeout(() => {
                    throw e;
                }, 0);

                return false;
            }
        }).filter(Boolean);

        report({ ecommerce: null });
        report({
            event: 'promotionView',
            ecommerce: {
                promoView: {
                    promotions: promoImpressions
                }
            }
        });
    }
}

/**
 * @description Reports GTM product impressions on page load
 * @returns {void}
 */
export function reportProductImpressions() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmImpressions' does not exist on type '... Remove this comment to see the full error message
    if (window.gtmImpressions
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmImpressions' does not exist on type '... Remove this comment to see the full error message
        && window.gtmImpressions.ecommerce
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmImpressions' does not exist on type '... Remove this comment to see the full error message
        && window.gtmImpressions.ecommerce.impressions) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmImpressions' does not exist on type '... Remove this comment to see the full error message
        window.gtmImpressions.ecommerce.impressions.forEach(impression => {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'productPositions' does not exist on type... Remove this comment to see the full error message
            if (window.productPositions && window.productPositions[impression.productID]) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'productPositions' does not exist on type... Remove this comment to see the full error message
                impression.position = window.productPositions[impression.productID];

                if (window.location.search.indexOf('q=') > -1) {
                    impression.list = pageProperties.searchList;
                }
            }

            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            impressedProducts.push(impression.productID);
            delete impression.productID;
        });
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmImpressions' does not exist on type '... Remove this comment to see the full error message
        report(window.gtmImpressions);
    }
}

/**
 * @description Sends products impressions to dataLayer
 * @returns {void}
 */
export const reportImpression = debounce(() => {
    impressionsDebounce.forEach(impression => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        impressedProducts.push(impression.productID || impression.id);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'productID' does not exist on type 'never... Remove this comment to see the full error message
        delete impression.productID;
    });
    let listName;

    if (window.location.search.indexOf('q=') > -1) {
        listName = 'Search Result';
    } else if (window.contexts.includes('plp')) {
        listName = 'PLP';
    } else {
        listName = 'productCarousel';
    }

    const event = {
        event: 'productImpression',
        ecommerce: {
            currencyCode: pageProperties.currentCurrency,
            impressions: impressionsDebounce.map((impression, index) => {
                const impressionObj = {
                    list: listName,

                    // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                    ...impression
                };

                impressionObj.position = index;

                return impressionObj;
            })
        }
    };

    report({ ecommerce: null });
    report(event);
    impressionsDebounce = [];
}, 50);
