import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

/* global dataLayer */
/**
 * @description GTM base utils
 * @module base
 * @category widgets
 * @subcategory gtm/util
 */
export const pageProperties = {
    currentCurrency: '',
    searchList: 'Search Results'
};

/**
 * @description main method to push changes to dataLayer
 * @param  {...any} args - data object
 * @returns {void}
 */
export function report(...args) {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'dataLayer'.
    if (typeof dataLayer !== 'undefined') {
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'dataLayer'.
        dataLayer.push(...args);
    }
}

/**
 * @description Gets product list value
 * @param {refElement} link - product tile
 * @returns {string} result
 */
export function getProductList(link) {
    let list = '';

    if (window.location.search.indexOf('q=') > -1) {
        list = 'Search Result';
    } else if (window.contexts.includes('plp')) {
        list = 'PLP';
    }

    const itemList = link.data('list');

    switch (itemList) {
        case 'recommendation':
            list = 'productCarousel'; // TODO: handle attribute link.data('listType') for Recommendation Slot Title value
            break;
        default:
            break;
    }

    return list;
}

/**
 * @description Sets tile position on the PLP \ Search page
 * @param {refElement} tileInfo - product tile
 * @param {string} pid - product ID
 * @returns {void}
 */
export function setTilePosition(tileInfo, pid) {
    pid = pid || tileInfo.id;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'productPositions' does not exist on type... Remove this comment to see the full error message
    if (window.productPositions && window.productPositions[pid]) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'productPositions' does not exist on type... Remove this comment to see the full error message
        tileInfo.position = window.productPositions[pid];

        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmImpressions' does not exist on type '... Remove this comment to see the full error message
    } else if (window.gtmImpressions && window.gtmImpressions.ecommerce && window.gtmImpressions.ecommerce.impressions) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtmImpressions' does not exist on type '... Remove this comment to see the full error message
        const impression = window.gtmImpressions.ecommerce.impressions.find((gtmImpression) => {
            const productID = pid.toString();

            return gtmImpression.id === productID;
        });

        if (impression) {
            tileInfo.position = impression.position || '';
        }
    }
}

/**
 * @description Sends to dataLayer an information about product on product link click
 * @param {refElement} link - product link
 * @returns {void}
 */
export function trackProductClick(link) {
    const list = getProductList(link);
    const tileInfo = link.data('analytics');
    const pid = link.data('pid');

    if (!tileInfo) {
        return;
    }

    setTilePosition(tileInfo, pid);

    const gtmProductViewData = {
        productTilePosition: tileInfo.position,
        productTileList: list
    };

    localStorageWrapper.setItem('gtmProductViewData', JSON.stringify(gtmProductViewData));

    report({
        event: 'productClick',
        ecommerce: {
            click: {
                actionField: { list },
                products: [tileInfo]
            }
        }
    });
}
