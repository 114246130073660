/**
 * @module cssLoadChecker
 * @category widgets
 * @subcategory toolbox
 * @description
 * Util that used for checking if CSS was loaded.
 * Return Promise that resolved as soon as CSS loaded.
 * @example
 * cssLoadChecker.get().then(() => {
 *  do some staff after css load
 * });
 */
const CSS_SELECTOR = '.js-styles';

export default {
    /**
     * @description Get loading state of all link nodes
     * @returns {Promise} - loading state
     */
    get: function () {
        return Promise.all(
            Array.from(document.querySelectorAll(CSS_SELECTOR), link => this.getLoadedState(link))
        );
    },
    /**
     * @description Get loading state of particular link node
     * @param {object} css - link DOM node
     * @returns {Promise} - loading state
     */
    getLoadedState(css) {
        if (module.hot) { // This trick not work with hot reload
            return new Promise(resolve => setTimeout(() => resolve(true), 200));
        }

        return new Promise((resolve) => {
            if (css.media === 'all' || css.media === '') {
                try {
                    /**
                     * This trick is needed to be sure css are loaded across all browsers
                     * `document.styleSheets` is not suitable since FF changes the length prematurely
                     * Combined solution from https://www.phpied.com/when-is-a-stylesheet-really-loaded/
                     */
                    const cssRules = css.sheet.cssRules; // Only populated when css is loaded. This is cross-browser solution.

                    if (cssRules) {
                        resolve(true);
                    }
                } catch (e) {
                    css.addEventListener('load', resolve);
                    css.addEventListener('error', resolve);
                }
            } else {
                css.addEventListener('load', resolve);
                css.addEventListener('error', resolve);
            }
        });
    }
};
