import { TWidget } from 'widgets/Widget';

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof DismissBlock} DismissBlock widget
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class DismissBlock
     * @augments Widget
     * @classdesc DismissBlock widget used for display notification about using a cookie.
     * Allow dismissing the notification by handle customer's click on the Accept button
     * @property {string} data-widget - Widget name `dismissBlock`
     * @property {string} data-key - Key for window.sessionStorage to store header banner display status.
     * If value exists - header will not be shown.
     * @example <caption>Example of DismissBlock widget usage</caption>
     * <div
     *     data-widget="dismissBlock"
     *     data-key="hide_header_banner"
     * >
     *     ... widget markup
     *     <button data-event-click.once.prevent="dismiss">close</button>
     * </div>
     */
    class DismissBlock extends Widget {
        prefs() {
            return {
                classesEnabled: 'm-visible',
                key: 'hide_cookies_notification',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            super.init();

            const dismissBlockStatus = window.sessionStorage.getItem(this.prefs().key);

            if (!dismissBlockStatus || dismissBlockStatus === '0') {
                this.ref('self').addClass(this.prefs().classesEnabled);
            }
        }

        /**
         * @description Dismiss cookie notification
         * @listens dom#click
         * @returns {void}
         */
        dismiss() {
            this.ref('self').removeClass(this.prefs().classesEnabled);
            window.sessionStorage.setItem(this.prefs().key, '1');
        }
    }

    return DismissBlock;
}
