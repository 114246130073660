import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns ListAccessibility widget
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class ListAccessibility
     * @augments Widget
     * @classdesc Represents ListAccessibility component that implement Basic Accessibility requirements for list of elements.
     * Has next features:
     * 1. Define list of focusable elements inside current widget
     * 2. Allow set focus to item or current item
     * 3. Allow set focus to first/last item
     * 4. Allow set focus to next/previous item
     *
     * Widget should be not used as standalone widget, it should be mixed to other widgets (see {@tutorial WidgetsMixinsStrategy})
     */
    class ListAccessibility extends Widget {
        focusableItems: Array<InstanceType<TWidget>> = [];

        currentItem: InstanceType<TWidget> | undefined;

        firstItem: any;

        lastItem: any;

        prefs() {
            return {
                itemLink: 'itemLink',
                ...super.prefs()
            };
        }

        /**
         * @description Define Items
         * @param currentItem Current Item
         */
        defineItems(currentItem?: InstanceType<TWidget>): void {
            if (this.items) {
                this.focusableItems = [];
                this.focusableItems = this.items.filter((item) => {
                    return item.isShown() && 'focus' in item;
                });

                if (currentItem && this.focusableItems.includes(currentItem)) {
                    this.currentItem = currentItem;
                } else {
                    this.currentItem = this.focusableItems[0];
                }

                this.firstItem = this.focusableItems[0];
                this.lastItem = this.focusableItems[this.focusableItems.length - 1];
            }
        }

        /**
         * @description Set focus to item
         * @param item item
         */
        setFocusToItem(item?: InstanceType<TWidget>): void {
            if (item) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'focus' does not exist on type 'Widget'.
                item.focus();
                this.currentItem = item;
            }
        }

        /**
         * @description Set focus to current item
         */
        setFocusToCurrentItem(): void {
            this.setFocusToItem(this.currentItem);
        }

        /**
         * @description Set focus to first item
         */
        setFocusToFirstItem(): void {
            this.setFocusToItem(this.firstItem);
        }

        /**
         * @description Set focus to last item
         */
        setFocusToLastItem(): void {
            this.setFocusToItem(this.lastItem);
        }

        /**
         * @description Get item index
         * @param item item widget
         * @returns Item index
         */
        getItemIndex(item?: InstanceType<TWidget>): number {
            return this.focusableItems && item ? this.focusableItems.indexOf(item) : -1;
        }

        /**
         * @description Set focus to next item
         */
        setFocusToNextItem(): void {
            if (this.focusableItems) {
                const newItem = this.currentItem === this.lastItem
                    ? this.firstItem
                    : this.focusableItems[this.getItemIndex(this.currentItem) + 1];

                this.setFocusToItem(newItem);
            }
        }

        /**
         * @description Set focus to previous item
         */
        setFocusToPreviousItem(): void {
            if (this.focusableItems) {
                const newItem = this.currentItem === this.firstItem
                    ? this.lastItem
                    : this.focusableItems[this.getItemIndex(this.currentItem) - 1];

                this.setFocusToItem(newItem);
            }
        }

        /**
         * @description Set Focus
         */
        focus(): void {
            this.has(this.prefs().itemLink, (itemLink) => {
                itemLink.focus();
            });
        }
    }

    return ListAccessibility;
}
