/**
 * @description Base ConfirmDialog implementation
 * @param Modal Base Modal widget for extending
 * @returns Confirmation Dialog class
 */
export default function (Modal: ReturnType <typeof import('widgets/global/Modal').default>) {
    /**
     * @category widgets
     * @subcategory global
     * @class ConfirmDialog
     * @augments Modal
     * @classdesc Confirm dialog, which could be used to confirm an action in different contexts, like
     * - Remove coupon from the cart
     * - Remove product from the cart
     * Can have different markup, but serves 3 actions: `confirm`, `cancel` and `alternativeconfirm`
     * Emits 3 events: {@link ConfirmDialog#event:confirm}, {@link Modal#event:cancel} and {@link ConfirmDialog#event:alternativeconfirm} to notify parents about user triggered action.
     * @property {string} data-widget - Widget name `confirmDialog`
     * @property {string} data-widget-event-confirm - method to be triggered on parent's widget, once customer confirms dialog
     * @property {string} data-widget-event-alternativeConfirm - method to be triggered on parent's widget, once customer confirms dialog
     * @property {string} data-widget-event-cancel - method to be triggered on parent's widget, once customer cancels dialog
     * @example <caption>Example of Modal widget</caption>
     * <div hidden="hidden" data-id="confirmDialog"
     *     data-widget="confirmDialog"
     *     data-widget-event-confirm="confirmedRemoveProduct"
     *     data-widget-event-alternativeconfirm="moveProductToWishlist"
     *     data-widget-event-cancel="cancelledRemoveProduct"
     * >
     *     ... delete product confirm dialog contents
     *     <button type="button" data-event-click.prevent="confirm" data-dismiss="modal">
     *         ${Resource.msg('button.confirm','cart',null)}
     *     </button>
     *     <button type="button" data-event-click.prevent="alternativeconfirm" data-dismiss="modal">
     *         ${Resource.msg('button.movetowishlist','cart',null)}
     *     </button>
     *     <button type="button" data-event-click.prevent="cancel" data-dismiss="modal">
     *         ${Resource.msg('button.cancel','cart',null)}
     *     </button>
     * </div>
     */
    class ConfirmDialog extends Modal {
        /**
         * @description Confirm Handler
         * @emits ConfirmDialog#confirm
         * @returns {void}
         */
        confirm() {
            this.closeModal();
            /**
             * @description Event dispatched, when modal `confirm` button was pressed
             * @event ConfirmDialog#confirm
             */
            this.emit('confirm');
        }

        /**
         * @description alternativeConfirm Handler
         * @emits ConfirmDialog#alternativeconfirm
         * @returns {void}
         */
        alternativeConfirm() {
            this.closeModal();
            /**
             * @description Event dispatched, when modal alternative button (not `cancel` and not `confirm`) was pressed
             * @event ConfirmDialog#alternativeconfirm
             */
            this.emit('alternativeconfirm');
        }
    }

    return ConfirmDialog;
}
