/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicInput').default>} BasicInput
 */

/**
 * @description InputDate implementation
 * @param {BasicInput} BasicInput Base widget for extending
 * @returns {typeof InputDate} InputDate class
 */
export default function (BasicInput) {
    /**
     * @class InputDate
     * @augments BasicInput
     */
    class InputDate extends BasicInput {
        init() {
            super.init();

            this.setStartDate();
        }

        setStartDate() {
            const minDate = new Date().toISOString();
            const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString();

            this.ref('field').prop('min', minDate.slice(0, 10));
            this.ref('field').prop('max', maxDate.slice(0, 10));

            return this;
        }
    }

    return InputDate;
}
